import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';

export default function MessageModal({ openModal, closeModal, content }) {
    return (
        <div>
            <Dialog
                open={openModal}
                onClose={closeModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}} id="alert-dialog-description">
                        <Typography sx={{fontWeight: "bold"}}>{content ?? ""}</Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeModal} autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
