import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "components/textfield";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function SalesForceCRM({ credentials, onChange }) {
  const [username, setUsername] = useState(credentials?.username ?? "");
  const [password, setPassword] = useState(credentials?.password ?? "");
  const [clientId, setClientId] = useState(credentials?.clientId ?? "");
  const [clientSecret, setClientSecret] = useState(
    credentials?.clientSecret ?? "",
  );
  const [propertyId, setPropertyId] = useState(credentials?.propertyId ?? "");

  const [showClientSecret, setShowClientSecret] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const gridItemStyle = { paddingBottom: "1.3rem" };

  const handleTextFieldsChange = (e) => {
    const { name, value } = e.target;

    const credentials = {
      username: username,
      password: password,
      clientId: clientId,
      clientSecret: clientSecret,
      propertyId: propertyId,
    };

    if (name === "username") {
      setUsername(value);
      credentials.username = value;
    } else if (name === "password") {
      setPassword(value);
      credentials.password = value;
    } else if (name === "clientId") {
      setClientId(value);
      credentials.clientId = value;
    } else if (name === "clientSecret") {
      setClientSecret(value);
      credentials.clientSecret = value;
    } else if (name === "propertyId") {
      setPropertyId(value);
      credentials.propertyId = value;
    }

    onChange(credentials);
  };

  return (
    <Grid container paddingBottom='1rem'>
      <Grid item xs={3}></Grid>
      <Grid item xs={12} md={6} sx={gridItemStyle}>
        <TextField
          required
          name='username'
          label='Username'
          value={username}
          onChange={handleTextFieldsChange}
        />
      </Grid>
      <Grid item xs={3}></Grid>

      {/* ----------- */}

      <Grid item xs={3}></Grid>
      <Grid item xs={12} md={6} sx={gridItemStyle}>
        <TextField
          required
          name='password'
          label='Password'
          value={password}
          onChange={handleTextFieldsChange}
          type={showPassword ? "text" : "password"}
          rightIcon={showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
          onClickRightIcon={() => setShowPassword(!showPassword)}
        />
      </Grid>
      <Grid item xs={3}></Grid>

      {/* ----------- */}

      <Grid item xs={3}></Grid>
      <Grid item xs={12} md={6} sx={gridItemStyle}>
        <TextField
          required
          name='clientId'
          label='Client ID'
          value={clientId}
          onChange={handleTextFieldsChange}
        />
      </Grid>
      <Grid item xs={3}></Grid>

      {/* ----------- */}

      <Grid item xs={3}></Grid>
      <Grid item xs={12} md={6} sx={gridItemStyle}>
        <TextField
          required
          name='clientSecret'
          label='Client Secret'
          value={clientSecret}
          onChange={handleTextFieldsChange}
          type={showClientSecret ? "text" : "password"}
          rightIcon={
            showClientSecret ? <VisibilityIcon /> : <VisibilityOffIcon />
          }
          onClickRightIcon={() => setShowClientSecret(!showClientSecret)}
        />
      </Grid>
      <Grid item xs={3}></Grid>

      {/* ----------- */}

      <Grid item xs={3}></Grid>
      <Grid item xs={12} md={6}>
        <TextField
          required
          name='propertyId'
          label='Property ID'
          value={propertyId}
          onChange={handleTextFieldsChange}
        />
      </Grid>
      <Grid item xs={3}></Grid>
    </Grid>
  );
}

export default SalesForceCRM;
