import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "components/textfield";

function ResmanSystem({ credentials,company, community, onChange }) {
  const [accountId, setAccountId] = useState(credentials?.accountId ?? "");
  const [propertyId, setPropertyId] = useState(credentials?.propertyId ?? "");

  const handleTextFieldsChange = (e) => {
    const { name, value } = e.target;

    const credentials = {
      accountId: accountId,
      propertyId: propertyId,
    };

    if (name === "accountId") {
      setAccountId(value);
      credentials.accountId = value;
    } else if (name === "propertyId") {
      setPropertyId(value);
      credentials.propertyId = value;
    }

    onChange(credentials);
  };
  const useCompanyLevel = community?.community?.use_company_level_data_settings && company?.data_providers?.includes("resman")
  return (
    <Grid container paddingY='1rem'>
      <Grid item xs={3}></Grid>
        <Grid item xs={12} md={6} sx={{ marginBottom: "1.3rem" }}>
        { useCompanyLevel ? (null) : (
                <TextField
                    required
                    name='accountId'
                    label='Account ID'
                    value={accountId}
                    onChange={handleTextFieldsChange}
                />
           )
        }
        </Grid>
      <Grid item xs={3}></Grid>

      {/* ----------- */}

      <Grid item xs={3}></Grid>
      <Grid item xs={12} md={6}>
        <TextField
          required
          name='propertyId'
          label='Property ID'
          value={propertyId}
          onChange={handleTextFieldsChange}
        />
      </Grid>
      <Grid item xs={3}></Grid>
    </Grid>
  );
}

export default ResmanSystem;
