import React from "react";
import Box from "@mui/material/Box";
import RadioGroup from "@mui/material/RadioGroup";
import RadioButton from "components/radio-button";

function PropertyManagementTypeSection({ types, selectedType, onChange }) {
  return (
    <Box sx={{ marginTop: "-0.5rem", marginBottom: "0.5rem" }}>
      <RadioGroup
        row
        spacing={10}
        value={selectedType}
        onChange={onChange}
        sx={{ display: "flex", justifyContent: "space-evenly" }}
      >
        {types &&
          types.map((type, index) => (
            <RadioButton
              key={index}
              label={type}
              sx={{ marginRight: "1rem" }}
            />
          ))}
      </RadioGroup>
    </Box>
  );
}

export default PropertyManagementTypeSection;
