import React, { useCallback, useState, useEffect } from "react";
import Button from "components/button";
import Box from "@mui/material/Box";
import FormAlert from "components/form-alert";
import { FormApi } from "apis";
import Divider from "@mui/material/Divider";
import { useTheme } from "styled-components";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Container, StyledFooter, StyledSpinnerContainer } from "../style";
import AdditionalPagesFormHeader from "./header";
import _ from "lodash";
import AdditionalPagesFormWebPage from "./additional-pages-list/webpage";
import AdditionPageImagePage from "./image-page/index";
import FileUploadLoaderModal from "components/file_upload_popup-loader";

function AdditionalPagesForm({ expanded, checkStatus, closeForm }) {
  const theme = useTheme();
  const [fetchingSpecs, setFetchingSpecs] = useState(false);
  const [message, setMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [saveAndFinish, setSaveAndFinish] = useState(false);
  const [loading, setLoading] = useState(false)
  const [messageType, setMessageType] = useState("");
  const [additionalPages, setAdditionalPages] = useState({
    webPage: [],
    imagePage: []
  });

  const formAlertCloseButtonClicked = () => {
    setMessage("");
    setMessageType("");
  };


  const setMessageText = (text, type) => {
    setMessage(text);
    setMessageType(type)
    setTimeout(() => {
      formAlertCloseButtonClicked();
    }, 5000);
  };

  const { communityId } = useParams();

  const getMappedImages = useCallback((media) => {
    let images = [];
    if (media.length > 0) {
      media.forEach((image) => {
        images.push({
          id: image?.id,
          photo: image?.image?.url,
          type: 'image',
          isLocal: false,
        })
      })
    }
    return images;
  }, []);

  const getMappedFiles = useCallback((media) => {
    let files = [];
    if (media.length > 0) {
      media.forEach((file) => {
        if (file?.file?.url) {
          files.push({
            id: file?.id,
            file: file?.file?.url,
            type: 'file',
            isLocal: false,
          })
        }
      })
    }
    return files;
  }, []);

  const getMappedPagesForClient = useCallback((data) => {
    let webpages = [];
    let imagepages = [];
    if (data?.webpages?.length > 0) {
      data?.webpages.forEach((webpage) => {
        webpages.push({
          id: webpage?.id,
          name: webpage?.name,
          url: webpage?.url,
          isLocal: false,
          type: 'webpage',
        })
      })
    }
    if (data?.imagepages?.length > 0) {
      data?.imagepages.forEach((imagepage) => {
        imagepages.push({
          id: imagepage?.id,
          name: imagepage?.name,
          gallery: getMappedImages(imagepage?.media) ?? [],
          files: getMappedFiles(imagepage?.file) ?? [],
          isLocal: false,
          type: 'imagepage',
        })
      })
    }
    return {
      imagePage: imagepages,
      webPage: webpages,
    }
  }, [getMappedImages, getMappedFiles]);

  const fetchAdditionalPages = useCallback(() => {
    if (expanded) {
      if (communityId) {
        setFetchingSpecs(true);
        FormApi.fetchAdditionalPages(communityId).then((response) => {
          setFetchingSpecs(false);
          if (response.success) {
            let { data } = response;
            let getMappedData = getMappedPagesForClient(data);
            setAdditionalPages({
              webPage: getMappedData.webPage,
              imagePage: getMappedData.imagePage,
            });
          }
        }, (error) => {
          setFetchingSpecs(false);
        })
      }
    }
  }, [expanded, communityId, getMappedPagesForClient])

  const onDelete = (page, pageType) => {
    if (page.isLocal) {
      if (pageType === 'imagepage') {
        const imagePages = [...additionalPages.imagePage];
        const index = _.findIndex(imagePages, page);
        imagePages.splice(index, 1);
        setAdditionalPages({
          ...additionalPages,
          imagePage: imagePages,
        });
      } else if (pageType === 'webpage') {
        const webPages = [...additionalPages.webPage];
        const index = _.findIndex(webPages, page);
        webPages.splice(index, 1);
        setAdditionalPages({
          ...additionalPages,
          webPage: webPages,
        });
      }
    } else {
      if (communityId) {
        const successMessage = "Additional pages details deleted successfully!";
        const errorMessage = "Failed to delete Additional pages!";
        setSubmitting(true);
        FormApi.deleteAdditionalPages(communityId, page.id, pageType).then((response) => {
          setSubmitting(false);
          if (response.success) {
            let { data } = response;
            const getMappedData = getMappedPagesForClient(data);
            setAdditionalPages({
              webPage: getMappedData.webPage,
              imagePage: getMappedData.imagePage,
            });
            setMessageText(successMessage, "success");
            checkStatus();
          }
        }, (error) => {
          setSubmitting(false);
          setMessageText(errorMessage, "error");
        })
      }
    }
  }

  const updateFiles = (page, files) => {
    const imagePages = [...additionalPages.imagePage]
    const imagePagesIndex = _.findIndex(imagePages, page)

    imagePages[imagePagesIndex].gallery = files;
    setAdditionalPages({
      ...additionalPages,
      imagePage: imagePages,
    })
  }

  const removeFileButtonClicked = (webpage, file) => {
    const deleteImage = () => {
      const imagePages = [...additionalPages?.imagePage]
      const imagePagesIndex = _.findIndex(imagePages, webpage)
      const galleryList = [...imagePages[imagePagesIndex].gallery];
      const index = _.findIndex(galleryList, file);
      galleryList.splice(index, 1);
      imagePages[imagePagesIndex].gallery = galleryList;
      setAdditionalPages({
        ...additionalPages,
        imagePage: imagePages,
      });
    }
    const deleteFile = () => {
      const imagePages = [...additionalPages?.imagePage]
      const imagePagesIndex = _.findIndex(imagePages, webpage)
      const filesList = [...imagePages[imagePagesIndex].files];
      const index = _.findIndex(filesList, file);
      filesList.splice(index, 1);
      imagePages[imagePagesIndex].files = filesList;
      setAdditionalPages({
        ...additionalPages,
        imagePage: imagePages,
      });
    }
    if (file.isLocal) {
      if (file.type === "image") {
        deleteImage();
      } else {
        deleteFile();
      }
    } else {
      if (communityId) {
        setSubmitting(true);
        const successMessage = "Additional page image deleted successfully";
        const errorMessage = "Failed to delete additional page image";
        FormApi.deleteAdditionalPagesImage(communityId, file?.id, file?.type).then((response) => {
          setSubmitting(false);
          if (response.success) {
            let { data } = response;
            let getMappedData = getMappedPagesForClient(data);
            setAdditionalPages({
              webPage: getMappedData.webPage,
              imagePage: getMappedData.imagePage,
            });
            setMessageText(successMessage, "success");
            checkStatus();
          }
        }, (error) => {
          setSubmitting(false);
          setMessageText(errorMessage, "error");
        });
      }
    }
  };

  const canUserProceed = () => {
    let hasError = false;
    let webpageNames = [];
    let imagePageNames = [];
    if (additionalPages?.webPage?.length > 0) {
      additionalPages?.webPage.forEach((page) => {
        if (page?.name.trim() === "") {
          setMessageText("Please add valid webpage name!", "error");
          hasError = true;
          return false;
        }
        if (page?.url?.trim() === "") {
          setMessageText("Please add valid webpage url!", "error");
          hasError = true;
          return false;
        }
        if (webpageNames.includes(page?.name)) {
          setMessageText("Webpage name must be unique!", "error");
          hasError = true;
          return false;
        } else {
          webpageNames.push(page?.name);
        }
      })
    }
    if (additionalPages?.imagePage?.length > 0) {
      additionalPages?.imagePage.forEach((page) => {
        if (page?.name === "" || page?.name === null) {
          setMessageText("Please add valid imagepage name!", "error");
          hasError = true;
          return false;
        }
        if (imagePageNames.includes(page?.name)) {
          setMessageText("ImagePage name must be unique!", "error");
          hasError = true;
          return false;
        } else {
          imagePageNames.push(page?.name);
        }
      })
    }
    return !hasError;
  }

  const getMappedImagesForServer = media => {
    let images = [];
    if (media?.length > 0) {
      media.forEach((image) => {
        images.push({
          id: image?.isLocal ? null : image?.id,
          image: image?.isLocal ? image?.originalFile : null,
          type: 'image',
        })
      })
    }
    return images;
  }

  const getMappedFilesForServer = media => {
    let files = [];
    if (media?.length > 0) {
      media.forEach((file) => {
        files.push({
          id: file?.isLocal ? null : file?.id,
          file: file?.isLocal ? file?.originalFile : null,
          type: 'file',
        })
      })
    }
    return files;
  }

  const getMappedDataForServer = () => {
    let data = [];
    if (additionalPages?.webPage.length > 0) {
      additionalPages?.webPage.forEach((page) => {
        data.push({
          id: page?.isLocal ? null : page?.id,
          name: page?.name ?? "",
          url: page?.url,
          type: 'webpage'
        })
      })
    }
    if (additionalPages?.imagePage.length > 0) {
      additionalPages?.imagePage.forEach((page) => {
        data.push({
          id: page?.isLocal ? null : page?.id,
          name: page?.name ?? "",
          gallery: getMappedImagesForServer(page?.gallery),
          files: getMappedFilesForServer(page?.files),
          type: 'imagepage',
        });
      });
    }
    return data;
  }

  const onSubmitButtonClicked = () => {
    if (canUserProceed()) {
      setSubmitting(true);
      const data = getMappedDataForServer();
      if (communityId) {
        const successMessage = "Additional pages saved successfully!";
        const errorMessage = "Failed to save additional pages!";
        FormApi.addAdditionalPages(communityId, data).then((response) => {
          setSubmitting(false);
          if (response?.success) {
            let { data } = response;
            const getMappedData = getMappedPagesForClient(data);
            setAdditionalPages({
              webPage: getMappedData.webPage,
              imagePage: getMappedData.imagePage,
            });
            setMessageText(successMessage, "success");
            checkStatus();
          }
        }, (error) => {
          setSubmitting(false);
          setMessageText(errorMessage, "error");
        })
      }
    }
  }

  useEffect(() => {
    let abortController = new AbortController();
    fetchAdditionalPages(abortController);
    return () => {
      abortController.abort();
    }
  }, [fetchAdditionalPages]);

  if (fetchingSpecs) {
    return (
      <StyledSpinnerContainer>
        <CircularProgress size={20} />
      </StyledSpinnerContainer>
    );
  }

  const generateWebPage = () => {
    return {
      id: _.uniqueId(),
      name: "",
      url: "",
      isLocal: true,
      type: "webpage"
    }
  }

  const generateImagePage = () => {
    return {
      id: _.uniqueId(),
      name: "",
      gallery: [],
      isLocal: true,
      files: [],
      type: "imagepage",
    }
  }

  const handleAddWebPageButtonClick = () => {
    const additionalPagesList = [...additionalPages.webPage];
    let newWebPage = generateWebPage();
    additionalPagesList.push(newWebPage);
    setAdditionalPages({
      ...additionalPages,
      webPage: additionalPagesList
    });
  }

  const handleImagePageGalleryButtonClicked = () => {
    let imagePages = [...additionalPages?.imagePage]
    const newGallery = generateImagePage();
    imagePages.push(newGallery);
    setAdditionalPages({
      ...additionalPages,
      imagePage: imagePages,
    })
  }

  const uploadFiles = () => {
    const data = getMappedDataForServer();
    if (communityId) {
      setLoading(true);
      FormApi.addAdditionalPages(communityId, data, "in_progress").then((response) => {
        setLoading(false);
        if (response?.success) {
          let { data } = response;
          const getMappedData = getMappedPagesForClient(data);
          setAdditionalPages({
            webPage: getMappedData.webPage,
            imagePage: getMappedData.imagePage,
          });
          checkStatus();
        }
      }, (error) => {
        setLoading(false);
      })
    }
  }

  const onSaveAndSubmitButtonClicked = () => {
    if (canUserProceed()) {
      const data = getMappedDataForServer();
      if (communityId) {
        setSaveAndFinish(true);
        FormApi.addAdditionalPages(communityId, data, "in_progress").then((response) => {
          setSaveAndFinish(false);
          if (response?.success) {
            let { data } = response;
            const getMappedData = getMappedPagesForClient(data);
            setAdditionalPages({
              webPage: getMappedData.webPage,
              imagePage: getMappedData.imagePage,
            });
            checkStatus();
          }
        }, (error) => {
          setSaveAndFinish(false);
        })
      }
      closeForm();
    }
  }

  const updateName = (e, index) => {
    let webpage = additionalPages.webPage
    let webpageList = [...webpage];
    webpageList[index].name = e.target.value;
    setAdditionalPages({
      ...additionalPages,
      webPage: webpageList,
    })
  }

  const updateUrl = (e, index) => {
    let webpage = additionalPages.webPage
    let webpageList = [...webpage];
    webpageList[index].url = e.target.value;
    setAdditionalPages({
      ...additionalPages,
      webPage: webpageList,
    })
  }

  const updateImageMap = (imagePage) => {
    setAdditionalPages({
      ...additionalPages,
      imagePage: imagePage,
    })
    uploadFiles();
  }

  const updateImagePageName = (e, index) => {
    let imagePage = additionalPages.imagePage
    let imagePageList = [...imagePage];
    imagePageList[index].name = e.target.value;
    setAdditionalPages({
      ...additionalPages,
      imagePage: imagePageList,
    })
  }

  return (
    <Container>
      <AdditionalPagesFormHeader
        addImagePageClick={handleImagePageGalleryButtonClicked}
        addWebpageClick={handleAddWebPageButtonClick}
      />

      <Divider />

      {additionalPages?.imagePage?.length > 0 && (
        <AdditionPageImagePage
          imagePage={additionalPages?.imagePage}
          addImagePageClick={handleImagePageGalleryButtonClicked}
          onUpdate={updateImageMap}
          updateName={updateImagePageName}
          removeFile={removeFileButtonClicked}
          onDelete={onDelete}
          updateFiles={updateFiles}
        />
      )}

      {additionalPages?.imagePage?.length > 0 && additionalPages?.webPage.length > 0 && (
        <Divider />
      )}

      {additionalPages?.webPage.map((page, index) => (
        <AdditionalPagesFormWebPage
          key={page?.id}
          page={page}
          index={index}
          updateName={updateName}
          updateUrl={updateUrl}
          onDelete={onDelete}
        />
      )
      )}

      {message && messageType && (
        <Box>
          <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
          <FormAlert
            message={message}
            type={messageType}
            onClose={() => formAlertCloseButtonClicked()}
          />
        </Box>
      )}

      <StyledFooter>
        <Button
          bgColor={theme.colors.gray}
          titleColor={theme.colors.white}
          title='Save & Finish Later'
          loading={saveAndFinish}
          disabled={saveAndFinish}
          onClick={onSaveAndSubmitButtonClicked}
        />
        <Button bgColor={theme.colors.green} title='Submit' disabled={submitting}
          loading={submitting} onClick={onSubmitButtonClicked} />
      </StyledFooter>
      <FileUploadLoaderModal
        openModal={loading}
      />
    </Container>
  );
}

export default AdditionalPagesForm;
