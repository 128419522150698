import React from "react";
import Link from "@mui/material/Link";
import {
  StyledBox,
  StyledHeaderTitle,
} from "pages/client/client-dashboard-page/style";

function TitleLabels({ communityName }) {
  return (
    <StyledBox>
      <StyledHeaderTitle>
        Please go through each section below to complete your on-boarding.
      </StyledHeaderTitle>

      <StyledHeaderTitle>
        If you have any questions along the way, please email{" "}
        <Link>support@pynwheel.com</Link>
      </StyledHeaderTitle>
    </StyledBox>
  );
}

export default TitleLabels;
