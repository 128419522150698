import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { StyledAccordionHeader } from "../style";
import { StyledBox } from "../../files-list/style";
import { TitleAndStatusBox } from "../accordion-form-header/style";
import AccordionActionButtons from "../accordion-action-buttons";
import Button from "../../button";
import { useTheme } from "styled-components";
import UploadDataCredentialModal from "../../../pages/admin/admin-dashboard-page/upload_data_credential_modal";
import Paths from "../../../routes/paths";
import AccordionArrowButton from "../accordion-arrow-button";
import { CompanyDetailForm } from "components/forms";

function AccordionCompanyFormHeader({
  cId,
  title,
  expanded,
  onClickExpand,
  companySetting,
  company,
}) {
  const theme = useTheme();
  const [openUploadDataCredentialModal, setOpenUploadDataCredentialModal] =
    useState(false);

  const openUploadDataCredentialButtonClicked = (companyName) => {
    setOpenUploadDataCredentialModal(true);
  };

  return (
    <Box>
      <StyledAccordionHeader style={{ backgroundColor: "#e8e9e9" }}>
        <Box sx={{ flexGrow: 1 }}>
          <StyledBox>
            <TitleAndStatusBox>
              <Typography
                variant="h6"
                fontSize={20}
                sx={{ textAlign: "left", marginLeft: "2rem" }}
              >
                {title}
              </Typography>
            </TitleAndStatusBox>
          </StyledBox>
        </Box>
        {companySetting && (
          <Button
            bgColor={theme.colors.green}
            title="Upload Data Credentials"
            onClick={() => openUploadDataCredentialButtonClicked(title)}
          />
        )}
        {openUploadDataCredentialModal && (
          <UploadDataCredentialModal
            text={"successfully upload data credentials."}
            onClose={() => setOpenUploadDataCredentialModal(false)}
            cId={cId}
            company={company}
          />
        )}
        <AccordionArrowButton expanded={expanded} onClick={onClickExpand} />
      </StyledAccordionHeader>
    </Box>
  );
}

export default AccordionCompanyFormHeader;
