import { Grid } from '@mui/material'
import TextField from 'components/textfield'
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import React from 'react'

const AdditionalPagesFormWebPage = ({page, index, updateName, updateUrl, onDelete}) => {

  return (
    <Grid
    container
    spacing={1.5}
    marginTop={'0rem'}
    marginBottom = {'1rem'}
    >
      <Grid item md={0.4}>
      <Tooltip title='Delete Webpage'>
          <IconButton size='small' onClick={() => onDelete(page, page.type)}>
            <DeleteIcon fontSize='small' />
          </IconButton>
        </Tooltip>
      </Grid>
    <Grid item xs={12} md={5.8}>
        <TextField
            label='Name'
            value= {page?.name ?? ""}
            onChange = {(e) => {updateName(e, index)}}
        />
    </Grid>
    <Grid item xs={12} md={5.8}>
        <TextField
            label='Link'
            value={page?.url ?? ""}
            onChange={(e) => {updateUrl(e, index)}}
        />
    </Grid>
    </Grid>
  )
}

export default AdditionalPagesFormWebPage