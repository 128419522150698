import React from "react";
import Link from "@mui/material/Link";
import { StyledBox, StyledHeaderTitle } from "./style";

function TitleLabels() {
  return (
    <StyledBox>
      <StyledHeaderTitle>
        We are excited to work with you, please go through details of each
        property and complete your onboarding process.
      </StyledHeaderTitle>

      <StyledHeaderTitle>
        If you have any questions along the way, please email{" "}
        <Link>support@pynwheel.com</Link>
      </StyledHeaderTitle>
    </StyledBox>
  );
}

export default TitleLabels;
