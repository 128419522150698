import Box from "@mui/material/Box";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import FileUploadIcon from "@mui/icons-material/CloudUpload";

export const StyledFileDropZone = styled(Box)`
  display: flex;
  justify-content: center;
  padding: ${({ padding }) => (padding ? padding : "2rem")};
  background-color: ${({ disabled }) => (disabled ? "#D3D3D3" : "#949b9b")};
  border-radius: 0.3rem;
  cursor: pointer;
`;

export const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
`;

export const StyledFileUploadIcon = styled(FileUploadIcon)`
  color: ${({ theme }) => theme.colors.white};
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`;
