import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import moment from "moment";
import Paths from "routes/paths";
import Utils from "helpers/utils";
import { CommunityApi, FormApi } from "apis";
import FiltersBox from "./filters-box";
import TitleLabels from "./title-labels";
import { useHistory } from "react-router";
import CommentsModal from "./comments-modal";
import SettingsModal from "./settings-modal";
import { useTheme } from "styled-components";
import Container from "@mui/material/Container";
import MixConstants from "constants/mix-constants";
import CommunitiesList from "components/communities-list";
import TablePagination from "@mui/material/TablePagination";
import { PageMainContainer, CenteredBox } from "pages/style";
import CircularProgress from "@mui/material/CircularProgress";
import { SettingsImage, CommentImage, CommentDotImage } from "assests/images";
import SearchBox from "pages/client/client-dashboard-page/search-box";
import { useSelector, useDispatch } from "react-redux";
import communityFilterActions from "redux/actions/community_filter_actions";
import EmailPreviewModal from "./email_preview_modal";
import {StyledButtonSpinnerContainer, StyledFooter} from "components/forms/style";
import ConfirmModal from "./confirm_modal";
function AdminDashboardPage() {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalNoOfCommunities, setTotalNoOfCommunities] = useState(0);
  const [communities, setCommunities] = useState([]);
  const [fetchingCommunities, setFetchingCommunities] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [openComments, setOpenComments] = useState(false);
  const [selectedCommunity, setSelectedCommunity] = useState(null);
  const [openEmailPreviewModal, setOpenEmailPreviewModal] = useState(false);
  const [moveToProduction, setMovetoProduction] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [emailState, setEmailState] = useState({
    communityId: "",
    communityUserId: "",
    address: "",
    subject: "",
    body: "",
  });

  const productTypesList = [
    "All Products",
    "Pynwheel Tour",
    "Pynwheel Touch",
    "Pynwheel Maps"
  ];

  const statusesList = [
    "All Statuses",
    "Not Started",
    "In Progress",
    "100% Content Submitted",
    "Approved",
    "Submitted for Review",
    "Released",
    "Needs Attention",
  ];

  const filter = useSelector((state) => state.filter);

  const handleFollowUpEmails = (communityId, communityUserId) => {
    if (communityId && communityUserId) {
      FormApi.previewFollowUpEmails(communityId, communityUserId).then((response) => {
        if (response.success){
          setEmailState({
            communityId: communityId,
            communityUserId: communityUserId,
            email: response.email.emails,
            subject: response.email.subject,
            body: response.email.email.body.raw_source
          });
          setOpenEmailPreviewModal(true);
        }
      }, (error) => {
      });
    }
  }

  const handleSubmitForReviewEmailModal = (communityId, communityUserId) => {
    if (communityId && communityUserId) {
      FormApi.previewSubmitForReview(communityId, communityUserId).then((response) => {
        if (response.success){
          setEmailState({
            communityId: communityId,
            communityUserId: communityUserId,
            email: response.email.email,
            subject: response.email.subject,
            body: response.email.body.body.raw_source
          });
          setOpenEmailPreviewModal(true);
        }
      }, (error) => {
      });
    }
  }
  const handleMoveToProduction = useCallback((community, communityUserId, communitiesList, status, title) => {
    if (title ===  "Submit for Review"){
      handleSubmitForReviewEmailModal(community.id, communityUserId);
    }
    if (community && communityUserId) {
      setMovetoProduction(true);
      status = Utils.convertStatusForDatabase(status);
      FormApi.moveToProduction(community.id, communityUserId, status).then((response) => {
        setMovetoProduction(false);
        if (response.success){
          const {data} = response;
          let communityIndex;
          for (let i in communitiesList){
            if (communitiesList[i].id === community.id){
              communityIndex = i;
            }
            i++;
          }
          const getMappedData = getMappedProductsAndForms(data);
          communitiesList[communityIndex].progressSteps = getMappedData.progressSteps;
          communitiesList[communityIndex].products = getMappedData.products;
          communitiesList[communityIndex].submittedPercentage = getMappedData.submittedPercentage;
          communitiesList[communityIndex].approvedPercentage = getMappedData.approvedPercentage;
          setCommunities(communitiesList);
        }
      }, (error) => {
        setMovetoProduction(false);
      })
    }
  }, [])

  const formsToIgnoreForStatus = (form) => {
    return(
      form.name !== "Your Company Details" && form.name !== "Touchscreen Installation Details" && form.name !== "Additional Pages" && form.name !== "eBrochure" && form.name !== "Amenity Images" && form.name !== "Design Direction"
    )
  }

  const moveToProductionButtonActionButton = (item) => {
    let allStatus = [];
    if (item?.community_detail_forms?.length > 0) {
      item.community_detail_forms.map((form, index) => {
        if(formsToIgnoreForStatus(form)){
          const status = Utils.convertStatus(form.status)
          allStatus.push(status.name);
        }
        return []
      });
    }
    const formApproved = ((allStatus.filter(x => (x=== MixConstants.FormStatus.RELEASED || x=== MixConstants.FormStatus.APPLICATION_IN_REVIEW || x=== MixConstants.FormStatus.APPROVED) && (allStatus.includes(MixConstants.FormStatus.APPROVED))).length) / allStatus.length ) * 100;
    const applicationReview = ((allStatus.filter(x => (x=== MixConstants.FormStatus.APPLICATION_IN_REVIEW || x === MixConstants.FormStatus.RELEASED)).length) / allStatus.length ) * 100;
    const released = ((allStatus.filter(x => x=== MixConstants.FormStatus.RELEASED).length) / allStatus.length ) * 100;
    if (formApproved === 100 && allStatus.includes(MixConstants.FormStatus.APPROVED)){
      return {buttonTitle: "Submit for Review", status: MixConstants.FormStatus.UNDER_REVIEW, disabled: false}
    } else if (applicationReview === 100 && allStatus.includes(MixConstants.FormStatus.APPLICATION_IN_REVIEW)){
      return {buttonTitle: "Release", status: MixConstants.FormStatus.RELEASED, disabled: false};
    } else if (released === 100 && allStatus.includes(MixConstants.FormStatus.RELEASED)){
      return {buttonTitle: "Released", disabled: true};
    } else {
      return {buttonTitle: "Release", disabled: true}
    }
  }

  const getMappedProductsAndForms = (item) => {
    let products = item?.community_products;
    if (products?.length > 0) {
      products = products.map((p) => _.startCase(p)).join(", ");
    }
    let progressSteps = [];
    let allStatus = [];
    if (item?.community_detail_forms?.length > 0) {
      progressSteps = item.community_detail_forms.map((form, index) => {
        const status = Utils.convertStatus(form.status)
        if (form.name !== "Manage Your Stops for Pynwheel Tour App"){
          allStatus.push(status.name);
        }
        return {
          id: index,
          title: form.name,
          status: status,
          subTitle: "",
        };
      });
    }

    const submitted = ((allStatus.filter(x => x=== MixConstants.FormStatus.SUBMITTED || x=== MixConstants.FormStatus.APPROVED || x=== MixConstants.FormStatus.APPLICATION_IN_REVIEW || x=== MixConstants.FormStatus.RELEASED || x=== MixConstants.FormStatus.FORM_APPROVED).length) / allStatus.length ) * 100;
    const approved = ((allStatus.filter(x =>  x=== MixConstants.FormStatus.RELEASED || x=== MixConstants.FormStatus.FORM_APPROVED || x=== MixConstants.FormStatus.APPLICATION_IN_REVIEW || x=== MixConstants.FormStatus.APPROVED).length) / allStatus.length ) * 100;

    return {
      submittedPercentage: Math.floor(submitted),
      approvedPercentage: Math.floor(approved),
      progressSteps: progressSteps,
      products: products,
    };
  };

  const fetchCommunities = useCallback(
    (abortController) => {
      setCommunities([]);
      const openSettingsButtonClicked = (community) => {
        setOpenSettings(true);
        setSelectedCommunity(community);
      };

      const openCommentsButtonClicked = (community) => {
        setOpenComments(true);
        setSelectedCommunity(community);
      };

      setFetchingCommunities(true);
      let products = filter.selectedProductTypes.map((product) => {
        switch (product) {
          case "All Products":
            return "";
          case "Pynwheel Tour":
            return "self_tour";
          case "Pynwheel Touch":
            return "pynwheel_touch";
          case "Pynwheel Maps":
            return "pynwheel_maps";
          default:
            return "";
        }
      });

      let statuses = filter.selectedStatus.map((status) => {
        switch (status) {
          case "All Statuses":
            return "";
          case "Not Started":
            return "not_started";
          case "In Progress":
            return "in_progress";
          case MixConstants.FormStatus.APPLICATION_IN_REVIEW:
            return "in_review";
          case "100% Content Submitted":
            return "100_content_submitted";
          case "In Production":
            return MixConstants.FormStatus.IN_PRODUCTION;
          case "Application in Q/A":
            return MixConstants.FormStatus.APPLICATION_IN_QA;
          case "Approved":
            return "approved";
          case "Released":
            return MixConstants.FormStatus.RELEASED;
          case "Needs Attention":
            return MixConstants.FormStatus.NEEDS_ATTENTION;
          default:
            return "";
        }
      });

      CommunityApi.fetchCommunities(
        page + 1,
        rowsPerPage,
        searchQuery,
        products,
        statuses,
      ).then(
        (response) => {
          const aborted = abortController.signal.aborted;
          if (aborted === false) {
            setFetchingCommunities(false);
            const { data } = response;
            if (data && data.length > 0) {
              const communitiesList = data.map((item) => {
                const communityUserId = item?.id;
                const community = item?.community;
                const communityId = item?.community?.id;

                const comments = item?.community?.comments;
                let mappedProductsAndForms = getMappedProductsAndForms(item);
                const actionButtons = [
                  {
                    id: 1,
                    title: "User Portal View",
                    bgColor: theme.colors.green,
                    titleColor: theme.colors.white,
                    onClick: () =>
                      history.push(`${Paths.Client.COMMUNITY}/${communityId}`, {
                        params: {
                          communityUserId: communityUserId,
                        },
                      }),
                  },
                  {
                    id: 2,
                    title: "Send Follow Up Email",
                    bgColor: theme.colors.green,
                    titleColor: theme.colors.white,
                    onClick: () => {
                      handleFollowUpEmails(communityId, communityUserId);
                    },
                  },
                  {
                    id: 3,
                    title: moveToProduction ? (
                      <StyledButtonSpinnerContainer>
                        <CircularProgress size={15} />
                      </StyledButtonSpinnerContainer>
                    ) : moveToProductionButtonActionButton(item).buttonTitle,
                    bgColor: moveToProductionButtonActionButton(item).disabled ? theme.colors.lightGray : theme.colors.green,
                    titleColor: moveToProductionButtonActionButton(item).disabled ? theme.colors.black : theme.colors.white,
                    disabled: moveToProductionButtonActionButton(item).disabled,
                    onClick: () => {
                      handleMoveToProduction(community, communityUserId, communitiesList, moveToProductionButtonActionButton(item).status, moveToProductionButtonActionButton(item).buttonTitle)
                    },
                  },
                ];
                const iconActionButtons = [
                  {
                    id: 1,
                    title: "Settings",
                    bgColor: theme.colors.white,
                    titleColor: theme.colors.black,
                    endIcon: <img src={SettingsImage} alt='settings-icon' />,
                    onClick: () => openSettingsButtonClicked(community),
                  },
                  {
                    id: 2,
                    title: "Comments",
                    bgColor: theme.colors.white,
                    titleColor: theme.colors.black,
                    endIcon: (
                      <img
                        src={
                          comments.length > 0 ? CommentDotImage : CommentImage
                        }
                        alt='comment-icon'
                      />
                    ),
                    onClick: () => openCommentsButtonClicked(item),
                  },
                ];
                return {
                  userId: item?.id,
                  id: communityId,
                  title: `${item?.community?.name}`,
                  companyName: item?.company_details?.name,
                  company: item?.company_details,
                  invitationDate: moment(item?.invitation_date).format(
                    "D MMM YYYY",
                  ),
                  status: MixConstants.FormStatus.IN_PROGRESS,
                  actionButtons: actionButtons,
                  iconActionButtons: iconActionButtons,
                  progressSteps: mappedProductsAndForms.progressSteps,
                  products: mappedProductsAndForms.products,
                  productionStarted: item?.community?.production_started_date,
                  submittedForFinalApproval: item?.community?.submitted_final_approval_date,
                  releaseDate: item?.community?.released_date,
                  submittedPercentage: mappedProductsAndForms.submittedPercentage,
                  approvedPercentage: mappedProductsAndForms.approvedPercentage,
                };
              });
              setCommunities(communitiesList);
              setTotalNoOfCommunities(response?.meta?.total_entries);
            } else {
              setCommunities([]);
              setTotalNoOfCommunities(0);
            }
          }
        },
        (error) => {
          setFetchingCommunities(false);
        },
      );
    },
    [
      page,
      rowsPerPage,
      searchQuery,
      history,
      theme,
      filter.selectedProductTypes,
      filter.selectedStatus,
      handleMoveToProduction,
      moveToProduction
    ],
  );

  useEffect(() => {
    let abortController = new AbortController();
    fetchCommunities(abortController);
    return () => {
      abortController.abort();
    };
  }, [fetchCommunities]);

  const handleProductDropdownChange = (e, value) => {
    if (e.target.innerText === productTypesList[0]) {
      dispatch(communityFilterActions.updateProductList([productTypesList[0]]));
    } else {
      let values = [...value];
      const index = values.findIndex((v) => v === productTypesList[0]);

      if (index >= 0) {
        values.splice(index, 1);
      }
      if (values.length === 0) {
        values.push("All Products");
      }
      dispatch(communityFilterActions.updateProductList(values));
    }
  };

  const handleStatusDropdownChange = (e, value) => {
    if (e.target.innerText === statusesList[0]) {
      dispatch(communityFilterActions.updateStatusesList([statusesList[0]]));
    } else {
      let values = [...value];
      const index = values.findIndex((v) => v === statusesList[0]);

      if (index >= 0) {
        values.splice(index, 1);
      }
      if (values.length === 0) {
        values.push("All Statuses");
      }
      dispatch(communityFilterActions.updateStatusesList(values));
    }
  };

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const handleSettingChange = (settingCommunity, signal) => {
    if (settingCommunity) {
      let communityIndex;
      communities.map((community, index) => {
        if (community.id === settingCommunity.id) {
          communityIndex = index;
        }
        return [];
      });
      let communityList = [...communities];

      let selectedCommunity = communityList[communityIndex];
      FormApi.fetchCommunity(
        selectedCommunity.id,
        selectedCommunity.userId,
      ).then(
        (response) => {
          if (signal === false) {
            if (response?.data) {
              const data = response.data;
              if (data?.community_detail_forms?.length > 0) {
                let mappedProductsAndForms = getMappedProductsAndForms(data);
                communityList[communityIndex]["progressSteps"] =
                  mappedProductsAndForms.progressSteps;
                communityList[communityIndex]["submittedPercentage"] =
                  mappedProductsAndForms.submittedPercentage;
                communityList[communityIndex]["approvedPercentage"] =
                  mappedProductsAndForms.approvedPercentage;
                communityList[communityIndex]["products"] =
                  mappedProductsAndForms.products;

                setCommunities(communityList);
              }
            }
          }
        },
        (error) => {},
      );
    }
  };

  const handleSearchFieldChange = (e) => {
    setPage(0);
    setSearchText(e.target.value);

    if (e.target.value === "") {
      setSearchQuery("");
    }
  };

  return (
    <PageMainContainer sx={{ width: 1050 }}>
      <Container maxWidth='md'>
        <TitleLabels />
      </Container>

      <FiltersBox
        secondDropdownName='Product Type'
        secondDropdownValue={filter.selectedProductTypes}
        secondDropdownOptions={productTypesList}
        secondDropdownOnChange={handleProductDropdownChange}
        secondDropdownMultiple={true}
        thirdDropdownName='Current Status'
        thirdDropdownValue={filter.selectedStatus}
        thirdDropdownOptions={statusesList}
        thirdDropdownOnChange={handleStatusDropdownChange}
        thirdDropdownMultiple={true}
        onClickFilter={() => alert("Filter button pressed")}
      />

      <SearchBox
        label='Search by company / community name'
        onChange={handleSearchFieldChange}
        searching={searchQuery !== "" && fetchingCommunities}
        onClickSearchIcon={() => setSearchQuery(searchText)}
      />

      {communities.length > 0 && <CommunitiesList communities={communities}/>}

      {communities.length > 0 && totalNoOfCommunities > communities.length ? (
        <CenteredBox>
          <TablePagination
            component='div'
            count={totalNoOfCommunities}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 15, 20]}
          />
          <CenteredBox />
          {fetchingCommunities && <CircularProgress size={20} />}
        </CenteredBox>
      ) : fetchingCommunities ? (
        <CenteredBox>
          <CircularProgress size={20} />
        </CenteredBox>
      ) : communities.length === 0 ? (
        <CenteredBox>
          <p>No communities found</p>
        </CenteredBox>
      ) : null}
      {openEmailPreviewModal && (
        <EmailPreviewModal
          community={selectedCommunity}
          onClose={() => setOpenEmailPreviewModal(false)}
          emailSent= {setOpenConfirmationModal}
          email = {emailState}
          updateEmail = {setEmailState}
        />
      )}
      {openConfirmationModal && (
        <ConfirmModal
        text={"Email sent successfully"}
        onClose={() => setOpenConfirmationModal(false)}
        />
      )}
      {openSettings && (
        <SettingsModal
          community={selectedCommunity}
          onClose={() => setOpenSettings(false)}
          onSave={handleSettingChange}
        />
      )}
      {openComments && (
        <CommentsModal
          community={selectedCommunity}
          onClose={() => setOpenComments(false)}
        />
      )}
    </PageMainContainer>
  );
}

export default AdminDashboardPage;
