import React, { useCallback, useState, useEffect } from "react";
import { saveAs } from "file-saver";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Button from "components/button";
import Box from "@mui/material/Box";
import FormAlert from "components/form-alert";
import { FormApi } from "apis";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "components/textfield";
import Divider from "@mui/material/Divider";
import { useTheme } from "styled-components";
import ImageViewer from "components/image-viewer";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Container, StyledFooter, StyledSpinnerContainer } from "../style";
import FileGridItem from "components/files-grid-list/file-grid-item";
import ImageFileUploadContainer from "components/image-file-upload-container";
import { HardwareSpec2 } from "assests/images";
import MixConstants from "constants/mix-constants";

function HardwareSpecsAndInstallationForm({ expanded, checkStatus, status, closeForm}) {
  const theme = useTheme();
  const spacing = 2;
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [activePhotoIndex, setActivePhotoIndex] = useState(0);
  const [fetchingSpecs, setFetchingSpecs] = useState(false);
  const [saveAndFinsih, setSaveAndFinish] = useState(false);
  const [message, setMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [messageType, setMessageType] = useState("");

  const [state, setState] = useState({
    phone: "",
    name: "",
    image: null,
    showExampleImage: false,
  });

  const formAlertCloseButtonClicked = () => {
    setMessage("");
    setMessageType("");
  };

  const setNumberFormat = (e) => {
    let validator = /^[0-9,-]+$/
    if ((e.target.value.match(validator) && e.target.value.length <= 15) || e.target.value === ""){
      setState({
        ...state,
        phone: e.target.value,
      });
    }
  }

  const setMessageText = (text, type) => {
    setMessage(text);
    setMessageType(type)
    setTimeout(() => {
      formAlertCloseButtonClicked();
    }, 5000);
  };

  const exampleWallImage = {
    photo: HardwareSpec2,
    name: "Garden Style Community",
    type: "image",
    caption: "",
    subcaption: "",
  };
  const { communityId } = useParams();

  const wallImageExampleButtonClicked = () => {
    setIsGalleryOpen(true);

    setState({
      ...state,
      showExampleImage: true,
    });
  };

  const getMappedHardwareSpec = useCallback((hardwareSpecs) => {
    let image;
    if (hardwareSpecs?.image?.url) {
      image = {
        photo: hardwareSpecs?.image?.url,
        isLocal: false,
        type: "image",
      }
    }
    return {
      id: hardwareSpecs?.id,
      phone: hardwareSpecs?.phone,
      name: hardwareSpecs?.name,
      image: image?.photo ? image : null
    }
  }, [])

  const fetchPynwheelTouchHardwareSpec = useCallback(() => {
    if (expanded) {
      if (communityId) {
        setFetchingSpecs(true);
        FormApi.fetchPynwheelTouchHardwareSpec(communityId).then((response) => {
          setFetchingSpecs(false);
          if (response.success) {
            let { data } = response;
            let getMappedData = getMappedHardwareSpec(data);
            setState(getMappedData);
          }
        }, (error) => {
          setFetchingSpecs(false);
        })
      }
    }
  }, [expanded, communityId, getMappedHardwareSpec])

  const viewFileButtonClicked = (index) => {
    setIsGalleryOpen(true);
    setActivePhotoIndex(index);

    setState({
      ...state,
      showExampleImage: false,
    });
  };

  const resetState = () => {
    setState({
      ...state,
      image: null,
      name: "",
      phone: "",
      id: "",
    });
  }
  const onDelete = () => {
    if (communityId && state.id) {
      const successMessage = "Hardware installation details deleted successfully";
      const errorMessage = "Failed to delete hardware installation details";
      setSubmitting(true)
      FormApi.deletePynwheelTouchHardwareDetails(communityId).then((response) => {
        setSubmitting(false);
        if (response.success) {
          resetState();
          checkStatus();
          setMessageText(successMessage, "success");
        }
      }, (error) => {
        setSubmitting(false);
        setMessageText(errorMessage, "error");
      })
    } else {
      resetState();
    }
  }

  const removeFileButtonClicked = () => {
    if (state.image.isLocal) {
      setState({
        ...state,
        image: null,
      });
    } else {
      if (communityId) {
        setSubmitting(true);
        const successMessage = "Hardware specs image deleted successfully";
        const errorMessage = "Failed to delete hardware specs image";
        FormApi.deletePynwheelTouchHardwareSpec(communityId).then((response) => {
          setSubmitting(false);
          if (response.success) {
            setState({
              ...state,
              image: null,
            });
            if (checkStatus) {
              checkStatus();
            }
            setMessageText(successMessage, "success");
          }
        }, (error) => {
          setSubmitting(false);
          setMessageText(errorMessage, "error");
        });
      }
    }
  };

  const validFields = () => {
    let hasError =  false;
    return !hasError;
  }

  const canUserProceed = () => {
    let hasError = false;
    if (state.phone === "") {
      hasError = true;
      setMessageText("Please add cell phone number.", "error");
    }
    if (state.name === "") {
      hasError = true;
      setMessageText("Please add name.", "error");
    }
    if (!state?.image?.url === null) {
      hasError = true;
      setMessageText("Please add image.", "error");
    }
    return !hasError;
  }

  const onSubmitButtonClicked = () => {
    if (canUserProceed()) {
      setSubmitting(true);
      const data = {
        id: state.id,
        name: state.name,
        phone: state.phone,
        image: state?.image?.isLocal ? state?.image?.originalFile : null,
      }
      if (communityId) {
        const successMessage = "Hardware specs saved successfully";
        const errorMessage = "Failed to save hardware specs";
        FormApi.savePynwheelTouchHardwareSpec(communityId, data).then((response) => {
          setSubmitting(false);
          if (response?.success) {
            let { data } = response;
            const getMappedData = getMappedHardwareSpec(data);
            if (checkStatus) {
              checkStatus();
            }
            setState(getMappedData);
            setMessageText(successMessage, "success");
          }
        }, (error) => {
          setSubmitting(false);
          setMessageText(errorMessage, "error");
        })
      }
    }
  }

  const onSaveAndSubmitButtonClicked = () => {
    if (validFields()) {
      const data = {
        id: state.id,
        name: state.name,
        phone: state.phone,
        image: state?.image?.isLocal ? state?.image?.originalFile : null,
      }
      if (communityId) {
        setSaveAndFinish(true);
        FormApi.savePynwheelTouchHardwareSpec(communityId, data, "in_progress").then((response) => {
        setSaveAndFinish(false);
          if (response?.success) {
            let { data } = response;
            const getMappedData = getMappedHardwareSpec(data);
            if (checkStatus) {
              checkStatus();
            }
            setState(getMappedData);
          }
        }, (error) => {
        setSaveAndFinish(false);
        })
      }
    }
    closeForm();
  }

  const handleDroppedFile = (files) => {
    if (files && files.length > 0) {
      setState({
        ...state,
        image: files[0],
      });
    }
  };

  useEffect(() => {
    let abortController = new AbortController();
    fetchPynwheelTouchHardwareSpec(abortController);
    return () => {
      abortController.abort();
    }
  }, [fetchPynwheelTouchHardwareSpec]);

  if (fetchingSpecs) {
    return (
      <StyledSpinnerContainer>
        <CircularProgress size={20} />
      </StyledSpinnerContainer>
    );
  }

  return (
    <Container>
      <Grid
        container
        spacing={spacing}
        alignItems='center'
        marginTop='0rem'
        marginBottom='1rem'
      >
        <Box sx={{ width: "100%", textAlign: "right", mb: -5 }}>
          <Tooltip title='Delete Hardware Installation Details'>
            <IconButton size='small' onClick={onDelete}>
              <DeleteIcon fontSize='small' />
            </IconButton>
          </Tooltip>
        </Box>
        <Grid item xs={12} md={state.image ? 5.5 : 6}>
          <Typography sx={{ fontWeight: 500, mb: "10px" }}>
            On-Site Installation Contact
          </Typography>
          <Box sx={{ mb: "10px" }}>
            <TextField
              required
              name='name'
              value={state.name ? state.name : ""}
              label='Name'
              onChange={(e) => setState({
                ...state,
                name: e.target.value,
              })}
            />
          </Box>
          <Box sx={{ mb: "10px" }}>
            <TextField
              required
              name='phone'
              value={state.phone ? state.phone : ""}
              label='Phone'
              onChange={(e) => setNumberFormat(e)}
            />
          </Box>
          <Typography>
            Image of the wall where the touch screen is going to be installed
            with RECESSED power and ethernet outlet.
          </Typography>
          <Link onClick={() => wallImageExampleButtonClicked()}>Example</Link>
        </Grid>
        <Grid item xs={12} md={state.image ? 5 : 6}>
          <ImageFileUploadContainer
            padding='1rem'
            helperText=''
            onFileDrop={handleDroppedFile}
            maxFiles={1}
          />
        </Grid>
        {state.image && (
          <Grid item xs={12} md={1}>
            <FileGridItem
              file={state.image}
              onClickView={() => viewFileButtonClicked()}
              onClickDownload={() => saveAs(state.image?.photo)}
              onClickRemove={() => removeFileButtonClicked()}
            />
          </Grid>
        )}
      </Grid>

      <Divider />
      {message && messageType && (
        <Box>
          <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
          <FormAlert
            message={message}
            type={messageType}
            onClose={() => formAlertCloseButtonClicked()}
          />
        </Box>
      )}

      <StyledFooter>
        <Button
          bgColor={theme.colors.gray}
          titleColor={theme.colors.white}
          disabled={saveAndFinsih}
          loading={saveAndFinsih}
          title='Save & Finish Later'
          onClick={onSaveAndSubmitButtonClicked}
        />
        <Button bgColor={theme.colors.green} title='Submit' disabled={status !== MixConstants.FormStatus.DEPLOYED ? submitting : true}
          loading={submitting} onClick={onSubmitButtonClicked} />
      </StyledFooter>

      <ImageViewer
        show={isGalleryOpen}
        photos={state.showExampleImage ? [exampleWallImage] : [state.image]}
        activePhotoIndex={activePhotoIndex}
        onClose={() => setIsGalleryOpen(false)}
      />
    </Container>
  );
}

export default HardwareSpecsAndInstallationForm;
