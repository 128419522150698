import Box from "@mui/material/Box";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

export const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const StyledSearchBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const StyledHeaderTitle = styled(Typography)`
  font-size: 1.125rem;
  font-weight: 400;
  text-align: center;
`;
