import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "components/textfield";

function YardiRentCafeCRM({ credentials, onChange }) {
  const [marketingApiKey, setMarketingApiKey] = useState(
    credentials?.marketingApiKey ?? "",
  );
  const [propertyId, setPropertyId] = useState(credentials?.propertyId ?? "");
  const [propertyCode, setPropertyCode] = useState(
    credentials?.propertyCode ?? "",
  );

  const gridItemStyle = { paddingBottom: "1.3rem" };

  const handleTextFieldsChange = (e) => {
    const { name, value } = e.target;

    const credentials = {
      marketingApiKey: marketingApiKey,

      propertyId: propertyId,
      propertyCode: propertyCode,
    };

    if (name === "marketingApiKey") {
      setMarketingApiKey(value);
      credentials.marketingApiKey = value;
    } else if (name === "propertyId") {
      setPropertyId(value);
      credentials.propertyId = value;
    } else if (name === "propertyCode") {
      setPropertyCode(value);
      credentials.propertyCode = value;
    }

    onChange(credentials);
  };

  return (
    <Grid container paddingBottom='1rem'>
      <Grid item xs={3}></Grid>
      <Grid item xs={12} md={6} sx={gridItemStyle}>
        <TextField
          required
          name='marketingApiKey'
          label='Marketing API Key'
          value={marketingApiKey}
          onChange={handleTextFieldsChange}
        />
      </Grid>
      <Grid item xs={3}></Grid>

      <Grid item xs={3}></Grid>
      <Grid item xs={12} md={6} sx={gridItemStyle}>
        <TextField
          required
          name='propertyId'
          label='Property Id'
          value={propertyId}
          onChange={handleTextFieldsChange}
        />
      </Grid>
      <Grid item xs={3}></Grid>

      {/* ----------- */}

      <Grid item xs={3}></Grid>
      <Grid item xs={12} md={6}>
        <TextField
          required
          name='propertyCode'
          label='Property Code'
          value={propertyCode}
          onChange={handleTextFieldsChange}
        />
      </Grid>
      <Grid item xs={3}></Grid>
    </Grid>
  );
}

export default YardiRentCafeCRM;
