import { Stack, Typography } from '@mui/material';
import Button from 'components/button';
import { useTheme } from 'styled-components';
import React from 'react';
import { StyledHeaderSection } from '../style';


function AdditionalPagesFormHeader({ addWebpageClick, addImagePageClick }) {
    const theme = useTheme();

    return (
        <StyledHeaderSection>
            <Typography>
                Additional Pages (Optional)
            </Typography>
            <Stack spacing={1}>

            <Button
                title="Add Webpage"
                bgColor={theme.colors.green}
                onClick={addWebpageClick}
            />
            <Button
                title="Add Image Page"
                bgColor={theme.colors.green}
                onClick={addImagePageClick}
            />
            </Stack>
        </StyledHeaderSection>
    );
}


export default AdditionalPagesFormHeader