import Box from "@mui/material/Box";
import styled from "styled-components";
import { device } from "helpers/device";

export const Container = styled(Box)`
  padding: ${({ padding }) => (padding ? padding : "1rem")};
`;

export const CenteredBox = styled(Box)`
  display: flex;
  align-items: center;
`;

export const StyledHeaderSection = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  @media ${device.desktop} {
    align-items: flex-start;
    flex-direction: column;

    & > button {
      margin-top: 0.5rem;
    }
  }
`;

export const StyledFooter = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -1rem;

  & > button {
    margin: 0.8rem;
  }
  @media ${device.mobileS} {
    flex-direction: column;

    & > button {
      margin: 0.5rem;
    }
  }
`;

export const StyledButtonSpinnerContainer = styled(Box)`
  display: flex;
  justify-content: center;
  padding: 5px;
`;

export const StyledSpinnerContainer = styled(Box)`
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;
