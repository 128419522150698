import Box from "@mui/material/Box";
import styled from "styled-components";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

export const StyledProgressStep = styled(Box)`
  display: flex;
  align-items: center;
  padding: 0.3rem 1rem;
`;

export const StyledAvatar = styled(Avatar)`
  width: 1.3rem;
  height: 1.3rem;
  margin-right: 0.5rem;
`;

export const StyledTitle = styled(Typography)`
  font-size: 0.9rem;
  font-weight: 500;
`;

export const StyledStatus = styled(Typography)`
  color: ${({ color, theme }) => (color ? color : theme.colors.status)};
  margin-left: 0.5rem;

  font-size: 0.85rem;
  font-weight: 400;
`;

export const StyledSubTitle = styled(Typography)`
  color: ${({ color, theme }) => (color ? color : theme.colors.black)};
  font-size: 0.85rem;
  font-weight: 400;
`;
