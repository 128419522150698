import Box from "@mui/material/Box";
import styled from "styled-components";
import Avatar from "@mui/material/Avatar";

export const StyledBox = styled(Box)`
  width: 7rem;
  height: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledFileIcon = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  border-radius: 0.2rem;
  border: ${({ theme }) => `1px solid ${theme.colors.lightGray}`};
`;

export const StyledAvatar = styled(Avatar)`
  width: 5rem;
  height: 5rem;
  object-fit: contain;
  border: ${({ theme }) => `1px solid ${theme.colors.lightGray}`};
`;

export const StyledVideoBox = styled(Box)`
  position: absolute;
  width: 5rem;
  height: 5rem;
  opacity: 0.5;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const CenteredBox = styled(Box)`
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
