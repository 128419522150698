import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "components/textfield";

function YardiRentCafeSystem({ credentials,company, community, onChange }) {
  const [apiToken, setApiToken] = useState(credentials?.apiToken ?? "");
  const [propertyCode, setPropertyCode] = useState(
    credentials?.propertyCode ?? "",
  );
  const [companyCode, setCompanyCode] = useState(
    credentials?.companyCode ?? "",
  );

  const handleTextFieldsChange = (e) => {
    const { name, value } = e.target;
    
    const credentials = {
      apiToken: apiToken,
      propertyCode: propertyCode,
      companyCode: companyCode

    };

    if (name === "apiToken") {
      setApiToken(value);
      credentials.apiToken = value;
    } else if (name === "propertyCode") {
      setPropertyCode(value);
      credentials.propertyCode = value;
    }else if (name === "companyCode") {
      setCompanyCode(value);
      credentials.companyCode = value;
    }

    onChange(credentials);
  };

  useEffect(() => {
  }, [apiToken, propertyCode, companyCode])

  const useCompanyLevel = community?.community?.use_company_level_data_settings && company?.data_providers?.includes("yardirentcafe")

  return (
    <Box sx={{ paddingY: "1rem" }}>
      <Grid container>
        <Grid item xs={3}></Grid>
        {useCompanyLevel ? (null) : (
        <Grid item xs={12} md={6} sx={{ marginBottom: "1.3rem" }}>
          <TextField
            required
            name='apiToken'
            label='Vendor API Token'
            value={apiToken}
            onChange={handleTextFieldsChange}
          />
        </Grid>)}
        <Grid item xs={3}></Grid>
        
        {/* ----------- */}
        
        <Grid item xs={3}></Grid>
        {useCompanyLevel ? (null) : (
        <Grid item xs={12} md={6} sx={{ marginBottom: "1.3rem" }}>
          <TextField
            required
            name='companyCode'
            label='Company Code'
            value={companyCode}
            onChange={handleTextFieldsChange}
          />
        </Grid>)}
        <Grid item xs={3}></Grid>

        {/* ----------- */}

        <Grid item xs={3}></Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            name='propertyCode'
            label='Property Code'
            value={propertyCode}
            onChange={handleTextFieldsChange}
          />
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
    </Box>
  );
}

export default YardiRentCafeSystem;
