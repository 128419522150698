import React from "react";
import {
  StyledBox,
  StyledHeaderTitle,
} from "pages/client/client-dashboard-page/style";

function TitleLabels() {
  return (
    <StyledBox>
      <StyledHeaderTitle>
        Welcome to the new clients onboarding portal dashboard, you can view
        community progress and take required actions and follow-ups. Use filters
        to see the communities you are searching for.
      </StyledHeaderTitle>
    </StyledBox>
  );
}

export default TitleLabels;
