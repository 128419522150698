import React, { useState } from "react";
import { FormApi } from "apis";
import Box from "@mui/material/Box";
import Modal from "components/modal";
import { Container, Divider } from "@mui/material";
import TextField from "components/textfield";
import { useTheme } from "styled-components";
import FormAlert from "components/form-alert";
import { useIsMounted } from "hooks/use-is-mounted";
import TextEditor from "components/texteditor";
import LoggedInUserHelper from "helpers/loggedin-user-helper.js";

function LatchInviteModal({
  isOpen = true,
  onClose,
  state,
  setState,
  community,
}) {
  let user = LoggedInUserHelper.user();
  const initToEmail = "integrations@latch.com";
  const initFromEmail = user?.email;
  const initSubject = `Pynwheel - Latch OpenKit Request for ${community?.company_details?.name} ${community?.community?.name}`;
  const initBody = `<p>
                      Please enable Pynwheel for <strong> ${
                        community?.company_details?.name
                      } ${community?.community?.name}</strong>. 
                      <br/>
                      <br/>
                      Thank you,<br/>
                      ${user?.name || "User"}</p>`;

  const [sendingEmail, setSendingEmail] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [message, setMessage] = useState("");
  const [toEmail, setToEmail] = useState(initToEmail);
  const [fromEmail, setFromEmail] = useState(initFromEmail);
  const [subject, setSubject] = useState(initSubject);
  const [body, setBody] = useState(initBody);
  const [toEmailError, setToEmailError] = useState("");
  const [fromEmailError, setFromEmailError] = useState("");

  const theme = useTheme();
  const isMounted = useIsMounted();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const submitButtonClicked = () => {
    const errorMessage = "Error occurred while sending emails";

    if (!validateEmail(toEmail)) {
      setToEmailError("Invalid email address");
      return;
    }
    if (!validateEmail(fromEmail)) {
      setFromEmailError("Invalid email address");
      return;
    }

    if (community?.community?.id && LoggedInUserHelper.id()) {
      setSendingEmail(true);
      FormApi.sendLatchInviteEmail(
        community?.community?.id,
        toEmail,
        fromEmail,
        subject,
        body
      ).then(
        (response) => {
          setSendingEmail(false);
          setMessageText(
            response.message,
            response.success ? "success" : "error"
          );
          if (response.success) {
            onClose();
            setState((prevState) => ({
              ...prevState,
              latchCheckBoxOptions: prevState.latchCheckBoxOptions.map(
                (option, index) => {
                  if (index === 1) {
                    return { ...option, checked: true };
                  }
                  return option;
                }
              ),
            }));
          }
        },
        (error) => {
          setSendingEmail(false);
          setMessageText(errorMessage, "error");
        }
      );
    }
  };

  const actionButtons = [
    {
      id: 1,
      title: "Cancel",
      bgColor: theme.colors.lightGray,
      titleColor: theme.colors.white,
      disabled: sendingEmail,
      onClick: onClose,
    },
    {
      id: 2,
      title: "Send Email",
      bgColor: theme.colors.green,
      titleColor: theme.colors.white,
      loading: sendingEmail,
      disabled: sendingEmail,
      onClick: () => submitButtonClicked(),
    },
  ];

  const handleEmailSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleToEmailAddressChange = (e) => {
    setToEmail(e.target.value);
    setToEmailError(""); // Clear error message when user starts typing
  };

  const handleFromEmailAddressChange = (e) => {
    setFromEmail(e.target.value);
    setFromEmailError(""); // Clear error message when user starts typing
  };

  const handleEmailBodyChange = (e) => {
    setBody(e.target.value);
  };

  const setMessageText = (text, type) => {
    setMessage(text);
    setMessageType(type);

    setTimeout(() => {
      formAlertCloseButtonClicked();
    }, 5000);
  };

  const formAlertCloseButtonClicked = () => {
    if (isMounted.current) {
      setMessage("");
    }
  };

  return (
    <Modal
      open={isOpen}
      title={"Latch Invitation"}
      onClose={sendingEmail ? null : onClose}
      footerButtons={actionButtons}
    >
      <Container>
        <Box sx={{ paddingTop: "1rem" }}>
          <TextField
            label={"From Address"}
            multiline
            rows={1}
            disabled={sendingEmail}
            value={fromEmail}
            onChange={handleFromEmailAddressChange}
            error={fromEmailError !== ""}
            helperText={fromEmailError}
          />
        </Box>
        <Box sx={{ paddingTop: "1rem" }}>
          <TextField
            label={"To Address"}
            multiline
            rows={1}
            disabled={sendingEmail}
            value={toEmail}
            onChange={handleToEmailAddressChange}
            error={toEmailError !== ""}
            helperText={toEmailError}
          />
        </Box>
        <Box sx={{ paddingTop: "1rem" }}>
          <TextField
            label={"Subject"}
            multiline
            rows={1}
            disabled={sendingEmail}
            value={subject}
            onChange={handleEmailSubjectChange}
          />
        </Box>
        <Box sx={{ paddingTop: "1rem" }}>
          <TextEditor html={body} onChange={handleEmailBodyChange} />
        </Box>
      </Container>
      {message && messageType && (
        <Box>
          <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
          <FormAlert
            message={message}
            type={messageType}
            onClose={() => formAlertCloseButtonClicked()}
          />
        </Box>
      )}
    </Modal>
  );
}

export default LatchInviteModal;
