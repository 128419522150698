import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import Box from "@mui/material/Box";
import Button from "components/button";
import Checkbox from "components/checkbox";
import Divider from "@mui/material/Divider";
import { Grid } from "@mui/material";
import { useTheme } from "styled-components";
import FilesList from "components/files-list";
import PropertyTypeSection from "./property-type-section";
import MapImagesList from "components/files-list/map-images-list";
import FloorRangeInstructionsSection from "./floor-range-instructions-section";
import { useParams } from "react-router-dom";
import FormAlert from "components/form-alert";
import { FormApi } from "apis";
import CircularProgress from "@mui/material/CircularProgress";
import { Container, StyledFooter, StyledSpinnerContainer } from "../style";
import ConfirmChangePropertyTypeModal from "./confirm-change-property-modal";
import MixConstants from "constants/mix-constants";
import FileUploadContainer from "components/file-upload-container";
import Utils from "helpers/utils";

function PropertyMapImagesForm({ expanded, checkStatus, status, closeForm }) {
  const theme = useTheme();

  const [state, setState] = useState({
    selectedPropertyType: null,
    files: [],
    uploadLabeledImages: false,
  });
  const [fetching, setFetching] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const { communityId } = useParams();

  const [submitting, setSubmitting] = useState(false);
  const [finishLater, setFinishLater] = useState(false);
  const propertyTypes = [
    "Garden Style Community",
    "Mid-rise or High-rise Building(s)",
  ];
  const [openPorpertyMapChangeModal, setOpenPorpertyMapChangeModal] =
    useState(false);

  const getPropertyType = useCallback((type) => {
    if (type === "Garden Style Community") {
      return "garden_style_community";
    }
    if (type === "garden_style_community") {
      return "Garden Style Community";
    }
    if (type === "Mid-rise or High-rise Building(s)") {
      return "mid_high_rise_community";
    }
    if (type === "mid_high_rise_community") {
      return "Mid-rise or High-rise Building(s)";
    }
  }, []);

  const getMappedImages = useCallback((propertyMaps) => {
    let propertyMap = [];
    let labelImages = false;
    if (propertyMaps?.garden_style_community) {
      if (propertyMaps?.garden_style_community?.label_image?.url !== null) {
        labelImages = true;
      }
      if (propertyMaps?.garden_style_community?.image?.url) {
        propertyMap.push({
          ...propertyMaps?.garden_style_community?.image,
          id: propertyMaps?.garden_style_community.id,
          photo: propertyMaps?.garden_style_community?.image?.url,
          isLocal: false,
          file_type: "image",
          type: "Garden Style Community",
          additionalImage:
            propertyMaps?.garden_style_community?.label_image?.url !== null
              ? propertyMaps?.garden_style_community?.label_image
              : null,
        });
      }
      if (propertyMaps?.garden_style_community?.file?.url) {
        propertyMap.push({
          ...propertyMaps?.garden_style_community?.file,
          id: propertyMaps?.garden_style_community.id,
          photo: propertyMaps?.garden_style_community?.file?.url,
          isLocal: false,
          type: "Garden Style Community",
          additionalImage:
            propertyMaps?.garden_style_community?.label_image?.url !== null
              ? propertyMaps?.garden_style_community?.label_image
              : null,
        });
      }
    }
    if (
      propertyMaps?.mid_high_rise_community &&
      propertyMaps?.mid_high_rise_community?.length > 0
    ) {
      propertyMaps.mid_high_rise_community.map((maps) => {
        if (maps.label_image?.url !== null) {
          labelImages = true;
        }
        if (maps?.image?.url) {
          propertyMap.push({
            ...maps,
            id: maps.id,
            photo: maps.image?.url,
            isLocal: false,
            range: maps.range,
            name: maps.name,
            file_type: "image",
            type: "Mid-rise or High-rise Building(s)",
            additionalImage:
              maps?.label_image?.url !== null ? maps.label_image : null,
          });
        } else {
          propertyMap.push({
            ...maps,
            id: maps.id,
            photo: maps.file?.url,
            isLocal: false,
            range: maps.range,
            name: maps.name,
            type: "Mid-rise or High-rise Building(s)",
            additionalImage:
              maps?.label_image?.url !== null ? maps.label_image : null,
          });
        }
        return [];
      });
    }
    return {
      files: propertyMap,
      uploadLabeledImages: labelImages,
    };
  }, []);

  const formAlertCloseButtonClicked = () => {
    setMessage("");
  };

  const setMessageText = (text, type) => {
    formAlertCloseButtonClicked();
    setMessage(text);
    setMessageType(type);
    setTimeout(() => {
      formAlertCloseButtonClicked();
    }, 5000);
  };

  const fetchingPropertyMap = useCallback(
    (abortController) => {
      if (expanded && communityId) {
        setFetching(true);
        FormApi.fetchPropertyMap(communityId).then(
          (response) => {
            const aborted = abortController.signal.aborted;
            if (aborted === false) {
              setFetching(false);
              if (response?.success) {
                let fetchedPropertyMap = getMappedImages(response.data);
                setState({
                  selectedPropertyType: getPropertyType(
                    Object.keys(response.data)[0],
                  ),
                  files: fetchedPropertyMap.files,
                  uploadLabeledImages: fetchedPropertyMap.uploadLabeledImages,
                });
              }
            }
          },
          (error) => {
            setFetching(false);
          },
        );
      }
    },
    [expanded, communityId, getMappedImages, getPropertyType],
  );

  const handleDroppedFiles = (files) => {
    if (state.selectedPropertyType === propertyTypes[0]) {
      const filesWithLabeledImage = files.map((file) => {
        return {
          ...file,
          additionalImage: null,
        };
      });
      setState({
        ...state,
        files: _.union(state.files[0], filesWithLabeledImage),
      });
    } else {
      const filesWithLabeledImage = files.map((file) => {
        return {
          ...file,
          additionalImage: null,
        };
      });
      setState({
        ...state,
        files: _.union(state.files, filesWithLabeledImage),
      });
    }
  };

  const handleFilesListUpdate = (updatedFilesList) => {
    setState({
      ...state,
      files: updatedFilesList,
    });
  };

  const handleProperyMapDeleted = (deletedPropertymap) => {
    const deleteMessage = () => {
      const index = _.findIndex(state.files, deletedPropertymap);
      let propertyMaps = [...state.files];
      propertyMaps.splice(index, 1);
      setState({
        ...state,
        files: propertyMaps,
      });
    };
    let successMessage = "Property map deleted successfully";
    let errorMessage = "Failed to delete property map";
    setSubmitting(true);
    let type = getPropertyType(deletedPropertymap.type);
    FormApi.deletePropertyMap(communityId, deletedPropertymap.id, type).then(
      (response) => {
        if (response?.success) {
          setMessageText(successMessage, "success");
          setSubmitting(false);
          checkStatus();
          deleteMessage();
        }
      },
      (error) => {
        setMessageText(errorMessage, "error");

        setSubmitting(false);
      },
    );
    setSubmitting(false);
  };

  const handleLabelImageDeleted = (deletedPropertymap, index) => {
    const deleteMessage = () => {
      let filesList = [...state.files];
      filesList[index].additionalImage = null;
      setState({
        ...state,
        files: filesList,
      });
    };
    let successMessage = "Label image deleted successfully";
    let errorMessage = "Failed to delete label image";
    setSubmitting(true);
    let type = getPropertyType(deletedPropertymap.type);
    FormApi.deleteLabelImage(communityId, deletedPropertymap.id, type).then(
      (response) => {
        if (response?.success) {
          setMessageText(successMessage, "success");
          setSubmitting(false);
          checkStatus();
          deleteMessage();
        }
      },
      (error) => {
        setMessageText(errorMessage, "error");

        setSubmitting(false);
      },
    );
    setSubmitting(false);
  };

  const canUserProceed = () => {
    let hasError = false;
    if (state.selectedPropertyType === null) {
      setMessageText("Please select property type before submitting.", "error");
      hasError = true;
      return false;
    }
    if (state?.files?.length === 0) {
      setMessageText(
        `Please add atleast one image for ${state.selectedPropertyType}`,
        "error",
      );
      hasError = true;
      return false;
    }
    if (state.selectedPropertyType === propertyTypes[0]) {
      if (state?.files?.length > 1) {
        setMessageText(
          "Select only one image for garden style property",
          "error",
        );
        hasError = true;
        return false;
      }
      if (state?.files?.length < 1) {
        setMessageText("Select one image for garden style property", "error");
        hasError = true;
        return false;
      }
    }
    if (state.selectedPropertyType === propertyTypes[1]) {
      let floorName = [];
      let floorRange = [];
      state.files.map((file) => {
        if (!file.name) {
          setMessageText("Floor name can not be empty ", "error");
          hasError = true;
          return false;
        }
        if (!file.range) {
          setMessageText("Floor range can not be empty ", "error");
          hasError = true;
          return false;
        }
        if (floorName.includes(file.name)) {
          setMessageText("Floor name must be unique. ", "error");
          hasError = true;
          return false;
        } else {
          floorName.push(file.name);
        }
        // eslint-disable-next-line no-useless-escape
        if (!file?.range?.match(/^[0-9-,\[\]]*$/g)) {
          setMessageText("Floor range should be a number ", "error");
          hasError = true;
          return false;
        } else {
          if (file.range.includes("-")) {
            const values = file.range.split("-");
            for (let i = parseInt(values[0]); i <= parseInt(values[1]); i++) {
              if (floorRange.includes(i)) {
                setMessageText("Floor range must be unique ", "error");
                hasError = true;
                return false;
              } else {
                floorRange.push(i.toString());
              }
            }
          } else {
            if (floorRange.includes(file.range)) {
              setMessageText("Floor range must be unique ", "error");
              hasError = true;
              return false;
            } else {
              floorRange.push(file.range);
            }
          }
        }

        return [];
      });
    }

    return !hasError;
  };

  const onSubmitButtonClicked = () => {
    if (canUserProceed()) {
      let sentData;
      let data;

      if (state.selectedPropertyType === propertyTypes[0]) {
        data = state.files.map((image) => {
          return {
            id: !image.isLocal ? image.id : null,
            image:
              image.isLocal && Utils.isImageVideo(image)
                ? image?.originalFile
                : null,
            file:
              image.isLocal && !Utils.isImageVideo(image)
                ? image?.originalFile
                : null,
            label_image: state.uploadLabeledImages
              ? image.additionalImage.originalFile
              : null,
          };
        });
        sentData = data[0];
      }

      if (state.selectedPropertyType === propertyTypes[1]) {
        data = state.files.map((image) => {
          return {
            id: !image.isLocal ? image.id : null,
            image:
              image.isLocal && Utils.isImageVideo(image)
                ? image?.originalFile
                : null,
            file:
              image.isLocal && !Utils.isImageVideo(image)
                ? image?.originalFile
                : null,
            range: image.range,
            name: image.name,
            label_image:
              image.additionalImage !== null
                ? image.additionalImage.originalFile
                : null,
          };
        });
        sentData = data;
      }

      if (communityId) {
        const successMessage = "Property map submitted successfully";
        const errorMessage = "Failed to submit property map";
        setSubmitting(true);
        FormApi.savePropertyMap(
          communityId,
          sentData,
          getPropertyType(state.selectedPropertyType),
        ).then(
          (response) => {
            setSubmitting(false);
            if (response?.data) {
              let data = getMappedImages(response.data);
              setState({
                selectedPropertyType: state.selectedPropertyType,
                files: data.files,
                uploadLabeledImages: data.uploadLabeledImages,
              });
              checkStatus();
              setMessageText(successMessage, "success");
            } else {
              setMessageText(errorMessage, "error");
            }
          },
          (error) => {
            setSubmitting(false);
            setMessageText(errorMessage, "error");
          },
        );
      }
    }
  };

  const onSaveAndSubmitButtonClicked = () => {
    if (state.files.length > 0) {
      if (canUserProceed()) {
        let sentData;
        let data;

        if (state.selectedPropertyType === propertyTypes[0]) {
          data = state?.files?.map((image) => {
            return {
              id: !image.isLocal ? image.id : null,
              image: image.isLocal ? image?.originalFile : null,
              label_image: state.uploadLabeledImages
                ? image.additionalImage.originalFile
                : null,
            };
          });
          sentData = data[0];
        }

        if (state.selectedPropertyType === propertyTypes[1]) {
          data = state?.files?.map((image) => {
            return {
              id: !image.isLocal ? image.id : null,
              image: image.isLocal ? image?.originalFile : null,
              range: image.range,
              name: image.name,
              label_image:
                image.additionalImage !== null
                  ? image.additionalImage.originalFile
                  : null,
            };
          });
          sentData = data;
        }
        if (communityId) {
          setFinishLater(true);
          FormApi.savePropertyMap(
            communityId,
            sentData,
            getPropertyType(state.selectedPropertyType),
            (status = "in_progress"),
          ).then(
            (response) => {
              setFinishLater(false);

              if (response?.data) {
                let data = getMappedImages(response.data);
                setState({
                  selectedPropertyType: state.selectedPropertyType,
                  files: data.files,
                  uploadLabeledImages: data.uploadLabeledImages,
                });
                checkStatus();
              } else {
              }
            },
            (error) => {
              setFinishLater(false);
            },
          );
        }
        closeForm();
      }
    } else {
      formAlertCloseButtonClicked();
      closeForm();
    }
  };

  const handlePropertyTypeChange = (e) => {
    if (state.files.length > 0) {
      state.files.map((file) => {
        if (!file.isLocal) {
          setOpenPorpertyMapChangeModal(true);
        } else {
          setState({
            ...state,
            selectedPropertyType: e.target.value,
          });
        }
        return [];
      });
    } else {
      setState({
        ...state,
        selectedPropertyType: e.target.value,
      });
    }
  };

  const handlePropertyTypeChangeModal = (type) => {
    checkStatus();
    const typeChange =
      getPropertyType(type) === propertyTypes[0]
        ? propertyTypes[1]
        : propertyTypes[0];
    setState({
      ...state,
      files: [],
      selectedPropertyType: typeChange,
    });
    setOpenPorpertyMapChangeModal(false);
  };

  const handleUploadLabeledImagesCheckboxChange = (name, value) => {
    setState({
      ...state,
      uploadLabeledImages: value,
    });
  };

  useEffect(() => {
    let abortController = new AbortController();
    fetchingPropertyMap(abortController);
    return () => {
      abortController.abort();
    };
  }, [fetchingPropertyMap]);

  if (fetching) {
    return (
      <StyledSpinnerContainer>
        <CircularProgress size={20} />
      </StyledSpinnerContainer>
    );
  }

  return (
    <Container>
      <PropertyTypeSection
        types={propertyTypes}
        selectedType={state.selectedPropertyType}
        onChange={handlePropertyTypeChange}
      />
      {state.selectedPropertyType && (
        <Box sx={{ marginTop: "0.5rem" }}>
          <Divider sx={{ marginBottom: "0.5rem" }} />
          <Checkbox
            label='If the image to be displayed in the app does not have a label for every unit number and amenity then upload a separate image with labels for reference for the Pynwheel production team.'
            checked={state.uploadLabeledImages}
            onChange={(e) =>
              handleUploadLabeledImagesCheckboxChange(
                e.target.name,
                e.target.checked,
              )
            }
          />
        </Box>
      )}

      {state.selectedPropertyType && (
        <Box sx={{ marginTop: "0.5rem", marginBottom: "1rem" }}>
          <FileUploadContainer
            accept='.png, .jpg, .jpeg'
            onFileDrop={handleDroppedFiles}
            helperText='.png or .jpg atleast 1400px'
            validationEnabled={false}
            maxFiles={state.selectedPropertyType === propertyTypes[0] ? 1 : 0}
          />
        </Box>
      )}

      {state.files.length > 0 &&
      state.selectedPropertyType === propertyTypes[1] ? (
        <Box>
          <FloorRangeInstructionsSection />
          <Divider sx={{ marginBottom: "1rem" }} />
        </Box>
      ) : null}

      {state.files.length > 0 ? (
        state.selectedPropertyType === propertyTypes[0] ? (
          <Grid>
            <FilesList
              files={state.files}
              onUpdate={(updatedFilesList) =>
                handleFilesListUpdate(updatedFilesList)
              }
              onDeleteImage={handleProperyMapDeleted}
              withAdditionalImage={state.uploadLabeledImages}
              onDeleteLabelImage={handleLabelImageDeleted}
            />
          </Grid>
        ) : (
          <MapImagesList
            files={state.files}
            onUpdate={(updatedFilesList) =>
              handleFilesListUpdate(updatedFilesList)
            }
            withAdditionalImage={state.uploadLabeledImages}
            onDelete={handleProperyMapDeleted}
            onDeleteLabelImage={handleLabelImageDeleted}
          />
        )
      ) : null}

      <Divider sx={{ marginTop: "0.5rem" }} />
      {message && messageType && (
        <Box>
          <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
          <FormAlert
            message={message}
            type={messageType}
            onClose={() => formAlertCloseButtonClicked()}
          />
        </Box>
      )}
      {openPorpertyMapChangeModal && (
        <ConfirmChangePropertyTypeModal
          propertyType={getPropertyType(state.selectedPropertyType)}
          onAccept={handlePropertyTypeChangeModal}
          onClose={() => setOpenPorpertyMapChangeModal(false)}
        />
      )}

      <StyledFooter>
        <Button
          bgColor={theme.colors.gray}
          titleColor={theme.colors.white}
          disabled={finishLater}
          loading={finishLater}
          title='Save & Finish Later'
          onClick={onSaveAndSubmitButtonClicked}
        />
        <Button
          bgColor={theme.colors.green}
          title='Submit'
          submitting={submitting}
          disabled={
            status !== MixConstants.FormStatus.DEPLOYED ? submitting : true
          }
          loading={submitting}
          onClick={onSubmitButtonClicked}
        />
      </StyledFooter>
    </Container>
  );
}

export default PropertyMapImagesForm;
