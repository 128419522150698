import React from "react";
import moment from "moment";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import { purple, green } from "@mui/material/colors";
import LoggedInUserHelper from "helpers/loggedin-user-helper";
import { StyledCommentView, StyledText, TimeText } from "./style";

function CommentView({ comment }) {
  const creatorId = comment?.creator?.id;
  const firstName = comment?.creator?.first_name;
  const lastName = comment?.creator?.last_name;
  const message = comment?.content;
  const firstNameFirstLetter = firstName?.substring(0, 1)?.toUpperCase();
  const isCurrentUserComment = creatorId === LoggedInUserHelper.id();
  const color1 = purple[500];
  const color2 = green[500];
  const createdAt = moment(comment?.created_at).fromNow();

  return (
    <StyledCommentView>
      <Avatar
        variant='rounded'
        sx={{ bgcolor: isCurrentUserComment ? color1 : color2 }}
      >
        {firstNameFirstLetter}
      </Avatar>
      <Box sx={{ marginLeft: "0.8rem" }}>
        <StyledText bold='true' display='inline'>
          {firstName} {lastName}
        </StyledText>
        <TimeText display='inline'>{createdAt}</TimeText>
        <StyledText>{message}</StyledText>
      </Box>
    </StyledCommentView>
  );
}

export default CommentView;
