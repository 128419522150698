import React, { useState, useEffect, useCallback, useRef } from "react";
import { CommunityApi } from "apis";
import Box from "@mui/material/Box";
import Modal from "components/modal";
import Checkbox from "components/checkbox";
import Divider from "@mui/material/Divider";
import TextField from "components/textfield";
import { useTheme } from "styled-components";
import FormAlert from "components/form-alert";
import { StyledBox, StyledRow } from "./style";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import RadioGroup from "@mui/material/RadioGroup";
import RadioButton from "components/radio-button";
import { useIsMounted } from "hooks/use-is-mounted";
import CircularProgress from "@mui/material/CircularProgress";

function SettingsModal({
  open = true,
  title = "Settings",
  community,
  onClose,
  onSave,
}) {
  const [fetchingSettings, setFetchingSettings] = useState(false);
  const [savingSettings, setSavingSettings] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [message, setMessage] = useState("");

  const [isPWTouchSelected, setIsPWTouchSelected] = useState(false);
  const [selectedPWTouchHardware, setSelectedPWTouchHardware] = useState(null);
  const [selectedPWDesignStyle, setSelectedPWDesignStyle] = useState(null);
  const [selectedPWInstallation, setSelectedPWInstallation] = useState(null);
  const [selectedPWStand, setSelectedPWStand] = useState(null);
  const [isPWMapsSelected, setIsPWMapsSelected] = useState(false);
  const [isPWAccessSelected, setIsPWAccessSelected] = useState(true);
  const [isSelfTourSelected, setIsSelfTourSelected] = useState(false);
  const [isSelfTourHardwareSelected, setIsSelfTourHardwareSelected] =
    useState(false);
  const [isSelfTourPWAccessSelected, setIsSelfTourPWAccessSelected] =
    useState(false);
  const [selfTourPWAccessPynsValue, setSelfTourPWAccessPynsValue] = useState(0);
  const [selfTourPWAccessDeadboltsValue, setSelfTourPWAccessDeadboltsValue] =
    useState(0);
  const [isSelfTourPWAccessOtherSelected, setIsSelfTourPWAccessOtherSelected] =
    useState(false);
  const [selfTourPWAccessOtherTypeValue, setSelfTourPWAccessOtherTypeValue] =
    useState(0);
  const [
    selfTourPWAccessOtherQuantityValue,
    setSelfTourPWAccessOtherQuantityValue,
  ] = useState(0);
  const [isSelfTourLatchSelected, setIsSelfTourLatchSelected] = useState(false);
  const [isSelfTourIglooSelected, setIsSelfTourIglooSelected] = useState(false);
  const [isSelfTourRemoteLockSelected, setIsSelfTourRemoteLockSelected] =
    useState(false);
  const [selfTourRemoteLockIglooValue, setSelfTourRemoteLockIglooValue] =
    useState(0);
  const [selfTourRemoteLockYaleValue, setSelfTourRemoteLockYaleValue] =
    useState(0);
  const [selfTourRemoteLockSchlageValue, setSelfTourRemoteLockSchlageValue] =
    useState(0);
  const [isDesignServicesSelected, setIsDesignServicesSelected] =
    useState(false);
  const [designServiceFloorPlans2DValue, setDesignServiceFloorPlans2DValue] =
    useState(0);
  const [designServiceFloorPlans3DValue, setDesignServiceFloorPlans3DValue] =
    useState(0);
  const [designServiceSitePlanValue, setDesignServiceSitePlanValue] =
    useState(0);
  const [isAdditionalServicesSelected, setIsAdditionalServicesSelected] =
    useState(false);
  const [
    additionalServicesVirtualStagingValue,
    setAdditionalServicesVirtualStagingValue,
  ] = useState(0);
  const [
    additionalServicesPhotographyValue,
    setAdditionalServicesPhotographyValue,
  ] = useState(0);
  const [
    additionalServicesPanoskinTourPackageValue,
    setAdditionalServicesPanoskinTourPackageValue,
  ] = useState(0);

  const isMounted = useIsMounted();
  const theme = useTheme();
  const recordId = community?.id;
  const commentsEndRef = useRef(null);
  const paddingTop = "0.5rem";

  const fetchSettings = useCallback(
    (abortController) => {
      setFetchingSettings(true);

      if (recordId) {
        CommunityApi.fetchSettings(recordId).then(
          (response) => {
            const aborted = abortController.signal.aborted;
            if (aborted === false) {
              setFetchingSettings(false);

              if (response?.data) {
                const po = response.data?.product_options;

                setIsPWTouchSelected(po?.pynwheel_touch?.is_enabled);
                setSelectedPWTouchHardware(
                  po?.pynwheel_touch?.options?.hardware,
                );
                setSelectedPWDesignStyle(
                  po?.pynwheel_touch?.options?.design_style,
                );
                setSelectedPWInstallation(
                  po?.pynwheel_touch?.options?.installation,
                );
                setSelectedPWStand(po?.pynwheel_touch?.options?.stand);
                setIsPWMapsSelected(po?.pynwheel_maps);
                setIsPWAccessSelected(po?.pynwheel_access);
                setIsSelfTourSelected(po?.self_tour?.is_enabled);
                setIsSelfTourHardwareSelected(
                  po?.self_tour?.options?.hardware_required?.is_enabled,
                );
                setIsSelfTourPWAccessSelected(
                  po?.self_tour?.options?.hardware_required?.options
                    ?.pynwheel_access?.is_enabled,
                );
                setSelfTourPWAccessPynsValue(
                  po?.self_tour?.options?.hardware_required?.options
                    ?.pynwheel_access?.options?.pyns,
                );
                setSelfTourPWAccessDeadboltsValue(
                  po?.self_tour?.options?.hardware_required?.options
                    ?.pynwheel_access?.options?.deadbolts,
                );
                setIsSelfTourPWAccessOtherSelected(
                  po?.self_tour?.options?.hardware_required?.options
                    ?.pynwheel_access?.options?.others?.is_enabled,
                );
                setSelfTourPWAccessOtherTypeValue(
                  po?.self_tour?.options?.hardware_required?.options
                    ?.pynwheel_access?.options?.others?.options?.type,
                );
                setSelfTourPWAccessOtherQuantityValue(
                  po?.self_tour?.options?.hardware_required?.options
                    ?.pynwheel_access?.options?.others?.options?.quantity,
                );
                setIsSelfTourLatchSelected(
                  po?.self_tour?.options?.hardware_required?.options?.latch,
                );
                setIsSelfTourIglooSelected(
                  po?.self_tour?.options?.hardware_required?.options?.igloo,
                );
                setIsSelfTourRemoteLockSelected(
                  po?.self_tour?.options?.hardware_required?.options
                    ?.remote_lock?.is_enabled,
                );
                setSelfTourRemoteLockIglooValue(
                  po?.self_tour?.options?.hardware_required?.options
                    ?.remote_lock?.options?.igloo,
                );
                setSelfTourRemoteLockYaleValue(
                  po?.self_tour?.options?.hardware_required?.options
                    ?.remote_lock?.options?.yale,
                );
                setSelfTourRemoteLockSchlageValue(
                  po?.self_tour?.options?.hardware_required?.options
                    ?.remote_lock?.options?.schlage,
                );
                setIsDesignServicesSelected(
                  po?.graphic_design_services?.is_enabled,
                );
                setDesignServiceFloorPlans2DValue(
                  po?.graphic_design_services?.options?.floorplans?.["2d"],
                );
                setDesignServiceFloorPlans3DValue(
                  po?.graphic_design_services?.options?.floorplans?.["3d"],
                );
                setDesignServiceSitePlanValue(
                  po?.graphic_design_services?.options?.site_plan,
                );
                setIsAdditionalServicesSelected(
                  po?.additional_options?.is_enabled,
                );
                setAdditionalServicesVirtualStagingValue(
                  po?.additional_options?.options.virtual_staging,
                );
                setAdditionalServicesPhotographyValue(
                  po?.additional_options?.options.photography,
                );
                setAdditionalServicesPanoskinTourPackageValue(
                  po?.additional_options?.options.panoskin_tour_package,
                );
              }
            }
          },
          (error) => {
            setFetchingSettings(false);
          },
        );
      }
    },
    [recordId],
  );

  useEffect(() => {
    let abortController = new AbortController();
    fetchSettings(abortController);
    return () => {
      abortController.abort();
    };
  }, [fetchSettings]);

  const scrollToBottom = () => {
    setTimeout(() => {
      commentsEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 50);
  };

  const actionButtons = [
    {
      id: 1,
      title: "Close",
      bgColor: theme.colors.lightGray,
      titleColor: theme.colors.white,
      disabled: savingSettings,
      onClick: onClose,
    },
    {
      id: 2,
      title: "Save",
      bgColor: theme.colors.green,
      titleColor: theme.colors.white,
      disabled: savingSettings,
      loading: savingSettings,
      onClick: () => saveButtonClicked(),
    },
  ];

  const handleCheckboxChange = (name, value) => {
    if (name === "isPWTouchSelected") {
      setIsPWTouchSelected(value);
    } else if (name === "isPWAccessSelected") {
      setIsPWAccessSelected(value);
    } else if (name === "isPWMapsSelected") {
      setIsPWMapsSelected(value);
    } else if (name === "isSelfTourSelected") {
      setIsSelfTourSelected(value);
    } else if (name === "isSelfTourHardwareSelected") {
      setIsSelfTourHardwareSelected(value);
    } else if (name === "isSelfTourPWAccessSelected") {
      setIsSelfTourPWAccessSelected(value);
    } else if (name === "isSelfTourPWAccessOtherSelected") {
      setIsSelfTourPWAccessOtherSelected(value);
    } else if (name === "isSelfTourLatchSelected") {
      setIsSelfTourLatchSelected(value);
    } else if (name === "isSelfTourIglooSelected") {
      setIsSelfTourIglooSelected(value);
    } else if (name === "isSelfTourRemoteLockSelected") {
      setIsSelfTourRemoteLockSelected(value);
    } else if (name === "isDesignServicesSelected") {
      setIsDesignServicesSelected(value);
    } else if (name === "isAdditionalServicesSelected") {
      setIsAdditionalServicesSelected(value);
    }
  };

  const handleTextFieldChange = (e) => {
    const { name, value } = e.target;

    if (name === "selfTourPWAccessPynsValue") {
      setSelfTourPWAccessPynsValue(value);
    } else if (name === "selfTourPWAccessDeadboltsValue") {
      setSelfTourPWAccessDeadboltsValue(value);
    } else if (name === "selfTourPWAccessOtherTypeValue") {
      setSelfTourPWAccessOtherTypeValue(value);
    } else if (name === "selfTourPWAccessOtherQuantityValue") {
      setSelfTourPWAccessOtherQuantityValue(value);
    } else if (name === "selfTourRemoteLockIglooValue") {
      setSelfTourRemoteLockIglooValue(value);
    } else if (name === "selfTourRemoteLockYaleValue") {
      setSelfTourRemoteLockYaleValue(value);
    } else if (name === "selfTourRemoteLockSchlageValue") {
      setSelfTourRemoteLockSchlageValue(value);
    } else if (name === "designServiceFloorPlans2DValue") {
      setDesignServiceFloorPlans2DValue(value);
    } else if (name === "designServiceFloorPlans3DValue") {
      setDesignServiceFloorPlans3DValue(value);
    } else if (name === "designServiceSitePlanValue") {
      setDesignServiceSitePlanValue(value);
    } else if (name === "additionalServicesVirtualStagingValue") {
      setAdditionalServicesVirtualStagingValue(value);
    } else if (name === "additionalServicesPhotographyValue") {
      setAdditionalServicesPhotographyValue(value);
    } else if (name === "additionalServicesPanoskinTourPackageValue") {
      setAdditionalServicesPanoskinTourPackageValue(value);
    }
  };

  const saveButtonClicked = () => {
    formAlertCloseButtonClicked();
    setSavingSettings(true);

    if (recordId) {
      const successMessage = "Settings saved successfully";
      const errorMessage = "Failed to save settings";

      const settings = {
        product_options: {
          dwelo_community: community?.dwelo_community,

          product_options: {
            self_tour: {
              is_enabled: isSelfTourSelected,
              options: {
                hardware_required: {
                  is_enabled: isSelfTourSelected && isSelfTourHardwareSelected,
                  options: {
                    pynwheel_access: {
                      is_enabled:
                        isSelfTourSelected &&
                        isSelfTourHardwareSelected &&
                        isSelfTourPWAccessSelected,
                      options: {
                        pyns:
                          isSelfTourSelected &&
                          isSelfTourHardwareSelected &&
                          isSelfTourPWAccessSelected
                            ? selfTourPWAccessPynsValue
                            : 0,
                        deadbolts:
                          isSelfTourSelected &&
                          isSelfTourHardwareSelected &&
                          isSelfTourPWAccessSelected
                            ? selfTourPWAccessDeadboltsValue
                            : 0,
                        others: {
                          is_enabled:
                            isSelfTourSelected &&
                            isSelfTourHardwareSelected &&
                            isSelfTourPWAccessSelected &&
                            isSelfTourPWAccessOtherSelected,
                          options: {
                            type:
                              isSelfTourSelected &&
                              isSelfTourHardwareSelected &&
                              isSelfTourPWAccessSelected &&
                              isSelfTourPWAccessOtherSelected
                                ? selfTourPWAccessOtherTypeValue
                                : "",
                            quantity:
                              isSelfTourSelected &&
                              isSelfTourHardwareSelected &&
                              isSelfTourPWAccessSelected &&
                              isSelfTourPWAccessOtherSelected
                                ? selfTourPWAccessOtherQuantityValue
                                : 0,
                          },
                        },
                      },
                    },
                    latch:
                      isSelfTourSelected &&
                      isSelfTourHardwareSelected &&
                      isSelfTourLatchSelected,
                    igloo:
                      isSelfTourSelected &&
                      isSelfTourHardwareSelected &&
                      isSelfTourIglooSelected,
                    remote_lock: {
                      is_enabled:
                        isSelfTourSelected &&
                        isSelfTourHardwareSelected &&
                        isSelfTourRemoteLockSelected,
                      options: {
                        igloo:
                          isSelfTourSelected &&
                          isSelfTourHardwareSelected &&
                          isSelfTourRemoteLockSelected
                            ? selfTourRemoteLockIglooValue
                            : 0,
                        yale:
                          isSelfTourSelected &&
                          isSelfTourHardwareSelected &&
                          isSelfTourRemoteLockSelected
                            ? selfTourRemoteLockYaleValue
                            : 0,
                        schlage:
                          isSelfTourSelected &&
                          isSelfTourHardwareSelected &&
                          isSelfTourRemoteLockSelected
                            ? selfTourRemoteLockSchlageValue
                            : 0,
                      },
                    },
                  },
                },
              },
            },

            pynwheel_touch: {
              is_enabled: isPWTouchSelected,
              options: {
                design_style: isPWTouchSelected ? selectedPWDesignStyle : "",
                hardware: isPWTouchSelected ? selectedPWTouchHardware : "",
                installation: isPWTouchSelected ? selectedPWInstallation : "",
                stand: isPWTouchSelected ? selectedPWStand : "",
              },
            },

            pynwheel_maps: isPWMapsSelected,

            pynwheel_access: isPWAccessSelected,

            graphic_design_services: {
              is_enabled: isDesignServicesSelected,
              options: {
                floorplans: {
                  "2d": isDesignServicesSelected
                    ? designServiceFloorPlans2DValue
                    : 0,
                  "3d": isDesignServicesSelected
                    ? designServiceFloorPlans3DValue
                    : 0,
                },
                site_plan: isDesignServicesSelected
                  ? designServiceSitePlanValue
                  : 0,
              },
            },

            additional_options: {
              is_enabled: isAdditionalServicesSelected,
              options: {
                virtual_staging: isAdditionalServicesSelected
                  ? additionalServicesVirtualStagingValue
                  : 0,
                photography: isAdditionalServicesSelected
                  ? additionalServicesPhotographyValue
                  : 0,
                panoskin_tour_package: isAdditionalServicesSelected
                  ? additionalServicesPanoskinTourPackageValue
                  : 0,
              },
            },
          },
        },
      };

      CommunityApi.saveSettings(recordId, settings).then(
        (response) => {
          setSavingSettings(false);
          if (response?.data) {
            const abortController = new AbortController();
            const signal = abortController.signal.aborted;
            onSave(community, signal);
            scrollToBottom();
            setMessageText(successMessage, "success");
          } else {
            setMessageText(errorMessage, "error");
          }
        },
        (error) => {
          setSavingSettings(false);
          setMessageText(errorMessage, "error");
        },
      );
    }
  };

  const setMessageText = (text, type) => {
    setMessage(text);
    setMessageType(type);

    setTimeout(() => {
      formAlertCloseButtonClicked();
    }, 5000);
  };

  const formAlertCloseButtonClicked = () => {
    if (isMounted.current) {
      setMessage("");
    }
  };

  const renderCheckbox = (label, name, checked) => {
    return (
      <Checkbox
        label={label}
        name={name}
        checked={checked}
        disabled={savingSettings}
        onChange={(e) => handleCheckboxChange(e.target.name, e.target.checked)}
      />
    );
  };

  const renderSelfTourSettings = () => {
    return (
      <Box>
        <StyledRow>
          {renderCheckbox(
            "Pynwheel Tour",
            "isSelfTourSelected",
            isSelfTourSelected,
          )}
        </StyledRow>
        {isSelfTourSelected && (
          <>
            <StyledRow marginLeft='2rem'>
              {renderCheckbox(
                "Hardware Required",
                "isSelfTourHardwareSelected",
                isSelfTourHardwareSelected,
              )}
            </StyledRow>
            {isSelfTourHardwareSelected && (
              <>
                <StyledRow marginLeft='4rem'>
                  {renderCheckbox(
                    "Zerv",
                    "isSelfTourPWAccessSelected",
                    isSelfTourPWAccessSelected,
                  )}
                </StyledRow>
                <StyledRow marginLeft='4rem' paddingTop={paddingTop}>
                  {renderCheckbox(
                    "Latch",
                    "isSelfTourLatchSelected",
                    isSelfTourLatchSelected,
                  )}
                </StyledRow>
                <StyledRow marginLeft='4rem'>
                  {renderCheckbox(
                    "Igloo",
                    "isSelfTourIglooSelected",
                    isSelfTourIglooSelected,
                  )}
                </StyledRow>
                <StyledRow marginLeft='4rem'>
                  {renderCheckbox(
                    "Remote Lock",
                    "isSelfTourRemoteLockSelected",
                    isSelfTourRemoteLockSelected,
                  )}
                </StyledRow>
                {isSelfTourRemoteLockSelected && (
                  <>
                    <StyledRow marginLeft='6rem' paddingTop={paddingTop}>
                      <StyledRow>
                        <Typography>Igloo</Typography>
                      </StyledRow>
                      <StyledRow marginLeft='0.5rem'>
                        <TextField
                          type='number'
                          label='Quantity'
                          disabled={savingSettings}
                          name='selfTourRemoteLockIglooValue'
                          value={selfTourRemoteLockIglooValue}
                          onChange={handleTextFieldChange}
                        />
                      </StyledRow>
                    </StyledRow>

                    <StyledRow marginLeft='6rem' paddingTop={paddingTop}>
                      <StyledRow>
                        <Typography>Yale</Typography>
                      </StyledRow>
                      <StyledRow marginLeft='0.5rem'>
                        <TextField
                          type='number'
                          label='Quantity'
                          disabled={savingSettings}
                          name='selfTourRemoteLockYaleValue'
                          value={selfTourRemoteLockYaleValue}
                          onChange={handleTextFieldChange}
                        />
                      </StyledRow>
                    </StyledRow>

                    <StyledRow
                      marginLeft='6rem'
                      paddingTop={paddingTop}
                      paddingBottom='0.8rem'
                    >
                      <StyledRow>
                        <Typography>Schlage</Typography>
                      </StyledRow>
                      <StyledRow marginLeft='0.5rem'>
                        <TextField
                          type='number'
                          label='Quantity'
                          disabled={savingSettings}
                          name='selfTourRemoteLockSchlageValue'
                          value={selfTourRemoteLockSchlageValue}
                          onChange={handleTextFieldChange}
                        />
                      </StyledRow>
                    </StyledRow>
                  </>
                )}
              </>
            )}
          </>
        )}
      </Box>
    );
  };

  const renderPynwheelTouchSettings = () => {
    return (
      <Box>
        <StyledRow>
          {renderCheckbox(
            "Pynwheel Touch",
            "isPWTouchSelected",
            isPWTouchSelected,
          )}
        </StyledRow>
        {isPWTouchSelected && (
          <>
            <StyledRow marginLeft='2rem' paddingTop={paddingTop}>
              <Typography variant='subtitle2'>Design Style</Typography>
            </StyledRow>
            <StyledRow marginLeft='2rem'>
              <RadioGroup
                value={selectedPWDesignStyle}
                onChange={(e) => setSelectedPWDesignStyle(e.target.value)}
              >
                <RadioButton label='Futurist' disabled={savingSettings} />
                <RadioButton label='Expressionist' disabled={savingSettings} />
                <RadioButton
                  label='Modernist Vertical'
                  disabled={savingSettings}
                />
                <RadioButton
                  label='Modernist Horizontal'
                  disabled={savingSettings}
                />
              </RadioGroup>
            </StyledRow>

            <StyledRow marginLeft='2rem' paddingTop={paddingTop}>
              <Typography variant='subtitle2'>Hardware</Typography>
            </StyledRow>
            <StyledRow marginLeft='2rem'>
              <RadioGroup
                value={selectedPWTouchHardware}
                onChange={(e) => setSelectedPWTouchHardware(e.target.value)}
              >
                <RadioButton
                  label='42” Touchscreen'
                  disabled={savingSettings}
                />
                <RadioButton
                  label='55” Touchscreen'
                  disabled={savingSettings}
                />
                <RadioButton
                  label='65” Touchscreen'
                  disabled={savingSettings}
                />
                <RadioButton label='No Touchscreen' disabled={savingSettings} />
              </RadioGroup>
            </StyledRow>

            <StyledRow marginLeft='2rem' paddingTop={paddingTop}>
              <Typography variant='subtitle2'>Installation</Typography>
            </StyledRow>
            <StyledRow marginLeft='2rem'>
              <RadioGroup
                value={selectedPWInstallation}
                onChange={(e) => setSelectedPWInstallation(e.target.value)}
              >
                <RadioButton label='Yes' disabled={savingSettings} />
                <RadioButton label='No' disabled={savingSettings} />
              </RadioGroup>
            </StyledRow>

            <StyledRow marginLeft='2rem' paddingTop={paddingTop}>
              <Typography variant='subtitle2'>Stand</Typography>
            </StyledRow>
            <StyledRow marginLeft='2rem'>
              <RadioGroup
                value={selectedPWStand}
                onChange={(e) => setSelectedPWStand(e.target.value)}
              >
                <RadioButton label='Upright' disabled={savingSettings} />
                <RadioButton label='Kiosk' disabled={savingSettings} />
                <RadioButton label='N/A' disabled={savingSettings} />
              </RadioGroup>
            </StyledRow>
          </>
        )}
      </Box>
    );
  };

  const renderPynwheelMapsSettings = () => {
    return (
      <Box>
        <StyledRow>
          {renderCheckbox(
            "Pynwheel Maps",
            "isPWMapsSelected",
            isPWMapsSelected,
          )}
        </StyledRow>
      </Box>
    );
  };

  const renderGraphicDesignServicesSettings = () => {
    return (
      <Box>
        <StyledRow>
          {renderCheckbox(
            "Graphic Design Services",
            "isDesignServicesSelected",
            isDesignServicesSelected,
          )}
        </StyledRow>
        {isDesignServicesSelected && (
          <>
            <StyledRow marginLeft='2rem' paddingTop={paddingTop}>
              <Typography variant='subtitle2'>Floor Plans</Typography>
            </StyledRow>

            <StyledRow marginLeft='2rem' paddingTop={paddingTop}>
              <StyledRow>
                <Typography>2D</Typography>
              </StyledRow>
              <StyledRow marginLeft='0.5rem'>
                <TextField
                  type='number'
                  label='Quantity'
                  disabled={savingSettings}
                  name='designServiceFloorPlans2DValue'
                  value={designServiceFloorPlans2DValue}
                  onChange={handleTextFieldChange}
                />
              </StyledRow>
            </StyledRow>

            <StyledRow marginLeft='2rem' paddingTop={paddingTop}>
              <StyledRow>
                <Typography>3D</Typography>
              </StyledRow>
              <StyledRow marginLeft='0.5rem'>
                <TextField
                  type='number'
                  label='Quantity'
                  disabled={savingSettings}
                  name='designServiceFloorPlans3DValue'
                  value={designServiceFloorPlans3DValue}
                  onChange={handleTextFieldChange}
                />
              </StyledRow>
            </StyledRow>

            <StyledRow marginLeft='2rem' paddingTop={paddingTop}>
              <Typography variant='subtitle2'>Site Plan</Typography>
            </StyledRow>

            <StyledRow
              marginLeft='2rem'
              paddingTop={paddingTop}
              paddingBottom='0.8rem'
            >
              <StyledRow>
                <TextField
                  type='number'
                  label='Quantity'
                  disabled={savingSettings}
                  name='designServiceSitePlanValue'
                  value={designServiceSitePlanValue}
                  onChange={handleTextFieldChange}
                />
              </StyledRow>
            </StyledRow>
          </>
        )}
      </Box>
    );
  };

  const renderAdditionalServicesSettings = () => {
    return (
      <Box>
        <StyledRow>
          {renderCheckbox(
            "Additional Services",
            "isAdditionalServicesSelected",
            isAdditionalServicesSelected,
          )}
        </StyledRow>
        {isAdditionalServicesSelected && (
          <>
            <StyledRow marginLeft='2rem' paddingTop={paddingTop}>
              <StyledRow>
                <Typography>Virtual Staging</Typography>
              </StyledRow>
              <StyledRow marginLeft='0.5rem'>
                <TextField
                  type='number'
                  label='Quantity'
                  disabled={savingSettings}
                  name='additionalServicesVirtualStagingValue'
                  value={additionalServicesVirtualStagingValue}
                  onChange={handleTextFieldChange}
                />
              </StyledRow>
            </StyledRow>

            <StyledRow marginLeft='2rem' paddingTop={paddingTop}>
              <StyledRow>
                <Typography>Photography</Typography>
              </StyledRow>
              <StyledRow marginLeft='0.5rem'>
                <TextField
                  type='number'
                  label='Quantity'
                  disabled={savingSettings}
                  name='additionalServicesPhotographyValue'
                  value={additionalServicesPhotographyValue}
                  onChange={handleTextFieldChange}
                />
              </StyledRow>
            </StyledRow>

            <StyledRow marginLeft='2rem' paddingTop={paddingTop}>
              <StyledRow>
                <Typography>TourBuilder</Typography>
              </StyledRow>
              <StyledRow marginLeft='0.5rem'>
                <TextField
                  type='number'
                  label='Quantity'
                  disabled={savingSettings}
                  name='additionalServicesPanoskinTourPackageValue'
                  value={additionalServicesPanoskinTourPackageValue}
                  onChange={handleTextFieldChange}
                />
              </StyledRow>
            </StyledRow>
          </>
        )}
      </Box>
    );
  };

  return (
    <Modal
      maxWidth='lg'
      open={open}
      title={title}
      onClose={savingSettings ? null : onClose}
      footerButtons={actionButtons}
    >
      <Container maxWidth='lg'>
        {fetchingSettings ? (
          <StyledBox>
            <CircularProgress size={20} />
          </StyledBox>
        ) : (
          <Box>
            {renderSelfTourSettings()}
            <Divider />
            {renderPynwheelTouchSettings()}
            <Divider />
            {renderPynwheelMapsSettings()}
            <Divider />
            {renderGraphicDesignServicesSettings()}
            <Divider />
            {renderAdditionalServicesSettings()}
          </Box>
        )}
        {message && messageType && (
          <Box sx={{ paddingTop: "1rem" }} ref={commentsEndRef}>
            <FormAlert
              message={message}
              type={messageType}
              onClose={() => formAlertCloseButtonClicked()}
            />
          </Box>
        )}
      </Container>
    </Modal>
  );
}

export default SettingsModal;
