import React from "react";
import _ from "lodash";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import TextField from "components/textfield";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import FilesGridList from "components/files-grid-list";
import FileUploadContainer from "components/file-upload-container";

function ImagePageGalleryItem({ page, onDropFile, updateName, index, onDelete, removeFile, updateFiles }) {
  const onRemoveFileButtonClicked = (file) => {
    removeFile(page, file)
  }

  const handleFilsUpdate = (files) => {
    updateFiles(page, files);
  }
  return (
    <Box>
      <Grid spacing={2} container alignItems='center' paddingY='0.4rem'>
        <Grid item xs={12} md={3}>
          <Stack direction='row' spacing={1}>
            <Tooltip title='Remove Gallery'>
              <IconButton size='small' onClick={() => onDelete(page, page.type)}>
                <DeleteIcon fontSize='small' />
              </IconButton>
            </Tooltip>
            <TextField
              required
              label='Name'
              value={page?.name ?? ""}
              onChange={(e) => updateName(e, index)}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={3}>
          <FileUploadContainer
            accept="image/jpeg, image/png, application/pdf"
            helperText="png, jpg, pdf"
            padding='0.5rem'
            onFileDrop={(image) => onDropFile(image, index)}
            disabled={page?.name === "" ? true : false}
            disableErrorMessage = "Please add Imagepage name first."
          />
        </Grid>
        {(page?.gallery?.length > 0 || page?.files?.length > 0) && (
          <Grid item xs={12} md={6}>
            <FilesGridList
              files={_.union(page?.gallery, page?.files)}
              onUpdate={handleFilsUpdate}
              deleteImage={onRemoveFileButtonClicked}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default ImagePageGalleryItem;
