import React, { useCallback } from "react";
import _ from "lodash";
import FileDropZone from "components/file-drop-zone";

function FileUploadContainer({
  accept,
  helperText = "",
  padding,
  onFileDrop,
  maxFiles = 0,
  disabled,
  disableErrorMessage,
  validationEnabled = true,
}) {
  const onDrop = useCallback(
    (files) => {
      let filesList = files.map((file) => {
        return {
          id: _.uniqueId(),
          isLocal: true,
          type: file?.type,
          originalFile: file,
          fileSize: file.size,
        };
      });

      onFileDrop && onFileDrop(filesList);
    },
    [onFileDrop],
  );
  return (
    <FileDropZone
      accept={accept}
      helperText={helperText}
      padding={padding}
      onFileDrop={onDrop}
      maxFiles={maxFiles}
      disabled={disabled}
      validationEnabled={validationEnabled}
      disableErrorMessage={disableErrorMessage}
    />
  );
}

export default FileUploadContainer;
