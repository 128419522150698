import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import allReducers from "./reducers/all-reducers";

const loggerMiddleware = createLogger();

//create store without REDUX_DEVTOOLS_EXTENSION

// const store = createStore(
//     allReducers
//     applyMiddleware(
//         thunkMiddleware,
//         loggerMiddleware
//     )
// );

//create store with REDUX_DEVTOOLS_EXTENSION
//https://github.com/zalmoxisus/redux-devtools-extension

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  allReducers,
  composeEnhancers(applyMiddleware(thunkMiddleware, loggerMiddleware)),
);

export default store;
