import config from "./api-config";
import Persistence from "helpers/persistence";
import { simpleHeader } from "helpers/api-header";
import LoggedInUserHelper from "helpers/loggedin-user-helper";

const EndPoints = {
  LOGIN: `${config.baseURL}/api/v2/auth/token`,
  SIGNUP: `${config.baseURL}/api/v2/auth/signup/`,
  LOGOUT: `${config.baseURL}/api/v2/access_token/revoke`,
};

const AuthApi = {
  login,
  logout,
  signup,
};
  
/**
 *  Login
 */

async function login(email, password) {
  const formData = new FormData();

  formData.append("email", email);
  formData.append("password", password);
  formData.append("grant_type", "password");

  const requestOptions = {
    method: "POST",
    headers: simpleHeader,
    body: formData,
  };

  const response = await fetch(EndPoints.LOGIN, requestOptions);
  const user = await handleResponse(response);

  if (user.access_token) {
    Persistence.setAccessToken(user.access_token);
  }
  if (user.user_details) {
    Persistence.setUserData(user.user_details);
    // return user.user_details;
  }

  return user;
}

/**
 *  Logout
 */

async function logout() {
  const token = LoggedInUserHelper.token();
  const formData = new FormData();
  formData.append("token", token);

  const requestOptions = {
    method: "POST",
    headers: simpleHeader,
    body: formData,
  };

  const response = await fetch(EndPoints.LOGOUT, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Sign Up
 */

async function signup(user) {
  const requestOptions = {
    method: "POST",
    headers: simpleHeader,
    body: JSON.stringify(user),
  };

  const response = await fetch(EndPoints.SIGNUP, requestOptions);
  return handleResponse(response);
}

/**
 *  Response Handler
 */

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      return Promise.reject(data);
    }
    return data;
  });
}

export default AuthApi;
