import React, { useState, useEffect, useCallback } from "react";
import { FormApi } from "apis";
import validator from "validator";
import { saveAs } from "file-saver";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "components/button";
import Divider from "@mui/material/Divider";
import TextField from "components/textfield";
import { useTheme } from "styled-components";
import FormAlert from "components/form-alert";
import ImageViewer from "components/image-viewer";
import Typography from "@mui/material/Typography";
import MixConstants from "constants/mix-constants";
import { useParams, useHistory } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import FileUploadContainer from "components/file-upload-container";
import FileGridItem from "components/files-grid-list/file-grid-item";
import { Container, StyledFooter, StyledSpinnerContainer } from "../style";
import Utils from "helpers/utils";

function CommunityDetailForm({
  onUpdateCommunityName,
  expanded,
  checkStatus,
  status,
  closeForm,
}) {
  const [fetchingCommunityDetails, setFetchingCommunityDetails] =
    useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [message, setMessage] = useState("");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [managerName, setManagerName] = useState("");
  const [managerEmail, setManagerEmail] = useState("");
  const [managerPhone, setManagerPhone] = useState("");
  const [website, setWebsite] = useState("");
  const [numberOfUnits, setNumberOfUnits] = useState("");
  const [logo, setLogo] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");

  const [brandFeatureAccess, setBrandFeatureAccess] = useState(false);
  const [brandGuidelinesPdfFile, setBrandGuidelinesPdfFile] = useState(null);

  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [filesToView, setFilesToView] = useState([]);

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [cityError, setCityError] = useState("");
  const [stateError, setStateError] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");

  const theme = useTheme();
  const spacing = 2;

  const history = useHistory();
  let { communityId } = useParams();
  const communityUserId = history?.location?.state?.params?.communityUserId;

  const setFormatNumber = (e) => {
    let validator = /^[0-9,-]+$/;
    if (
      (e.target.value.match(validator) && e.target.value.length <= 15) ||
      e.target.value === ""
    ) {
      setPhone(e.target.value);
    }
  };

  const resetErrorMessage = () => {
    setNameError("");
    setEmailError("");
    setPhoneError("");
    setAddressError("");
    setCityError("");
    setStateError("");
    setZipCodeError("");
  };

  const validFields = () => {
    let hasError = false;
    resetErrorMessage();
    if (!communityId) {
      setMessageText("Community id is missing", "error");
      hasError = true;
      return false;
    }
    if (email.trim() !== "" && !validator.isEmail(email)) {
      setEmailError("Invalid Email");
      hasError = true;
    }
    return !hasError;
  };

  const setLogoState = (data) => {
    let logoObj;
    if (data?.logo?.url) {
      logoObj = {
        photo: data?.logo?.url,
        type: "image",
        isLocal: false,
      };
    }
    if (data?.file?.url) {
      logoObj = {
        photo: data?.file?.url,
        type: "file",
        isLocal: false,
      };
    }
    setLogo(logoObj);
  };

  const setManagerFormatNumber = (e) => {
    let validator = /^[0-9,-]+$/;
    if (
      (e.target.value.match(validator) && e.target.value.length <= 15) ||
      e.target.value === ""
    ) {
      setManagerPhone(e.target.value);
    }
  };

  const fetchCommunityDetails = useCallback(
    (abortController) => {
      setFetchingCommunityDetails(true);
      if (expanded) {
        if (communityId && communityUserId) {
          FormApi.fetchCommunityDetails(communityId, communityUserId).then(
            (response) => {
              const aborted = abortController.signal.aborted;
              if (aborted === false) {
                setFetchingCommunityDetails(false);
                if (response?.data) {
                  const data = response.data;
                  onUpdateCommunityName(data?.name);
                  setName(data?.name ?? "");
                  setEmail(data?.email ?? "");
                  setPhone(data?.phone ?? "");
                  setAddress(data?.address ?? "");
                  setCity(data?.city ?? "");
                  setState(data?.state ?? "");
                  setZipCode(data?.zip ?? "");
                  setManagerName(data?.property_manager_name ?? "");
                  setManagerEmail(data?.property_manager_email ?? "");
                  setManagerPhone(data?.property_manager_phone ?? "");
                  setWebsite(data?.website ?? "");
                  setNumberOfUnits(data?.number_of_units ?? "");
                  setBrandFeatureAccess(data?.brand_feature_access);
                  setLogoState(data);
                }
              }
            },
            (error) => {
              setFetchingCommunityDetails(false);
            },
          );
        }
      }
    },
    [communityId, communityUserId, onUpdateCommunityName, expanded],
  );

  useEffect(() => {
    let abortController = new AbortController();
    fetchCommunityDetails(abortController);
    return () => {
      abortController.abort();
    };
  }, [fetchCommunityDetails]);

  const handleDroppedLogoFile = (files) => {
    setLogo(files[0]);
  };

  const removeLogoFileButtonClicked = () => {
    formAlertCloseButtonClicked();
    if (logo?.isLocal) {
      setLogo(null);
    } else {
      if (communityId && communityUserId) {
        const successMessage = "Community logo deleted successfully";
        const errorMessage = "Failed to delete community logo";

        setSubmitting(true);

        FormApi.deleteCommunityLogo(communityId).then(
          (response) => {
            setSubmitting(false);

            if (response?.success) {
              setLogo(null);
              setMessageText(successMessage, "success");
            } else {
              setMessageText(errorMessage, "error");
            }
          },
          (error) => {
            setSubmitting(false);
            setMessageText(errorMessage, "error");
          },
        );
      }
    }
  };

  const viewLogoButtonClicked = () => {
    setIsGalleryOpen(true);
    setFilesToView([logo?.photo || URL.createObjectURL(logo?.originalFile)]);
  };

  const handleDroppedPdfFile = (files) => {
    setBrandGuidelinesPdfFile(files[0]);
  };

  const removePdfFileButtonClicked = () => {
    setBrandGuidelinesPdfFile(null);
  };

  const setMessageText = (text, type) => {
    setMessage(text);
    setMessageType(type);

    setTimeout(() => {
      formAlertCloseButtonClicked();
    }, 5000);
  };

  const canUserProceed = () => {
    let hasError = false;

    if (!communityId) {
      setMessageText("Community id is missing", "error");
      hasError = true;
      return false;
    }
    if (name.trim() === "") {
      setNameError("Required");
      hasError = true;
    } else {
      setNameError("");
    }
    if (email.trim() === "") {
      setEmailError("Required");
      hasError = true;
    } else {
      setEmailError("");
    }
    if (email.trim() !== "" && !validator.isEmail(email)) {
      setEmailError("Invalid Email");
      hasError = true;
    }
    if (phone === "") {
      setPhoneError("Required");
      hasError = true;
    } else {
      setPhoneError("");
    }

    if (address.trim() === "") {
      setAddressError("Required");
      hasError = true;
    } else {
      setAddressError("");
    }
    if (city.trim() === "") {
      setCityError("Required");
      hasError = true;
    } else {
      setCityError("");
    }
    if (state.trim() === "") {
      setStateError("Required");
      hasError = true;
    } else {
      setStateError("");
    }
    if (zipCode === "") {
      setZipCodeError("Required");
      hasError = true;
    } else {
      setZipCodeError("");
    }
    return !hasError;
  };

  const formAlertCloseButtonClicked = () => {
    setMessage("");
  };

  const submitButtonClicked = () => {
    if (canUserProceed()) {
      const logoFile =
        logo?.originalFile && Utils.isImageVideo(logo?.originalFile)
          ? logo?.originalFile
          : null;
      const file =
        logo?.originalFile && !Utils.isImageVideo(logo?.originalFile)
          ? logo?.originalFile
          : null;
      const data = {
        community: {
          name: name,
          email: email,
          phone: phone,
          address: address,
          city: city,
          state: state,
          zip: zipCode,
          property_manager_name: managerName,
          property_manager_email: managerEmail,
          property_manager_phone: managerPhone,
          website: website,
          number_of_units: numberOfUnits,
        },
      };
      formAlertCloseButtonClicked();

      if (communityId && communityUserId) {
        const successMessage = "Community detail submitted successfully";
        const errorMessage = "Failed to submit community detail";

        setSubmitting(true);

        FormApi.saveCommunity(
          communityId,
          communityUserId,
          data,
          logoFile,
          file,
        ).then(
          (response) => {
            setSubmitting(false);

            if (response?.data) {
              setLogoState(response?.data);
              setMessageText(successMessage, "success");
              checkStatus();
            } else {
              setMessageText(errorMessage, "error");
            }
          },
          (error) => {
            setSubmitting(false);
            setMessageText(errorMessage, "error");
          },
        );
      }
    }
  };

  const saveAndSubmitButtonClicked = () => {
    if (validFields()) {
      const logoFile =
        logo?.originalFile && Utils.isImageVideo(logo?.originalFile)
          ? logo?.originalFile
          : null;
      const file =
        logo?.originalFile && !Utils.isImageVideo(logo?.originalFile)
          ? logo?.originalFile
          : null;
      const data = {
        community: {
          name: name,
          email: email,
          phone: phone,
          address: address,
          city: city,
          state: state,
          zip: zipCode,
          property_manager_name: managerName,
          property_manager_email: managerEmail,
          property_manager_phone: managerPhone,
          website: website,
          number_of_units: numberOfUnits,
        },
      };
      formAlertCloseButtonClicked();

      if (communityId && communityUserId) {
        FormApi.saveCommunity(
          communityId,
          communityUserId,
          data,
          logoFile,
          file,
          (status = "in_progress"),
        ).then(
          (response) => {
            setSubmitting(false);

            if (response?.data) {
              checkStatus();
            } else {
            }
          },
          (error) => {},
        );
        closeForm();
      }
    }
  };

  const renderTextFields = () => {
    return (
      <Box>
        <Grid container spacing={spacing}>
          <Grid item xs={12} md={4}>
            <TextField
              required
              name='name'
              value={name}
              error={nameError !== ""}
              helperText={nameError}
              label='Community/Property Name'
              disabled={submitting}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              required
              name='email'
              value={email}
              label='Property Email'
              error={emailError !== ""}
              helperText={emailError}
              disabled={submitting}
              onChange={(e) => setEmail(e.target.value.trim())}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              required
              name='phone'
              value={phone}
              label='Property Phone'
              error={phoneError !== ""}
              helperText={phoneError}
              disabled={submitting}
              onChange={(e) => setFormatNumber(e)}
              placeholder={MixConstants.PhonePlaceholder}
            />
          </Grid>
        </Grid>

        <Divider sx={{ marginY: "1rem" }} />

        <Grid container spacing={spacing}>
          <Grid item xs={12}>
            <TextField
              required
              multiline
              rows={3}
              name='address'
              value={address}
              label='Address'
              error={addressError !== ""}
              helperText={addressError}
              disabled={submitting}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              required
              name='city'
              value={city}
              label='City'
              error={cityError !== ""}
              helperText={cityError}
              disabled={submitting}
              onChange={(e) => setCity(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              required
              name='state'
              value={state}
              label='State'
              error={stateError !== ""}
              helperText={stateError}
              disabled={submitting}
              onChange={(e) => setState(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              required
              name='zipCode'
              value={zipCode}
              label='Zip Code'
              error={zipCodeError !== ""}
              helperText={zipCodeError}
              disabled={submitting}
              onChange={(e) => setZipCode(e.target.value)}
            />
          </Grid>
        </Grid>

        <Divider sx={{ marginY: "1rem" }} />

        <Grid container spacing={spacing}>
          <Grid item xs={12} md={4}>
            <TextField
              name='managerName'
              value={managerName}
              label='Property Manager Name'
              disabled={submitting}
              onChange={(e) => setManagerName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              name='managerEmail'
              value={managerEmail}
              label='Property Manager Email'
              error={
                managerEmail && !validator.isEmail(managerEmail) ? true : false
              }
              helperText={
                managerEmail && !validator.isEmail(managerEmail)
                  ? "Invalid Email"
                  : ""
              }
              disabled={submitting}
              onChange={(e) => setManagerEmail(e.target.value.trim())}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              name='managerPhone'
              value={managerPhone}
              label='Property Manager Phone'
              disabled={submitting}
              onChange={(e) => setManagerFormatNumber(e)}
              placeholder={MixConstants.PhonePlaceholder}
            />
          </Grid>
        </Grid>

        <Divider sx={{ marginY: "1rem" }} />

        <Grid container spacing={spacing}>
          <Grid item xs={12} md={8}>
            <TextField
              name='website'
              value={website}
              label='Website'
              disabled={submitting}
              onChange={(e) => setWebsite(e.target.value.trim())}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              type='number'
              name='numberOfUnits'
              value={numberOfUnits}
              label='Number of Units'
              disabled={submitting}
              onChange={(e) => setNumberOfUnits(e.target.value)}
            />
          </Grid>
        </Grid>
      </Box>
    );
  };

  if (fetchingCommunityDetails) {
    return (
      <StyledSpinnerContainer>
        <CircularProgress size={20} />
      </StyledSpinnerContainer>
    );
  }

  return (
    <Container>
      {renderTextFields()}

      <Box>
        <Divider sx={{ marginTop: "1rem" }} />
        <Grid container spacing={spacing} alignItems='center' marginTop='0rem'>
          <Grid item xs={12} md={logo ? 5.4 : 6}>
            <Typography display='inline'>
              If you have brand logo for this property, please upload it here.
            </Typography>{" "}
          </Grid>
          <Grid item xs={12} md={logo ? 5.3 : 6}>
            <FileUploadContainer
              accept='.pdf, .png, .jpg, .ai, .psd, .eps'
              helperText='.png or .jpg atleast 600px or original design files'
              padding='1rem'
              onFileDrop={handleDroppedLogoFile}
              maxFiles={1}
            />
          </Grid>
          {logo && (
            <Grid item xs={12} md={1}>
              <FileGridItem
                file={logo.originalFile ? logo.originalFile : logo}
                onClickView={() => viewLogoButtonClicked()}
                onClickDownload={() =>
                  saveAs(logo?.originalFile ? logo?.originalFile : logo?.photo)
                }
                onClickRemove={() => removeLogoFileButtonClicked()}
              />
            </Grid>
          )}
        </Grid>
      </Box>

      {brandFeatureAccess && (
        <Box>
          <Divider sx={{ marginTop: "1rem" }} />
          <Grid
            container
            spacing={spacing}
            alignItems='center'
            marginTop='0rem'
          >
            <Grid item xs={12} md={brandGuidelinesPdfFile ? 5.5 : 6}>
              <Typography display='inline'>
                If you have brand guidelines for this property, please upload
                them here.
              </Typography>{" "}
              <Typography display='inline' sx={{ fontStyle: "italic" }}>
                (Optional)
              </Typography>
            </Grid>
            <Grid item xs={12} md={brandGuidelinesPdfFile ? 5.5 : 6}>
              <FileUploadContainer
                accept='.pdf'
                helperText='.pdf'
                padding='1rem'
                onFileDrop={handleDroppedPdfFile}
                maxFiles={1}
              />
            </Grid>
            {brandGuidelinesPdfFile && (
              <Grid item xs={12} md={1}>
                <FileGridItem
                  file={brandGuidelinesPdfFile}
                  onClickDownload={() =>
                    saveAs(brandGuidelinesPdfFile?.originalFile)
                  }
                  onClickRemove={() => removePdfFileButtonClicked()}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      )}

      {message && messageType && (
        <Box>
          <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
          <FormAlert
            message={message}
            type={messageType}
            onClose={() => formAlertCloseButtonClicked()}
          />
        </Box>
      )}

      <Box>
        <Divider sx={{ marginTop: "1rem" }} />
        <StyledFooter>
          <Button
            bgColor={theme.colors.gray}
            titleColor={theme.colors.white}
            title='Save & Finish Later'
            disabled={submitting}
            onClick={() => saveAndSubmitButtonClicked()}
          />
          <Button
            bgColor={theme.colors.green}
            title='Submit'
            disabled={
              status !== MixConstants.FormStatus.DEPLOYED ? submitting : true
            }
            loading={submitting}
            onClick={() => submitButtonClicked()}
          />
        </StyledFooter>
      </Box>

      <ImageViewer
        show={isGalleryOpen}
        photos={filesToView}
        onClose={() => setIsGalleryOpen(false)}
      />
    </Container>
  );
}

export default CommunityDetailForm;
