import React, { useCallback, useState, useEffect } from "react";
import { saveAs } from "file-saver";
import Grid from "@mui/material/Grid";
import Button from "components/button";
import Box from "@mui/material/Box";
import FormAlert from "components/form-alert";
import { FormApi } from "apis";
import TextField from "components/textfield";
import Divider from "@mui/material/Divider";
import { useTheme } from "styled-components";
import ImageViewer from "components/image-viewer";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Container, StyledFooter, StyledSpinnerContainer } from "../style";
import FileGridItem from "components/files-grid-list/file-grid-item";
import MixConstants from "constants/mix-constants";
import _ from "lodash";
import FileUploadContainer from "components/file-upload-container";
import Utils from "helpers/utils";

function DesignDirectionForm({ expanded, checkStatus, status, closeForm, design }) {
  const theme = useTheme();
  const spacing = 2;
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [activePhotoIndex, setActivePhotoIndex] = useState(0);
  const [fetchingSpecs, setFetchingSpecs] = useState(false);
  const [message, setMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [saveAndFinsh, setSaveAndFinish] = useState(false);
  const [messageType, setMessageType] = useState("");

  const generateEmptyHexColors = () => {
    return {
      1: "",
      2: "",
      3: "",
      4: "",
      5: ""
    }
  }

  const [designDirection, setDesignDirection] = useState({
    id: _.uniqueId(),
    image: "",
    file: "",
    hexColors: generateEmptyHexColors(),
    direction: "",
    additionalDirection: "",
    type: "",
  })

  const formAlertCloseButtonClicked = () => {
    setMessage("");
    setMessageType("");
  };

  const setMessageText = (text, type) => {
    setMessage(text);
    setMessageType(type)
    setTimeout(() => {
      formAlertCloseButtonClicked();
    }, 5000);
  };

  const { communityId } = useParams();

  const getMappedImageForClient = (image) => {
    if (image?.url) {
      return {
        type: 'image',
        photo: image?.url,
        isLocal: false,
      }
    }
  }

  const getMappedFileForClient = (file) => {
    if (file?.url) {
      return {
        type: 'file',
        file: file?.url,
        isLocal: false,
      }
    }
  }

  const getMappedDesignDirectionForClient = useCallback((data) => {
    if (data) {
      return {
        id: data?.id,
        image: getMappedImageForClient(data?.image),
        hexColors: data?.hex_colors ? JSON.parse(data?.hex_colors) : generateEmptyHexColors(),
        direction: data?.direction,
        additionalDirection: data?.additional_direction,
        file: getMappedFileForClient(data?.file),
        type: getMappedImageForClient(data?.image) ? "image" : "file",
        isLocal: false,
      }
    }
  }, [])

  const getMappedUploadedImage = (image) => {
    return {
      type: 'image',
      photo: image?.originalFile,
      isLocal: true,
    }
  }

  const fetchDesignDirection = useCallback(() => {
    if (expanded) {
      if (communityId) {
        setFetchingSpecs(true);
        FormApi.fetchDesignDirection(communityId).then((response) => {
          setFetchingSpecs(false);
          if (response.success) {
            let { data } = response;
            const mappedData = getMappedDesignDirectionForClient(data);
            setDesignDirection(mappedData);
          }
        }, (error) => {
          setFetchingSpecs(false);
        })
      }
    }
  }, [expanded, communityId, getMappedDesignDirectionForClient])

  const viewFileButtonClicked = (index) => {
    setIsGalleryOpen(true);
    setActivePhotoIndex(index);
  };

  const removeFileButtonClicked = () => {
    if (designDirection?.image?.isLocal || designDirection?.file?.isLocal) {
      if (designDirection?.type === "image") {
        setDesignDirection({
          ...designDirection,
          image: null,
        });
      } else {
        setDesignDirection({
          ...designDirection,
          file: null,
        });
      }
    } else {
      if (communityId && designDirection.id) {
        setSubmitting(true);
        const successMessage = "Design Direction image deleted successfully";
        const errorMessage = "Failed to delete design direction image";
        FormApi.deleteDesignDirectionImage(communityId, designDirection.id, designDirection.type).then((response) => {
          setSubmitting(false);
          if (response.success) {
            const mappedData = getMappedDesignDirectionForClient(response?.data);
            setDesignDirection(mappedData);
            setMessageText(successMessage, "success");
            checkStatus();
          }
        }, (error) => {
          setSubmitting(false);
          setMessageText(errorMessage, "error");
        });
      }
    }
  };

  const sendRequestToBE = () => {

    let sendRequest = false;
    if (designDirection.images) {
      sendRequest = true;
      return true;
    }
    if (designDirection?.additionalDirection) {
      sendRequest = true;
      return true;
    }
    if (designDirection?.direction) {
      sendRequest = true;
      return true;
    }
    if (designDirection?.file) {
      sendRequest = true;
      return true;
    }
    for (const color in designDirection?.hexColors) {
      if (designDirection?.hexColors[color] !== "") {
        sendRequest = true;
        return true;
      }
    }
    return sendRequest;
  }

  const canUserProceed = () => {
    let hasError = false;
    return !hasError;
  }

  const getMappedDataForServer = () => {
    return {
      id: designDirection?.id,
      hex_colors: JSON.stringify(designDirection?.hexColors),
      image: designDirection?.image?.isLocal ? designDirection?.image?.photo : null,
      file: designDirection?.file?.isLocal ? designDirection?.file?.originalFile : null,
      direction: designDirection?.direction,
      additional_direction: designDirection?.additionalDirection
    }
  }

  const onSaveAndSubmitButtonClicked = () => {
    if (sendRequestToBE()) {
      const data = getMappedDataForServer();
      if (communityId && designDirection.id) {
        setSaveAndFinish(true);
        FormApi.updateDesignDirection(communityId, designDirection.id, data, "in_progress").then((response) => {
          setSaveAndFinish(false);
          if (response?.success) {
            let { data } = response;
            const getMappedData = getMappedDesignDirectionForClient(data);
            setDesignDirection(getMappedData)
            checkStatus();
          }
        }, (error) => {
          setSaveAndFinish(false);
        })
      }
      closeForm();
    } else {
      closeForm();
    }
  }

  const onSubmitButtonClicked = () => {
    if (canUserProceed()) {
      setSubmitting(true);
      const data = getMappedDataForServer();
      if (communityId && designDirection.id) {
        const successMessage = "Design Direction updated successfully";
        const errorMessage = "Failed to save design direction";
        FormApi.updateDesignDirection(communityId, designDirection.id, data).then((response) => {
          setSubmitting(false);
          if (response?.success) {
            let { data } = response;
            const getMappedData = getMappedDesignDirectionForClient(data);
            setDesignDirection(getMappedData)
            setMessageText(successMessage, "success");
            checkStatus();
          }
        }, (error) => {
          setSubmitting(false);
          setMessageText(errorMessage, "error");
        })
      }
    }
  }

  const handleDroppedFile = (files) => {
    if (files && files.length > 0) {
      if (Utils.isImageVideo(files[0])) {
        setDesignDirection({
          ...designDirection,
          image: getMappedUploadedImage(files[0]),
          file: "",
          type: "image",
        })
      } else {
        setDesignDirection({
          ...designDirection,
          image: "",
          file: files[0],
          type: "file",
        })
      }
    }
  };

  useEffect(() => {
    let abortController = new AbortController();
    fetchDesignDirection(abortController);
    return () => {
      abortController.abort();
    }
  }, [fetchDesignDirection]);

  if (fetchingSpecs) {
    return (
      <StyledSpinnerContainer>
        <CircularProgress size={20} />
      </StyledSpinnerContainer>
    );
  }

  const handleDirectionChange = (e) => {
    let direction = designDirection?.direction;
    direction = e.target.value;
    setDesignDirection({
      ...designDirection,
      direction: direction,
    })
  }

  const handleAdditionalDirectionChange = (e) => {
    let additionalDirection = designDirection?.additionalDirection;
    additionalDirection = e.target.value;
    setDesignDirection({
      ...designDirection,
      additionalDirection: additionalDirection,
    })
  }

  const handleHexColorsChange = (e, index) => {
    let hexColor = designDirection?.hexColors;
    hexColor[`${index}`] = e.target.value;
    setDesignDirection({
      ...designDirection,
      hexColors: hexColor,
    })
  }

  const getLinkForDownload = () => {
    if (designDirection.type === "image") {
      saveAs(designDirection.image?.isLocal ? designDirection.image?.photo : designDirection.image?.photo, "Design Direction");
    } else if (designDirection.type === "file") {
      saveAs(designDirection.file?.isLocal ? designDirection.file?.originalFile : designDirection.file?.file, "Design Direction");
    }
  }

  return (
    <Container>
      <Grid
        container
        spacing={spacing}
        alignItems='center'
        marginTop='0rem'
        marginBottom='1rem'
      >
        <Grid item xs={12} md={designDirection.image || designDirection.file ? 5.5 : 6}>
          <Typography>
            Upload files here, such as graphical standards guides, brand guide, or brochures, to help us understand your branding better.
          </Typography>
        </Grid>
        <Grid item xs={12} md={designDirection.image || designDirection.file ? 5 : 6}>
          <FileUploadContainer
            padding='1rem'
            helperText=''
            onFileDrop={handleDroppedFile}
            maxFiles={1}
          />
        </Grid>
        {(designDirection.image || designDirection.file) && (
          <Grid item xs={12} md={1}>
            <FileGridItem
              file={_.union(designDirection?.file, designDirection?.image)}
              onClickView={() => viewFileButtonClicked()}
              onClickDownload={getLinkForDownload}
              onClickRemove={() => removeFileButtonClicked()}
            />
          </Grid>
        )}
      </Grid>
      <Divider />
      <Grid item xs={12} md={12}>
        <Typography sx={{ mt: 2, mb: 1 }}>
          Hex colors (Optional)
        </Typography>
      </Grid>
      <Grid container spacing={2} paddingBottom='1rem'>
        <Grid item xs={12} md={6}>
          <TextField
            label='Hex Color Code 1'
            name='Hex Color 1'
            value={designDirection?.hexColors["1"] ?? ""}
            onChange={(e) => { handleHexColorsChange(e, 1) }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label='Hex Color Code 2'
            name='Hex Color 2'
            value={designDirection?.hexColors["2"] ?? ""}
            onChange={(e) => { handleHexColorsChange(e, 2) }}
          />
        </Grid>
      </Grid>
      {design === "Expressionist" && (
        <Grid container spacing={2} paddingBottom='1rem'>
          <Grid item xs={12} md={4}>
            <TextField
              label='Hex Color Code 3 '
              name='Hex Color 3'
              value={designDirection?.hexColors["3"] ?? ""}
              onChange={(e) => { handleHexColorsChange(e, 3) }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label='Hex Color Code 4'
              name='Hex Color 4'
              value={designDirection?.hexColors["4"] ?? ""}
              onChange={(e) => { handleHexColorsChange(e, 4) }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label='Hex Color Code 5'
              name='Hex Color 5'
              value={designDirection?.hexColors["5"] ?? ""}
              onChange={(e) => { handleHexColorsChange(e, 5) }}
            />
          </Grid>
        </Grid>
      )}
      <Divider />
      <Grid container mt={1} spacing={2} paddingBottom='1rem'>
        <Grid item xs={12} md={12}>
          <Typography>
            Please add any other details here that will give us a better understanding of your brand.
          </Typography>
          <TextField
            multiline
            rows={3}
            name='direction'
            value={designDirection?.direction ?? ""}
            onChange={handleDirectionChange}
          />
        </Grid>
        {design === "Expressionist" && (
          <Grid item xs={12} md={12}>
            <Typography>
              Additional Direction (Optional)
            </Typography>
            <TextField
              multiline
              rows={3}
              name='additionalDirection'
              value={designDirection?.additionalDirection ?? ""}
              onChange={handleAdditionalDirectionChange}
            />
          </Grid>
        )}
      </Grid>

      {message && messageType && (
        <Box>
          <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
          <FormAlert
            message={message}
            type={messageType}
            onClose={() => formAlertCloseButtonClicked()}
          />
        </Box>
      )}

      <StyledFooter>
        <Button
          bgColor={theme.colors.gray}
          titleColor={theme.colors.white}
          disabled={saveAndFinsh}
          loading={saveAndFinsh}
          title='Save & Finish Later'
          onClick={onSaveAndSubmitButtonClicked}
        />
        <Button bgColor={theme.colors.green} title='Submit' disabled={status !== MixConstants.FormStatus.DEPLOYED ? submitting : true}
          loading={submitting} onClick={onSubmitButtonClicked} />
      </StyledFooter>

      {designDirection?.image && (
        <ImageViewer
          show={isGalleryOpen}
          photos={[designDirection.photo]}
          activePhotoIndex={activePhotoIndex}
          onClose={() => setIsGalleryOpen(false)}
        />
      )}
    </Container>
  );
}

export default DesignDirectionForm;
