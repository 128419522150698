import React from "react";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { StyledAvatar, VerticalStyledBox } from "./style";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box } from "@mui/system";
import handleFileSource from "helpers/fileHandler";

function FileItem({ file, onClickView, onClickDownload, onClickRemove }) {
  return (
    <Box display='flex' alignItems={"flex-start"}>
      <VerticalStyledBox>
        <Stack spacing={2} direction='row' alignItems='center'>
          <StyledAvatar
            alt='file thumbnail'
            variant='rounded'
            src={handleFileSource(file)}
          />
        </Stack>
        <Stack direction='row'>
          <Tooltip title='View'>
            <IconButton onClick={onClickView} size='small'>
              <VisibilityIcon fontSize='small' />
            </IconButton>
          </Tooltip>

          <Tooltip title='Download'>
            <IconButton onClick={onClickDownload} size='small'>
              <FileDownloadIcon fontSize='small' />
            </IconButton>
          </Tooltip>

          <Tooltip title='Remove'>
            <IconButton onClick={onClickRemove} size='small'>
              <ClearIcon fontSize='small' />
            </IconButton>
          </Tooltip>
        </Stack>
      </VerticalStyledBox>
    </Box>
  );
}

export default FileItem;
