import styled from "styled-components";
import LoadingButton from "@mui/lab/LoadingButton";

export const StyledButton = styled(LoadingButton)`
  width: ${({ width }) => width};
  color: ${({ titlecolor }) => titlecolor};
  background-color: ${({ theme, bgcolor, disabled }) =>
    disabled ? theme.colors.lightGray : bgcolor};
  border-radius: 2rem;
  font-size: 0.85rem;
  font-weight: 400;
  padding: 0.3rem 0.6rem;
  text-transform: none;

  & > img {
    width: 1.3rem;
    height: 1.3rem;
    object-fit: contain;
    margin-left: 0.5rem;
  }

  &:hover {
    background-color: ${({ bgcolor }) => bgcolor};
  }
`;
