import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import { CommunityApi } from "apis";
import Modal from "components/modal";
import TextField from "components/textfield";
import { useTheme } from "styled-components";
import FormAlert from "components/form-alert";
import CommentView from "components/comment-view";
import { VerticalScrollBar } from "components/scrollbar/style";

function CommentsModal({
  open = true,
  title = "Comments",
  textFieldLabel = "Comment",
  community,
  onClose,
}) {
  const [submittingComment, setSubmittingComment] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [message, setMessage] = useState("");
  const [comment, setComment] = useState("");
  const theme = useTheme();

  const commentsEndRef = useRef(null);
  const communityId = community?.community?.id;
  let comments = community?.community?.comments;

  const scrollToBottom = () => {
    setTimeout(() => {
      commentsEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 1000);
  };

  useEffect(() => {
    scrollToBottom();
  }, [comments]);

  const actionButtons = [
    {
      id: 1,
      title: "Cancel",
      bgColor: theme.colors.lightGray,
      titleColor: theme.colors.white,
      disabled: submittingComment,
      onClick: onClose,
    },
    {
      id: 2,
      title: "Submit",
      bgColor: theme.colors.green,
      titleColor: theme.colors.white,
      loading: submittingComment,
      disabled: submittingComment,
      onClick: () => submitButtonClicked(),
    },
  ];

  const handleCommentFieldChange = (e) => {
    setComment(e.target.value);
  };

  const submitButtonClicked = () => {
    formAlertCloseButtonClicked();
    setSubmittingComment(true);

    if (communityId) {
      const successMessage = "Comment added successfully";
      const errorMessage = "Failed to save comment";

      CommunityApi.addComment(communityId, comment).then(
        (response) => {
          setSubmittingComment(false);

          if (response?.data?.id) {
            comments.push(response.data);
            scrollToBottom();
            setComment("");
            setMessageText(successMessage, "success");
          } else {
            setMessageText(errorMessage, "error");
          }
        },
        (error) => {
          setSubmittingComment(false);
          setMessageText(errorMessage, "error");
        },
      );
    }
  };

  const setMessageText = (text, type) => {
    setMessage(text);
    setMessageType(type);

    setTimeout(() => {
      formAlertCloseButtonClicked();
    }, 5000);
  };

  const formAlertCloseButtonClicked = () => {
    setMessage("");
  };

  const renderCommentsList = () => {
    if (comments && comments.length > 0) {
      return (
        <VerticalScrollBar>
          {comments.map((comment, index) => (
            <CommentView key={index} comment={comment} />
          ))}
          {message && messageType && (
            <Box sx={{ paddingTop: "0rem" }}>
              <FormAlert
                message={message}
                type={messageType}
                onClose={() => formAlertCloseButtonClicked()}
              />
            </Box>
          )}
          <Box ref={commentsEndRef} />
        </VerticalScrollBar>
      );
    }
  };

  return (
    <Modal
      open={open}
      title={title}
      onClose={submittingComment ? null : onClose}
      footerButtons={actionButtons}
    >
      <Box>{renderCommentsList()}</Box>
      <Box sx={{ paddingTop: "1rem" }}>
        <TextField
          label={textFieldLabel}
          autoFocus
          multiline
          rows={3}
          disabled={submittingComment}
          value={comment}
          onChange={handleCommentFieldChange}
        />
      </Box>
    </Modal>
  );
}

export default CommentsModal;
