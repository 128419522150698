import { combineReducers } from "redux";
import loginReducer from "./login-reducer";
import signupReducer from "./signup-reducer";
import logoutReducer from "./logout-reducer";
import messageReducer from "./message-reducer";
import communityFilterReducer from "./community-filter-reducer";

const allReducers = combineReducers({
  login: loginReducer,
  signup: signupReducer,
  logout: logoutReducer,
  message: messageReducer,
  filter: communityFilterReducer,
});

export default allReducers;
