import React, { useState } from "react";
import { FormApi } from "apis";
import Box from "@mui/material/Box";
import Modal from "components/modal";
import { Container, Divider } from "@mui/material";
import TextField from "components/textfield";
import { useTheme } from "styled-components";
import FormAlert from "components/form-alert";
import { useIsMounted } from "hooks/use-is-mounted";
import TextEditor from "components/texteditor";

function SubmitForReviewEmailModal({
  open = true,
  onClose,
  email,
  updateEmail,
  emailSent,
}) {
  const [sendingEmail, setSendingEmail] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [message, setMessage] = useState("");
  const theme = useTheme();

  const isMounted = useIsMounted();

  const submitButtonClicked = () => {
    const errorMessage = "Error occurred while sending emails";
    if (email.communityId && email.communityUserId){
      setSendingEmail(true);
      FormApi.sendFollowUpEmails(email.communityId, email.communityUserId, email.email, email.subject, email.body).then((response) => {
        setSendingEmail(false);
        if (response.success) {
          emailSent(true)
          onClose();
        }
      }, (error) => {
        setSendingEmail(false);
        setMessageText(errorMessage, "error");
      })
    }
  }

  const actionButtons = [
    {
      id: 1,
      title: "Cancel",
      bgColor: theme.colors.lightGray,
      titleColor: theme.colors.white,
      disabled: sendingEmail,
      onClick: onClose,
    },
    {
      id: 2,
      title: "Send Email",
      bgColor: theme.colors.green,
      titleColor: theme.colors.white,
      loading: sendingEmail,
      disabled: sendingEmail,
      onClick: () => submitButtonClicked(),
    },
  ];

  const handleEmailSubjectChange = (e) => {
    updateEmail({
      ...email,
      subject: e.target.value,
    })
  };

  const handleEmailAddressChange = (e) => {
    updateEmail({
      ...email,
      email: e.target.value,
    })
  }

  const handleEmailBodyChange = (e) => {
    updateEmail({
      ...email,
      body: e,
    })
  }

  const setMessageText = (text, type) => {
    setMessage(text);
    setMessageType(type);

    setTimeout(() => {
      formAlertCloseButtonClicked();
    }, 5000);
  };

  const formAlertCloseButtonClicked = () => {
    if (isMounted.current) {
      setMessage("");
    }
  };

  return (
    <Modal
      open={open}
      title={"Email Preview"}
      onClose={sendingEmail ? null : onClose}
      footerButtons={actionButtons}
    >
      <Container>
        <Box sx={{ paddingTop: "1rem" }}>
        <TextField
          label={"Address"}
          multiline
          rows={1}
          disabled={sendingEmail}
          value={email.email ? email.email : ""}
          onChange={handleEmailAddressChange}
        />
      </Box>
        <Box sx={{ paddingTop: "1rem" }}>
        <TextField
          label={"Subject"}
          multiline
          rows={1}
          disabled={sendingEmail}
          value={email.subject ? email.subject : ""}
          onChange={handleEmailSubjectChange}
        />
      </Box>
      <Box sx={{ paddingTop: "1rem" }}>
      <TextEditor html={email.body ? email.body : ""} onChange={handleEmailBodyChange}/>
      </Box>
      </Container>
      {message && messageType && (
        <Box>
          <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
          <FormAlert
            message={message}
            type={messageType}
            onClose={() => formAlertCloseButtonClicked()}
          />
        </Box>
      )}
    </Modal>
  );
}

export default SubmitForReviewEmailModal;
