import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import _ from "lodash";

export default function FileUploadErrorModal({
  openModal,
  closeModal,
  fileErrors,
  maxFiles,
}) {
  let errors = [];
  const errorFilesWithCode = (error) => {
    const errorMessage = error.errors[0];
    let errorCode = errorMessage.code;
    const fileName = error.file.name;
    const fileType = error.file.type;
    if (errors.length > 0) {
      errors.forEach((error) => {
        if (error?.code === errorCode && error?.fileType === fileType) {
          error.files.push(fileName);
        } else {
          errors.push({
            code: errorCode,
            files: [fileName],
            fileType: fileType,
          });
        }
      });
    } else {
      errors.push({
        code: errorCode,
        files: [fileName],
        fileType: fileType,
      });
    }
  };

  fileErrors.forEach((error) => {
    errorFilesWithCode(error);
  });

  const readableMessage = (error) => {
    switch (error.code) {
      case "video-too-large":
        return `Video file size too large. Please re-submit files that are maximum of 500MB each`;
      case "image-too-large":
        return `Image file size too large. Please re-submit files that are maximum of 2MB and minimum 2000 pixels wide`;
      case "too-many-files":
        return `Maximum file(s) allowed ${maxFiles}`;
      case "file-invalid-type":
        return `File type not allowed`;
      default:
        return `Undetected Error`;
    }
  };

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={closeModal}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {"Please look into the following issues."}
        </DialogTitle>
        <DialogContent>
          <Divider />
          <Box id='alert-dialog-description'>
            {errors.map((error) => (
              <Typography key={_.uniqueId()}>
                {readableMessage(error)}
              </Typography>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
