import React from "react";
import Box from "@mui/material/Box";
import moment from "moment";
import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';
import CircularProgress from "components/circular-progress";
import AccordionArrowButton from "../accordion-arrow-button";
import AccordionActionButtons from "../accordion-action-buttons";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/ErrorOutlineTwoTone';
import { TitleAndStatusBox } from "../accordion-form-header/style";
import {
  KeyValueTextsBox,
  StyledSubTitleName,
  StyledSubTitleValue,
  CircularProgressContainer,
} from "./style";
import {
  StyledStatus,
  StyledAccordionHeader,
  StyledAccordionHeaderContent,
  StyledAccordionHeaderTextContentContainer,
} from "../style";
import MixConstants from "constants/mix-constants";
const RejectMessageTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'black',
    minWidth: 390,
    minHeight: 90,
    display: "flex",
    flex: 1, justifyContent: 'center', alignItems:"center",
    fontSize: "1rem",
    border: '1px solid #F36538',
  },
}));

function AccordionFormProgressHeader({
  progressCircleSize = 14,
  firstPercentage,
  firstPercentageTitle,
  firstPercentageColor,
  secondPercentage,
  secondPercentageTitle,
  secondPercentageColor,
  title,
  status,
  expanded,
  onClickExpand,
  subTitle1Name,
  subTitle1Value,
  subTitle2Name,
  subTitle2Value,
  subTitle3Name,
  subTitle3Value,
  actionButtons,
  iconActionButtons,
  showCancelIcon,
  productionStarted,
  releaseDate,
  submittedFinalApproval,
}) {
  return (
    <StyledAccordionHeader>
      <Box sx={{ flexGrow: 1 }}>
        <StyledAccordionHeaderContent>
          <CircularProgressContainer size={progressCircleSize}>
            <CircularProgress
              size={progressCircleSize}
              outerCirclePercentage={firstPercentage}
              innerCirclePercentage={secondPercentage}
              outerCircleColor={firstPercentageColor}
              innerCircleColor={secondPercentageColor}
              outerPercentageTitle={firstPercentageTitle}
              innerPercentageTitle={secondPercentageTitle}
            />
          </CircularProgressContainer>
          <StyledAccordionHeaderTextContentContainer>
            <TitleAndStatusBox>
              <Box sx={{display: "flex", maxWidth: 450}}>
                  <Typography variant='h6'>{title}</Typography>
                  {status && (
                    <StyledStatus variant='subtitle2'>{status}</StyledStatus>
                  )}
                {showCancelIcon && (
                  <Typography sx={{maxWidth: 22, m:0 , p:0 }}>{<RejectMessageTooltip placement={"right"} title={MixConstants.FormStatus.REJECTED_TOOLTIP_MESSAGE}>
                    <CancelIcon sx={{fontSize:35, ml:1}} color={"warning"} />
                </RejectMessageTooltip>}</Typography>
                )}
              </Box>

            </TitleAndStatusBox>
            <KeyValueTextsBox>
              <Box>
                
                <Box sx={{ display: "flex"}}>
                  <StyledSubTitleName sx={{lineHeight: 1}}>{subTitle2Name}&nbsp;</StyledSubTitleName>
                  <StyledSubTitleValue sx={{ maxWidth: 425, lineHeight: 1 }}>{(typeof subTitle2Value === 'string') ? subTitle2Value.replace(/Self/g, "Pynwheel") : ""}</StyledSubTitleValue>
                </Box>

                <Box sx={{ display: "flex"}}>
                  <StyledSubTitleName  sx={{lineHeight: 1}}>{subTitle1Name}&nbsp;</StyledSubTitleName>
                  <StyledSubTitleValue sx={{lineHeight: 1}}>{subTitle1Value}</StyledSubTitleValue>
                </Box>

                {productionStarted && (
                  <Box sx={{ display: "flex" }}>
                    <StyledSubTitleName sx={{lineHeight: 1}}>Production Started: &nbsp;</StyledSubTitleName>
                    <StyledSubTitleValue sx={{lineHeight: 1}}>{moment(productionStarted).format("D MMM YYYY",)}</StyledSubTitleValue>
                  </Box>
                )}
                {submittedFinalApproval && (
                  <Box sx={{ display: "flex" }}>
                    <StyledSubTitleName sx={{lineHeight: 1}}>Submitted for Review: &nbsp;</StyledSubTitleName>
                    <StyledSubTitleValue sx={{lineHeight: 1}}>{moment(submittedFinalApproval).format("D MMM YYYY",)}</StyledSubTitleValue>
                  </Box>
                )}
                {releaseDate && (
                  <Box sx={{ display: "flex" }}>
                    <StyledSubTitleName sx={{lineHeight: 1}}>Released: &nbsp;</StyledSubTitleName>
                    <StyledSubTitleValue sx={{lineHeight: 1}}>{moment(releaseDate).format("D MMM YYYY",)}</StyledSubTitleValue>
                  </Box>
                )}
              </Box>
            </KeyValueTextsBox>
          </StyledAccordionHeaderTextContentContainer>
        </StyledAccordionHeaderContent>
      </Box>

      <AccordionActionButtons buttons={iconActionButtons} />
      <AccordionActionButtons buttons={actionButtons} />

      <AccordionArrowButton expanded={expanded} onClick={onClickExpand} />
    </StyledAccordionHeader>
  );
}

export default AccordionFormProgressHeader;
