import communityFilterConstants from "redux/constants/community-filter-constants";

const initialState = {
  selectedProductTypes: ["All Products"],
  selectedStatus: ["All Statuses"],
}

export default function communityFilterReducer(state = initialState, action) {
  switch(action.type){
    case communityFilterConstants.UPDATE_PRODUCT_LIST:
      return {
        ...state,
        selectedProductTypes: action.values
      };
    case communityFilterConstants.UPDATE_STATUSES_LIST:
      return {
        ...state,
        selectedStatus: action.values
      }

    default:
      return state;
  }
}