import { FormApi } from "apis";
import { theme } from "themes/theme";
import Button from "components/button";
import HeaderSection from "./header-section";
import { useParams } from "react-router-dom";
import AmenitiesList from "./amenities-list";
import FormAlert from "components/form-alert";
import CreateAmenityModal from "./create-amenity-modal";
import { Divider, Box, CircularProgress } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Container, StyledFooter, StyledSpinnerContainer } from "../style";

const AmenityImagesForm = ({
  expanded,
  checkStatus,
  closeForm,
  selfTourEnabled,
}) => {
  const [fetchingAmenitiesImages, setFetchingAmenitiesImages] = useState(false);
  const [openCreateAmenityModal, setOpenCreateAmenityModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [saveAndFinsih, setSaveAndFinish] = useState(false);
  const [amenities, setAmenities] = useState([]);
  const [messageType, setMessageType] = useState("");
  const [message, setMessage] = useState("");

  let { communityId } = useParams();

  const getMappedData = (dataList) => {
    if (dataList && dataList.length > 0) {
      const mappedData = dataList.map((data) => {
        let amenityGalleries = data?.amenity_galleries;
        let amenityImage = data?.amenity_image;

        amenityGalleries = amenityGalleries.map((amenityGallery) => {
          return {
            ...amenityGallery,
            isLocal: false,
            photo: amenityGallery?.image?.url,
          };
        });

        amenityImage = {
          isLocal: false,
          photo: data?.amenity_image?.url,
        };

        return {
          ...data,
          amenity_image: amenityImage,
          amenity_galleries: amenityGalleries,
        };
      });

      return mappedData;
    }

    return [];
  };

  const fetchAmenities = useCallback(
    (abortController) => {
      if (expanded) {
        if (communityId) {
          setFetchingAmenitiesImages(true);
          setAmenities([]);

          FormApi.fetchAmenities(communityId).then(
            (response) => {
              const aborted = abortController.signal.aborted;

              if (aborted === false) {
                setFetchingAmenitiesImages(false);
                if (response?.success) {
                  const mappedData = getMappedData(response?.data);
                  setAmenities(mappedData);
                }
              }
            },
            (error) => {
              setFetchingAmenitiesImages(false);
            },
          );
        }
      }
    },
    [communityId, expanded],
  );

  useEffect(() => {
    let abortController = new AbortController();
    fetchAmenities(abortController);
    return () => {
      abortController.abort();
    };
  }, [fetchAmenities]);

  const newAmenityCreatedSuccessfully = (amenity) => {
    // updateAmenityStatus("in_progress");

    let amenityImage = amenity;
    amenityImage.amenity_image.photo = amenityImage.amenity_image.url;

    let amenitiesList = [...amenities];
    amenitiesList.push(amenityImage);
    setAmenities(amenitiesList);

    setOpenCreateAmenityModal(false);
  };

  // const handleUpdateAmenity = (amenity, index) => {
  //   const amenitiesList = [...amenities];
  //   amenitiesList[index] = amenity;
  //   setAmenities(amenitiesList);
  // };

  const handleDeleteAmenity = (amenity, index) => {
    if (amenity) {
      const successMessage = "Amenity deleted successfully";
      const errorMessage = "Failed to delete amenity";

      setSubmitting(true);
      FormApi.deleteAmenity(communityId, amenity.id).then(
        (response) => {
          setSubmitting(false);
          const amenitiesList = [...amenities];
          amenitiesList[index].updating = false;

          if (response?.success) {
            amenitiesList.splice(index, 1);
            // checkStatus();
            setMessageText(successMessage, "success");
          } else {
            setMessageText(errorMessage, "error");
          }
          setAmenities(amenitiesList);
        },
        (error) => {
          const amenitiesList = [...amenities];
          amenitiesList[index].updating = false;
          setAmenities(amenitiesList);

          setSubmitting(false);
          setMessageText(errorMessage, "error");
        },
      );
    }
  };

  const formAlertCloseButtonClicked = () => {
    setMessage("");
    setMessageType("");
  };

  const setMessageText = (text, type) => {
    setMessage(text);
    setMessageType(type);
    setTimeout(() => {
      formAlertCloseButtonClicked();
    }, 5000);
  };

  const userCanProceed = () => {
    if (amenities.length === 0) {
      setMessageText("Please add at least one amenity", "error");
      return false;
    }

    const hasMissingName =
      amenities.find(
        (amenity) => amenity?.name === "" || amenity?.name === null,
      ) !== undefined;

    const hasMissingImage =
      amenities.find(
        (amenity) =>
          amenity?.amenity_image === null ||
          amenity?.amenity_image === undefined,
      ) !== undefined;

    if (hasMissingName) {
      setMessageText("Please add amenity name.", "error");
      return false;
    } else if (hasMissingImage) {
      setMessageText("Please add amenity image.", "error");
      return false;
    }

    return true;
  };

  const getMappedDataForServer = (status) => {
    let data = { status: status };

    if (amenities.length > 0) {
      data.amenities = amenities.map((amenity) => {
        return {
          id: amenity?.id,
          name: amenity?.name,
          description: amenity?.description,
          amenity_type: amenity?.amenity_type,
          video_link: amenity?.video_link,
          amenity_galleries: amenity?.amenity_galleries?.filter(
            (g) => g.failed !== true,
          ),
        };
      });
    }
    return data;
  };

  const onSaveAndSubmitButtonClicked = () => {
    if (userCanProceed()) {
      setSaveAndFinish(true);
      const data = getMappedDataForServer("in_progress");

      FormApi.saveAmenities(communityId, data).then(
        (response) => {
          setSaveAndFinish(false);
          if (response.success) {
            checkStatus();
            closeForm();
          }
        },
        (error) => {
          setSaveAndFinish(false);
        },
      );
    }
  };

  const onSubmitButtonClicked = () => {
    if (userCanProceed()) {
      setSubmitting(true);
      const data = getMappedDataForServer("submitted");
      const successMessage = "Amenities saved successfully.";
      const errorMessage = "Failed to save amenity.";

      FormApi.saveAmenities(communityId, data).then(
        (response) => {
          setSubmitting(false);

          if (response.success) {
            let abortController = new AbortController();
            fetchAmenities(abortController);

            setMessageText(successMessage, "success");
            checkStatus();
          }
        },
        (error) => {
          setSubmitting(false);
          setMessageText(errorMessage, "error");
        },
      );
    }
  };

  if (fetchingAmenitiesImages) {
    return (
      <StyledSpinnerContainer>
        <CircularProgress size={20} />
      </StyledSpinnerContainer>
    );
  }

  return (
    <Container>
      <HeaderSection
        onClickAddNewAmenity={() => setOpenCreateAmenityModal(true)}
      />

      <Divider />

      <AmenitiesList
        communityId={communityId}
        amenities={amenities}
        onDelete={(amenity, index) => handleDeleteAmenity(amenity, index)}
        // onUpdate={(amenity, index) => handleUpdateAmenity(amenity, index)}
        selfTourEnabled={selfTourEnabled}
      />

      {message && messageType && (
        <Box>
          <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
          <FormAlert
            message={message}
            type={messageType}
            onClose={() => formAlertCloseButtonClicked()}
          />
        </Box>
      )}

      <Divider />

      <StyledFooter>
        <Button
          bgColor={theme.colors.gray}
          titleColor={theme.colors.white}
          title='Save & Finish Later'
          disabled={saveAndFinsih}
          loading={saveAndFinsih}
          onClick={onSaveAndSubmitButtonClicked}
        />
        <Button
          bgColor={theme.colors.green}
          title='Submit'
          disabled={submitting}
          loading={submitting}
          onClick={onSubmitButtonClicked}
        />
      </StyledFooter>

      {openCreateAmenityModal && (
        <CreateAmenityModal
          communityId={communityId}
          onClose={() => setOpenCreateAmenityModal(false)}
          onCreate={(amenity) => newAmenityCreatedSuccessfully(amenity)}
        />
      )}
    </Container>
  );
};

export default AmenityImagesForm;
