import updateStoreConstant from "../constants/update_store_constants";

const updateStoreActions = {
  updateCompanyStatus,
}

function updateCompanyStatus(user) {
  return { type: updateStoreConstant.UPDATE_COMPANY_STATUS, user };
}

export default updateStoreActions;
