import Box from "@mui/material/Box";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

export const StyledText = styled(Typography)`
  font-size: ${({ size }) => size};
  font-weight: ${({ weight }) => (weight ? weight : 400)};
  color: ${({ theme, color }) => (color ? color : theme.colors.white)};
  text-align: center;
`;

export const StyledNamedLogo = styled.img`
  height: 12rem;
  object-fit: contain;
  padding: 1rem;
`;

export const StyledLogo = styled.img`
  height: 5rem;
  object-fit: contain;
`;

export const CenteredBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;
