import React, { useCallback, useEffect, useState } from "react";
import { FormApi } from "apis";
import Box from "@mui/material/Box";
import Modal from "components/modal";
import { Divider } from "@mui/material";
import TextField from "components/textfield";
import { useTheme } from "styled-components";
import FormAlert from "components/form-alert";
import { useIsMounted } from "hooks/use-is-mounted";
import { useParams, useHistory } from "react-router-dom";
import Utils from "helpers/utils";
import { StyledSpinnerContainer } from "components/forms/style";
import CircularProgress from "@mui/material/CircularProgress";

function ReportIssueModal({
  open = true,
  title = "Report issue",
  textFieldLabel = "Report issue",
  reportIssue,
  onClose,
  onSave,
}) {
  const [submittingReport, setSubmittingReport] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [message, setMessage] = useState("");
  const [report, setReport] = useState("");
  const theme = useTheme();
  const [fetching, setFetching] = useState(false);

  const history = useHistory();
  const isMounted = useIsMounted();

  const { communityId } = useParams();
  const communityUserId = history?.location?.state?.params?.communityUserId;

  const actionButtons = [
    {
      id: 1,
      title: "Cancel",
      bgColor: theme.colors.lightGray,
      titleColor: theme.colors.white,
      disabled: submittingReport,
      onClick: onClose,
    },
    {
      id: 2,
      title: "Submit",
      bgColor: theme.colors.green,
      titleColor: theme.colors.white,
      loading: submittingReport,
      disabled: submittingReport,
      onClick: () => submitButtonClicked(),
    },
  ];

  const handleReportIssueFileChange = (e) => {
    setReport(e.target.value);
  };

  const submitButtonClicked = () => {
    if (report) {
      formAlertCloseButtonClicked();
      setSubmittingReport(true);

      if (communityId) {
        const successMessage = "Report issued successfully";
        const errorMessage = "Failed to report issue";

        FormApi.updateStatusAndRemarks(
          communityId,
          communityUserId,
          reportIssue.title,
          reportIssue.button,
          report,
        ).then(
          (response) => {
            setSubmittingReport(false);
            if (response?.success) {
              onSave(response.data);
              setMessageText(successMessage, "success");
            } else {
              setMessageText(errorMessage, "error");
            }
          },
          (error) => {
            setSubmittingReport(false);
            setMessageText(errorMessage, "error");
          },
        );
      }
    } else {
      setMessageText("Please write issue to submit!", "error");
    }
  };

  const setMessageText = (text, type) => {
    setMessage(text);
    setMessageType(type);

    setTimeout(() => {
      formAlertCloseButtonClicked();
    }, 5000);
  };

  const formAlertCloseButtonClicked = () => {
    if (isMounted.current) {
      setMessage("");
    }
  };

  const setReportedIssueText = useCallback((data) => {
    data?.community_detail_forms.map((form) => {
      if (form.name === reportIssue.title){
        const remarks = Utils.convertStatus(form.status);
        setReport(remarks.remarks);
      }
      return [];
    })
  }, [reportIssue]);

  const fetchReportIssue = useCallback((abortController) =>{
    if (communityId && communityUserId){
      setFetching(true);
      FormApi.fetchCommunity(communityId, communityUserId).then((response) => {
      setFetching(false);
        if(response.success){
          setReportedIssueText(response.data);
        }
      }, (error) => {
      setFetching(false);
      })
    }
  }, [communityId, communityUserId, setReportedIssueText]);

  useEffect(() => {
    const abortController = new AbortController();
    if (title === "Edit Issue"){
      fetchReportIssue(abortController);
    }
    return () => {
      abortController.abort();
    }
  }, [fetchReportIssue, title])

  return (
    <Modal
      open={open}
      title={title}
      onClose={submittingReport ? null : onClose}
      footerButtons={actionButtons}
    >
      {fetching ? (
        <Box>
          <StyledSpinnerContainer>
            <CircularProgress size={20} />
          </StyledSpinnerContainer>
        </Box>
      ) : (
        <Box sx={{ paddingTop: "1rem" }}>
          <TextField
            label={textFieldLabel}
            autoFocus
            multiline
            rows={3}
            disabled={submittingReport}
            value={report}
            onChange={handleReportIssueFileChange}
          />
        </Box>
      )}
      {message && messageType && (
        <Box>
          <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
          <FormAlert
            message={message}
            type={messageType}
            onClose={() => formAlertCloseButtonClicked()}
          />
        </Box>
      )}
    </Modal>
  );
}

export default ReportIssueModal;
