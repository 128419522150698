import React from "react";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";

function RadioButton({ label, disabled }) {
  return (
    <FormControlLabel
      control={<Radio />}
      value={label}
      label={label}
      disabled={disabled}
    />
  );
}

export default RadioButton;
