import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "components/button";
import _ from "lodash";
import Stack from "@mui/material/Stack";
import Checkbox from "components/checkbox";
import Divider from "@mui/material/Divider";
import { useTheme } from "styled-components";
import Typography from "@mui/material/Typography";
import { Container, StyledFooter, StyledSpinnerContainer } from "../style";
import FormAlert from "components/form-alert";
import CircularProgress from "@mui/material/CircularProgress";
import MixConstants from "constants/mix-constants";
import DeleteSecureLockConfirmationModal from "./lock-providers-data/delete-confirmation-modal";
import { useParams } from "react-router-dom";
import {
  OtherLock,
  IglooLock,
  DweloLock,
  LatchLock,
} from "./lock-providers-data";
import { FormApi } from "apis";

function LockProvidersAndCredentialsForm({
  expanded,
  checkStatus,
  closeForm,
  community,
}) {
  const theme = useTheme();
  const spacing = 2;

  const { communityId } = useParams();
  const [fetching, setFetching] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [saveAndFinish, setSaveAndFinish] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [confirmationModalValues, setConfirmationModalValues] = useState({
    type: "",
    id: "",
  });

  const areaTypes = ["unitsOptions"];

  const latchCheckBoxOptions = useCallback(() => {
    return [
      { label: "is_building_name_added", checked: false },
      { label: "is_integration_submitted", checked: false },
      { label: "is_mission_control_setup", checked: false },
    ];
  }, []);

  const [latchLockState, setLatchLockState] = useState({
    latchPropertyName: "",
    latchEmailModalOpen: false,
    latchCheckBoxOptions: latchCheckBoxOptions(),
  });

  const [dweloLockState, setDweloLockState] = useState({
    communityId: "",
    clientId: "",
    clientSecret: "",
  });

  const [iglooLockState, setIglooLockState] = useState({
    communityId: "",
    clientId: "",
    clientSecret: "",
    isClientAuth: false,
    isAuthCode: false,
    isAuthorizedWithPynwheel: false,
    redirectURI: "",
    homeName: "",
    iglooVersion: "igloohome",
    iglooworksApiKey: "",
    iglooworksDepartmentId: ""
  });

  const [otherLock, setOtherLock] = useState({
    type: "unit",
    description: "",
  });

  const [remoteLock, setRemoteLock] = useState("");

  const unitsOptionsFormat = useCallback(() => {
    return [
      { label: "LATCH", checked: false },
      { label: "Dwelo", checked: false },
      { label: "RemoteLock", checked: false },
      { label: "Igloohome / Iglooworks", checked: false },
      { label: "Other", checked: false, type: "unit" },
    ];
  }, []);

  const [state, setState] = useState({
    unitsOptions: unitsOptionsFormat(),
  });

  const checkIfAllLatchOptionsChecked = () => {
    if (latchLockState.latchCheckBoxOptions.every((option) => option.checked)) {
      return true;
    } else {
      return false;
    }
  };

  const OpenConfirmationModal = (data) => {
    setConfirmationModalValues({
      type: data.lock.type,
      id: data.lock.id,
      clientType: data.lock.clientType,
      lockType: data.lock?.lockType ?? "",
    });
    setOpenDeleteConfirmation(true);
  };

  const isLockIsFetchedFromServer = (lock) => {
    if (lock.label === MixConstants.Locks.LATCHCLIENT) {
      if (latchLockState?.id) {
        return {
          isFetched: true,
          lock: {
            type: "Latch",
            clientType: MixConstants.Locks.LATCHCLIENT,
            id: latchLockState?.id,
          },
        };
      }
    } else if (lock.label === MixConstants.Locks.REMOTECLIENT) {
      if (remoteLock?.id) {
        return {
          isFetched: true,
          lock: {
            type: "Remote",
            clientType: MixConstants.Locks.REMOTECLIENT,
            id: remoteLock?.id,
          },
        };
      }
    } else if (lock.label === MixConstants.Locks.DWELOCLIENT) {
      if (dweloLockState?.id) {
        return {
          isFetched: true,
          lock: {
            type: "Dwelo",
            clientType: MixConstants.Locks.DWELOCLIENT,
            id: dweloLockState?.id,
          },
        };
      }
    } else if (lock.label === MixConstants.Locks.IGLOOCLIENT) {
      if (iglooLockState?.id) {
        return {
          isFetched: true,
          lock: {
            type: "Igloohome",
            clientType: MixConstants.Locks.IGLOOCLIENT,
            id: iglooLockState?.id,
          },
        };
      }
    } else if (
      lock.label === MixConstants.Locks.OTHERLOCK &&
      lock.type === "unit"
    ) {
      if (otherLock?.id) {
        return {
          isFetched: true,
          lock: {
            type: "Other",
            lockType: lock.type,
            clientType: MixConstants.Locks.OTHERLOCK,
            id: otherLock?.id,
          },
        };
      }
    }

    return {
      isFetched: false,
    };
  };

  const handleUnitsOptionChange = (name, value, index) => {
    let options = [...state.unitsOptions];
    if (value === false) {
      const result = isLockIsFetchedFromServer(options[index]);
      if (result.isFetched) {
        OpenConfirmationModal(result);
      } else {
        options[index].checked = value;
      }
    } else {
      options[index].checked = value;
    }

    setState({
      ...state,
      unitsOptions: options,
    });
  };

  const resetFetchedData = (data, lockType) => {
    if (data) {
      if (data === MixConstants.Locks.LATCHCLIENT) {
        setLatchLockState({
          latchPropertyName: "",
          latchEmailModalOpen: false,
          latchCheckBoxOptions: latchCheckBoxOptions(),
        });
      } else if (data === MixConstants.Locks.DWELOCLIENT) {
        setDweloLockState({
          communityId: "",
          clientId: "",
          clientSecret: "",
        });
      } else if (data === MixConstants.Locks.IGLOOCLIENT) {
        setIglooLockState({
          communityId: "",
          clientId: "",
          clientSecret: "",
          isClientAuth: false,
          isAuthCode: false,
          isAuthorizedWithPynwheel: false,
          redirectURI: "",
          homeName: "",
          iglooVersion: "igloohome",
          iglooworksApiKey: "",
          iglooworksDepartmentId: ""
        });
      } else if (data === MixConstants.Locks.REMOTECLIENT) {
        setRemoteLock("");
      } else if (data === MixConstants.Locks.OTHERLOCK && lockType === "unit") {
        setOtherLock({
          description: "",
        });
      }
    }
  };

  function setLatchCheckBoxOptions(latchOptions) {
    const options = [];
    for (const [key, value] of Object.entries(latchOptions)) {
      options.push({
        name: key,
        checked: value,
      });
    }
    return options;
  }

  const checkIgloohomeRequiredFields = () => {
    if(iglooLockState.iglooVersion === "igloohome") {
      if (!iglooLockState.homeName) {
        setMessageText("Home name is required", "error");
        return true;
      }

      if (!iglooLockState.isAuthCode) {
        if (!(iglooLockState.clientId && iglooLockState.clientSecret)) {
          setMessageText("Client Id & Secret are required", "error");
          return true;
        }
      }
    }

    else if(iglooLockState.iglooVersion === "iglooworks") {
      if (!(iglooLockState.iglooworksApiKey && iglooLockState.iglooworksDepartmentId)) {
        setMessageText("Api Key & Department Id are required", "error");
        return true;
      }
    }

    return false;
  };

  const setFetchedData = useCallback((data) => {
    if (data) {
      if (data.type === MixConstants.Locks.LATCHCLIENT) {
        setLatchLockState({
          id: data.details.id,
          latchPropertyName: data.details.latch_property_name,
          latchCheckBoxOptions: setLatchCheckBoxOptions(
            data.details.setup_options
          ),
        });
      } else if (data.type === MixConstants.Locks.DWELOCLIENT) {
        setDweloLockState({
          id: data.details.id,
          communityId: data.details.default_community_id,
          clientId: data.details.client_id,
          clientSecret: data.details.client_secret,
        });
      } else if (data.type === MixConstants.Locks.IGLOOCLIENT) {
        setIglooLockState({
          id: data.details.id,
          communityId: data.details.community_id,
          clientId: data.details.client_id,
          clientSecret: data.details.client_secret,
          isClientAuth: data.details.is_client_auth,
          isAuthCode: data.details.is_auth_code,
          isAuthorizedWithPynwheel: data.details.authenticated_with_pynwheel,
          redirectURI: data.details.redirect_uri,
          homeName: data.details.home_name,
          iglooVersion: data.details.igloo_version,
          iglooworksApiKey: data.details.iglooworks_api_key,
          iglooworksDepartmentId: data.details.iglooworks_department_id
        });
      } else if (data.type === MixConstants.Locks.REMOTECLIENT) {
        setRemoteLock({
          id: data.details.id,
        });
      } else if (data.type === MixConstants.Locks.OTHERLOCK) {
        data.details.forEach((otherLock) => {
          if (otherLock.area_type === "unit") {
            setOtherLock({
              id: otherLock?.id,
              description: otherLock?.description,
              type: otherLock?.area_type,
            });
          }
        });
      }
    }
  }, []);

  const getMappedData = useCallback(
    (data) => {
      const unitOptions = _.cloneDeep(unitsOptionsFormat());
      if (data?.length > 0) {
        unitOptions.map((unitOption) => {
          data.map((lock) => {
            if (
              unitOption.label === lock.type &&
              unitOption.label !== "Other"
            ) {
              unitOption.checked = true;
              setFetchedData(lock);
            }
            if (
              unitOption.label === lock.type &&
              unitOption.label === "Other"
            ) {
              lock.details.forEach((eachOtherLock) => {
                if (eachOtherLock.area_type === "unit") {
                  unitOption.checked = true;
                  setFetchedData(lock);
                }
              });
            }
            return [];
          });
          return [];
        });
      }
      return {
        unitsOptions: unitOptions,
      };
    },
    [unitsOptionsFormat, setFetchedData]
  );

  const fetchingSecureLocks = useCallback(() => {
    if (expanded && communityId) {
      setFetching(true);
      FormApi.fetchSecureLocks(communityId).then(
        (response) => {
          setFetching(false);
          if (response.success) {
            const { data } = response;
            const mappedData = getMappedData(data);
            setState({
              unitsOptions: mappedData.unitsOptions,
            });
          }
        },
        (error) => {
          setFetching(false);
        }
      );
    }
  }, [expanded, communityId, getMappedData]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchingSecureLocks(abortController);

    return () => {
      abortController.abort();
    };
  }, [fetchingSecureLocks]);

  const removeAccount = () => {
    FormApi.removeIgloohomeAuthAccount(communityId).then(
      (response) => {
        setMessageText(
          response?.message,
          response.success ? "success" : "error"
        );
      },
      (error) => {
        setMessageText(error?.message, "error");
      }
    );

    setTimeout(() => {
      closeForm();
    }, 1000);
  };

  const canUserProceed = (locks) => {
    let hasError = false;
    if (locks.length > 0) {
      locks.map((lock) => {
        if (lock.lock === MixConstants.Locks.LATCHCLIENT) {
          if (!checkIfAllLatchOptionsChecked()) {
            hasError = true;
            setMessageText(
              "All Latch Lock Checkboxes should be checked before submit",
              "error"
            );
          } else if (!latchLockState?.latchPropertyName) {
            hasError = true;
            setMessageText(
              "Please complete Latch Lock mandatory fields",
              "error"
            );
          }
        } else if (lock.lock === MixConstants.Locks.IGLOOCLIENT) {
          hasError = checkIgloohomeRequiredFields();
        } else if (
          lock.lock === MixConstants.Locks.OTHERLOCK &&
          lock.type === areaTypes[0]
        ) {
          if (!otherLock?.description) {
            hasError = true;
            setMessageText(
              "Please add some description for other lock",
              "error"
            );
          }
        }

        return [];
      });
    } else {
      hasError = true;
      setMessageText(
        "Please select atleast one lock before submission.",
        "error"
      );
    }
    return !hasError;
  };

  const getDataForServer = (lock) => {
    if (lock.lock === MixConstants.Locks.DWELOCLIENT) {
      return {
        id: dweloLockState?.id ? dweloLockState.id : null,
        type: "Dwelo",
        community_id: dweloLockState.communityId,
        client_id: dweloLockState.clientId,
        client_secret: dweloLockState.clientSecret,
      };
    } else if (lock.lock === MixConstants.Locks.IGLOOCLIENT) {
      return {
        id: iglooLockState?.id ? iglooLockState.id : null,
        type: "Igloohome",
        community_id: iglooLockState.communityId,
        client_id: iglooLockState.clientId,
        client_secret: iglooLockState.clientSecret,
        is_client_auth: iglooLockState.isClientAuth,
        is_auth_code: iglooLockState.isAuthCode,
        home_name: iglooLockState.homeName,
        igloo_version: iglooLockState.iglooVersion,
        iglooworks_api_key: iglooLockState.iglooworksApiKey,
        iglooworks_department_id: iglooLockState.iglooworksDepartmentId

      };
    } else if (lock.lock === MixConstants.Locks.LATCHCLIENT) {
      return {
        id: latchLockState?.id ? latchLockState.id : null,
        type: "Latch",
        latch_property_name: latchLockState?.latchPropertyName,
        latch_check_box_options: latchLockState?.latchCheckBoxOptions,
      };
    } else if (lock.lock === MixConstants.Locks.REMOTECLIENT) {
      return {
        id: remoteLock?.id ? remoteLock.id : null,
        type: "Remote",
      };
    } else if (
      lock.lock === MixConstants.Locks.OTHERLOCK &&
      lock.type === areaTypes[0]
    ) {
      return {
        id: otherLock?.id ? otherLock.id : null,
        type: "Other",
        otherType: "unit",
        description: otherLock?.description,
      };
    }
  };

  const onSubmitButtonClicked = () => {
    let locks = [];
    for (let key in state) {
      state[key].map((lock) => {
        if (!locks.includes(lock.label) && lock.checked) {
          locks.push({ lock: lock.label, type: key });
        }
        return [];
      });
    }
    if (canUserProceed(locks)) {
      setSubmitting(true);
      let data = [];
      locks.map((lock) => {
        const lockData = getDataForServer(lock);
        if (lockData) {
          data.push(lockData);
        }
        return [];
      });

      if (communityId && data) {
        const successMessage = "Secure access settings updated successfully";
        const errorMessage = "Failed to update secure access settings";
        FormApi.addSecureLocks(communityId, data).then(
          (response) => {
            setSubmitting(false);
            if (response.success) {
              const { data } = response;
              const mappedData = getMappedData(data);
              setMessageText(successMessage, "success");
              checkStatus();
              setState({
                unitsOptions: mappedData.unitsOptions,
              });
            }
          },
          (error) => {
            setMessageText(errorMessage, "error");
            setSubmitting(false);
          }
        );
      }
    }
  };

  const onSaveAndSubmitButtonClicked = () => {
    let locks = [];
    for (let key in state) {
      state[key].map((lock) => {
        if (!locks.includes(lock.label) && lock.checked) {
          locks.push({ lock: lock.label, type: key });
        }
        return [];
      });
    }
    let data = [];
    locks.map((lock) => {
      const lockData = getDataForServer(lock);
      if (lockData) {
        data.push(lockData);
      }
      return [];
    });

    if (communityId && data) {
      setSaveAndFinish(true);

      FormApi.addSecureLocks(communityId, data, "in_progress").then(
        (response) => {
          setSaveAndFinish(false);
          if (response.success) {
            const { data } = response;
            const mappedData = getMappedData(data);
            checkStatus();
            setState({
              unitsOptions: mappedData.unitsOptions,
            });
          }
        },
        (error) => {
          setSaveAndFinish(false);
        }
      );
    }
    closeForm();
  };

  const formAlertCloseButtonClicked = () => {
    setMessage("");
    setMessageType("");
  };

  const handleModalSettingChange = (locks, type, lockType) => {
    setOpenDeleteConfirmation(false);
    resetFetchedData(type, lockType);
    const mappedData = getMappedData(locks);
    setState({
      unitsOptions: mappedData.unitsOptions,
    });
  };

  const setMessageText = (text, type) => {
    setMessage(text);
    setMessageType(type);
    setTimeout(() => {
      formAlertCloseButtonClicked();
    }, 5000);
  };

  if (fetching) {
    return (
      <StyledSpinnerContainer>
        <CircularProgress size={20} />
      </StyledSpinnerContainer>
    );
  }

  return (
    <Container>
      <Stack spacing={spacing} sx={{ marginTop: "1rem" }}>
        <Typography>
          What type of access control devices are you using?
        </Typography>
        <Box>
          {state.unitsOptions.map((option, index) => (
            <Box key={index}>
              <Checkbox
                label={option.label}
                checked={option.checked}
                onChange={(e) =>
                  handleUnitsOptionChange(
                    e.target.name,
                    e.target.checked,
                    index
                  )
                }
              />
              {option.checked && index === 0 ? (
                <LatchLock
                  state={latchLockState}
                  setState={setLatchLockState}
                  community={community}
                />
              ) : option.checked && index === 1 ? (
                <DweloLock
                  state={dweloLockState}
                  setState={setDweloLockState}
                />
              ) : option.checked && index === 2 ? null : option.checked &&
                index === 3 ? (
                <IglooLock
                  state={iglooLockState}
                  setState={setIglooLockState}
                  removeAccount={removeAccount}
                  communityId={communityId}
                  closeForm={closeForm}
                />
              ) : option.checked && index === 4 ? (
                <OtherLock state={otherLock} setState={setOtherLock} />
              ) : null}
            </Box>
          ))}
        </Box>
      </Stack>

      {message && messageType && (
        <Box>
          <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
          <FormAlert
            message={message}
            type={messageType}
            onClose={() => formAlertCloseButtonClicked()}
          />
        </Box>
      )}

      <Divider sx={{ marginTop: "0.5rem" }} />
      {openDeleteConfirmation && (
        <DeleteSecureLockConfirmationModal
          lock={confirmationModalValues}
          onAccept={handleModalSettingChange}
          onClose={() => setOpenDeleteConfirmation(false)}
          checkStatus={checkStatus}
        />
      )}
      <StyledFooter>
        <Button
          bgColor={theme.colors.gray}
          titleColor={theme.colors.white}
          loading={saveAndFinish}
          disabled={saveAndFinish}
          title="Save & Finish Later"
          onClick={onSaveAndSubmitButtonClicked}
        />
        <Button
          bgColor={theme.colors.green}
          title="Submit"
          disabled={submitting}
          loading={submitting}
          onClick={onSubmitButtonClicked}
        />
      </StyledFooter>
    </Container>
  );
}

export default LockProvidersAndCredentialsForm;
