const PhonePlaceholder = "202-555-0112";
const IglooAuthURL = "https://auth.igloohome.co/login?client_id=4cqefo4mkm0170i0k45h9dfi01&response_type=code&redirect_uri=https%3A%2F%2Fpynwheelconnect.com&scope=igloohomeapi%2Falgopin-daily+igloohomeapi%2Falgopin-hourly+igloohomeapi%2Falgopin-onetime+igloohomeapi%2Falgopin-permanent+igloohomeapi%2Fcreate-pin-bridge-proxied-job+igloohomeapi%2Fdelete-pin-bridge-proxied-job+igloohomeapi%2Fget-devices+igloohomeapi%2Fget-job-status+igloohomeapi%2Flock-bridge-proxied-job+igloohomeapi%2Funlock-bridge-proxied-job+igloohomeapi%2Fget-master-pin+igloohomeapi%2Fget-properties+openid+profile"
const selfTour = {
  defaultDirection: "For instance, Take a right onto Broadway, traveling north from First Street. Follow the signs leading to the Leasing Office and park in any of the designated spaces marked with a 'Prospective Resident' sign.",
  amenityExample: "Name (ie Pool)",
  unitExample: "Unit Number (ie, 732)",
  imageText: "Add copy here that will accompany the image to the left. It can be a description of this image or something else about this stop that you want visitors to know. Keep it short (preferably 90 characters or less)."
}

const FormStatus = {
  NONE: "",
  IN_PROGRESS: "In Progress...",
  SUBMITTED: "Submitted",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  DEPLOYED: "Deployed",
  FORM_APPROVED: "Form Approved",
  ATTENTION_REQUIRED: "Attention Required",
  NEEDS_ATTENTION: "Rejected",
  APPLICATION_IN_QA: "Application in QA",
  APPLICATION_IN_PRODUCTION: "Application in Production",
  APPLICATION_IN_REVIEW: "Submitted for Review",
  UNDER_REVIEW: "in_review",
  RELEASED: "Released",
  IN_PRODUCTION: "In Production",
  APP_IN_PRODUCTION: "in_production",
  REJECTED_TOOLTIP_MESSAGE: "Attention! some data is not approved by Pynwheel. Expand to see details."
};

const Locks = {
  ZERVCLIENT: "Standard fob reader (Pynwheel to retrofit to work with Pynwheel App)",
  LATCHCLIENT: "LATCH",
  DWELOCLIENT: "Dwelo",
  SCHLAGECLIENT: "Schlage Z-Wave, Encode, or Control",
  IGLOOCLIENT: "Igloohome / Iglooworks",
  YALECLIENT: "Yale Assure",
  REMOTECLIENT: "RemoteLock",
  OTHERLOCK: "Other"
}

const Role = {
  SUPER_ADMIN: "Super Admin",
  CLIENT: "New Client",
};

const Community = {
  DummyPropertyName: "@@@"
}

const MixConstants = {
  PhonePlaceholder,
  FormStatus,
  Role,
  Locks,
  selfTour,
  IglooAuthURL,
  Community
};



export default MixConstants;
