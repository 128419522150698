import React, { useState } from "react";
import { saveAs } from "file-saver";
import Box from "@mui/material/Box";
import FileGridItem from "./file-grid-item";
import ImageViewer from "components/image-viewer";
import { HorizontalScrollBar } from "components/scrollbar/style";

function FilesGridList({ files, onUpdate, deleteImage, disabled }) {
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [activePhotoIndex, setActivePhotoIndex] = useState(0);

  const viewFileButtonClicked = (index) => {
    setIsGalleryOpen(true);
    setActivePhotoIndex(index);
  };

  const removeFileButtonClicked = (index, file) => {
    if (file.isLocal) {
      let filesList = [...files];
      filesList.splice(index, 1);
      onUpdate(filesList);
    } else {
      deleteImage(file, index);
    }
  };

  const downloadFileButtonClicked = (file) => {
    let path = null;

    if (file?.originalFile) {
      path = file.originalFile;
    } else if (file?.photo) {
      path = file.photo;
    } else if (file?.video) {
      path = file.video;
    } else if (file?.file?.url) {
      path = file.file.url;
    } else if (file?.file) {
      path = file.file;
    }
    if (path) {
      saveAs(path);
    }
  };

  const renderFiles = (files) => {
    return (
      files &&
      files.map((file, index) => (
        <Box key={file?.id}>
          <FileGridItem
            file={file}
            disabled={disabled}
            onClickView={() => viewFileButtonClicked(index)}
            onClickDownload={() => downloadFileButtonClicked(file)}
            onClickRemove={() => removeFileButtonClicked(index, file)}
          />
        </Box>
      ))
    );
  };

  return (
    <HorizontalScrollBar>
      {renderFiles(files)}
      <ImageViewer
        show={isGalleryOpen}
        photos={files}
        activePhotoIndex={activePhotoIndex}
        onClose={() => setIsGalleryOpen(false)}
      />
    </HorizontalScrollBar>
  );
}

export default FilesGridList;
