import React, { useState } from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import {SitemapExample1, FloorPlateSampleL2} from "assests/images";
import ImageViewer from "components/image-viewer";
import RadioGroup from "@mui/material/RadioGroup";
import RadioButton from "components/radio-button";
import Typography from "@mui/material/Typography";

function PropertyTypeSection({ types, selectedType, onChange }) {
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [exampleImageToShow, setExampleImageToShow] = useState(null);

  const gardenStyleCommunityImage = {
    photo: SitemapExample1,
    name: "Garden Style Community",
    caption: "",
    subcaption: "",
  };

  const highRiseBuildingImage = {
    photo: FloorPlateSampleL2,
    name: "Mid-rise or High-rise Building",
    caption: "",
    subcaption: "",
  };

  const exampleButtonClicked = (index) => {
    if (index === 0) {
      setExampleImageToShow(gardenStyleCommunityImage);
    } else {
      setExampleImageToShow(highRiseBuildingImage);
    }
    setIsGalleryOpen(true);
  };

  return (
    <Box sx={{ marginTop: "0", marginBottom: "0.5rem" }}>
      <Stack spacing={2}>
        <Typography>Type of Property:</Typography>
        <RadioGroup value={selectedType} onChange={onChange}>
          {types &&
            types.map((type, index) => (
              <Box key={index}>
                <RadioButton label={type} />
                <Link onClick={() => exampleButtonClicked(index)}>example</Link>
              </Box>
            ))}
        </RadioGroup>
      </Stack>
      <ImageViewer
        show={isGalleryOpen}
        photos={[exampleImageToShow]}
        onClose={() => setIsGalleryOpen(false)}
      />
    </Box>
  );
}

export default PropertyTypeSection;
