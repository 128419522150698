import React, { useState } from "react";
import { saveAs } from "file-saver";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "components/textfield";
import Typography from "@mui/material/Typography";
import ImageViewer from "components/image-viewer";
import FileGridItem from "components/files-grid-list/file-grid-item";
import ImageFileUploadContainer from "components/image-file-upload-container";
import { RadioGroup } from "@mui/material";
import RadioButton from "components/radio-button";
import MixConstants from "constants/mix-constants";

function TourStopAccordionContent({
  tourStopIndex,
  tourStop,
  onUploadFiles,
  onUpdateFiles,
  updateVideoLink,
  updateDirectionalText,
  updateTourDescription,
  onGalleryImageDelete,
  amenityType,
  updateTourStop,
  characterLength,
}) {
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [activePhotoIndex, setActivePhotoIndex] = useState(0);
  const poolOpeningTypes = ["Seasonally", "Year-Round"];
  const poolGrills = ["Yes", "No"];
  const yogaClasses = ["Yes", "No"];
  const terrace = ["Yes", "No"];
  const coffeeStationOptions = ["Yes", "No"];

  const boxStyle = { marginTop: "1rem" };
  const spacing = 2;

  const viewFileButtonClicked = (index) => {
    setIsGalleryOpen(true);
    setActivePhotoIndex(index);
  };

  const handleImageDescriptionChange = (e, index) => {
    let filesList = [...tourStop?.images];
    filesList[index].description = e.target.value;
    onUpdateFiles(filesList);
  };

  const removeFileButtonClicked = (index, file) => {
    let filesList = [...tourStop?.images];
    if (file.isLocal) {
      filesList.splice(index, 1);
      onUpdateFiles(filesList);
    } else {
      onGalleryImageDelete(tourStopIndex, index, file);
    }
  };

  const renderFiles = (files) => {
    return (
      files &&
      files.map((file, index) => (
        <Grid
          key={index}
          spacing={spacing}
          container
          alignItems='center'
          marginTop='0rem'
        >
          <Grid item xs={12} md={4}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FileGridItem
                  file={file}
                  onClickView={() => viewFileButtonClicked(index)}
                  onClickDownload={() => saveAs(file?.photo)}
                  onClickRemove={() => removeFileButtonClicked(index, file)}
                />
              </Box>

              {index !== 0 && (
                <Typography>
                  Text to appear above this image (Optional):
                </Typography>
              )}
            </Box>
          </Grid>
          {index === 0 && (
            <Grid item xs={12} md={8}>
              {" "}
              <Typography>
                This image will appear under the description above.
              </Typography>{" "}
            </Grid>
          )}
          {index !== 0 && (
            <Grid item xs={12} md={8}>
              <TextField
                multiline
                placeholder={MixConstants.selfTour.imageText}
                rows={4}
                value={file?.description ?? ""}
                onChange={(e) => handleImageDescriptionChange(e, index)}
              />
            </Grid>
          )}
        </Grid>
      ))
    );
  };

  const hours = () => {
    return (
      <Box
        sx={{
          mt: "15px",
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box sx={{ mr: "20px" }}>
          <Typography noWrap={true}>Optional (Open from:)</Typography>
        </Box>
        <Box width={"30%"} sx={{ mr: "20px" }}>
          <TextField
            type='time'
            value={
              tourStop.amenityDetails.openingHour
                ? tourStop.amenityDetails.openingHour
                : ""
            }
            onChange={(e) => openingTime(e)}
          />
        </Box>
        <Box sx={{ mr: "20px" }}>
          <Typography noWrap={true}>To:</Typography>
        </Box>
        <Box width={"30%"} sx={{ mr: "20px" }}>
          <TextField
            type='time'
            value={
              tourStop.amenityDetails.closingHour
                ? tourStop.amenityDetails.closingHour
                : ""
            }
            onChange={(e) => closingTime(e)}
          />
        </Box>
      </Box>
    );
  };

  const leasingCenter = () => {
    return <Box>{hours()}</Box>;
  };

  const changePoolOpening = (e) => {
    tourStop["amenityDetails"]["poolOpening"] = e.currentTarget.value;
    updateTourStop(tourStopIndex, tourStop);
  };

  const openingTime = (e) => {
    tourStop["amenityDetails"]["openingHour"] = e.target.value;
    updateTourStop(tourStopIndex, tourStop);
  };

  const closingTime = (e) => {
    tourStop["amenityDetails"]["closingHour"] = e.target.value;
    updateTourStop(tourStopIndex, tourStop);
  };

  const changePoolGrills = (e) => {
    tourStop["amenityDetails"]["poolGrills"] = e.currentTarget.value;
    updateTourStop(tourStopIndex, tourStop);
  };

  const changeYogaText = (e) => {
    tourStop["amenityDetails"]["yogaText"] = e.target.value;
    updateTourStop(tourStopIndex, tourStop);
  };

  const changePetCreiteria = (e) => {
    tourStop["amenityDetails"]["petCriteria"] = e.target.value;
    updateTourStop(tourStopIndex, tourStop);
  };

  const changeYogaClasses = (e) => {
    tourStop["amenityDetails"]["yogaClasses"] = e.currentTarget.value;
    updateTourStop(tourStopIndex, tourStop);
  };

  const changePackageLocker = (e) => {
    tourStop["amenityDetails"]["packageLocker"] = e.target.value;
    updateTourStop(tourStopIndex, tourStop);
  };

  const changeTerrace = (e) => {
    tourStop["amenityDetails"]["terrace"] = e.currentTarget.value;
    updateTourStop(tourStopIndex, tourStop);
  };

  const changeCoffeeStation = (e) => {
    tourStop["amenityDetails"]["coffeeStation"] = e.currentTarget.value;
    updateTourStop(tourStopIndex, tourStop);
  };

  const radioButtons = (types, value, onChange) => (
    <RadioGroup
      row
      spacing={10}
      value={value}
      onChange={onChange}
      sx={{ display: "flex", justifyContent: "space-evenly" }}
    >
      {types &&
        types.map((type, index) => (
          <RadioButton key={index} label={type} sx={{ marginRight: "1rem" }} />
        ))}
    </RadioGroup>
  );

  const textField = (text, value, onChange, optional) => {
    return (
      <Box sx={{ display: "flex", alignItems: "center", mt: "15px" }}>
        <Box sx={{ mr: "15px" }}>
          <Typography noWrap>{text}</Typography>
        </Box>
        <TextField value={value ? value : ""} onChange={onChange} />
        {optional && (
          <Box sx={{ ml: "10px" }}>
            <Typography>(optional)</Typography>
          </Box>
        )}
      </Box>
    );
  };

  const pool = () => {
    return (
      <Box>
        {hours()}
        <Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ mr: "20px", fontWeight: "bold" }}>
              Open
            </Typography>
            {radioButtons(
              poolOpeningTypes,
              tourStop.amenityDetails.poolOpening
                ? tourStop.amenityDetails.poolOpening
                : "",
              changePoolOpening,
            )}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ mr: "20px", fontWeight: "bold" }}>
              Grills
            </Typography>
            {radioButtons(
              poolGrills,
              tourStop.amenityDetails.poolGrills
                ? tourStop.amenityDetails.poolGrills
                : "",
              changePoolGrills,
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  const fitnessCenter = () => {
    return (
      <Box>
        {hours()}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ mr: "20px", fontWeight: "bold" }}>
            Classes
          </Typography>
          {radioButtons(
            yogaClasses,
            tourStop.amenityDetails.yogaClasses
              ? tourStop.amenityDetails.yogaClasses
              : "",
            changeYogaClasses,
          )}
        </Box>
      </Box>
    );
  };

  const yogaStudio = () => {
    return (
      <Box>
        {hours()}
        {textField(
          "Equipment brand",
          tourStop.amenityDetails.yogaText
            ? tourStop.amenityDetails.yogaText
            : "",
          changeYogaText,
          true,
        )}
      </Box>
    );
  };

  const dogPark = () => {
    return (
      <Box>
        {textField(
          "Pet criteria",
          tourStop.amenityDetails.petCriteria
            ? tourStop.amenityDetails.petCriteria
            : "",
          changePetCreiteria,
          false,
        )}
      </Box>
    );
  };

  const gameRoom = () => {
    return hours();
  };

  const packageLocker = () => {
    return (
      <Box>
        {textField(
          "Vendor",
          tourStop.amenityDetails.packageLocker
            ? tourStop.amenityDetails.packageLocker
            : "",
          changePackageLocker,
          false,
        )}
      </Box>
    );
  };

  const conferenceRoom = () => {
    return hours();
  };

  const businessCenter = () => {
    return hours();
  };

  const other = () => {
    return hours();
  };

  const rooftopLounge = () => {
    return (
      <Box>
        {hours()}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ mr: "20px", fontWeight: "bold" }}>
            Terrace
          </Typography>
          {radioButtons(
            terrace,
            tourStop.amenityDetails.terrace
              ? tourStop.amenityDetails.terrace
              : "",
            changeTerrace,
          )}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ mr: "20px", fontWeight: "bold" }}>
            Coffee Station
          </Typography>
          {radioButtons(
            coffeeStationOptions,
            tourStop.amenityDetails.coffeeStation
              ? tourStop.amenityDetails.coffeeStation
              : "",
            changeCoffeeStation,
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      {tourStop.type === "amenity" && amenityType === "Leasing Center"
        ? leasingCenter()
        : ""}
      {tourStop.type === "amenity" && amenityType === "Pool" ? pool() : ""}
      {tourStop.type === "amenity" &&
      amenityType === "Yoga Studio / Fitness Studio"
        ? fitnessCenter()
        : ""}
      {tourStop.type === "amenity" && amenityType === "Fitness Center"
        ? yogaStudio()
        : ""}
      {tourStop.type === "amenity" && amenityType === "Dog Park"
        ? dogPark()
        : ""}
      {tourStop.type === "amenity" && amenityType === "Game Room"
        ? gameRoom()
        : ""}
      {tourStop.type === "amenity" && amenityType === "Package Locker"
        ? packageLocker()
        : ""}
      {tourStop.type === "amenity" && amenityType === "Conference Room"
        ? conferenceRoom()
        : ""}
      {tourStop.type === "amenity" && amenityType === "Business Center / Lounge"
        ? businessCenter()
        : ""}
      {tourStop.type === "amenity" && amenityType === "Other" ? other() : ""}
      {tourStop.type === "amenity" &&
      amenityType === "Clubhouse / Resident Lounge"
        ? rooftopLounge()
        : ""}
      {/* <Box sx={boxStyle}>
        <Typography sx={{ marginBottom: "1rem", display: "inline" }}>
          Short description (What would you tell a prospect about this stop on
          their tour? What are its best selling points or most important
          details?)
        </Typography>
        <Typography sx={{ display: "inline" }}>
          {" "}
          <strong>{characterLength} characters allowed</strong>.
        </Typography>
        <TextField
          maxLength={characterLength}
          multiline
          rows={4}
          value={tourStop?.description ?? ""}
          onChange={(e) => updateTourDescription(e, tourStopIndex)}
        />
      </Box> */}

      <Box sx={boxStyle}>
        <Typography sx={{ marginBottom: "1rem", display: "inline" }}>
          Directional text (What will the prospect see when they are on their
          way to the stop during tour?).
        </Typography>
        <Typography sx={{ display: "inline" }}>
          {" "}
          <strong>{characterLength} characters allowed</strong>.
        </Typography>
        <TextField
          maxLength={characterLength}
          multiline
          value={tourStop?.directionalText ?? ""}
          onChange={(e) => updateDirectionalText(e, tourStopIndex)}
          rows={4}
          placeholder='Keep in mind that the visitor could be coming from any direction, so it should be general information about the location of the stop. (You don’t want to say “Go down the hallway and turn right.” Instead, say “The pool is located in the courtyard behind the clubhouse.”)'
        />
      </Box>

      {/* <Box sx={boxStyle}>
        <Grid spacing={spacing} container alignItems='center'>
          <Grid item xs={12} md={4}>
            <Typography display='inline'>Video or 3D tour link</Typography>{" "}
            <Typography display='inline' sx={{ fontStyle: "italic" }}>
              (Optional)
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <TextField maxLength={500} value={tourStop?.videoLink ?? ""} onChange={(e) => updateVideoLink(e, tourStopIndex)} />
          </Grid>
        </Grid>
      </Box> */}

      {/* <Box sx={boxStyle}>
        <Typography>
          Upload image(s) for this stop.
        </Typography>
        {tourStop.type === "unit" && (
          <Typography><strong>Note</strong>: <i>If images have been uploaded for the floor plan associated with this unit, and images are not uploaded below, then the floor plan images will appear for this stop.</i></Typography>
        )}
        <ImageFileUploadContainer
          helperText=''
          onFileDrop={onUploadFiles}
        />
      </Box>

      <Box>
        {renderFiles(tourStop?.images)}
        <ImageViewer
          show={isGalleryOpen}
          photos={tourStop?.images}
          activePhotoIndex={activePhotoIndex}
          onClose={() => setIsGalleryOpen(false)}
        />
      </Box> */}
    </Box>
  );
}

export default TourStopAccordionContent;
