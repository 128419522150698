const communityForms = [
  // {
  //   id: 1,
  //   title: "Your Company Details",
  //   description: "Please add details about this community below.",
  //   expanded: false,
  //   status: "",
  // },
  {
    id: 2,
    title: "Connect Your Property Management System",
    description:
      "Provide credentials for Pynwheel to access unit data, floor plan data, and pricing and availability.",
    expanded: false,
    status: "",
  },
  {
    id: 3,
    title: "Your Community Details",
    description: "Please add details about this community below.",
    expanded: false,
    status: "",
  },
  {
    id: 4,
    title: "Property Map Images",
    description: "Property map images will be uploaded in this section.",
    expanded: false,
    status: "",
  },
  {
    id: 5,
    title: "Floor Plan Images",
    description: "",
    expanded: false,
    status: "",
  },
  {
    id: 6,
    title: "Amenity Images",
    description:
      "",
    expanded: false,
    status: "",
  }
  ,
  {
    id: 7,
    title: "Gallery Media/Property Images",
    description: "Create galleries for the Pynwheel Touch app.",
    expanded: false,
    status: "",
  },
  {
    id: 8,
    title: "Slideshow/Video for Pynwheel Touch Home Page",
    description:
      "You can upload a video or images which will be displayed on the home page in Pynwheel Touch.",
    expanded: false,
    status: "",
  },
  {
    id: 9,
    title: "Touchscreen Installation Details",
    description:
      "Provide an image of the wall where the screen is to be installed.",
    expanded: false,
    status: "",
  },
  {
    id: 10,
    title: "Secure Access Settings",
    description:
      "Provide information about controlled access points such as locks and fob readers.",
    expanded: false,
    status: "",
  },
  {
    id: 11,
    title: "Manage Your Stops for Pynwheel Tour App",
    description:
      "Fill in details below about each stop and upload required images.",
    expanded: false,
    status: "",
  },
  {
    id: 12,
    title: "Visiting Hours for Tours",
    description:
      "Add visiting hours below for when you would like to open up the property for Self/Guided tours for visitors.",
    expanded: false,
    status: "",
  },
  {
    id: 13,
    title: "Design Direction",
    description:
      "",
    expanded: false,
    status: "",
  },
  {
    id: 14,
    title: "eBrochure",
    description:
      "",
    expanded: false,
    status: "",
  },
  {
    id: 15,
    title: "Additional Pages",
    description:
      "",
    expanded: false,
    status: "",
  },
];

export default communityForms;
