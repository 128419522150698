import React, { useCallback } from "react";
import _ from "lodash";
import FileDropZone from "components/file-drop-zone";

function ImageFileUploadContainer({
  helperText = "Provide .png or .jpg atleast 300px or original design files",
  padding,
  onFileDrop,
  maxFiles = 0,
  disabled,
  disableErrorMessage,
  validationEnabled = true,
}) {
  const onDrop = useCallback(
    (files) => {
      let filesList = files.map((file, index) => {
        const image = URL.createObjectURL(file);
        return {
          id: _.uniqueId(),
          type: "image",
          photo: image,
          number: index + 1,
          name: file?.name,
          caption: file?.name,
          subcaption: "",
          thumbnail: image,
          originalFile: file,
          isLocal: true,
        };
      });

      onFileDrop && onFileDrop(filesList);
    },
    [onFileDrop],
  );

  return (
    <FileDropZone
      accept='image/jpeg, image/png'
      helperText={helperText}
      padding={padding}
      onFileDrop={onDrop}
      maxFiles={maxFiles}
      disabled={disabled}
      validationEnabled={validationEnabled}
      disableErrorMessage={disableErrorMessage}
    />
  );
}

export default ImageFileUploadContainer;
