import React from "react";
import Box from "@mui/material/Box";
import AmenityListItem from "./amenity-list-item";

function AmenitiesList({ communityId, amenities, onDelete, selfTourEnabled }) {
  const renderAmenities = (amenities) => {
    return (
      amenities &&
      amenities.map((amenity, index) => {
        return (
          <Box key={amenity.id} sx={{ marginTop: "1rem" }}>
            <AmenityListItem
              communityId={communityId}
              index={index}
              amenity={amenity}
              onDelete={onDelete}
              selfTourEnabled={selfTourEnabled}
            />
          </Box>
        );
      })
    );
  };
  return <Box>{renderAmenities(amenities)}</Box>;
}

export default AmenitiesList;
