import Box from "@mui/material/Box";
import styled from "styled-components";

export const HorizontalScrollBar = styled(Box)`
  display: flex;
  overflow: hidden;
  overflow-x: scroll;

  ::-webkit-scrollbar {
    height: ${({ height }) => (height ? height : "0.5rem")};
  }

  ::-webkit-scrollbar-track {
    // background: #f1f1f1;
    border-radius: ${({ height }) => (height ? height : "0.5rem")};
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.lightGray};
    border-radius: ${({ height }) => (height ? height : "0.5rem")};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.lightGray};
  }
`;

export const VerticalScrollBar = styled(Box)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: scroll;
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : "23.2rem")};

  ::-webkit-scrollbar {
    width: ${({ width }) => (width ? width : "0.5rem")};
  }

  ::-webkit-scrollbar-track {
    // background: #f1f1f1;
    border-radius: ${({ width }) => (width ? width : "0.5rem")};
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.lightGray};
    border-radius: ${({ width }) => (width ? width : "0.5rem")};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.lightGray};
  }
`;
