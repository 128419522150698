import React, { useState } from "react";
import FileItem from "./file-item";
import { saveAs } from "file-saver";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "components/textfield";
import ImageViewer from "components/image-viewer";
import { VerticalScrollBar } from "components/scrollbar/style";
import FileGridItem from "components/files-grid-list/file-grid-item";
import ImageFileUploadContainer from "components/image-file-upload-container";
import handleFileSource from "helpers/fileHandler";
import handleFileAdditionalSource from "helpers/fileHandlerAdditional";

function MapImagesList({
  files,
  onUpdate,
  withAdditionalImage,
  onDelete,
  onDeleteLabelImage,
}) {
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [activePhotoIndex, setActivePhotoIndex] = useState(0);
  const [filesToView, setFilesToView] = useState([]);

  const handleChangeFloorplanRange = (event, index) => {
    let filesList = [...files];
    filesList[index].range = event.target.value;
    onUpdate(filesList);
  };

  const handleChangeFloorplanName = (event, index) => {
    let filesList = [...files];
    filesList[index].name = event.target.value;
    onUpdate(filesList);
  };

  const viewFileButtonClicked = (index) => {
    setIsGalleryOpen(true);
    setActivePhotoIndex(index);
    setFilesToView(files?.map((file) => handleFileSource(file)));
  };

  const viewAdditionalFileButtonClicked = (index) => {
    setIsGalleryOpen(true);
    setActivePhotoIndex(index);
    setFilesToView(
      files?.map((additionalFile) =>
        handleFileAdditionalSource(additionalFile),
      ),
    );
  };
  const removeFileButtonClicked = (index, file) => {
    if (file.isLocal) {
      let filesList = [...files];
      filesList.splice(index, 1);
      onUpdate(filesList);
    } else {
      onDelete(file);
    }
  };

  const removeAdditionalFileButtonClicked = (index, file) => {
    if (file.isLocal) {
      let filesList = [...files];
      filesList[index].additionalImage = null;
      onUpdate(filesList);
    } else {
      onDeleteLabelImage(file, index);
    }
  };
  const handleAdditionalDroppedFiles = (additionalFiles, index) => {
    let filesList = [...files];
    filesList[index].additionalImage = additionalFiles[0];
    onUpdate(filesList);
  };

  const renderFiles = (files) => {
    return (
      files &&
      files.map((file, index) => (
        <Box key={file.id}>
          {!withAdditionalImage ? (
            <Grid container alignItems='center' paddingY='0.5rem'>
              <Grid item xs={12} md={5}>
                <FileItem
                  file={file}
                  onClickView={() => viewFileButtonClicked(index)}
                  onClickDownload={() => saveAs(handleFileSource(file))}
                  onClickRemove={() => removeFileButtonClicked(index, file)}
                />
              </Grid>
              <Grid item xs={6} sx={{ marginLeft: "1rem" }} md={3}>
                <TextField
                  label='Floor name'
                  value={file?.name ? file.name : ""}
                  onChange={(event) => handleChangeFloorplanName(event, index)}
                />
              </Grid>
              <Grid item xs={6} sx={{ marginLeft: "1rem" }} md={3}>
                <TextField
                  label='Floor Range'
                  value={file?.range ? file.range : ""}
                  onChange={(event) => handleChangeFloorplanRange(event, index)}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container display='flex' alignItems='center'>
              <Grid
                item
                xs={12}
                md={withAdditionalImage && file.additionalImage ? 5.4 : 6}
              >
                <FileItem
                  file={file}
                  onClickView={() => viewFileButtonClicked(index)}
                  onClickDownload={() => saveAs(handleFileSource(file))}
                  onClickRemove={() => removeFileButtonClicked(index, file)}
                />
                <Box sx={{ marginTop: "0.5rem" }}>
                  <TextField
                    label='Floor Name'
                    value={file?.name ? file.name : ""}
                    onChange={(event) =>
                      handleChangeFloorplanName(event, index)
                    }
                  />
                </Box>
                <Box sx={{ marginTop: "0.5rem" }}>
                  <TextField
                    label='Floor Range'
                    value={file?.range ? file.range : ""}
                    onChange={(event) =>
                      handleChangeFloorplanRange(event, index)
                    }
                  />
                </Box>
              </Grid>

              {withAdditionalImage && (
                <Grid
                  item
                  xs={12}
                  md={file.additionalImage ? 5.4 : 6}
                  sx={{
                    padding: "0.5rem",
                  }}
                >
                  <ImageFileUploadContainer
                    onFileDrop={(files) =>
                      handleAdditionalDroppedFiles(files, index)
                    }
                    helperText='.png or .jpg labeled image'
                    validationEnabled={false}
                  />
                </Grid>
              )}

              {file.additionalImage && withAdditionalImage && (
                <Grid item xs={12} md={1}>
                  <FileGridItem
                    file={{ ...file.additionalImage, type: "image" }}
                    onClickView={() => viewAdditionalFileButtonClicked(index)}
                    onClickDownload={() => {
                      saveAs(handleFileAdditionalSource(file));
                    }}
                    onClickRemove={() =>
                      removeAdditionalFileButtonClicked(index, file)
                    }
                  />
                </Grid>
              )}
            </Grid>
          )}
        </Box>
      ))
    );
  };

  return (
    <Box>
      <VerticalScrollBar paddingX='0.3rem'>
        {renderFiles(files)}
      </VerticalScrollBar>
      <ImageViewer
        show={isGalleryOpen}
        photos={filesToView}
        activePhotoIndex={activePhotoIndex}
        onClose={() => setIsGalleryOpen(false)}
      />
    </Box>
  );
}

export default MapImagesList;
