import React from "react";
import Routes from "routes";
import { theme } from "themes/theme";
import GlobalStyles from "styles/global-styles";
import { ThemeProvider } from "styled-components";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Routes />
    </ThemeProvider>
  );
}

export default App;
