import React from "react";
import { StyledAccordion } from "./style";

function Accordion({ expanded, header, children, showBorder = true }) {
  return (
    <StyledAccordion
      expanded={expanded}
      showborder={showBorder ? "true" : "false"}
    >
      {header}
      {children}
    </StyledAccordion>
  );
}

export default Accordion;
