import { Divider, Stack, Typography } from '@mui/material'
import React from 'react'
import { StyledHeaderSection } from 'components/forms/style'


function AdditionalPagesFormHeader() {
    return (
        <>
        <StyledHeaderSection>
            <Stack spacing={0}>
            <Typography>
                As an option, you can add additional pages to your app that showcase an image, a <br/>sildeshow of multiple images, or a webpage.
            </Typography>
            </Stack>
        </StyledHeaderSection>
            <Divider />
        </>
    );
}


export default AdditionalPagesFormHeader