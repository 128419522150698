import Box from "@mui/material/Box";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

export const StyledCircularProgress = styled(Box)`
  position: absolute;
  width: ${({ size }) => `${size / 2}rem`};
  height: ${({ size }) => `${size / 2}rem`};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OuterCircularProgress = styled(Box)`
  position: absolute;
`;

export const InnerCircularProgress = styled(Box)`
  position: absolute;
  padding: ${({ circleSize }) => `${0.04 * circleSize}rem`};
`;

export const ColorDot = styled(Box)`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  background-color: ${({ color }) => color};
`;

export const StyledStatus = styled(Typography)`
  font-size: 0.65rem;
  font-weight: ${({ weight }) => (weight ? weight : 400)};
`;
