import React from "react";
import Box from "@mui/material/Box";
import { StyledHeaderSection } from "../style";
import Typography from "@mui/material/Typography";

function HeaderSection({ onClickAddFloorPlan }) {

  return (
    <StyledHeaderSection>
      <Box>
        <Typography>
          Add floor plan images here.
        </Typography>
      </Box>
    </StyledHeaderSection>
  );
}

export default HeaderSection;
