import React from "react";
import Grid from "@mui/material/Grid";
import Dropdown from "components/dropdown";

function FiltersBox({
  secondDropdownName,
  secondDropdownValue,
  secondDropdownOptions,
  secondDropdownOnChange,
  secondDropdownMultiple,
  thirdDropdownName,
  thirdDropdownValue,
  thirdDropdownOptions,
  thirdDropdownOnChange,
  thirdDropdownMultiple,
}) {
  return (
    <Grid container spacing={2} marginBottom='2rem'>
      <Grid item xs={12} md={6}>
        <Dropdown
          label={secondDropdownName}
          value={secondDropdownValue}
          options={secondDropdownOptions}
          onChange={secondDropdownOnChange}
          multiple={secondDropdownMultiple}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Dropdown
          label={thirdDropdownName}
          value={thirdDropdownValue}
          options={thirdDropdownOptions}
          onChange={thirdDropdownOnChange}
          multiple={thirdDropdownMultiple}
        />
      </Grid>
    </Grid>
  );
}

export default FiltersBox;
