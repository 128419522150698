import React, { useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "components/textfield";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import MixConstants from "constants/mix-constants";
import Dropdown from "components/dropdown";

function TourStopAccordionHeader({
  index,
  tourStop,
  onClickDelete,
  onChangeTourStopName,
  setAmenityType,
  submitting,
}) {
  useEffect(() => {
    if (scrollDiv.current) {
      scrollDiv.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, []);
  const scrollDiv = useRef("");
  const amenityTypes = [
    "Select an amenity type",
    "Leasing Center",
    "Pool",
    "Fitness Center",
    "Clubhouse / Resident Lounge",
    "Yoga Studio / Fitness Studio",
    "Dog Park",
    "Playground",
    "Game Room",
    "Dog Wash",
    "Package Locker",
    "Mail Room",
    "Conference Room",
    "Business Center / Lounge",
    "Other",
  ];

  const setAmenityTypeOnChange = (e, value) => {
    if (value) {
      setAmenityType(value, index);
    }
  };

  const getTourStopName = () => {
    if (tourStop?.name) {
      return tourStop.name;
    } else {
      if (tourStop.type === "amenity" && !tourStop?.userTourNameChange) {
        if (tourStop.amenityType) {
          return tourStop.amenityType;
        } else {
          return "";
        }
      }
    }
  };

  return (
    <Box>
      <Grid spacing={2} container alignItems='center' ref={scrollDiv}>
        <Grid item xs={12} md={12}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              display='inline'
              sx={{ fontSize: "1rem", fontWeight: 500 }}
            >
              <strong>{tourStop?.name}</strong> Tour Stop
              {tourStop?.type === "amenity" ? " (Amenity)" : " (Unit)"}
            </Typography>
            <IconButton
              size='small'
              onClick={onClickDelete}
              sx={{ marginLeft: "0.5rem" }}
              disabled={submitting}
            >
              <DeleteIcon fontSize='small' />
            </IconButton>
          </Box>
        </Grid>
        {/* <Grid item xs={12} md={12}>
          <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
            {tourStop.type === "amenity" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "40%",
                }}
              >
                <Box sx={{ width: "20%", mr: "15px" }}>
                  <Typography
                    display='inline'
                    sx={{ mr: "10px", fontStyle: "italic" }}
                  >
                    Category
                  </Typography>
                </Box>
                <Box sx={{ width: "80%", mr: "15px" }}>
                  <Dropdown
                    value={
                      tourStop.amenityType
                        ? tourStop.amenityType
                        : amenityTypes[0]
                    }
                    options={amenityTypes}
                    onChange={(e, value) => {
                      setAmenityTypeOnChange(e, value);
                    }}
                  />
                </Box>
              </Box>
            )}
            <Box>
              <Typography
                display='inline'
                sx={{ mr: "10px", fontStyle: "italic" }}
              >
                {tourStop.type === "amenity"
                  ? MixConstants.selfTour.amenityExample
                  : MixConstants.selfTour.unitExample}
              </Typography>
            </Box>
            <Box sx={{ width: tourStop.type === "amenity" ? "40%" : "80%" }}>
              <TextField
                value={getTourStopName() ?? ""}
                onChange={onChangeTourStopName}
              />
            </Box>
          </Box>
        </Grid> */}
      </Grid>
    </Box>
  );
}

export default TourStopAccordionHeader;
