import { Grid } from '@mui/material'
import TextField from 'components/textfield'
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import React from 'react'

const EbroucherWebLinkForm = ({ weblink, index, updateName, updateUrl, onDelete }) => {

  return (
    <Grid
    container
    spacing={2}
    marginTop={'0rem'}
    marginBottom = {'1rem'}
    >
      <Grid item xs={0.4}>
        <Tooltip title='Delete Webpage'>
          <IconButton size='small' onClick={() => {onDelete(weblink)}}>
            <DeleteIcon fontSize='small' />
          </IconButton>
        </Tooltip>
      </Grid>
    <Grid item xs={12} md={5.8}>
        <TextField
            label='Name'
            value={weblink?.name ?? ""}
            onChange={(e) => {updateName(e, index)}}
        />
    </Grid>
    <Grid item xs={12} md={5.8}>
        <TextField
            label='Link'
            value={weblink?.url ?? ""}
            onChange={(e) => {updateUrl(e, index)}}
        />
    </Grid>
    </Grid >
  )
}

export default EbroucherWebLinkForm