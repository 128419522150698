import React, { useState, useEffect } from "react";
import validator from "validator";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "components/button";
import Checkbox from "components/checkbox";
import { useTheme } from "styled-components";
import TextField from "components/textfield";
import FormAlert from "components/form-alert";
import { useSelector, useDispatch } from "react-redux";
import loginActions from "redux/actions/login-actions";
import messageActions from "redux/actions/message-actions";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { PynwheelImage, PYNLaunchLogoImage } from "assests/images";
import { StyledText, StyledNamedLogo, StyledLogo, CenteredBox } from "./style";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";

function LoginPage() {
  const theme = useTheme();
  const formBoxWidth = "18.5rem";

  const [state, setState] = useState({
    email: null,
    isValidEmail: false,
    isEmptyEmail: false,
    password: null,
    isEmptyPassword: false,
    showPassword: false,
    rememberMe: false,
    errorMessage: null,
  });

  const dispatch = useDispatch();
  const messageState = useSelector((state) => state.message);
  const loginState = useSelector((state) => state.login);

  const missingEmailText = "Email address is missing";
  const invalidEmailText = "Email address is invalid";
  const missingPasswordText = "Password is missing";

  useEffect(() => {
    return () => {
      dispatch(messageActions.clearMessage());
    };
  }, [dispatch]);

  const handleEmailFieldChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      email: value,
      isValidEmail: validator.isEmail(value),
      isEmptyEmail: validator.isEmpty(value),
      errorMessage: "",
    });
  };

  const handlePasswordFieldChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      password: value,
      isEmptyPassword: validator.isEmpty(value),
      errorMessage: "",
    });
  };

  const passwordEyeIconClicked = () => {
    setState({
      ...state,
      showPassword: !state.showPassword,
    });
  };

  const handleRememberMeCheckboxChange = (name, value) => {
    setState({
      ...state,
      rememberMe: value,
    });
  };

  const canUserProceed = () => {
    const { email, isEmptyEmail, isValidEmail, password, isEmptyPassword } =
      state;

    if (!email || isEmptyEmail) {
      setState({
        ...state,
        errorMessage: missingEmailText,
      });
    } else if (!isValidEmail) {
      setState({
        ...state,
        errorMessage: invalidEmailText,
      });
    } else if (!password || isEmptyPassword) {
      setState({
        ...state,
        errorMessage: missingPasswordText,
      });
    }
    return isValidEmail && password;
  };

  const signinButtonClicked = () => {
    const { email, password } = state;

    if (canUserProceed()) {
      dispatch(messageActions.clearMessage());
      dispatch(loginActions.login(email, password));
    }
  };

  const handleKeyDownOnEmailField = (e) => {
    if (e.key === "Enter") {
      if (state.password) {
        signinButtonClicked();
      }
    }
  };

  const handleKeyDownOnPasswordField = (e) => {
    if (e.key === "Enter") {
      if (state.email) {
        signinButtonClicked();
      }
    }
  };

  const leftGridStyle = {
    // backgroundColor: "#fbb034",
    // backgroundImage: "linear-gradient(315deg, #fbb034 0%, #ffdd00 74%)",
    ////
    backgroundColor: "#dfbb33",
    backgroundImage: "linear-gradient(315deg, #dfbb33 0%, #008ed1 74%)",
    ////
    // backgroundColor: "#f2cf07",
    // backgroundImage: "linear-gradient(315deg, #f2cf07 0%, #55d284 74%)",
  };

  return (
    <Box>
      <Grid container margin={0} sx={{ minHeight: "100vh" }}>
        <Grid item xs={12} sm={6} sx={leftGridStyle}>
          <CenteredBox>
            <StyledText size='2.5rem' weight={500}>
              Welcome to
            </StyledText>
            <StyledNamedLogo src={PYNLaunchLogoImage} alt='logo' />
            <StyledText size='2rem'>Log in to access your account</StyledText>
          </CenteredBox>
        </Grid>

        <Grid item xs={12} sm={6}>
          <CenteredBox>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <StyledLogo src={PynwheelImage} alt='logo' />
              <StyledText size='2rem' color={theme.colors.secondary}>
                Pynwheel Inc.
              </StyledText>
            </Box>

            <Box sx={{ paddingTop: "5rem" }}>
              <TextField
                label='Email Address'
                type='email'
                variant='standard'
                width={formBoxWidth}
                disabled={loginState.loggingIn}
                value={state.email ? state.email : ""}
                onChange={handleEmailFieldChange}
                onKeyDown={handleKeyDownOnEmailField}
              />
            </Box>

            <Box sx={{ paddingTop: "1rem" }}>
              <TextField
                label='Password'
                type={state.showPassword ? "text" : "password"}
                variant='standard'
                width={formBoxWidth}
                rightIcon={
                  state.showPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )
                }
                disabled={loginState.loggingIn}
                onClickRightIcon={() => passwordEyeIconClicked()}
                value={state.password ? state.password : ""}
                onChange={handlePasswordFieldChange}
                onKeyDown={handleKeyDownOnPasswordField}
              />
            </Box>

            <Box sx={{ paddingTop: "1rem", width: formBoxWidth, display: "flex", justifyContent: "space-between", alignItems: "center"}}>
              <Checkbox
                label='Remember me'
                checked={state.rememberMe}
                disabled={loginState.loggingIn}
                onChange={(e) =>
                  handleRememberMeCheckboxChange(
                    e.target.name,
                    e.target.checked,
                  )
                }
              />
              <Link to={{ pathname: `${process.env.REACT_APP_SERVER_BASE_URL}/users/password/new` }} target="_parent">
                <Typography>
                  Forgot Password?
                </Typography>
              </Link>
            </Box>

            <Box sx={{ paddingTop: "3rem" }}>
              <Button
                title='Login'
                width={formBoxWidth}
                onClick={signinButtonClicked}
                loading={loginState.loggingIn}
                disabled={!state.email || !state.password}
              />
            </Box>

            {messageState.message && (
              <Box sx={{ paddingTop: "1rem" }}>
                <FormAlert message={messageState.message} />
              </Box>
            )}
            {state.errorMessage && (
              <Box sx={{ paddingTop: "1rem" }}>
                <FormAlert message={state.errorMessage} />
              </Box>
            )}

            <Box sx={{ position: "absolute", bottom: "2rem" }}>
              <StyledText size='0.85rem' color={theme.colors.secondary}>
                Pynwheel Inc © 2021. All rights reserved
              </StyledText>
            </Box>
          </CenteredBox>
        </Grid>
      </Grid>
    </Box>
  );
}

export default LoginPage;
