import React from "react";
import Box from "@mui/material/Box";
import TextField from "components/textfield";
import Typography from "@mui/material/Typography";

function OtherLock({state, setState}) {
  const handleChangeDescription = (event) => {
    setState({
      ...state,
      description: event.target.value,
    })
  }
  return (
    <Box sx={{ paddingY: "0.6rem" }}>
      <Typography sx={{ marginBottom: "0.9rem" }}>
        Provide details about lock providers you will be using.
      </Typography>
      <TextField multiline rows={6} value={state.description ?? ""} onChange={handleChangeDescription} />
    </Box>
  );
}

export default OtherLock;
