import config from "./api-config";
import { authHeaderJson } from "helpers/api-header";

const CompanyApi = {
  updateCompany,
};

/**
 *  Update Company
 */

async function updateCompany(companyId, company) {
  const requestOptions = {
    method: "PUT",
    headers: authHeaderJson(),
    body: JSON.stringify(company),
  };

  const url = `${config.baseURL}/api/v2/user_details/${companyId}/update_company`;

  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Response Handler
 */

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      return Promise.reject(data);
    }
    return data;
  });
}

export default CompanyApi;
