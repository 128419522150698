import React from "react";
import Button from "components/button";
import { useTheme } from "styled-components";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

function BackButton({
  bgColor,
  titleColor,
  title,
  width,
  startIcon = <ArrowBackIosIcon />,
  endIcon,
  onClick,
}) {
  const theme = useTheme();

  return (
    <Button
      bgColor={bgColor ? bgColor : theme.colors.transparent}
      titleColor={titleColor ? titleColor : theme.colors.black}
      title={title}
      width={width}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
    />
  );
}

export default BackButton;
