import React from "react";
import { StyledSearchBox } from "./style";
import TextField from "components/textfield";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from "@mui/material/CircularProgress";

function SearchBox({
  label,
  onChange,
  width = "40rem",
  onClickSearchIcon,
  searching,
}) {
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onClickSearchIcon();
    }
  };

  return (
    <StyledSearchBox>
      <TextField
        width={width}
        label={label}
        onKeyDown={handleKeyDown}
        rightIcon={searching ? <CircularProgress size={20} /> : <SearchIcon />}
        onClickRightIcon={searching ? null : onClickSearchIcon}
        onChange={onChange}
      />
    </StyledSearchBox>
  );
}

export default SearchBox;
