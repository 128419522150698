import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import moment from "moment";
import Paths from "routes/paths";
import Utils from "helpers/utils";
import { CommunityApi } from "apis";
import SearchBox from "./search-box";
import TitleLabels from "./title-labels";
import { useHistory } from "react-router";
import Accordion from "components/accordion";
import { useTheme } from "styled-components";
import MixConstants from "constants/mix-constants";
import { CompanyDetailForm } from "components/forms";
import CommunitiesList from "components/communities-list";
import TablePagination from "@mui/material/TablePagination";
import { PageMainContainer, CenteredBox } from "pages/style";
import CircularProgress from "@mui/material/CircularProgress";
import AccordionFormHeader from "components/accordion/accordion-form-header";
import { EmptyCircleImage, InProgressImage, ApprovedImage, SubmittedImage, RejectedImage } from "assests/images";
import updateStoreActions from "redux/actions/update_store_actions";

function ClientDashboardPage() {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();

  const role = useSelector((state) => state.login.user.role);
  const isSuperAdmin = role === "Super admin" ? true : false;
  const stateStatus = useSelector((state) => state.login.user.company_details.company_status);
  const status = Utils.convertStatus(stateStatus);

  const [searchText, setSearchText] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalNoOfCommunities, setTotalNoOfCommunities] = useState(0);
  const [communities, setCommunities] = useState([]);
  const [fetchingCommunities, setFetchingCommunities] = useState(false);

  let [state, setState] = useState({
    companyDetailExpanded: false,
  });

  const getProgressPercentage = (item) => {
    let allStatus = [];
    if (item?.community_detail_forms?.length > 0) {
      item.community_detail_forms.map((form, index) => {
        if (form.name !== "Your Company Details" && form.name !== "Manage Your Stops for Self Tour App"){
          const status = Utils.convertStatus(form.status)
          allStatus.push(status.name);
        }
        return []
      });
    }

    const submitted = ((allStatus.filter(x => x=== MixConstants.FormStatus.SUBMITTED || x=== MixConstants.FormStatus.APPROVED || x=== MixConstants.FormStatus.APPLICATION_IN_REVIEW || x=== MixConstants.FormStatus.RELEASED || x=== MixConstants.FormStatus.FORM_APPROVED).length) / allStatus.length ) * 100;
    const approved = ((allStatus.filter(x =>  x=== MixConstants.FormStatus.RELEASED || x=== MixConstants.FormStatus.FORM_APPROVED || x=== MixConstants.FormStatus.APPLICATION_IN_REVIEW || x=== MixConstants.FormStatus.APPROVED).length) / allStatus.length ) * 100;

    return {
      submittedPercentage: Math.floor(submitted),
      approvedPercentage: Math.floor(approved),
    };
  }

  const statusImage = (status) => {
    if (status === MixConstants.FormStatus.NONE) {
      return EmptyCircleImage;
    } else if (status === MixConstants.FormStatus.RELEASED || status === MixConstants.FormStatus.APPLICATION_IN_REVIEW || status === MixConstants.FormStatus.APPROVED || status === MixConstants.FormStatus.FORM_APPROVED || status === MixConstants.FormStatus.DEPLOYED) {
      return ApprovedImage;
    } else if (status === MixConstants.FormStatus.IN_PROGRESS) {
      return InProgressImage;
    } else if (status === "Attention Required") {
      return RejectedImage;
    } else if (status === MixConstants.FormStatus.SUBMITTED) {
      return SubmittedImage;
    }
  };

  const fetchCommunities = useCallback(() => {
    setFetchingCommunities(true);
    setCommunities([]);
    CommunityApi.fetchCommunities(page + 1, rowsPerPage, searchQuery).then(
      (response) => {
        setFetchingCommunities(false);
        const { data } = response;
        if (data && data.length > 0) {
          dispatch(updateStoreActions.updateCompanyStatus(data[0].company_details));
          const communitiesList = data.map((item) => {
            const percentage = getProgressPercentage(item)
            const submittedPercentage = percentage.submittedPercentage ?? 0;
            const approvedPercentage = percentage.approvedPercentage ?? 0;
            const communityUserId = item?.id;
            const communityId = item?.community?.id;
            let products = item?.community_products;

            if (products?.length > 0) {
              products = products.map((p) => _.startCase(p)).join(", ");
            }

            const actionButtons = [
              {
                id: 1,
                title: "View Details",
                bgColor: theme.colors.green,
                titleColor: theme.colors.white,
                onClick: () =>
                  history.push(`${Paths.Client.COMMUNITY}/${communityId}`, {
                    params: {
                      communityUserId: communityUserId,
                    },
                  }),
              },
            ];
            let progressSteps = [];
            if (item?.community_detail_forms?.length > 0) {

              item?.community_detail_forms.map((form, index) => {
                if (!isSuperAdmin && form.name !== "Your Company Details"){
                  progressSteps.push({
                    id: index,
                    title: form.name,
                    status: Utils.convertStatus(form.status),
                    subTitile: "",
                  });
                }
                return [];
              });
            }
            return {
              id: item?.id,
              title: `${item?.company_details?.name} | ${item?.community?.name}`,
              companyName: item?.company_details?.name,
              company: item?.company_details,
              invitationDate: moment(item?.invitation_date).format(
                "D MMM YYYY",
              ),
              status: MixConstants.FormStatus.IN_PROGRESS,
              actionButtons: actionButtons,
              progressSteps: progressSteps,
              products: products,
              submittedPercentage: submittedPercentage,
              approvedPercentage: approvedPercentage,
            };
          });
          setCommunities(communitiesList);
          setTotalNoOfCommunities(response?.meta?.total_entries);
        } else {
          setCommunities([]);
          setTotalNoOfCommunities(0);
        }
      },
      (error) => {
        setFetchingCommunities(false);
      },
    );
  }, [page, rowsPerPage, searchQuery, history, theme, isSuperAdmin, dispatch]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchCommunities(abortController);
    return () => {
      abortController.abort();
    }
  }, [fetchCommunities]);

  const handleCompanyDetailAccordionChange = () => {
    setState({
      ...state,
      companyDetailExpanded: !state.companyDetailExpanded,
    });
  };

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const handleSearchFieldChange = (e) => {
    setPage(0);
    setSearchText(e.target.value);

    if (e.target.value === "") {
      setSearchQuery("");
    }
  };

  return (
    <PageMainContainer sx={{width: 1050}}>
      <TitleLabels />

      <Accordion
        expanded={state.companyDetailExpanded}
        header={
          <AccordionFormHeader
            expanded={state.companyDetailExpanded}
            onClickExpand={handleCompanyDetailAccordionChange}
            title='Your Company Details'
            // subTitle='Basic Information about company is required in this section.'
            rejectMessage={status.remarks ? status.remarks : ""}
            avatar={statusImage(status.name)}
            status={status.name}
          />
        }
      >
        <CompanyDetailForm status={status.name}
        />
      </Accordion>

      <SearchBox
        label='Search by company / community name'
        onChange={handleSearchFieldChange}
        searching={searchQuery !== "" && fetchingCommunities}
        onClickSearchIcon={() => setSearchQuery(searchText)}
      />

      <CommunitiesList communities={communities} />

      {communities.length > 0 && totalNoOfCommunities > communities.length ? (
        <CenteredBox>
          <TablePagination
            component='div'
            count={totalNoOfCommunities}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 15, 20]}
          />
          <CenteredBox />
          {fetchingCommunities && <CircularProgress size={20} />}
        </CenteredBox>
      ) : fetchingCommunities ? (
        <CenteredBox>
          <CircularProgress size={20} />
        </CenteredBox>
      ) : communities.length === 0 ? (
        <CenteredBox>
          <p>No communities found</p>
        </CenteredBox>
      ) : null}
    </PageMainContainer>
  );
}

export default ClientDashboardPage;
