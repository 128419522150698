import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "components/textfield";
import Typography from "@mui/material/Typography";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function DweloLock({state, setState}) {
  const [showClientSecret, setShowClientSecret] = useState(false);

  const handleCommunityIdChange = (event) => {
    setState({
      ...state,
      communityId: event.target.value,
    });
  }

  const handleClientIdChange = (event) => {
    setState({
      ...state,
      clientId: event.target.value,
    });
  }

  const handleClientSecretChange = (event) => {
    setState({
      ...state,
      clientSecret: event.target.value,
    });
  }

  return (
    <Box sx={{ paddingTop: "0.6rem" }}>
      <Typography>Provide credentials below.</Typography>

      <Grid container padding='1rem'>
        <Grid item xs={3}></Grid>
        <Grid item xs={12} md={6} sx={{ marginBottom: "1.3rem" }}>
          <TextField required label='Community ID' value={state.communityId} onChange={handleCommunityIdChange} />
        </Grid>
        <Grid item xs={3}></Grid>

        {/* ----------- */}

        <Grid item xs={3}></Grid>
        <Grid item xs={12} md={6} sx={{ marginBottom: "1.3rem" }}>
          <TextField label='Client ID' value={state.clientId} onChange={handleClientIdChange}/>
        </Grid>
        <Grid item xs={3}></Grid>

        {/* ----------- */}

        <Grid item xs={3}></Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label='Client Secret'
            value={state.clientSecret}
            onChange={handleClientSecretChange}
            type={showClientSecret ? "text" : "password"}
            rightIcon={
              showClientSecret ? <VisibilityIcon /> : <VisibilityOffIcon />
            }
            onClickRightIcon={() => setShowClientSecret(!showClientSecret)}
          />
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
    </Box>
  );
}

export default DweloLock;
