import Box from "@mui/material/Box";
import styled from "styled-components";

export const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
`;

export const StyledRow = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: ${({ marginLeft }) => marginLeft};
  padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop : "0rem")};
  padding-bottom: ${({ paddingBottom }) =>
    paddingBottom ? paddingBottom : "0rem"};
  padding-right: 0.25rem;
`;
