import React from "react";
import Alert from "@mui/material/Alert";

function FormAlert({ type = "error", message, onClose }) {
  return (
    <Alert severity={type} onClose={onClose}>
      {message}
    </Alert>
  );
}

export default FormAlert;
