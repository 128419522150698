import Box from "@mui/material/Box";
import styled from "styled-components";
import { device } from "helpers/device";

export const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const TitleAndStatusBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${device.mobileM} {
    flex-direction: column;
  }
`;
