import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import TextField from "components/textfield";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import ReplayIcon from "@mui/icons-material/Replay";
import ArticleIcon from "@mui/icons-material/Article";
import VideoFileIcon from "@mui/icons-material/VideoFile";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Typography, CircularProgress, Box } from "@mui/material";
import {
  StyledBox,
  StyledAvatar,
  StyledFileIcon,
} from "../files-grid-list/style";

function FileItemWithNameDescription({
  file,
  disabled,
  isDetailAvailable,
  onClickView,
  onClickDownload,
  onClickRemove,
  onClickRetry,
  onNameChange,
  onDescriptionChange,
}) {
  let isImage = false;
  let isVideo = false;
  let isFile = false;

  const isUploading = file?.uploading ?? false;
  const uploadProgress = file?.progress ?? 0;
  const uploadingFailed = file?.failed ?? false;
  const uploadingFailedMessage = "Failed";
  // const uploaded = file?.uploaded ?? false;
  // const uploadingFulfilled = file?.fulfilled ?? false;

  if (
    file?.type?.split("/")?.[0] === "image" ||
    file?.file_type?.split("/")?.[0] === "image" ||
    file?.photo
  ) {
    isImage = true;
  } else if (file?.type === "video") {
    isVideo = true;
  } else {
    isFile = true;
  }

  const renderImageView = () => {
    return (
      <StyledBox>
        {isImage ? (
          <StyledAvatar
            alt='file thumbnail'
            variant='rounded'
            src={
              file?.photo || file?.url
                ? file?.photo || file?.url
                : !file?.amenity
                ? URL.createObjectURL(file)
                : ""
            }
          />
        ) : isVideo ? (
          <StyledFileIcon>
            <VideoFileIcon fontSize='large' />
          </StyledFileIcon>
        ) : isFile ? (
          <StyledFileIcon>
            <ArticleIcon fontSize='large' />
          </StyledFileIcon>
        ) : null}

        {isUploading ? (
          <Box sx={{ display: "flex", alignItems: "center", marginTop: "3px" }}>
            <CircularProgress size={15} sx={{ marginRight: "5px" }} />
            <Typography sx={{ fontSize: "15px" }}>
              {Math.round(uploadProgress)}%
            </Typography>
          </Box>
        ) : uploadingFailed ? (
          <Stack direction='row'>
            <Typography
              sx={{ fontSize: "15px", marginTop: "3px", color: "red" }}
            >
              {uploadingFailedMessage}
            </Typography>

            <Tooltip title='Retry'>
              <IconButton onClick={onClickRetry} size='small'>
                <ReplayIcon fontSize='small' />
              </IconButton>
            </Tooltip>

            <Tooltip title='Remove'>
              <IconButton onClick={onClickRemove} size='small'>
                <ClearIcon fontSize='small' />
              </IconButton>
            </Tooltip>
          </Stack>
        ) : !disabled ? (
          <Stack direction='row'>
            {isImage && (
              <Tooltip title='View'>
                <IconButton onClick={onClickView} size='small'>
                  <VisibilityIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title='Download'>
              <IconButton onClick={onClickDownload} size='small'>
                <FileDownloadIcon fontSize='small' />
              </IconButton>
            </Tooltip>

            <Tooltip title='Remove'>
              <IconButton onClick={onClickRemove} size='small'>
                <ClearIcon fontSize='small' />
              </IconButton>
            </Tooltip>
          </Stack>
        ) : null}
      </StyledBox>
    );
  };

  return isDetailAvailable ? (
    <Grid container spacing={1} sx={{ marginTop: "10px" }}>
      <Grid item xs={12} md={2}>
        {renderImageView()}
      </Grid>

      <Grid item xs={12} md={4}>
        <TextField
          label='Name'
          defaultValue={file?.name ?? ""}
          onChange={onNameChange}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          label='Description'
          defaultValue={file?.description ?? ""}
          onChange={onDescriptionChange}
        />
      </Grid>
    </Grid>
  ) : (
    renderImageView()
  );
}

export default FileItemWithNameDescription;
