import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import Typography from "@mui/material/Typography";
import GalleryListItem from "./gallery-list-item";

function GalleryList({
  disabled,
  galleries,
  communityId,
  onDelete,
  onUpdate,
  onDeleteImage,
  onNameUpdate,
  onUploadAllFiles,
}) {
  const renderGalleries = (galleries) => {
    return (
      galleries &&
      galleries.map((gallery, index) => (
        <Box key={gallery.id}>
          <GalleryListItem
            index={index}
            gallery={gallery}
            communityId={communityId}
            disabled={disabled}
            onDelete={onDelete}
            onUpdate={onUpdate}
            onNameUpdate={onNameUpdate}
            onDeleteImage={onDeleteImage}
            onUploadAllFiles={onUploadAllFiles}
          />
        </Box>
      ))
    );
  };

  return (
    <Box sx={{ paddingTop: "1rem" }}>
      <Box>
        <Grid spacing={2} container alignItems='center'>
          <Grid item xs={12} md={3}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant='subtitle1' sx={{ paddingRight: "0.5rem" }}>
                Gallery Name
              </Typography>
              <Tooltip title='Examples: Exteriors, Interiors, Neighborhood etc.'>
                <InfoIcon fontSize='small' />
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography variant='subtitle1'>Upload Images</Typography>
          </Grid>
        </Grid>
      </Box>
      {renderGalleries(galleries)}
    </Box>
  );
}

export default GalleryList;
