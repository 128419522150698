import React from "react";
import FormGroup from "@mui/material/FormGroup";
import { Checkbox as CheckBox } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";

function Checkbox({ label, name, checked = false, disabled, onChange }) {
  return (
    <FormGroup>
      <FormControlLabel
        control={<CheckBox checked={checked} />}
        label={label}
        name={name}
        disabled={disabled}
        onChange={onChange}
      />
    </FormGroup>
  );
}

export default Checkbox;
