import React from "react";
import Paths from "./paths";
import history from "helpers/history";
import Header from "components/header";
import Footer from "components/footer";
import LoginPage from "pages/login-page";
import { useSelector } from "react-redux";
import Persistence from "helpers/persistence";
import MixConstants from "constants/mix-constants";
import AdminDashboardPage from "pages/admin/admin-dashboard-page";
import { Switch, Route, Redirect, Router } from "react-router-dom";
import ClientDashboardPage from "pages/client/client-dashboard-page";
import CommunityFormsListingPage from "pages/community-forms-listing-page";

const redirectToLoginPage = () => {
  return <Redirect to={Paths.Common.LOGIN} />;
};

const redirectToDashboardPage = () => {
  return <Redirect to='/' />;
};

const superAdminRoutes = () => {
  return (
    <Router history={history}>
      <Header />
      <Switch>
        <Route exact path='/'>
          <AdminDashboardPage />
        </Route>
        <Route path={`${Paths.Client.COMMUNITY}/:communityId`}>
          <CommunityFormsListingPage />
        </Route>
        <Route component={redirectToDashboardPage} />
      </Switch>
      <Footer />
    </Router>
  );
};

const clientRoutes = () => {
  return (
    <Router history={history}>
      <Header />
      <Switch>
        <Route exact path='/'>
          <ClientDashboardPage />
        </Route>
        <Route path={`${Paths.Client.COMMUNITY}/:communityId`}>
          <CommunityFormsListingPage />
        </Route>
        <Route component={redirectToDashboardPage} />
      </Switch>
      <Footer />
    </Router>
  );
};

const unAuthenticatedRoutes = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path='/'>
          <LoginPage />
        </Route>
        <Route path={Paths.Common.LOGIN}>
          <LoginPage />
        </Route>
        <Route component={redirectToLoginPage} />
      </Switch>
    </Router>
  );
};

const Routes = () => {
  const loginState = useSelector((state) => state.login);

  if (loginState?.user) {
    const isSuperAdmin =
      loginState.user?.role?.toLowerCase() ===
      MixConstants.Role.SUPER_ADMIN.toLowerCase();

    if (isSuperAdmin) {
      return superAdminRoutes();
    } else {
      return clientRoutes();
    }
  } else {
    Persistence.removeUserData();
    return unAuthenticatedRoutes();
  }
};

export default Routes;
