import React, {useEffect, useCallback, useState} from "react";
import Button from "components/button";
import _ from "lodash"
import Divider from "@mui/material/Divider";
import { useParams } from "react-router-dom";
import { useTheme } from "styled-components";
import { Container, StyledFooter, StyledSpinnerContainer } from "../style";
import TourHoursSection from "./tour-hours-section";
import { FormApi } from "apis";
import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import FormAlert from "components/form-alert";
import MixConstants from "constants/mix-constants";

function VisitingHoursForm({expanded, checkStatus, status, closeForm}) {
  const theme = useTheme();
  const {communityId} = useParams();
  
  const [submitting, setSubmitting] = useState(false);

  const [fetching, setFetching] = useState(true);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const tourDaysFormat = useCallback(() => {
    return [
      { id: 1, name: "Everyday", startTime: "", endTime: "", isLocal: true },
      { id: 2, name: "Monday", startTime: "", endTime: "", isLocal: true },
      { id: 3, name: "Tuesday", startTime: "", endTime: "", isLocal: true },
      { id: 4, name: "Wednesday", startTime: "", endTime: "", isLocal: true },
      { id: 5, name: "Thursday", startTime: "", endTime: "", isLocal: true },
      { id: 6, name: "Friday", startTime: "", endTime: "", isLocal: true },
      { id: 7, name: "Saturday", startTime: "", endTime: "", isLocal: true },
      { id: 8, name: "Sunday", startTime: "", endTime: "", isLocal: true },
    ]
  }, [])

  const [state, setState] = useState({
    selfDaysHour: _.cloneDeep(tourDaysFormat()),
    guidedDaysHour: _.cloneDeep(tourDaysFormat()),
  });

  const formAlertCloseButtonClicked = () => {
    setMessage("");
  };

  const setMessageText = (text, type) => {
    formAlertCloseButtonClicked();
    setMessage(text);
    setMessageType(type)
    setTimeout(() => {
      formAlertCloseButtonClicked();
    }, 5000);
  };

  const handleSetState = (values, type) => {
    if (type === 'guided'){
      setState({
        ...state,
        guidedDaysHour: values,
      });
    } else {
      setState({
        ...state,
        selfDaysHour: values,
      });
    }
  }

  const userCanProceed = () => {
    let hasError = false;
    state.selfDaysHour.map((selfDay) => {
      if (selfDay.startTime !== "" && selfDay.endTime === ""){
        setMessageText("Please select end time related to the start time!", "error");
        hasError = true;
      }
      if (selfDay.endTime !== "" && selfDay.startTime === ""){
        setMessageText("Please select start time related to the end time!", "error");
        hasError = true;
      }
      return [];
    });
    state.guidedDaysHour.map((guidedDay) => {
      if (guidedDay.startTime !== "" && guidedDay.endTime === ""){
        setMessageText("Please select end time related to the start time!", "error");
        hasError = true;
      }
      if (guidedDay.endTime !== "" && guidedDay.startTime === ""){
        setMessageText("Please select start time related to the end time!", "error");
        hasError = true;
      }
      return [];
    })
    return !hasError;
  }

  const filterNullId = (visitingHour) => {
    const keys = Object.keys(visitingHour);

    keys.forEach((key, index) => {
        if (visitingHour[key].id === null){
          delete visitingHour[key].id;
        }
    });
    return visitingHour;
  }

  const changeTimeTo12Hours = (time) => {
    return new Date('1970-01-01T' + time + 'Z')
      .toLocaleTimeString('en-US',
        {timeZone:'UTC',hour12:true,hour:'numeric',minute:'numeric'}
      );
  }

  const handleSubmitButtonClicked = () => {
    const successMessage = "Visiting hours updated successfully!";
    const errorMessage = "Unable to update visiting hours.";
    let data = [];
    let selfHour = {};
    let guidedHour = {};
    state.selfDaysHour.map((day) => {
      const dayName = day.name;
      if (dayName !== "Everyday"){
        selfHour[dayName] = {
          id: day.isLocal ?  null : day.id,
          opening_time: changeTimeTo12Hours(day.startTime),
          closing_time: changeTimeTo12Hours(day.endTime),
        }
      }
      return [];
    });
    state.guidedDaysHour.map((day) => {
      const dayName = day.name;
      if (dayName !== "Everyday"){
        guidedHour[dayName] = {
          id: day.isLocal ?  null : day.id,
          opening_time: changeTimeTo12Hours(day.startTime),
          closing_time: changeTimeTo12Hours(day.endTime),
        }
      }
      return [];
    });
    data = {
      Self_tour: filterNullId(selfHour),
      Guided_tour: filterNullId(guidedHour),
    }
    if(userCanProceed()){
      if(communityId){
        setSubmitting(true);
        FormApi.saveVisitingHours(communityId, data).then((response) => {
          setSubmitting(false);
          if(response.success){
            const {data} = response
            const mappedData = getMappedData(data);
            setState({
              selfDaysHour: mappedData.selfTour,
              guidedDaysHour: mappedData.guidedTour,
            });
            checkStatus();
            setMessageText(successMessage, "success");
          }
        }, (error) => {
          setMessageText(errorMessage, "error");
          setSubmitting(false);
        });
      }
    }
  }

  const handleSaveAndSubmitButtonClicked = () => {
    let data = [];
    let selfHour = {};
    let guidedHour = {};
    state.selfDaysHour.map((day) => {
      const dayName = day.name;
      if (dayName !== "Everyday"){
        selfHour[dayName] = {
          id: day.isLocal ?  null : day.id,
          opening_time: changeTimeTo12Hours(day.startTime),
          closing_time: changeTimeTo12Hours(day.endTime),
        }
      }
      return [];
    });
    state.guidedDaysHour.map((day) => {
      const dayName = day.name;
      if (dayName !== "Everyday"){
        guidedHour[dayName] = {
          id: day.isLocal ?  null : day.id,
          opening_time: changeTimeTo12Hours(day.startTime),
          closing_time: changeTimeTo12Hours(day.endTime),
        }
      }
      return [];
    });
    data = {
      Self_tour: filterNullId(selfHour),
      Guided_tour: filterNullId(guidedHour),
    }
    if(userCanProceed()){
      if(communityId){
        FormApi.saveVisitingHours(communityId, data, "in_progress").then((response) => {
          if(response.success){
            checkStatus();
          }
        }, (error) => {
        });
      }
    }
    closeForm();
  }

  const getMappedData = useCallback((hours) => {
    
    const convertTime12to24 = (time12h) => {
      const [time, modifier] = time12h.split(' ');
    
      let [hours, minutes] = time.split(':');
    
      if (hours === '12') {
        hours = '00';
      }
    
      if (modifier === 'PM') {
        hours = parseInt(hours, 10) + 12;
      }
    
      return `${hours}:${minutes}`;
    }
    let guidedDays = _.cloneDeep(tourDaysFormat());
    let selfDays = _.cloneDeep(tourDaysFormat());
      if (hours?.SELF_TOUR){
        selfDays.map((day) => {
          hours.SELF_TOUR.map((hour) => {
            if (day.name === hour.day){
              day.id = hour.id ;
              day.name = hour.day;
              day.startTime = convertTime12to24(hour.opening_time);
              day.endTime = convertTime12to24(hour.closing_time);
              day.isLocal = false;
            }
            return [];
          })
          return [];
        });
      }
      if (hours?.GUIDED_TOUR){
        guidedDays.map((day) => {
          hours.GUIDED_TOUR.map((hour) => {
            if (day.name === hour.day){
              day.id = hour.id ;
              day.name = hour.day;
              day.startTime = convertTime12to24(hour.opening_time);
              day.endTime = convertTime12to24(hour.closing_time);
              day.isLocal = false;
            }
            return [];
          })
          return [];
        })
      }
    return {
      selfTour: selfDays,
      guidedTour: guidedDays,
    }

  },[tourDaysFormat])

  const fetchVisitingHours = useCallback(() => {
    if (expanded){
      if (communityId){
        setFetching(true);
        FormApi.fetchVisitingHours(communityId).then((response) => {
          if (response.success){
            const {data} = response;
            const mappedData = getMappedData(data);
            setState({
              selfDaysHour: mappedData.selfTour,
              guidedDaysHour: mappedData.guidedTour,
            });
          }
          setFetching(false);
        }, (error) => {
          setFetching(false);
        })
      }
    }
  }, [communityId, expanded, getMappedData])

  useEffect(() => {
    const abortController = new AbortController();
    fetchVisitingHours(abortController);
    return () => {
      abortController.abort();
    }
  }, [fetchVisitingHours])
  
  if (fetching) {
    return (
      <StyledSpinnerContainer>
        <CircularProgress size={20} />
      </StyledSpinnerContainer>
    );
  }

  return (
    <Container>
      <TourHoursSection type='self' days={state.selfDaysHour} setDays={(values) => handleSetState(values, "self")}/>

      <Divider />

      <TourHoursSection type='guided' days={state.guidedDaysHour} setDays={(values) => handleSetState(values, "guided")}/>

      <Divider />
      {message && messageType && (
            <Box>
              <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
              <FormAlert
                message={message}
                type={messageType}
                onClose={() => formAlertCloseButtonClicked()}
              />
            </Box>
          )}

      <StyledFooter>
        <Button
          bgColor={theme.colors.gray}
          titleColor={theme.colors.white}
          title='Save & Finish Later'
          onClick={handleSaveAndSubmitButtonClicked}
        />
        <Button
        bgColor={theme.colors.green}
        loading={submitting}
        disabled={status !== MixConstants.FormStatus.DEPLOYED ? submitting : true}
        onClick={handleSubmitButtonClicked}
        title='Submit' />
      </StyledFooter>
    </Container>
  );
}

export default VisitingHoursForm;
