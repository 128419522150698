import React, { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Utils from "helpers/utils";
import TitleLabels from "./title-labels";
import { FormApi } from "apis";
import { useHistory, useParams } from "react-router-dom";
import ReportIssueModal from "./report-issue-modal";
import { useTheme } from "styled-components";
import Accordion from "components/accordion";
import BackButton from "components/back-button";
import { PageMainContainer } from "pages/style";
import { useIsMounted } from "hooks/use-is-mounted";
import {
  EmptyCircleImage,
  RejectedImage,
  InProgressImage,
  SubmittedImage,
  ApprovedImage,
} from "assests/images";
import CommunityForms from "constants/community-forms";
import AccordionFormHeader from "components/accordion/accordion-form-header";
import { Container } from "components/forms/style";
import MixConstants from "constants/mix-constants";
import {
  CompanyDetailForm,
  CommunityDetailForm,
  PropertyMapImagesForm,
  FloorPlansImagesForm,
  GalleryPropertyImagesForm,
  PynwheelTouchHomePageMediaForm,
  ConnectPropertyManagementSystemForm,
  LockProvidersAndCredentialsForm,
  ManageSelfTourStopsForm,
  HardwareSpecsAndInstallationForm,
  VisitingHoursForm,
} from "components/forms";
import DesignDirectionForm from "components/forms/design-direction-form";
import AmenityImagesForm from "components/forms/amenity-images-form";
import EBroucher from "components/forms/e-broucher-form";
import AdditionalPagesForm from "components/forms/additional-pages-form";
import Typography from "@mui/material/Typography";

function CommunityFormsListingPage() {
  const theme = useTheme();
  const history = useHistory();
  const isMounted = useIsMounted();
  const { communityId } = useParams();
  const communityUserId = history?.location?.state?.params?.communityUserId;
  const [communityName, setCommunityName] = useState("community");
  const communityForms = CommunityForms;
  const [forms, setForms] = useState([]);
  const [openReportIssue, setOpenReportIssue] = useState(false);
  const [openEditReportIssue, setOpenEditReportIssue] = useState(false);
  const role = useSelector((state) => state.login.user.role);
  const isSuperAdmin = role === "Super admin" ? true : false;
  const [statusUpdate, setStatusUpdate] = useState(false);
  const [company, setCompany] = useState("");
  const [floorplanCount, setFloorplanCount] = useState(0);
  const [designProduct, setDesignProduct] = useState({});
  const [commuityProducts, setCommuityProducts] = useState([]);
  const [community, setCommunity] = useState(false);
  const [headerText, setHeaderText] = useState("");
  const [directionalTextLimit, setDirectionalTextLimit] = useState(0);
  const [selfTourEnabled, setSelfTourEnabled] = useState(false) ;
  const [reportIssue, setReportIssue] = useState({
    title: "",
    button: "",
  });
  const validStatuses = ['in_progress', MixConstants.FormStatus.NONE, MixConstants.FormStatus.IN_PROGRESS, MixConstants.FormStatus.SUBMITTED, MixConstants.FormStatus.REJECTED];
  const lowerValidStatuses = validStatuses.map(status => status.toLowerCase());


  const closeExpandedForms = () => {
    const formsList = [...forms];
    formsList.forEach((f) => {
      f.expanded = false;
    });
    setForms(formsList);
  };

  const handleFormExpandChange = (form) => {
    const formsList = [...forms];
    formsList.forEach((f) => {
      if (f.id === form.id) {
        f.expanded = !f.expanded;
      } else {
        f.expanded = false;
      }
    });
    setForms(formsList);
  };

  const actionButtons = (status) => {
    if (status === "Submitted") {
      return [
        {
          id: 1,
          title: "APPROVE",
          bgColor: theme.colors.actionYellow,
          titleColor: theme.colors.black,
          onClick: (title) => handleActionButtonClicked(title, "APPROVE"),
        },
        {
          id: 2,
          title: "REPORT ISSUE",
          bgColor: theme.colors.actionYellow,
          titleColor: theme.colors.black,
          onClick: (title) => handleActionButtonClicked(title, "REPORT ISSUE"),
        },
      ];
    } else if (status === "Approved") {
      return [
        {
          id: 1,
          title: "APPROVED",
          bgColor: theme.colors.green,
          titleColor: theme.colors.white,
          onClick: (title) => handleActionButtonClicked(title, "APPROVED"),
        },
        {
          id: 2,
          title: "UNDO APPROVAL",
          bgColor: theme.colors.actionRed,
          titleColor: theme.colors.white,
          onClick: (title) => handleActionButtonClicked(title, "UNDO APPROVAL"),
        },
      ];
    } else if (status === MixConstants.FormStatus.APPLICATION_IN_REVIEW) {
      return [
        {
          id: 1,
          title: "UNDO APPROVAL",
          bgColor: theme.colors.actionRed,
          titleColor: theme.colors.white,
          onClick: (title) => handleActionButtonClicked(title, "UNDO APPROVAL"),
        },
        {
          id: 2,
          title: "REPORT ISSUE",
          bgColor: theme.colors.actionYellow,
          titleColor: theme.colors.black,
          onClick: (title) => handleActionButtonClicked(title, "REPORT ISSUE"),
        },
      ];
    } else if (status === "Attention Required") {
      return [
        {
          id: 1,
          title: "EDIT ISSUE",
          bgColor: theme.colors.actionYellow,
          titleColor: theme.colors.black,
          onClick: (title) => handleActionButtonClicked(title, "EDIT ISSUE"),
        },
        {
          id: 2,
          title: "REMOVE ISSUE",
          bgColor: theme.colors.actionRed,
          titleColor: theme.colors.white,
          onClick: (title) => handleActionButtonClicked(title, "REMOVE ISSUE"),
        },
      ];
    }
    return [];
  };

  const statusChange = (name) => {
    switch (name) {
      case "EDIT ISSUE":
        return "rejected";
      case "REPORT ISSUE":
        return "rejected";
      case "APPROVE" || "FORM APPROVED":
        return "approved";
      case "UNDO APPROVAL":
        return "submitted";
      case "REMOVE ISSUE":
        return "submitted";
      default:
        return "";
    }
  };

  const handleActionButtonClicked = (title, status) => {
    if (title && status) {
      setReportIssue({
        title: title,
        button: statusChange(status),
      });
      if (status === "REPORT ISSUE") {
        setOpenReportIssue(true);
      } else if (status === "EDIT ISSUE") {
        setOpenEditReportIssue(true);
      } else {
        saveUpdateStatus(title, statusChange(status));
      }
    }
  };

  const statusImage = (status) => {
    if (status === MixConstants.FormStatus.NONE) {
      return EmptyCircleImage;
    } else if (
      status === MixConstants.FormStatus.APPROVED ||
      status === MixConstants.FormStatus.APPLICATION_IN_REVIEW ||
      status === MixConstants.FormStatus.RELEASED
    ) {
      return ApprovedImage;
    } else if (status === MixConstants.FormStatus.IN_PROGRESS) {
      return InProgressImage;
    } else if (status === "Attention Required") {
      return RejectedImage;
    } else if (status === MixConstants.FormStatus.SUBMITTED) {
      return SubmittedImage;
    }
  };

  const setSavedIssuesOrStatus = (response) => {
    if (response?.community_detail_forms?.length > 0) setFetchedForms(response);

  };

  const updateCommunityName = useCallback((name) => {
    setCommunityName(name);
  }, []);

  const backButtonClicked = () => {
    history.goBack();
  };

  const setFetchedForms = useCallback(
    (fetchedForms) => {
      let newForms = [];
      communityForms.map((form) => {
        if (isSuperAdmin) {
          for (let index in fetchedForms?.community_detail_forms) {
            if (
              form.title === fetchedForms?.community_detail_forms[index].name
            ) {
              form.status = fetchedForms?.community_detail_forms[index].status;
              newForms.push(form);
            }
          }
        } else {
          if (form.title !== "Your Company Details") {
            for (let index in fetchedForms?.community_detail_forms) {
              if (
                form.title === fetchedForms?.community_detail_forms[index].name
              ) {
                form.status =
                  fetchedForms?.community_detail_forms[index].status;
                newForms.push(form);
              }
            }
          }
        }
        return [];
      });

      if (isMounted.current) {
        setForms(newForms);
      }
    },
    [communityForms, isMounted, isSuperAdmin],
  );

  const saveUpdateStatus = (formTitle, status) => {
    if (communityId) {
      FormApi.updateStatusAndRemarks(
        communityId,
        communityUserId,
        formTitle,
        status,
      ).then(
        (response) => {
          setStatusUpdate(false);
          if (response?.success) {
            setSavedIssuesOrStatus(response.data);
          }
        },
        (error) => {
          setStatusUpdate(false);
        },
      );
    }
  };

  const fetchForms = useCallback(() => {
    if (communityId && communityUserId) {
      FormApi.fetchCommunity(communityId, communityUserId).then(
        (response) => {
          if (isMounted.current) {
            if (response?.data) {
              const data = response.data;
              const products = data?.community_products ?? [];
              const community = data
              setCommuityProducts(products);
              setCommunity(community);
              setHeaderText(`${community?.company_details?.name} | ${community?.community?.name}`);

              if (data?.community?.product_options) {
                const parsedProducts = JSON.parse(
                  data?.community?.product_options,
                );
                setDesignProduct(
                  parsedProducts?.product_options?.pynwheel_touch?.options
                    ?.design_style,
                );

                setSelfTourEnabled(parsedProducts?.product_options?.self_tour?.is_enabled);
              }
              setDirectionalTextLimit(
                response.data.directional_text_characters,
              );
              setFloorplanCount(data?.floorplan_count);
              setCompany(data.company_details);
              setFetchedForms(data ?? []);
            }
          }
        },
        (error) => {
          if (isMounted.current) {
          }
        },
      );
    }
  }, [communityId, communityUserId, setFetchedForms, isMounted]);

  const interval = () => {
    fetchForms();
  };

  let floorPlanapiCalltry = 0;
  const floorplanFormHandle = () => {
    let intervall = setInterval(() => {
      interval();
      if (++floorPlanapiCalltry > 10) clearInterval(intervall);
    }, 6000);
  };

  useEffect(() => {
    fetchForms();
  }, [fetchForms]);

  const renderForms = (forms) => {
    let propertyManagementStep = forms.find(form => form?.title === 'Connect Your Property Management System' );
    return forms.map((form) => {
      let status = Utils.convertStatus(form.status)
      const shouldDisplayMessage = form?.title === 'Floor Plan Images' && (!propertyManagementStep.status || propertyManagementStep.status.length < 1 || lowerValidStatuses.includes(propertyManagementStep.status[0].name.toLowerCase()));
      const message = shouldDisplayMessage ? 'Images can be loaded after Property Management System is connected and approved' : '';
      
      return (
        <Box key={form.id} marginBottom='1rem'>
          <Accordion
            key={form.id}
            expanded={form.expanded}
            header={
              <AccordionFormHeader
                expanded={form.expanded}
                onClickExpand={() => handleFormExpandChange(form)}
                avatar={statusImage(status.name)}
                title={form.title}
                status={status.name}
                rejectMessage={status.remarks ? status.remarks : null}
                floorplanalert={message}
                actionButtons={isSuperAdmin ? shouldDisplayMessage? null : actionButtons(status.name) : null}
                statusUpdate={statusUpdate}
              />
            }
          >
            {form.id === 1 ? (
              <CompanyDetailForm
                closeForm={closeExpandedForms}
                expanded={form.expanded}
                adminViewCompany={company}
                checkStatus={fetchForms}
                status={status.name}
              />
            ) : form.id === 2 ? (
              <ConnectPropertyManagementSystemForm
                floorplanCount={floorplanCount}
                closeForm={closeExpandedForms}
                expanded={form.expanded}
                floorplanFormHandle={floorplanFormHandle}
                checkStatus={fetchForms}
                status={status.name}
                commuityProducts={commuityProducts}
                company={company}
                community={community}
              />
            ) : form.id === 3 ? (
              <CommunityDetailForm
                closeForm={closeExpandedForms}
                onUpdateCommunityName={updateCommunityName}
                expanded={form.expanded}
                checkStatus={fetchForms}
                status={status.name}
              />
            ) : form.id === 4 ? (
              <PropertyMapImagesForm
                closeForm={closeExpandedForms}
                expanded={form.expanded}
                checkStatus={fetchForms}
                status={status.name}
              />
            ) : form.id === 5 ? (
              <FloorPlansImagesForm
                closeForm={closeExpandedForms}
                expanded={form.expanded}
                checkStatus={fetchForms}
                status={status.name}
                isMessage={shouldDisplayMessage}
                selfTourEnabled={selfTourEnabled}
              />
            ) : form.id === 6 ? (
              <AmenityImagesForm
                closeForm={closeExpandedForms}
                expanded={form.expanded}
                checkStatus={fetchForms}
                status={status.name}
                selfTourEnabled={selfTourEnabled}
              />
            ) : form.id === 7 ? (
              <GalleryPropertyImagesForm
                closeForm={closeExpandedForms}
                expanded={form.expanded}
                checkStatus={fetchForms}
                status={status.name}
              />
            ) : form.id === 8 ? (
              <PynwheelTouchHomePageMediaForm
                closeForm={closeExpandedForms}
                expanded={form.expanded}
                checkStatus={fetchForms}
                status={status.name}
              />
            ) : form.id === 9 ? (
              <HardwareSpecsAndInstallationForm
                closeForm={closeExpandedForms}
                expanded={form.expanded}
                checkStatus={fetchForms}
                status={status.name}
              />
            ) : form.id === 10 ? (
              <LockProvidersAndCredentialsForm
                closeForm={closeExpandedForms}
                expanded={form.expanded}
                checkStatus={fetchForms}
                status={status.name}
                community={community}
              />
            ) : form.id === 11 ? (
              <ManageSelfTourStopsForm
                closeForm={closeExpandedForms}
                expanded={form.expanded}
                checkStatus={fetchForms}
                characterLength={directionalTextLimit}
                status={status.name}
              />
            ) : form.id === 12 ? (
              <VisitingHoursForm
                closeForm={closeExpandedForms}
                expanded={form.expanded}
                checkStatus={fetchForms}
                status={status.name}
              />
            ) : form.id === 13 ? (
              <DesignDirectionForm
                closeForm={closeExpandedForms}
                expanded={form.expanded}
                design={designProduct}
                checkStatus={fetchForms}
                status={status.name}
              />
            ) : form.id === 14 ? (
              <EBroucher
                closeForm={closeExpandedForms}
                expanded={form.expanded}
                checkStatus={fetchForms}
                status={status.name}
              />
            ) : form.id === 15 ? (
              <AdditionalPagesForm
                closeForm={closeExpandedForms}
                expanded={form.expanded}
                checkStatus={fetchForms}
                status={status.name}
              />
            ) : null}
          </Accordion>
        </Box>
      );
    });
  };

  return (
    <Container>
      <PageMainContainer sx={{ width: 1050 }}>
        <BackButton
          title='Back to Community List'
          onClick={backButtonClicked}
        />

        <Box>
          <Typography sx={{fontSize: "20px", fontWeight: "500", marginLeft: "0.5rem", marginTop: "0.5rem" }}>
            {headerText}
          </Typography>
        </Box>

        <TitleLabels communityName={communityName} />

        {renderForms(forms)}
        {openReportIssue && (
          <ReportIssueModal
            reportIssue={reportIssue}
            onClose={() => setOpenReportIssue(false)}
            onSave={(response) => setSavedIssuesOrStatus(response)}
          />
        )}
        {openEditReportIssue && (
          <ReportIssueModal
            title={"Edit Issue"}
            textFieldLabel={"Edit issue"}
            reportIssue={reportIssue}
            onClose={() => setOpenEditReportIssue(false)}
            onSave={(response) => setSavedIssuesOrStatus(response)}
          />
        )}
      </PageMainContainer>
    </Container>
  );
}

export default CommunityFormsListingPage;
