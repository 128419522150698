const size = {
  breakPoint_desktop: "1200px",
  breakPoint_mobileL: "993px",
  breakPoint_MobileM: "767px",
  breakPoint_MobileS: "600px",
};

export const device = {
  mobileS: `(max-width: ${size.breakPoint_MobileS})`,
  mobileM: `(max-width: ${size.breakPoint_MobileM})`,
  mobileL: `(max-width: ${size.breakPoint_mobileL})`,
  desktop: `(max-width: ${size.breakPoint_desktop})`,
};
