import Box from "@mui/material/Box";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

export const StyledBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  paddin-top: ${({ paddingTop }) => (paddingTop ? paddingTop : "0rem")};
`;

export const StyledTitle = styled(Typography)`
  margin-bottom: 1rem;
  text-align: left;
`;
