import React, { useState, useEffect, useRef } from "react";
import Modal from "components/modal";
import YardiRentCafeFields from "../../../components/company-level-data-credentials/yardi-rent-cafe-fields.js";
import EntrataFields from "../../../components/company-level-data-credentials/entrata-fields.js";

import Dropdown from "components/dropdown";
import { Grid, Typography } from "@mui/material";

const dataProviders = ["Entrata", "YardiRentCafe"];

function MassDataImportModal({
  open = true,
  title = "Upload a file",
  onClose,
  cId,
  company,
}) {
  const [selectedDataProvider, setSelectedDataProvider] = useState("Entrata");

  const handleDataProviderChange = (dataProvider) => {
    setSelectedDataProvider(dataProvider);
  };

  const fetchTitle = () => {
    switch (selectedDataProvider) {
      case "Entrata":
        return "Enter Company Credentials";
      case "YardiRentCafe":
        return title;
      default:
        return title;
    }
  };

  return (
    <Modal open={open} title={fetchTitle()} onClose={onClose}>
      <Grid container spacing={4} marginBottom="1rem">
        <Grid item xs={6}>
          <Typography sx={{ m: 0, p: 0, marginBottom: "5px" }}>
            {" "}
            Data Provider{" "}
          </Typography>
          <Dropdown
            label={"Select Data Provider"}
            options={dataProviders}
            value={selectedDataProvider}
            onChange={(e, dataProvider) =>
              handleDataProviderChange(dataProvider)
            }
          />
        </Grid>
        {selectedDataProvider === "YardiRentCafe" ? (
          <YardiRentCafeFields cId={cId} company={company} />
        ) : (
          <EntrataFields companyId={cId} company={company} />
        )}
      </Grid>
    </Modal>
  );
}
 
export default MassDataImportModal;
