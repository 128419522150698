import MixConstants from "constants/mix-constants";

const Utils = {
  convertToFormReadableStatus,
  jsObjectToFormData,
  convertStatus,
  convertStatusForDatabase,
  convert24hrto12hr,
  isImageVideo,
  inReviewToApproved,
  isAnyLocalFile,
  isImageFile,
  isVideoFile,
};

function isImageVideo(image){
  if (image?.type === "image/jpeg" || image?.type === "image/png" ){
    return true;
  }
  return false;
}

function isImageFile(file){
  if (file?.type === "image/jpeg" || file?.type === "image/png" ){
    return true;
  }
  return false;
}

function isVideoFile(file){
  if (file?.type === ".mp4" || file?.type === "video/mp4" ){
    return true;
  }
  return false;
}

function isAnyLocalFile(files){
  let isLocal = false;
  files.forEach((file) => {
    if (file.isLocal){
      isLocal = true;
    }
  })
  return isLocal;
}

function convert24hrto12hr(time){
  time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1){
    time = time.slice (1);
    time[5] = +time[0] < 12 ? ' AM' : ' PM'; 
    time[0] = +time[0] % 12 || 12;
  }
  return time.join (''); // return adjusted time or original string
}

function convertStatus(statuses) {
  let name;
  let remarks = "";
  let inProgress = false;
  if (statuses.length > 0){
    statuses.map((status) => {
      if (status !== null){
        if (status.remarks){
          remarks = status.remarks
          }
          if (status.name === "in_progress"){
            inProgress = true;
          } else {
            name = status.name;
          }
      }
      return [];
    })
  }
  let convertedName = convertToFormReadableStatus(inProgress ? "in_progress" : name);
  return {
    name: convertedName,
    remarks: remarks,
  }
}

function inReviewToApproved(status) {
  if (status === MixConstants.FormStatus.APPLICATION_IN_REVIEW){
    return MixConstants.FormStatus.APPROVED
  } else {
    return status
  }
}

function convertStatusForDatabase(localStatus) {
  if (localStatus === MixConstants.FormStatus.IN_PROGRESS) {
    return "in_progress";
  } else if (localStatus === MixConstants.FormStatus.IN_PRODUCTION || localStatus === MixConstants.FormStatus.APP_IN_PRODUCTION) {
    return "in_production";
  } else if (localStatus === MixConstants.FormStatus.APPLICATION_IN_QA) {
    return "application_in_qa";
  } else if (localStatus === MixConstants.FormStatus.RELEASED) {
    return "released";
  } else if (localStatus === MixConstants.FormStatus.FORM_APPROVED) {
    return "form_approved";
  } else if (localStatus === MixConstants.FormStatus.SUBMITTED) {
    return "submitted";
  } else if (localStatus === MixConstants.FormStatus.APPROVED) {
    return "approved";
  } else if (localStatus === MixConstants.FormStatus.ATTENTION_REQUIRED) {
    return "rejected";
  } else if (localStatus === MixConstants.FormStatus.UNDER_REVIEW) {
    return "in_review";
  } else if (localStatus === MixConstants.FormStatus.DEPLOYED){
    return "deployed";
  } else {
    return "";
  }
}

function convertToFormReadableStatus(serverStatus) {
  if (serverStatus === "in_progress") {
    return MixConstants.FormStatus.IN_PROGRESS;
  } else if (serverStatus === "submitted") {
    return MixConstants.FormStatus.SUBMITTED;
  } else if (serverStatus === "in_production") {
    return MixConstants.FormStatus.APPLICATION_IN_PRODUCTION;
  } else if (serverStatus === "form_approved") {
    return MixConstants.FormStatus.FORM_APPROVED;
  } else if (serverStatus === "released") {
    return MixConstants.FormStatus.RELEASED;
  } else if (serverStatus === "application_in_qa") {
    return MixConstants.FormStatus.APPLICATION_IN_QA;
  } else if (serverStatus === "approved") {
    return MixConstants.FormStatus.APPROVED;
  } else if (serverStatus === "rejected") {
    return MixConstants.FormStatus.ATTENTION_REQUIRED;
  } else if (serverStatus === "in_review") {
    return MixConstants.FormStatus.APPLICATION_IN_REVIEW;
  } else if (serverStatus === "deployed") {
    return MixConstants.FormStatus.DEPLOYED;
  } else {
    return MixConstants.FormStatus.NONE;
  }
}

function jsObjectToFormData(obj, form, namespace) {
  var fd = form || new FormData();
  var formKey;
  
  for (var property in obj) {
    if (obj[property]){
      if (obj.hasOwnProperty(property)) {
        if (namespace) {
          formKey = namespace + "[" + property + "]";
        } else {
          formKey = property;
        }
  
        if (obj[property] instanceof Date) {
          fd.append(formKey, obj[property].toISOString());
        } else if (obj[property] instanceof Array) {
          jsObjectToFormData(obj[property], fd, formKey);
        } else if (
          typeof obj[property] === "object" &&
          !(obj[property] instanceof File)
        ) {
          jsObjectToFormData(obj[property], fd, formKey);
        } else {
          // if it's a string or a File object
          fd.append(formKey, obj[property]);
        }
      }
    }
  }

  return fd;
}

export default Utils;
