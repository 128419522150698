import Box from "@mui/material/Box";
import styled from "styled-components";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";

export const StyledAppBar = styled(AppBar)`
  background-color: ${({ theme }) => theme.colors.header};
  padding: 0.5rem 0;
`;

export const StyledLogo = styled.img`
  width: 12rem;
  height: 3rem;
  object-fit: contain;
`;

export const StyledUsername = styled(Typography)`
  color: ${({ theme }) => theme.colors.black};
  padding-left: 0.5rem;
`;

export const StyledArrowBox = styled(Box)`
  display: flex;
  width: 0.7rem;
  margin-left: 0.5rem;
`;
