export const theme = {
  colors: {
    primary: "#dfbb33",
    secondary: "#424551",
    text: "#424551",

    header: "#e8e9e9",
    body: "#f4f6fa",
    footer: "#003333",

    status: "#dfbb33",

    tableHeader: "#949DA8",
    border: "#e1e1f1",

    transparent: "transparent",
    white: "#fff",
    black: "#000",
    red: "#ff5151",
    blue: "#0088EA",
    green: "#8ac865",
    yellow: "#dfbb33",
    gray: "#949DA8",
    
    actionRed: "#F36538",
    actionYellow: "#FFD504",
    actionGray: "#E9E9E9",
    
    whiteSmoke: "#f7f7f7",
    thinGray: "#2AB99533",
    lightGray: "#d3d3d3",
    mediumGray: "#dbdbdb",
    darkGray: "#9297b4",
  },
};
