import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import { FormApi } from "apis";
import Utils from "helpers/utils";
import Box from "@mui/material/Box";
import Button from "components/button";
import GalleryList from "./gallery-list";
import Divider from "@mui/material/Divider";
import { useTheme } from "styled-components";
import HeaderSection from "./header-section";
import { useParams } from "react-router-dom";
import FormAlert from "components/form-alert";
import MixConstants from "constants/mix-constants";
import CreateGalleryModal from "./create-gallery-modal";
import CircularProgress from "@mui/material/CircularProgress";
import FileUploadLoaderModal from "components/file_upload_popup-loader";
import { Container, StyledFooter, StyledSpinnerContainer } from "../style";

function GalleryPropertyImagesForm({
  expanded,
  checkStatus,
  status,
  closeForm,
}) {
  const [uploading, setUploading] = useState(false);
  const [fetchingGalleries, setFetchingGalleries] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [galleries, setGalleries] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [message, setMessage] = useState("");
  const [openCreateGalleryModal, setOpenCreateGalleryModal] = useState(false);

  const theme = useTheme();
  const maxGalleries = 7;

  const getMappedGalleries = useCallback((galleries) => {
    if (galleries && galleries.length > 0) {
      return galleries.map((gallery) => {
        const media = gallery?.media;
        let images = filterImagesFromMedia(media);
        let videos = filterVideosFromMedia(media);
        images = images.map((img) => {
          return {
            ...img,
            photo: img?.file?.url,
            type: "image",
            file_type: "image",
          };
        });
        videos = videos.map((img) => {
          return {
            ...img,
            photo: img?.file?.url,
            type: "video",
            file_type: "video",
          };
        });
        return {
          communityId: gallery?.community_id,
          isDefault: gallery?.is_default,
          id: gallery?.id,
          name: gallery?.name,
          images: images,
          videos: videos,
          isLocal: false,
        };
      });
    }
    return [];
  }, []);

  const filterImagesFromMedia = (media) => {
    if (media && media.length > 0) {
      return media.filter((item) => {
        return item?.file?.url && item?.file_type === "image";
      });
    }
    return [];
  };

  const filterVideosFromMedia = (media) => {
    if (media && media.length > 0) {
      return media.filter((item) => {
        return item?.file?.url && item?.file_type === "video";
      });
    }
    return [];
  };

  let { communityId } = useParams();

  const fetchGalleries = useCallback(
    (abortController) => {
      if (expanded) {
        const fetchGalleriesMedia = (galleriesData) => {
          const galleriesList = [...galleriesData];

          const fetchMedia = (communityId, galleryId, index) => {
            FormApi.fetchGalleryMedia(communityId, galleryId).then(
              (response) => {
                if (response?.media) {
                  const galleryToUpdate = galleriesList[index];
                  galleryToUpdate.media = response.media;
                  const mappedGalleryToUpdate = getMappedGalleries([
                    galleryToUpdate,
                  ]);
                  mappedGalleryToUpdate[0].fetchingMedia = false;
                  mappedGalleryToUpdate[0].failed = false;
                  galleriesList[index] = mappedGalleryToUpdate[0];
                  setGalleries(JSON.parse(JSON.stringify(galleriesList)));
                }
              },
              (error) => {
                const galleryToUpdate = galleriesList[index];
                galleryToUpdate.media = [];
                galleryToUpdate.fetchingMedia = false;
                galleryToUpdate.failed = true;
                galleriesList[index] = galleryToUpdate;
                setGalleries(JSON.parse(JSON.stringify(galleriesList)));
              },
            );
          };

          for (let i = 0; i < galleriesList.length; i++) {
            const gallery = galleriesList[i];
            fetchMedia(communityId, gallery?.id, i);
          }
        };

        setGalleries([]);
        setFetchingGalleries(true);
        if (communityId) {
          FormApi.fetchGalleries(communityId).then(
            (response) => {
              const aborted = abortController.signal.aborted;
              if (aborted === false) {
                setFetchingGalleries(false);
                if (response?.data) {
                  const galleriesData = response.data.map((gallery) => ({
                    ...gallery,
                    fetchingMedia: true,
                  }));
                  setGalleries(galleriesData);
                  fetchGalleriesMedia(galleriesData);
                }
              }
            },
            (error) => {
              setFetchingGalleries(false);
            },
          );
        }
      }
    },
    [communityId, getMappedGalleries, expanded],
  );

  useEffect(() => {
    let abortController = new AbortController();
    fetchGalleries(abortController);
    return () => {
      abortController.abort();
    };
  }, [fetchGalleries]);

  const generateGallery = (name, images, videos) => {
    return {
      id: _.uniqueId(),
      name: name,
      images: images,
      videos: videos,
      isLocal: true,
    };
  };

  const defaultGalleries = [
    generateGallery("Apartments", [], []),
    generateGallery("Community", [], []),
  ];

  const addNewGalleryButtonClicked = () => {
    setOpenCreateGalleryModal(true);

    // let galleriesList = [...galleries];
    // if (galleriesList.length < maxGalleries) {
    //   const gallery = generateGallery("", [], []);
    //   galleriesList.push(gallery);
    //   setGalleries(galleriesList);
    // }
  };

  const newGalleryCreatedSuccessfully = (gallery) => {
    updateGalleryStatus("in_progress");

    const mappedNewGalleryList = getMappedGalleries([gallery]);
    let galleriesList = [...galleries];
    galleriesList.push(mappedNewGalleryList[0]);
    setGalleries(galleriesList);
    setOpenCreateGalleryModal(false);
  };

  const handleGalleryDeleted = (deletedGallery) => {
    const successMessage = "Gallery deleted successfully";
    const errorMessage = "Failed to delete gallery";
    const deleteMessage = () => {
      const index = _.findIndex(galleries, deletedGallery);
      let galleriesList = [...galleries];
      galleriesList.splice(index, 1);
      setGalleries(galleriesList);
    };
    if (
      deletedGallery.id !== defaultGalleries[0].id ||
      deletedGallery.id !== defaultGalleries[1].id
    ) {
      if (!deletedGallery.isLocal) {
        setSubmitting(true);
        FormApi.deleteGallery(communityId, deletedGallery.id).then(
          (response) => {
            setSubmitting(false);
            if (response?.success) {
              updateGalleryStatus("in_progress");
              deleteMessage();
              // checkStatus();
              setMessageText(successMessage, "success");
            } else {
              setMessageText(errorMessage, "error");
            }
          },
          (error) => {
            setSubmitting(false);
            setMessageText(errorMessage, "error");
          },
        );
      } else {
        deleteMessage();
      }
    }
  };

  const handleGalleryNameUpdate = (updatedGallery) => {
    const index = _.findIndex(galleries, updatedGallery);
    let galleriesList = [...galleries];
    galleriesList[index] = updatedGallery;
    setGalleries(galleriesList);
    updateGalleryStatus("in_progress");
  };

  const handleGalleryUpdated = (updatedGallery) => {
    if (
      Utils.isAnyLocalFile(updatedGallery?.images) ||
      Utils.isAnyLocalFile(updatedGallery?.videos)
    ) {
      const index = _.findIndex(galleries, updatedGallery);
      let galleriesList = [...galleries];
      galleriesList[index] = updatedGallery;
      setGalleries(galleriesList);
      galleryUpdated();
    }
  };

  const handleGalleryImageDelete = (gallery, image, fileIndex) => {
    const deleteGallery = () => {
      const isImage = image?.type === "image" || image?.file_type === "image";

      const index = _.findIndex(galleries, gallery);
      let galleriesList = [...galleries];

      if (fileIndex >= 0) {
        if (isImage) {
          galleriesList[index].images.splice(fileIndex, 1);
        } else {
          galleriesList[index].videos.splice(fileIndex, 1);
        }
      }
      // const imageIndex = _.findIndex(galleriesList[index].images, image);
      // if (fileIndex !== -1) {
      //   galleriesList[index].images.splice(fileIndex, 1);
      // }
      // const videoIndex = _.findIndex(galleriesList[index].videos, image);
      // if (fileIndex !== -1) {
      //   galleriesList[index].videos.splice(fileIndex, 1);
      // }

      const galleriesArray = [...galleriesList];
      setRefreshing(true);
      setGalleries([]);

      setTimeout(() => {
        setGalleries(galleriesArray);
        setRefreshing(false);
      }, 500);
    };

    if (image.isLocal) {
      deleteGallery();
    } else {
      const successMessage = "Image deleted successfully";
      const errorMessage = "Failed to delete Image";
      setSubmitting(true);
      FormApi.deleteGalleryImage(communityId, gallery, image.id).then(
        (response) => {
          setSubmitting(false);
          if (response?.success) {
            updateGalleryStatus("in_progress");
            // checkStatus();
            deleteGallery();
            setMessageText(successMessage, "success");
          } else {
            setMessageText(errorMessage, "error");
          }
        },
        (error) => {
          setSubmitting(false);
          setMessageText(errorMessage, "error");
        },
      );
    }
  };

  const setMessageText = (text, type) => {
    setMessage(text);
    setMessageType(type);

    setTimeout(() => {
      formAlertCloseButtonClicked();
    }, 5000);
  };

  const canUserProceed = () => {
    let hasError = false;

    if (!communityId) {
      setMessageText("Community id is missing", "error");
      hasError = true;
      return false;
    }
    let hasEmptyGalleryName = false;
    for (let gallery of galleries) {
      if (gallery?.name?.trim() === "" || gallery.name === null) {
        hasEmptyGalleryName = true;
        break;
      }
    }
    if (hasEmptyGalleryName) {
      setMessageText("Gallery name should not be empty", "error");
      hasError = true;
      return false;
    }
    return !hasError;
  };

  const formAlertCloseButtonClicked = () => {
    setMessage("");
  };

  const getMappedDataForServer = () => {
    const data = galleries.map((gallery) => {
      let media = [...gallery.images, ...gallery.videos];
      let mappedMedia = [];

      if (media.length > 0) {
        media = media.filter((m) => m.originalFile);

        mappedMedia = media.map((m) => {
          return {
            file: m.originalFile,
          };
        });
      }

      return {
        id: !gallery.isLocal ? gallery.id : null,
        name: gallery.name ?? "",
        image: mappedMedia,
      };
    });

    return data;
  };

  const galleryUpdated = () => {
    const data = getMappedDataForServer();
    formAlertCloseButtonClicked();

    if (communityId) {
      const errorMessage = "Failed to submit galleries";
      setUploading(true);
      FormApi.saveGalleries(communityId, data, "in_progress").then(
        (response) => {
          setUploading(false);
          if (response?.data) {
            const mappedData = getMappedGalleries(response.data);
            setGalleries(mappedData);
          } else {
            setMessageText(errorMessage, "error");
          }
        },
        (error) => {
          setUploading(false);
          setMessageText(errorMessage, "error");
        },
      );
    }
  };

  // const submitButtonClicked = () => {
  //   if (canUserProceed()) {
  //     const data = getMappedDataForServer();
  //     formAlertCloseButtonClicked();

  //     if (communityId) {
  //       const successMessage = "Galleries submitted successfully";
  //       const errorMessage = "Failed to submit galleries";
  //       setSubmitting(true);

  //       FormApi.saveGalleries(communityId, data).then(
  //         (response) => {
  //           setSubmitting(false);
  //           if (response?.data) {
  //             const mappedData = getMappedGalleries(response.data);
  //             setGalleries(mappedData);
  //             setMessageText(successMessage, "success");
  //             checkStatus();
  //           } else {
  //             setMessageText(errorMessage, "error");
  //           }
  //         },
  //         (error) => {
  //           setSubmitting(false);
  //           setMessageText(errorMessage, "error");
  //         },
  //       );
  //     }
  //   }
  // };

  const updateGalleryStatus = (status) => {
    FormApi.updateGalleryStatus(status, communityId).then(
      (response) => {
        setSubmitting(false);
        if (response?.success) {
          checkStatus();
        }
      },
      (error) => {},
    );
  };

  const submitButtonClicked = () => {
    if (canUserProceed()) {
      formAlertCloseButtonClicked();

      if (communityId) {
        const successMessage = "Galleries submitted successfully";
        const errorMessage = "Failed to submit galleries";
        setSubmitting(true);

        FormApi.updateGalleryStatus("submitted", communityId).then(
          (response) => {
            setSubmitting(false);
            if (response?.success) {
              setMessageText(successMessage, "success");
              checkStatus();
            } else {
              setMessageText(errorMessage, "error");
            }
          },
          (error) => {
            setSubmitting(false);
            setMessageText(errorMessage, "error");
          },
        );
      }
    }
  };

  const submitAndSubmitButtonClicked = () => {
    updateGalleryStatus("in_progress");
    closeForm();
  };

  if (fetchingGalleries) {
    return (
      <StyledSpinnerContainer>
        <CircularProgress size={20} />
      </StyledSpinnerContainer>
    );
  }

  return (
    <Container>
      <HeaderSection
        onClickAddNewGallery={() => addNewGalleryButtonClicked()}
        disableButton={galleries.length >= maxGalleries}
      />

      <Divider />
      {galleries.length > 0 ? (
        <GalleryList
          disabled={submitting}
          galleries={galleries}
          communityId={communityId}
          onDelete={(gallery) => handleGalleryDeleted(gallery)}
          onUpdate={(gallery) => handleGalleryUpdated(gallery)}
          onNameUpdate={(gallery) => handleGalleryNameUpdate(gallery)}
          onDeleteImage={handleGalleryImageDelete}
          onUploadAllFiles={() => updateGalleryStatus("in_progress")}
        />
      ) : (
        <StyledSpinnerContainer>
          {refreshing ? <CircularProgress size={20} /> : "Galleries not found"}
        </StyledSpinnerContainer>
      )}

      {message && messageType && (
        <Box>
          <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
          <FormAlert
            message={message}
            type={messageType}
            onClose={() => formAlertCloseButtonClicked()}
          />
        </Box>
      )}

      <Box>
        <Divider sx={{ marginTop: "1rem" }} />
        <StyledFooter>
          <Button
            bgColor={theme.colors.gray}
            titleColor={theme.colors.white}
            title='Save & Finish Later'
            onClick={submitAndSubmitButtonClicked}
          />
          <Button
            bgColor={theme.colors.green}
            title='Submit'
            disabled={
              status !== MixConstants.FormStatus.DEPLOYED ? submitting : true
            }
            loading={submitting}
            onClick={() => submitButtonClicked()}
          />
        </StyledFooter>
      </Box>
      <FileUploadLoaderModal openModal={uploading} />

      {openCreateGalleryModal && (
        <CreateGalleryModal
          communityId={communityId}
          onClose={() => setOpenCreateGalleryModal(false)}
          onCreate={(gallery) => newGalleryCreatedSuccessfully(gallery)}
        />
      )}
    </Container>
  );
}

export default GalleryPropertyImagesForm;
