import config from "./api-config";
import { authHeader, authHeaderJson } from "helpers/api-header";

const CommunityApi = {
  fetchCommunities,
  fetchSettings,
  saveSettings,
  addComment,
  fetchTourStops,
};
/**
 *  Fetch Communities Page By Page
 */

async function fetchCommunities(
  page,
  rowsPerPage,
  queryString = "",
  products = "",
  statuses = "",
) {
  let productCheck = products.includes("") ? false : true;
  let product = Object.assign({}, products);
  let sendProducts = JSON.stringify(product);
  let statusCheck = statuses.includes("") ? false : true;
  let status = Object.assign({}, statuses);
  let sendStatuses = JSON.stringify(status);
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = `${
    config.baseURL
  }/api/v2/communities?page=${page}&per_page=${rowsPerPage}&keyword=${queryString}${
    productCheck ? "&products=" + sendProducts : ""
  }${statusCheck ? "&status=" + sendStatuses : ""}`;
  const response = await fetch(url, requestOptions);
  const communities = await handleResponse(response);
  return communities;
}

/**
 *  Fetch Settings
 */

async function fetchSettings(recordId) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${recordId}/get_products`;

  const response = await fetch(url, requestOptions);
  const settings = await handleResponse(response);
  return settings;
}

/**
 *  Save Settings
 */

async function saveSettings(recordId, settings) {
  const requestOptions = {
    method: "PUT",
    headers: authHeaderJson(),
    body: JSON.stringify(settings),
  };
  const url = `${config.baseURL}/api/v2/communities/${recordId}/update_products`;

  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Add Comment
 */

async function addComment(communityId, comment) {
  const formData = new FormData();
  formData.append("message", comment);

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };

  const url = `${config.baseURL}/api/v2/communities/${communityId}/add_comment`;

  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Fetch Tour Stops
 */

async function fetchTourStops(communityId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = `${config.baseURL}/api/v2/communities/${communityId}/tours`;
  const response = await fetch(url, requestOptions);
  const tourStops = await handleResponse(response);
  return tourStops;
}

/**
 *  Response Handler
 */

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      return Promise.reject(data);
    }
    return data;
  });
}

export default CommunityApi;
