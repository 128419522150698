import signupConstants from "../constants/signup-constants";

const initialState = { isSignedUp: false, user: null };

export default function signupReducer(state = initialState, action) {
  switch (action.type) {
    case signupConstants.SIGNUP_REQUEST:
      return {
        ...state,
        signingUp: true,
      };
    case signupConstants.SIGNUP_SUCCESS:
      return {
        ...state,
        signingUp: false,
        isSignedUp: true,
        user: action.user,
      };
    case signupConstants.SIGNUP_FAILURE:
      return {
        ...state,
        signingUp: false,
        isSignedUp: false,
        user: null,
      };
    case signupConstants.SIGNUP_CLEAR:
      return initialState;
    default:
      return state;
  }
}
