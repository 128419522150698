import cookieHelper from "./cookie-helper";

const Persistence = {
  getAccessToken,
  setAccessToken,
  removeAccessToken,

  setUserData,
  getUserData,
  removeUserData,

  removeAll,
};

/**
 *  Access Token
 */

function getAccessToken() {
  return cookieHelper.get("jwt");
}

function setAccessToken(token) {
  const options = {
    path: "/",
    //expires: date,
    maxAge: 24 * 60 * 60,
  };

  return cookieHelper.set("jwt", token, options);
}

function removeAccessToken() {
  return cookieHelper.remove("jwt");
}

/**
 *  User Data
 */

function setUserData(user) {
  localStorage.setItem("user", JSON.stringify(user));
}

function getUserData() {
  return JSON.parse(localStorage.getItem("user"));
}

function removeUserData() {
  localStorage.removeItem("user");
}

/**
 *  Remove All
 */

function removeAll() {
  removeAccessToken();
  removeUserData();
}

export default Persistence;
