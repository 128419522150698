import React, { useState, useEffect, useCallback } from "react";
import { FormApi } from "apis";
import Box from "@mui/material/Box";
import Button from "components/button";
import Checkbox from "components/checkbox";
import Dropdown from "components/dropdown";
import Divider from "@mui/material/Divider";
import { useParams } from "react-router-dom";
import FormAlert from "components/form-alert";
import { useTheme } from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import { Container, StyledFooter, StyledSpinnerContainer } from "../style";
import PropertyManagementTypeSection from "./property-management-type-section";
import MixConstants from "constants/mix-constants";
import {
  YardiRentCafePM,
  RentManagerPM,
  YardiVoyagerPM,
  RealPagePM,
  EntrataPM,
  ResmanPM,
  OtherPM,
} from "./property-management-data";
import {
  EntrataCRM,
  RealPageCRM,
  SalesForceCRM,
  YardiRentCafeCRM,
  KnockCRM,
} from "./crms-data";
import FunnelCRM from "./crms-data/funnel-crm";

function ConnectPropertyManagementSystemForm({
  expanded,
  checkStatus,
  status,
  closeForm,
  floorplanCount = 0,
  floorplanFormHandle,
  commuityProducts,
  company,
  community,
}) {
  const [fetchingDataProviders, setFetchingDataProviders] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [message, setMessage] = useState("");
  const [showSubmitButtons, setShowSubmitButtons] = useState(true);

  const [selectedPropertyManagementType, setSelectedPropertyManagementType] =
    useState(null);
  const [connectCRMs, setConnectCRMs] = useState(false);
  const [selectedCRM, setSelectedCRM] = useState(null);
  const [selectedCrmDropDown, setSelectedCrmDropDown] = useState(false);

  const [pmYardiRentCafeCredentials, setPMYardiRentCafeCredentials] =
    useState(null);

  const [pmRentManagerCredentials, setPMRentManagerCredentials] =
    useState(null);
  const [pmYardiVoyagerCredentials, setPMYardiVoyagerCredentials] =
    useState(null);
  const [pmRealPageCredentials, setPMRealPageCredentials] = useState(null);
  const [pmEntrataCredentials, setPMEntrataCredentials] = useState(null);
  const [pmResmanCredentials, setPMResmanCredentials] = useState(null);
  const [pmOtherCredentials, setPMOtherCredentials] = useState(null);

  const [crmEntrataCredentials, setCRMEntrataCredentials] = useState(null);
  const [crmRealPageCredentials, setCRMRealPageCredentials] = useState(null);
  const [crmSalesForceCredentials, setCRMSalesForceCredentials] =
    useState(null);
  const [crmYardiRentCafeCredentials, setCRMYardiRentCafeCredentials] =
    useState(null);
  const [crmKnockCredentials, setCRMKnockCredentials] = useState(null);
  const [crmFunnelCredentials, setCRMFunnelCredentials] = useState(null);

  const theme = useTheme();

  const CRM_ENTRATA = "Entrata";
  const CRM_REALPAGE = "RealPage";
  const CRM_SALESFORCE = "Salesforce";
  const CRM_YARDI_RENTCAFE = "Yardi RentCafe";
  const CRM_KNOCK = "Knock";
  const CRM_FUNNEL = "Funnel";

  const crms = [
    CRM_ENTRATA,
    CRM_FUNNEL,
    CRM_REALPAGE,
    CRM_SALESFORCE,
    CRM_YARDI_RENTCAFE,
    CRM_KNOCK,
  ];

  const PM_YARDI_RENTCAFE = "Yardi RentCafe";
  const PM_RENT_MANAGER = "Rent Manager";
  // const PM_YARDI_VOYAGER = "Yardi Voyager";
  const PM_REALPAGE = "RealPage";
  const PM_ENTRATA = "Entrata";
  const PM_RESMAN = "Resman";
  const PM_OTHER = "Other";

  const propertyManagementSystemsTypes = [
    PM_YARDI_RENTCAFE,
    PM_RENT_MANAGER,
    // PM_YARDI_VOYAGER,
    PM_REALPAGE,
    PM_ENTRATA,
    PM_RESMAN,
    PM_OTHER,
  ];

  const SERVER_PM_YARDI_RENTCAFE = "yardirentcafe";
  const SERVER_PM_RENT_MANAGER = "rentmanager";
  // const SERVER_PM_YARDI_VOYAGER = "yardi";
  const SERVER_PM_REALPAGE = "realpagesvc";
  const SERVER_PM_ENTRATA = "psi";
  const SERVER_PM_RESMAN = "resman";
  const SERVER_PM_OTHER = "other";

  const SERVER_CRM_YARDI_RENTCAFE = "yardirentcafe";
  const SERVER_CRM_ENTRATA = "psi";
  const SERVER_CRM_REALPAGE = "realpagesvc";
  const SERVER_CRM_SALESFORCE = "salesforce";
  const SERVER_CRM_KNOCK = "knock";
  const SERVER_CRM_FUNNEL = "funnel";

  let { communityId } = useParams();

  const formAlertCloseButtonClicked = () => {
    setMessage("");
  };

  const setMessageText = (text, type) => {
    formAlertCloseButtonClicked();
    setMessage(text);
    setMessageType(type);
    setTimeout(() => {
      formAlertCloseButtonClicked();
    }, 5000);
  };

  const fetchDataProviders = useCallback(
    (abortController) => {
      setFetchingDataProviders(true);

      const getSetSelectedPropertyManagementTypeAndData = (type, data) => {
        if (type === SERVER_PM_YARDI_RENTCAFE) {
          setPMYardiRentCafeCredentialsData(data);
          return PM_YARDI_RENTCAFE;
        } else if (type === SERVER_PM_RENT_MANAGER) {
          setPMRentManagerCredentialsData(data);
          return PM_RENT_MANAGER;
        } 
        // else if (type === SERVER_PM_YARDI_VOYAGER) {
        //   setPMYardiVoyagerCredentialsData(data);
        //   return PM_YARDI_VOYAGER;
        // } 
        else if (type === SERVER_PM_REALPAGE) {
          setPMRealPageCredentialsData(data);
          return PM_REALPAGE;
        } else if (type === SERVER_PM_ENTRATA) {
          setPMEntrataCredentialsData(data);
          return PM_ENTRATA;
        } else if (type === SERVER_PM_RESMAN) {
          setPMResmanCredentialsData(data);
          return PM_RESMAN;
        } else if (type === SERVER_PM_OTHER) {
          setPMOtherCredentialsData(data);
          return PM_OTHER;
        } else {
          return null;
        }
      };

      const setPMYardiRentCafeCredentialsData = (data) => {
        const credentials = {
          apiToken: data?.api_token ?? "",
          propertyCode: data.p_code ?? "",
          companyCode: data?.c_code ?? "",
        };
        setPMYardiRentCafeCredentials(credentials);
      };

      const setPMRentManagerCredentialsData = (data) => {
        const credentials = {
          rentmanagerUsername: data?.rentmanager_username ?? "",
          rentmanagerPassword: data.rentmanager_password ?? "",
          rentmanagerPropertyId: data?.rentmanager_property_id ?? "",
          rentmanagerBaseUrl: data?.rentmanager_base_url ?? "",
        };

        setPMRentManagerCredentials(credentials);
      };

      const setPMYardiVoyagerCredentialsData = (data) => {
        const credentials = {
          url: data?.url ?? "",
          username: data?.username ?? "",
          password: data?.password ?? "",
          propertyId: data?.property_id ?? "",
          serverName: data?.server_name ?? "",
          database: data?.database ?? "",
        };

        setPMYardiVoyagerCredentials(credentials);
      };

      const setPMRealPageCredentialsData = (data) => {
        const credentials = {
          siteId: data?.site_id ?? "",
          pmcId: data?.pmc_id ?? "",
        };
        setPMRealPageCredentials(credentials);
      };

      const setPMEntrataCredentialsData = (data) => {
        const credentials = {
          url: data?.entrata_url,
          username: data?.username,
          password: data?.password,
          propertyId: data?.property_id,
        };
        setPMEntrataCredentials(credentials);
      };

      const setPMResmanCredentialsData = (data) => {
        const credentials = {
          accountId: data?.resman_account_id ?? "",
          propertyId: data?.resman_property_id ?? "",
        };
        setPMResmanCredentials(credentials);
      };

      const setPMOtherCredentialsData = (data) => {
        const credentials = {
          other: data ?? "",
        };
        setPMOtherCredentials(credentials);
      };

      const getSetSelectedCRMAndData = (type, data) => {
        if (type === SERVER_CRM_YARDI_RENTCAFE) {
          setCRMYardiRentCafeCredentialsData(data);
          return CRM_YARDI_RENTCAFE;
        } else if (type === SERVER_CRM_ENTRATA) {
          setCRMEntrataCredentialsData(data);
          return CRM_ENTRATA;
        } else if (type === SERVER_CRM_REALPAGE) {
          setCRMRealPageCredentialsData(data);
          return CRM_REALPAGE;
        } else if (type === SERVER_CRM_SALESFORCE) {
          setCRMSalesForceCredentialsData(data);
          return CRM_SALESFORCE;
        } else if (type === SERVER_CRM_KNOCK) {
          setCRMKnockCredentialsData(data);
          return CRM_KNOCK;
        } else if (type === SERVER_CRM_FUNNEL) {
          setCRMFunnelCredentialsData(data);
          return CRM_FUNNEL;
        } else {
          return null;
        }
      };

      const setCRMYardiRentCafeCredentialsData = (data) => {
        const credentials = {
          username: data?.yardirentcafe_leads_api_user_name ?? "",
          password: data?.yardirentcafe_leads_api_password ?? "",
          marketingApiKey: data?.yardirentcafe_marketing_api_key ?? "",
          companyCode: data?.yardirentcafe_company_code ?? "",
          propertyId: data?.yardirentcafe_property_id ?? "",
          propertyCode: data?.yardirentcafe_property_code ?? "",
        };
        setCRMYardiRentCafeCredentials(credentials);
      };

      const setCRMEntrataCredentialsData = (data) => {
        const credentials = {
          url: data?.entrata_domain ?? "",
          username: data?.entrata_username ?? "",
          password: data?.entrata_password ?? "",
          propertyId: data?.entrata_property_id ?? "",
        };
        setCRMEntrataCredentials(credentials);
      };

      const setCRMRealPageCredentialsData = (data) => {
        const credentials = {
          siteId: data?.realpage_site_id ?? "",
          pmcId: data?.realpage_pmc_id ?? "",
        };
        setCRMRealPageCredentials(credentials);
      };

      const setCRMSalesForceCredentialsData = (data) => {
        const credentials = {
          username: data?.salesforce_username ?? "",
          password: data?.salesforce_password ?? "",
          clientId: data?.salesforce_client_id ?? "",
          clientSecret: data?.salesforce_client_id ?? "",
          propertyId: data?.salesforce_property_id ?? "",
        };
        setCRMSalesForceCredentials(credentials);
      };

      const setCRMKnockCredentialsData = (data) => {
        const credentials = {
          communityId: data?.knock_community_id ?? "",
          companyId: data?.knock_company_id ?? "",
        };
        setCRMKnockCredentials(credentials);
      };

      const setCRMFunnelCredentialsData = (data) => {
        const credentials = {
          apiKey: data?.funnel_api_key ?? "",
          communityId: data?.funnel_community_id ?? "",
        };
        setCRMFunnelCredentials(credentials);
      };

      if (expanded) {
        if (communityId) {
          FormApi.fetchDataProviders(communityId).then(
            (response) => {
              const aborted = abortController.signal.aborted;
              if (aborted === false) {
                setFetchingDataProviders(false);
                if (response?.data) {
                  const data = response.data;
                  setSelectedPropertyManagementType(
                    getSetSelectedPropertyManagementTypeAndData(
                      data?.data_provider,
                      data?.credentials
                    )
                  );
                  setConnectCRMs(data?.use_different_crm_provider ?? false);
                  const crm = getSetSelectedCRMAndData(
                    data?.crm_provider,
                    data?.crm_credentials
                  );
                  if (data?.use_different_crm_provider) {
                    setSelectedCrmDropDown(true);
                    setSelectedCRM(crm);
                  }
                }
              }
            },
            (error) => {
              setFetchingDataProviders(false);
            }
          );
        }
      }
    },
    [communityId, expanded]
  );

  useEffect(() => {
    let abortController = new AbortController();
    if (status === "In Progress..." || status === "") {
      setShowSubmitButtons(true);
    } else {
      setShowSubmitButtons(false);
    }
    fetchDataProviders(abortController);
    return () => {
      abortController.abort();
    };
  }, [fetchDataProviders, floorplanCount, status]);

  const handlePropertyManagementTypeChange = (e) => {
    setSelectedPropertyManagementType(e.target.value);
  };

  const handleCheckboxChange = (name, value) => {
    setConnectCRMs(value);
    setSelectedCrmDropDown(value);
  };

  const handleCRMsDropdownChange = (e, value) => {
    setSelectedCRM(value);
  };

  if (fetchingDataProviders) {
    return (
      <StyledSpinnerContainer>
        <CircularProgress size={20} />
      </StyledSpinnerContainer>
    );
  }

  const getDataForServer = () => {
    let data;
    let crmCredential;

    if (selectedPropertyManagementType === PM_YARDI_RENTCAFE) {
      if (pmYardiRentCafeCredentials) {
        data = {
          type: SERVER_PM_YARDI_RENTCAFE,
          details: {
            api_token: pmYardiRentCafeCredentials?.apiToken,
            p_code: pmYardiRentCafeCredentials?.propertyCode,
            c_code: pmYardiRentCafeCredentials?.companyCode,
            rentcafe_api_version: "RentCafe V2",
            use_different_crm_provider: connectCRMs,
          },
        };
      }
    } else if (selectedPropertyManagementType === PM_RENT_MANAGER) {
      if (pmRentManagerCredentials) {
        data = {
          type: SERVER_PM_RENT_MANAGER,
          details: {
            rentmanager_username: pmRentManagerCredentials?.rentmanagerUsername,
            rentmanager_password: pmRentManagerCredentials?.rentmanagerPassword,
            rentmanager_property_id:
              pmRentManagerCredentials?.rentmanagerPropertyId,
            rentmanager_base_url: pmRentManagerCredentials?.rentmanagerBaseUrl,
            use_different_crm_provider: connectCRMs,
          },
        };
      }
    } else if (selectedPropertyManagementType === PM_REALPAGE) {
      if (pmRealPageCredentials) {
        data = {
          type: SERVER_PM_REALPAGE,
          details: {
            site_id: pmRealPageCredentials?.siteId,
            pmc_id: pmRealPageCredentials?.pmcId,
            use_different_crm_provider: connectCRMs,
          },
        };
      }
    } else if (selectedPropertyManagementType === PM_RESMAN) {
      if (pmResmanCredentials) {
        data = {
          type: SERVER_PM_RESMAN,
          details: {
            resman_account_id: pmResmanCredentials?.accountId,
            resman_property_id: pmResmanCredentials?.propertyId,
            use_different_crm_provider: connectCRMs,
          },
        };
      }
    } else if (selectedPropertyManagementType === PM_ENTRATA) {
      if (pmEntrataCredentials) {
        data = {
          type: SERVER_PM_ENTRATA,
          details: {
            entrata_url: pmEntrataCredentials?.url,
            username: pmEntrataCredentials?.username,
            password: pmEntrataCredentials?.password,
            property_id: pmEntrataCredentials?.propertyId,
            use_different_crm_provider: connectCRMs,
          },
        };
      }
    } 
    // else if (selectedPropertyManagementType === PM_YARDI_VOYAGER) {
    //   if (pmYardiVoyagerCredentials) {
    //     data = {
    //       type: SERVER_PM_YARDI_VOYAGER,
    //       details: {
    //         url: pmYardiVoyagerCredentials?.url,
    //         username: pmYardiVoyagerCredentials?.username,
    //         password: pmYardiVoyagerCredentials?.password,
    //         property_id: pmYardiVoyagerCredentials?.propertyId,
    //         server_name: pmYardiVoyagerCredentials?.serverName,
    //         database: pmYardiVoyagerCredentials?.database,
    //         platform: "SQL Server",
    //         interface_entity: "Pynwheel",
    //         use_different_crm_provider: connectCRMs,
    //       },
    //     };
    //   }
    // } 
    else if (selectedPropertyManagementType === PM_OTHER) {
      if (pmOtherCredentials) {
        data = {
          type: SERVER_PM_OTHER,
          details: {
            new_requested_data_provider: pmOtherCredentials?.other,
            use_different_crm_provider: connectCRMs,
          },
        };
      }
    }

    if (connectCRMs) {
      if (selectedCRM === CRM_REALPAGE) {
        crmCredential = {
          crm_provider: SERVER_CRM_REALPAGE,
          realpage_site_id: crmRealPageCredentials?.siteId,
          realpage_pmc_id: crmRealPageCredentials?.pmcId,
        };
      } else if (selectedCRM === CRM_ENTRATA) {
        crmCredential = {
          crm_provider: SERVER_CRM_ENTRATA,
          entrata_domain: crmEntrataCredentials?.url,
          entrata_username: crmEntrataCredentials?.username,
          entrata_password: crmEntrataCredentials.password,
          entrata_property_id: crmEntrataCredentials?.propertyId,
        };
      } else if (selectedCRM === CRM_SALESFORCE) {
        crmCredential = {
          crm_provider: SERVER_CRM_SALESFORCE,
          salesforce_username: crmSalesForceCredentials?.username,
          salesforce_password: crmSalesForceCredentials?.password,
          salesforce_client_id: crmSalesForceCredentials.clientId,
          salesforce_secret_id: crmSalesForceCredentials?.clientSecret,
          salesforce_property_id: crmSalesForceCredentials?.propertyId,
        };
      } else if (selectedCRM === CRM_YARDI_RENTCAFE) {
        crmCredential = {
          crm_provider: SERVER_CRM_YARDI_RENTCAFE,
          yardirentcafe_marketing_api_key:
            crmYardiRentCafeCredentials.marketingApiKey,
          yardirentcafe_property_id: crmYardiRentCafeCredentials?.propertyId,
          yardirentcafe_property_code:
            crmYardiRentCafeCredentials?.propertyCode,
        };
        crmCredential = {
          crm_provider: SERVER_CRM_YARDI_RENTCAFE,
        };
      } else if (selectedCRM === CRM_KNOCK) {
        crmCredential = {
          crm_provider: SERVER_CRM_KNOCK,
          knock_community_id: crmKnockCredentials?.communityId,
          knock_company_id: crmKnockCredentials?.companyId,
        };
      } else if (selectedCRM === CRM_FUNNEL) {
        crmCredential = {
          crm_provider: SERVER_CRM_FUNNEL,
          funnel_api_key: crmFunnelCredentials?.apiKey,
          funnel_community_id: crmFunnelCredentials?.communityId,
        };
      }
    }
    return {
      data: data,
      crmCredential: crmCredential,
    };
  };

  const onSubmitButtonClicked = () => {
    const dataForServer = getDataForServer();
    let data = dataForServer.data;
    let crmCredential = dataForServer.crmCredential;

    if (userCanProceed(data)) {
      setSubmitting(true);
      const successMessage =
        "Details Saved! Floorplans section will be visible once data is imported!";
      const errorMessage = "Please provide all required details";

      FormApi.saveDataProviders(communityId, data, crmCredential).then(
        (response) => {
          setSubmitting(false);
          if (response.success) {
            setMessageText(successMessage, "success");
            setShowSubmitButtons(false);
            floorplanFormHandle();
          } else {
            setMessageText(response.message, "error");
          }
        },
        (error) => {
          setSubmitting(false);
          setMessageText(errorMessage, "error");
        }
      );
    }
  };

  const checkRentManagerFields = (data) => {
    return (
      data?.details?.rentmanager_username === "" ||
      data?.details?.rentmanager_password === "" ||
      data?.details?.rentmanager_property_id === "" ||
      data?.details?.rentmanager_base_url === ""
    );
  };

  const userCanProceed = (data, action) => {
    let hasError = false;

    if (selectedPropertyManagementType) {
      if (selectedPropertyManagementType === PM_RENT_MANAGER) {
        if (data) {
          if (action === "Save" && checkRentManagerFields(data)) {
            hasError = false;
            return true;
          } else {
            if (checkRentManagerFields(data)) {
              setMessageText(
                "Please provide all required details for Rent Mananger.",
                "error"
              );
              hasError = true;
              return false;
            }
          }
        } else {
          setMessageText(
            "Please provide all required details for Rent Manager.",
            "error"
          );

          hasError = true;
          return false;
        }
      }
      if (selectedPropertyManagementType === PM_YARDI_RENTCAFE) {
        if (data) {
          if (
            community?.community?.use_company_level_data_settings &&
            company?.data_providers?.includes("yardirentcafe")
              ? ""
              : data?.details?.api_token === "" ||
                data?.details?.p_code === "" ||
                (community?.community?.use_company_level_data_settings &&
                  company?.data_providers?.includes("yardirentcafe"))
              ? ""
              : data?.details?.c_code === ""
          ) {
            setMessageText(
              "Please provide all required details for Yardi RentCafe.",
              "error"
            );
            hasError = true;
            return false;
          }
        } else {
          setMessageText(
            "Please provide all required details for Yardi RentCafe.",
            "error"
          );
          hasError = true;
          return false;
        }
      }
      // if (selectedPropertyManagementType === PM_YARDI_VOYAGER) {
      //   if (data) {
      //     if (
      //       community?.community?.use_company_level_data_settings &&
      //       company?.data_providers?.includes("yardi")
      //         ? ""
      //         : data?.details?.password === "" ||
      //           data?.details?.property_id === "" ||
      //           (community?.community?.use_company_level_data_settings &&
      //             company?.data_providers?.includes("yardi"))
      //         ? ""
      //         : data?.details?.server_name === "" ||
      //           (community?.community?.use_company_level_data_settings &&
      //             company?.data_providers?.includes("yardi"))
      //         ? ""
      //         : data?.details?.database === "" ||
      //           (community?.community?.use_company_level_data_settings &&
      //             company?.data_providers?.includes("yardi"))
      //         ? ""
      //         : data?.details?.url === "" ||
      //           (community?.community?.use_company_level_data_settings &&
      //             company?.data_providers?.includes("yardi"))
      //         ? ""
      //         : data?.details?.username === ""
      //     ) {
      //       setMessageText(
      //         "Please provide all required details for Yardi Voyager.",
      //         "error"
      //       );
      //       hasError = true;
      //       return false;
      //     }
      //   } else {
      //     setMessageText(
      //       "Please provide all required details for Yardi Voyager.",
      //       "error"
      //     );
      //     hasError = true;
      //     return false;
      //   }
      // }
      if (selectedPropertyManagementType === PM_REALPAGE) {
        if (data) {
          if (
            data?.details?.site_id === "" ||
            (community?.community?.use_company_level_data_settings &&
              company?.data_providers?.includes("realpagesvc"))
              ? ""
              : data?.details?.pmc_id === ""
          ) {
            setMessageText(
              "Please provide all required details for RealPage.",
              "error"
            );
            hasError = true;
            return false;
          }
        } else {
          setMessageText(
            "Please provide all required details for RealPage.",
            "error"
          );
          hasError = true;
          return false;
        }
      }
      if (selectedPropertyManagementType === PM_ENTRATA) {
        if (data) {
          if (
            community?.community?.use_company_level_data_settings &&
            company?.data_providers?.includes("psi")
              ? ""
              : data?.details?.entrata_url === "" ||
                (community?.community?.use_company_level_data_settings &&
                  company?.data_providers?.includes("psi"))
              ? ""
              : data?.details?.password === "" ||
                data?.details?.property_id === "" ||
                (community?.community?.use_company_level_data_settings &&
                  company?.data_providers?.includes("psi"))
              ? ""
              : data?.details?.username === ""
          ) {
            setMessageText(
              "Please provide all required details for Entrata.",
              "error"
            );
            hasError = true;
            return false;
          }
        } else {
          setMessageText(
            "Please provide all required details for Entrata.",
            "error"
          );
          hasError = true;
          return false;
        }
      }
      if (selectedPropertyManagementType === PM_RESMAN) {
        if (data) {
          if (
            community?.community?.use_company_level_data_settings &&
            company?.data_providers?.includes("resman")
              ? ""
              : data?.details?.resman_account_id === "" ||
                data?.details?.resman_property_id === ""
          ) {
            setMessageText(
              "Please provide all required details for Resman.",
              "error"
            );
            hasError = true;
            return false;
          }
        } else {
          setMessageText(
            "Please provide all required details for Resman.",
            "error"
          );
          hasError = true;
          return false;
        }
      }
      if (selectedPropertyManagementType === PM_OTHER) {
        if (!data || data?.details?.new_requested_data_provider === "") {
          setMessageText("Please provide further details.", "error");
          hasError = true;
          return false;
        }
      }
    } else {
      setMessageText("Please provide the credential details", "error");
      hasError = true;
      return false;
    }

    return !hasError;
  };

  const onReplaceDataButtonClicked = () => {
    const dataForServer = getDataForServer();
    let data = dataForServer.data;
    let crmCredential = dataForServer.crmCredential;

    if (userCanProceed(data)) {
      setSubmitting(true);
      const successMessage =
        "Details Saved! Floorplans section will be visible once data is imported!";
      const errorMessage = "Please provide all required details";

      FormApi.replaceImportedData(communityId, data, crmCredential).then(
        (response) => {
          setSubmitting(false);
          if (response.success) {
            setMessageText(successMessage, "success");
            setShowSubmitButtons(false);
            floorplanFormHandle();
          } else {
            setMessageText(response?.message, "error");
            checkStatus();
          }
        },
        (error) => {
          setSubmitting(false);
          setMessageText(errorMessage, "error");
        }
      );
    }
  };

  const onSaveAndSubmitButtonClicked = () => {
    const dataForServer = getDataForServer();
    let data = dataForServer.data;
    let crmCredential = dataForServer.crmCredential;
    if (userCanProceed(data, "Save")) {
      formAlertCloseButtonClicked();
      data["status"] = "in_progress";

      FormApi.saveAndFinishLaterDataProvider(
        communityId,
        data,
        crmCredential,
        "in_progress"
      ).then(
        (response) => {
          if (response.success) {
            checkStatus();
          }
        },
        (error) => {}
      );
      closeForm();
    } else {
      formAlertCloseButtonClicked();
      closeForm();
    }
  };

  return (
    <Container>
      <PropertyManagementTypeSection
        types={propertyManagementSystemsTypes}
        selectedType={selectedPropertyManagementType}
        onChange={handlePropertyManagementTypeChange}
      />

      {selectedPropertyManagementType ? (
        <Box>
          <Divider />
          {selectedPropertyManagementType === PM_YARDI_RENTCAFE ? (
            <YardiRentCafePM
              credentials={pmYardiRentCafeCredentials}
              company={company}
              community={community}
              onChange={(credentials) =>
                setPMYardiRentCafeCredentials(credentials)
              }
            />
          ) : selectedPropertyManagementType === PM_RENT_MANAGER ? (
            <RentManagerPM
              credentials={pmRentManagerCredentials}
              company={company}
              community={community}
              onChange={(credentials) =>
                setPMRentManagerCredentials(credentials)
              }
            />
          ) : 
          // selectedPropertyManagementType === PM_YARDI_VOYAGER ? (
          //   <YardiVoyagerPM
          //     credentials={pmYardiVoyagerCredentials}
          //     company={company}
          //     community={community}
          //     onChange={(credentials) =>
          //       setPMYardiVoyagerCredentials(credentials)
          //     }
          //   />
          // ) : 
          selectedPropertyManagementType === PM_REALPAGE ? (
            <RealPagePM
              credentials={pmRealPageCredentials}
              company={company}
              community={community}
              onChange={(credentials) => setPMRealPageCredentials(credentials)}
            />
          ) : selectedPropertyManagementType === PM_ENTRATA ? (
            <EntrataPM
              credentials={pmEntrataCredentials}
              company={company}
              community={community}
              onChange={(credentials) => setPMEntrataCredentials(credentials)}
            />
          ) : selectedPropertyManagementType === PM_RESMAN ? (
            <ResmanPM
              credentials={pmResmanCredentials}
              company={company}
              community={community}
              onChange={(credentials) => setPMResmanCredentials(credentials)}
            />
          ) : selectedPropertyManagementType === PM_OTHER ? (
            <OtherPM
              credentials={pmOtherCredentials}
              onChange={(credentials) => setPMOtherCredentials(credentials)}
            />
          ) : null}
        </Box>
      ) : null}

      <Divider />

      <Box display="flex">
        <Checkbox
          label="Connect CRM (Optional)"
          checked={connectCRMs}
          onChange={(e) =>
            handleCheckboxChange(e.target.name, e.target.checked)
          }
        />
      </Box>

      {connectCRMs && (
        <Box
          display="flex"
          sx={{ paddingTop: "0.5rem", paddingBottom: "1rem" }}
        >
          <Dropdown
            required
            label="Select CRM Data Provider"
            value={selectedCRM}
            options={crms}
            onChange={handleCRMsDropdownChange}
          />
        </Box>
      )}

      {selectedCRM === CRM_ENTRATA && selectedCrmDropDown ? (
        <EntrataCRM
          credentials={crmEntrataCredentials}
          onChange={(credentials) => setCRMEntrataCredentials(credentials)}
        />
      ) : selectedCRM === CRM_REALPAGE && selectedCrmDropDown ? (
        <RealPageCRM
          credentials={crmRealPageCredentials}
          onChange={(credentials) => setCRMRealPageCredentials(credentials)}
        />
      ) : selectedCRM === CRM_SALESFORCE && selectedCrmDropDown ? (
        <SalesForceCRM
          credentials={crmSalesForceCredentials}
          onChange={(credentials) => setCRMSalesForceCredentials(credentials)}
        />
      ) : selectedCRM === CRM_YARDI_RENTCAFE && selectedCrmDropDown ? (
        <YardiRentCafeCRM
          credentials={crmYardiRentCafeCredentials}
          onChange={(credentials) =>
            setCRMYardiRentCafeCredentials(credentials)
          }
        />
      ) : selectedCRM === CRM_KNOCK && selectedCrmDropDown ? (
        <KnockCRM
          credentials={crmKnockCredentials}
          onChange={(credentials) => setCRMKnockCredentials(credentials)}
        />
      ) : selectedCRM === CRM_FUNNEL && selectedCrmDropDown ? (
        <FunnelCRM
          credentials={crmFunnelCredentials}
          onChange={(credentials) => setCRMFunnelCredentials(credentials)}
        />
      ) : null}

      <Divider />
      {message && messageType && (
        <Box>
          <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
          <FormAlert
            message={message}
            type={messageType}
            onClose={() => formAlertCloseButtonClicked()}
          />
        </Box>
      )}

      <StyledFooter>
        <Button
          bgColor={theme.colors.gray}
          titleColor={theme.colors.white}
          title="Save & Finish Later"
          onClick={onSaveAndSubmitButtonClicked}
        />
        <Button
          bgColor={theme.colors.green}
          title="Submit"
          loading={submitting}
          disabled={
            status !== MixConstants.FormStatus.DEPLOYED ? submitting : true
          }
          onClick={onSubmitButtonClicked}
        />
      </StyledFooter>

      {status === "Attention Required" ? (
        <StyledFooter>
          <Button
            bgColor={theme.colors.green}
            title="Replace Data"
            loading={submitting}
            disabled={
              status !== MixConstants.FormStatus.DEPLOYED ? submitting : true
            }
            onClick={onReplaceDataButtonClicked}
          />
        </StyledFooter>
      ) : (
        ""
      )}
    </Container>
  );
}

export default ConnectPropertyManagementSystemForm;
