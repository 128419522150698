import React from "react";
import { StyledDropdown } from "./style";
import TextField from "@mui/material/TextField";

function Dropdown({
  size = "small",
  width,
  fullWidth = true,
  label = "",
  required,
  value,
  defaultValue,
  options,
  renderOption,
  getOptionLabel,
  multiple = false,
  onChange,
}) {
  return (
    <StyledDropdown
      size={size}
      width={width}
      fullWidth={fullWidth}
      multiple={multiple}
      options={options}
      renderOption={renderOption}
      getOptionLabel={getOptionLabel}
      required={required}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      renderInput={(params) => (
        <TextField {...params} required={required} label={label} />
      )}
    />
  );
}

export default Dropdown;
