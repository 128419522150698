import React from "react";
import { StyledButton } from "./style";
import { useTheme } from "styled-components";

function Button({
  bgColor,
  titleColor,
  title,
  width,
  startIcon,
  endIcon,
  onClick,
  disabled,
  loading,
}) {
  const theme = useTheme();

  return (
    <StyledButton
      bgcolor={
        loading
          ? theme.colors.lightGray
          : bgColor
          ? bgColor
          : theme.colors.primary
      }
      titlecolor={titleColor ? titleColor : theme.colors.white}
      width={width}
      onClick={onClick}
      disabled={disabled}
      loading={loading}
    >
      {startIcon} {title} {endIcon}
    </StyledButton>
  );
}

export default Button;
