const Pynwheel = {
  CONNECT_APP: "https://pynwheelconnect.com/",
};

const Common = {
  LOGIN: "/login",
};

const Client = {
  COMMUNITY: "/community",
};

const Admin = {
  ADMIN_DASHBOARD: "/admin",
};

const Paths = {
  Pynwheel,
  Common,
  Client,
  Admin,
};

export default Paths;
