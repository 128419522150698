import React from "react";
import IconButton from "@mui/material/IconButton";
import { UpArrowImage, DownArrowImage } from "assests/images";

function AccordionArrowButton({ size = "4rem", expanded, onClick }) {
  return (
    <IconButton sx={{ width: size, height: size }} onClick={onClick}>
      {expanded ? (
        <img src={UpArrowImage} alt='icon' />
      ) : (
        <img src={DownArrowImage} alt='icon' />
      )}
    </IconButton>
  );
}

export default AccordionArrowButton;
