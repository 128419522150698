import { FormApi } from "apis";
import { Box } from "@mui/material";
import Modal from "components/modal";
import React, { useState } from "react";
import { useTheme } from "styled-components";
import TextField from "components/textfield";
import FormAlert from "components/form-alert";
import Container from "@mui/material/Container";

function CreateGalleryModal({ onClose, open = true, communityId, onCreate }) {
  const theme = useTheme();
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [galleryName, setGalleryName] = useState("");
  const [creatingGallery, setCreatingGallery] = useState(false);

  const actionButtons = [
    {
      id: 1,
      title: "Cancel",
      bgColor: theme.colors.lightGray,
      titleColor: theme.colors.white,
      disabled: creatingGallery,
      onClick: onClose,
    },
    {
      id: 2,
      title: "Create",
      bgColor: theme.colors.green,
      titleColor: theme.colors.white,
      disabled: galleryName.length < 3 || creatingGallery,
      loading: creatingGallery,
      onClick: () => createButtonClicked(),
    },
  ];

  const createButtonClicked = () => {
    const successMessage = "Gallery created successfully";
    const errorMessage = "Failed to create gallery";

    if (communityId) {
      setCreatingGallery(true);

      FormApi.createGallery(galleryName, communityId).then(
        (response) => {
          setCreatingGallery(false);
          if (response?.success) {
            setMessageText(successMessage, "success");

            if (onCreate) {
              onCreate(response?.data);
            }
          } else {
            setMessageText(errorMessage, "error");
          }
        },
        (error) => {
          setCreatingGallery(false);
          setMessageText(error?.message ?? errorMessage, "error");
        },
      );
    }
  };

  const setMessageText = (text, type) => {
    setMessage(text);
    setMessageType(type);

    setTimeout(() => {
      setMessage("");
    }, 5000);
  };

  return (
    <Modal
      maxWidth='sm'
      open={open}
      title={"Create Gallery"}
      onClose={creatingGallery ? null : onClose}
      footerButtons={actionButtons}
    >
      <Container maxWidth='md'>
        <TextField
          required
          disabled={creatingGallery}
          label='Gallery Name'
          name='galleryName'
          value={galleryName}
          onChange={(e) => setGalleryName(e.target.value)}
        />

        {message && messageType && (
          <Box sx={{ paddingTop: "1rem" }}>
            <FormAlert
              message={message}
              type={messageType}
              onClose={() => setMessage("")}
            />
          </Box>
        )}
      </Container>
    </Modal>
  );
}

export default CreateGalleryModal;
