import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import { FormApi } from "apis";
import Typography from "@mui/material/Typography";
import { useTheme } from "styled-components";
import { StyledFooter } from "components/forms/style";
import Button from "components/button";
import Box from "@mui/material/Box";
import Dropdown from "components/dropdown";
import TextField from "components/textfield";
import FormAlert from "components/form-alert";
import DataRow from "components/data_row";
import CircularProgress from "@mui/material/CircularProgress";

const currencies = [
  "United States Dollar (USD)",
  "Canadian Dollar (CAD)",
  "British Pound (GBP)",
];
const dataProvider = "psi";

const initialFormData = {
  domain: "",
  username: "",
  password: "",
};

function EntrataFields({ companyId, company }) {
  const theme = useTheme();
  const [formData, setFormData] = useState(initialFormData);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [message, setMessage] = useState("");
  const [selectedCurrency, setselectedCurrency] = useState(currencies[0]);
  const [propertyErrors, setPropertyErrors] = useState([]);
  const [hashTable, setHashTable] = useState({});
  const [errorHashTable, setErrorHashTable] = useState({});

  useEffect(() => {
    const isFilled = Object.values(formData).every((value) => value !== "");
    setIsFormValid(isFilled);
  }, [formData]);

  useEffect(() => {
    if (company && company.credential) {
      setFormData({
        ...formData,
        domain: company?.credential?.entrata_url,
        username: company.credential?.username,
        password: company.credential?.password,
      });
    }
  }, [company]);
  const formAlertCloseButtonClicked = () => {
    setMessage("");
  };

  const setMessageText = (text, type) => {
    formAlertCloseButtonClicked();
    setMessage(text);
    setMessageType(type);
    setTimeout(formAlertCloseButtonClicked, 5000);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleCurrencyChange = (currency) => {
    setselectedCurrency(currency);
  };

  const getCurrencyCode = () => {
    switch (selectedCurrency) {
      case "United States Dollar (USD)":
        return "840";
      case "Canadian Dollar (CAD)":
        return "124";
      case "British Pound (GBP)":
        return "826";
      default:
        return;
    }
  };

  const setFormAdditionalAttributes = () => {
    let updatedFormData = formData;
    updatedFormData.data_provider = dataProvider;
    updatedFormData.currency = getCurrencyCode();

    return updatedFormData;
  };

  const handlePropertyErrors = async (companyId, properties) => {
    const errors = [];

    for (const { property_id, property_name } of properties) {
      try {
        const innerApiResponse = await FormApi.companyMassImportData(
          companyId,
          property_id,
          property_name,
          setFormAdditionalAttributes()
        );
        setErrorHashTable((prev) => ({
          ...prev,
          [property_id]:  innerApiResponse.message,
        }));
        setHashTable((prev) => ({
          ...prev,
          [property_id]:  innerApiResponse.status === true ? "Success" : "Failed",
        }));
      } catch (error) {
        setMessageText(error.message, "error");
      }
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    setIsSuccess(true)
    setIsFormValid(false);
    e.preventDefault();
    try {
      const response = await FormApi.fetchPropertiesIds(
        companyId,
        setFormAdditionalAttributes()
      );

      if (response?.properties_ids?.length > 0) {
        setPropertyErrors((prevErrors) => [...prevErrors, ...response.properties_ids]);
        setIsSuccess(false)
        await handlePropertyErrors(companyId, response.properties_ids);
      } else {
        setIsSuccess(false)
        setMessageText(
          "Invalid company credentials! Couldn't fetch data",
          "error"
        );
      }
    } catch (error) {
      setMessageText(error.message, "error");
    }
  };

  return (
    <>
      <Grid item xs={6}>
        <Typography sx={{ m: 0, p: 0, marginBottom: "5px" }}>
          {" "}
          Select Currency
        </Typography>
        <Dropdown
          label={"Select Currency"}
          options={currencies}
          value={selectedCurrency} 
          onChange={(e, currency) => handleCurrencyChange(currency)}
        />
      </Grid>

      {/* ----------------------- */}

      <Grid item xs={12}>
        <Typography sx={{ m: 0, p: 0, marginBottom: "5px" }}>Domain</Typography>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={6}>
            <TextField
              name="domain"
              value={formData.domain}
              label="Enter domain"
              onChange={handleChange}
              disabled={
                company?.data_providers?.includes("psi") &&
                company?.credential?.entrata_url
                    ? true
                    : false
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Typography>.entrata.com/api/v1/propertyunits</Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* ----------------------- */}

      <Grid item xs={6}>
        <Typography sx={{ m: 0, p: 0, marginBottom: "5px" }}>
          Username
        </Typography>
        <TextField
          name="username"
          value={formData.username}
          label="Enter Username"
          onChange={handleChange}
          disabled={
            company?.data_providers?.includes("psi") &&
            company?.credential?.username
                ? true
                : false
          }
        />
      </Grid>

      {/* ----------------------- */}

      <Grid item xs={6}>
        <Typography sx={{ m: 0, p: 0, marginBottom: "5px" }}>
          Password
        </Typography>
        <TextField
          name="password"
          value={formData.password}
          label="Enter Password"
          onChange={handleChange}
          disabled={
            company?.data_providers?.includes("psi") &&
            company?.credential?.password
                ? true
                : false
          }
        />
      </Grid>

      {/* ----------------------- */}

      <Grid item xs={12} marginTop={"1rem"}>
        {message && messageType && (
          <Box>
            <FormAlert
              message={message}
              type={messageType}
              onClose={() => formAlertCloseButtonClicked()}
            />
          </Box>
        )}
      </Grid>

      {/* ----------------------- */}

      <Grid container spacing={2} >
        <Grid item xs={6}>
          <StyledFooter
            style={{ float: "left", marginLeft: "1rem", marginBottom: "1rem" }}
          >
            <Button
              bgColor={theme.colors.green}
              title="Submit"
              onClick={handleSubmit}
              disabled={!isFormValid}
            />
            { isSuccess &&
            <Typography sx={{ m: 0, p: 0 }}>
              <CircularProgress size={15} />
            </Typography>}
          </StyledFooter>
        </Grid>
      </Grid>

      {/* ----------------------- */}
      <Grid
        container
        spacing={2}
        style={{  marginLeft: "1rem" }}
      >
        {propertyErrors?.map((row, index) => (
          <Grid item xs={4} key={index + 1}>
            <DataRow
              row={row}
              loader={!hashTable?.[row?.property_id]}
              theme={theme}
              status={hashTable?.[row?.property_id]}
              errorMessage={errorHashTable?.[row?.property_id]}
              number={index + 1}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default EntrataFields;
