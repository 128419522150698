import config from "./api-config";
import Utils from "helpers/utils";
import { authHeader, authHeaderJson } from "helpers/api-header";

const FormApi = {
  fetchCommunity,
  fetchCommunityDetails,
  saveCommunity,
  deleteCommunityLogo,
  updateStatusAndRemarks,

  moveToProduction,

  sendFollowUpEmails,
  previewSubmitForReview,
  previewFollowUpEmails,

  sendLatchInviteEmail,

  fetchPropertyMap,
  deletePropertyMap,
  replaceImportedData,
  saveAndFinishLaterDataProvider,
  savePropertyMap,
  deleteLabelImage,
  changePropertyType,

  fetchFloorplans,
  saveFloorplans,
  deleteFloorplan,
  deleteFloorplanAmenity,
  deleteFloorplanImage,
  deleteFloorplanGalleryImage,

  fetchAmenities,
  createAmenity,
  deleteAmenity,
  deleteAmenityImage,
  deleteAmenityGalleryImage,
  saveAmenities,

  createGallery,
  updateGalleryName,
  updateGalleryStatus,
  fetchGalleries,
  fetchGalleryMedia,
  saveGalleries,
  deleteGalleryImage,
  deleteGallery,

  fetchPynwheelTouchHardwareSpec,
  deletePynwheelTouchHardwareDetails,
  deletePynwheelTouchHardwareSpec,
  savePynwheelTouchHardwareSpec,
  changePynwheelTouchHardwareSpec,

  fetchHomePage,
  saveHomePage,
  deleteHomePageVideo,
  deleteHomePageimage,

  fetchDataProviders,
  saveDataProviders,

  fetchSecureLocks,
  addSecureLocks,
  deleteSecureLock,
  deleteSecureLockFile,
  deleteIglooHomeLock,

  fetchVisitingHours,
  saveVisitingHours,

  fetchTourStops,
  saveTourStopsOld,
  saveTourStops,
  deleteTourStopsOld,
  deleteTourStops,
  deleteTourStopGallery,

  fetchImageToSetUploadProperties,

  fetchCommunityAmenities,
  addCommunityAmenities,
  deleteCommunityAmenities,

  fetchAdditionalPages,
  addAdditionalPages,
  deleteAdditionalPages,
  deleteAdditionalPagesImage,

  fetchDesignDirection,
  updateDesignDirection,
  deleteDesignDirectionImage,

  fetchEbrochure,
  updateEbrochure,
  deleteEbrochureImage,
  deleteEbrochureWeblink,
  fetchPropertiesIds,
  companyMassImportData,
  removeIgloohomeAuthAccount
};

/**
 *  Fetch Imgaes from Url to set upload properties
 */

async function fetchImageToSetUploadProperties(url) {
  let fileName = "image.jpg";
  const response = await fetch(url);
  const contentType = response.headers.get("content-type");
  const blob = await response.blob();
  const file = new File([blob], fileName, { contentType });

  const image = URL.createObjectURL(file);
  return {
    type: "image",
    photo: image,
    stockImage: true,
    name: file?.name,
    caption: file?.name,
    subcaption: "",
    thumbnail: image,
    originalFile: file,
    isLocal: true,
    description: "",
  };
}

/**
 *  Fetch Community Forms
 */

async function fetchCommunity(communityId, communityUserId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/get_community_detail_forms?community_user=${communityUserId}`;

  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Fetch Community Details
 */

async function fetchCommunityDetails(communityId, communityUserId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}?community_user_id=${communityUserId}`;

  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Save Community
 */

async function saveCommunity(
  communityId,
  communityUserId,
  data,
  logoFile,
  file,
  status = ""
) {
  const formData = new FormData();
  const parentKey = "community";
  const community = data.community;

  for (var key in community) {
    formData.append(`${parentKey}[${key}]`, community[key]);
  }
  if (logoFile) {
    formData.append(`${parentKey}[logo]`, logoFile);
  }
  if (file) {
    formData.append(`${parentKey}[file]`, file);
  }
  if (status) {
    formData.append(`${parentKey}[status]`, status);
  }

  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: formData,
  };

  const url = `${config.baseURL}/api/v2/communities/${communityId}?community_user_id=${communityUserId}`;

  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Delete Community Logo
 */

async function deleteCommunityLogo(communityId) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  const url = `${config.baseURL}/api/v2/communities/${communityId}/delete_community_logo`;

  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Save Community Status and Remarks
 */

async function updateStatusAndRemarks(
  communityId,
  communityUserId,
  form,
  status,
  remarks = ""
) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };

  const url = `${config.baseURL}/api/v2/communities/${communityId}/update_status_and_remarks?community_user_id=${communityUserId}&detail_type=${form}&status[name]=${status}&status[remarks]=${remarks}`;

  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Move to production
 */

async function moveToProduction(communityId, communityUserId, status) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/move_to_production?community_user_id=${communityUserId}&status=${status}`;

  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Preview Follow Up Emails
 */

async function previewFollowUpEmails(communityId, communityUserId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = `${config.baseURL}/api/v2/communities/${communityId}/preview_follow_up_email?community_user_id=${communityUserId}`;

  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Preview Submit for Review
 */

async function previewSubmitForReview(communityId, communityUserId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/preview_submit_for_review_email?community_user_id=${communityUserId}`;

  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Send Latch Invite Email
 */

async function sendLatchInviteEmail(communityId, toEmail, fromEmail, subject, body) {
  let formData = new FormData();
  
  formData.append("to_email", toEmail);
  formData.append("from_email", fromEmail);
  formData.append("subject", subject);
  formData.append("body", body);

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };
  
  const url = `${config.baseURL}/api/v2/communities/${communityId}/send_latch_initation_email`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);

  return result;
}

/**
 *  Send Follow Up Emails
 */

async function sendFollowUpEmails(
  communityId,
  communityUserId,
  emails,
  subject,
  emailBody
) {
 
  let formData = new FormData();
  formData.append("email_body", emailBody);
  formData.append("emails", emails);
  formData.append("subject", subject);

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };

  const url = `${config.baseURL}/api/v2/communities/${communityId}/send_follow_up_emails?community_user_id=${communityUserId}`;

  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);

  return result;
}

/**
 *   Fetch property map
 */

async function fetchPropertyMap(communityId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = `${config.baseURL}/api/v2/communities/${communityId}/community_property_map`;

  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Save property map
 */

async function savePropertyMap(communityId, data, type, status = "") {
  const propertyTypeConversion = (type) => {
    if (type === "garden_style_community") {
      return "sitemap";
    }
    if (type === "mid_high_rise_community") {
      return "floorplate";
    }
  };
  const parentKey = "community";
  const key = "property_type";
  const formData = Utils.jsObjectToFormData(
    data,
    new FormData(),
    propertyTypeConversion(type)
  );

  formData.append(`${parentKey}[${key}]`, type);
  formData.append("status", status);

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };

  const url = `${config.baseURL}/api/v2/communities/${communityId}/add_property_images`;

  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Delete Property Map
 */

async function deletePropertyMap(communityId, propertyId, propertyType) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  const url = `${config.baseURL}/api/v2/communities/${communityId}/delete_property_map?property_id=${propertyId}&property_type=${propertyType}`;

  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 * change property type
 */

async function changePropertyType(communityId, propertyType) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  const url = `${config.baseURL}/api/v2/communities/${communityId}/change_property_type?property_type=${propertyType}`;

  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Delete Property Map
 */

async function deleteLabelImage(communityId, propertyId, propertyType) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  const url = `${config.baseURL}/api/v2/communities/${communityId}/delete_label_image?property_id=${propertyId}&property_type=${propertyType}`;

  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Fetch Floorplans
 */

async function fetchFloorplans(communityId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/floorplans`;

  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *   Save and Update Floorplan
 */

async function saveFloorplans(communityId, data) {
  const requestOptions = {
    method: "POST",
    headers: authHeaderJson(),
    body: JSON.stringify(data),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/floorplans/save_floorplans_form`;

  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Delete Floorplan
 */

async function deleteFloorplan(communityId, id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/floorplans/${id}`;

  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Delete Floor Plan Primary Image
 */

async function deleteFloorplanImage(communityId, planId) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/floorplans/${planId}/remove_floorplan_media`;

  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Delete Floor Plan Other Gallery Image
 */

async function deleteFloorplanGalleryImage(communityId, planId, imageId) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/floorplans/${planId}/floorplan_amenities/${imageId}`;

  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Delete Floorplan Amenity Image
 */

async function deleteFloorplanAmenity(communityId, floorplanId, amenity) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/community_floor_plans/${floorplanId}/delete_floorplan_amenity?amenity_id=${amenity}`;

  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Fetch Amenities Images
 */

async function fetchAmenities(communityId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/amenities`;

  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Create Amenity Image
 */

async function createAmenity(data, communityId) {
  const formData = new FormData();

  for (var key in data) {
    formData.append(`${key}`, data[key]);
  }

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/amenities`;

  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Delete Amenity
 */

async function deleteAmenity(communityId, amenityId) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/amenities/${amenityId}`;

  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Delete Amenity Primary Image
 */

async function deleteAmenityImage(communityId, amenityId) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/amenities/${amenityId}/remove_amenity_media`;

  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Delete Amenity Other Gallery Image
 */

async function deleteAmenityGalleryImage(communityId, amenityId, imageId) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/amenities/${amenityId}/amenity_galleries/${imageId}`;

  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Save Amenities
 */

async function saveAmenities(communityId, data) {
  const requestOptions = {
    method: "POST",
    headers: authHeaderJson(),
    body: JSON.stringify(data),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/amenities/save_amenity_form`;

  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Create Gallery
 */

async function createGallery(name, communityId) {
  const formData = new FormData();
  formData.append("name", name);

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/create_new_gallery`;

  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Update Gallery Name
 */

async function updateGalleryName(galleryId, galleryName, communityId) {
  const formData = new FormData();
  formData.append("id", galleryId);
  formData.append("name", galleryName);

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/update_gallery_name`;

  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Update Gallery Status
 */

async function updateGalleryStatus(status, communityId) {
  const formData = new FormData();
  formData.append("status", status);

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/update_gallery_status`;

  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Fetch Galleries
 */

async function fetchGalleries(communityId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/galleries`;

  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Fetch Gallery Media
 */

async function fetchGalleryMedia(communityId, galleryId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/get_gallery_media?id=${galleryId}`;

  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Save Galleries
 */

async function saveGalleries(communityId, data, status = "") {
  const formData = Utils.jsObjectToFormData(data, new FormData(), "gallery");
  formData.append("status", status);
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/update_galleries`;

  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 * Delete Gallery
 */

async function deleteGallery(communityId, galleryId) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/galleries/${galleryId}`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 * Delete Gallery Images
 */

async function deleteGalleryImage(communityId, galleryId, imageId) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/galleries/${galleryId}/delete_gallery_image?gallery_image_id=${imageId}`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Fetch Data Providers
 */

async function fetchDataProviders(communityId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/community_data_provider`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Save and Finish Later Data Provider
 */

async function saveAndFinishLaterDataProvider(
  communityId,
  credential,
  crmCredential,
  status = ""
) {
  const formData = Utils.jsObjectToFormData(
    credential.details,
    new FormData(),
    "credential"
  );
  formData.append("data_provider", credential.type);
  formData.append("status", status);
  const parentKey = "crm_credential";
  const crmCredentials = crmCredential;

  for (var key in crmCredentials) {
    formData.append(`${parentKey}[${key}]`, crmCredentials[key]);
  }

  if (!crmCredential) {
    formData.append("credential[use_different_crm_provider]", false);
  }

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/save_finsih_later_data_provider`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Replace Imported Data
 */

async function replaceImportedData(
  communityId,
  credential,
  crmCredential,
  status = ""
) {
  const formData = Utils.jsObjectToFormData(
    credential.details,
    new FormData(),
    "credential"
  );
  formData.append("data_provider", credential.type);
  formData.append("status", status);
  const parentKey = "crm_credential";
  const crmCredentials = crmCredential;

  for (var key in crmCredentials) {
    formData.append(`${parentKey}[${key}]`, crmCredentials[key]);
  }

  if (!crmCredential) {
    formData.append("credential[use_different_crm_provider]", false);
  }

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/replace_imported_data`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Fetch Data Providers
 */

async function saveDataProviders(
  communityId,
  credential,
  crmCredential,
  status = ""
) {
  const formData = Utils.jsObjectToFormData(
    credential.details,
    new FormData(),
    "credential"
  );
  formData.append("data_provider", credential.type);
  formData.append("status", status);
  const parentKey = "crm_credential";
  const crmCredentials = crmCredential;

  for (var key in crmCredentials) {
    formData.append(`${parentKey}[${key}]`, crmCredentials[key]);
  }

  if (!crmCredential) {
    formData.append("credential[use_different_crm_provider]", false);
  }

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/update_data_provider`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Fetch HomePage Gallery images / Video
 */

async function fetchHomePage(communityId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/pynwheel_touch_homepage`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 * Create / Update HomePage Images / Video
 */

async function saveHomePage(communityId, data, status = "") {
  const formData = Utils.jsObjectToFormData(data, new FormData(), "");
  formData.append("status", status);
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/add_homepage_design`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);

  return result;
}

/**
 * Delete HomePage Video
 */

async function deleteHomePageVideo(communityId, videoId) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/pynwheel_touch_homepage/${videoId}/delete_homepage_video`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);

  return result;
}

/**
 * Delete HomePage Video
 */

async function deleteHomePageimage(communityId, imageId) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/delete_home_page_image?homepage_image_id=${imageId}`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);

  return result;
}

/**
 * Fetch Pynwheel Touch Hardware Specs
 */

async function fetchPynwheelTouchHardwareSpec(communityId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/get_pynwheel_touch_hardware_spec`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 * Save Pynwheel Touch Hardware Specs
 */

async function savePynwheelTouchHardwareSpec(communityId, data, status = "") {
  const formData = Utils.jsObjectToFormData(data, new FormData(), "hardware");
  formData.append("status", status);
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/add_pynwheel_touch_hardware_spec`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 * Save Pynwheel Touch Hardware Specs
 */

async function changePynwheelTouchHardwareSpec(communityId, type) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/change_default_design?changeType=${type}`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 * Delete Pynwheel Touch Hardware Specs Details
 */

async function deletePynwheelTouchHardwareDetails(communityId) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/delete_hardware_spec_details`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 * Delete Pynwheel Touch Hardware Specs image
 */

async function deletePynwheelTouchHardwareSpec(communityId) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/delete_pynwheel_touch_hardware_spec_image`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Fetch Secure Locks
 */

async function fetchSecureLocks(communityId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/secure_locks`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Add Secure Locks
 */

async function addSecureLocks(communityId, data, status = "") {
  const formData = Utils.jsObjectToFormData(
    data,
    new FormData(),
    "secure_locks"
  );
  formData.append("status", status);
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/add_secure_locks`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Delete Secure Locks
 */

async function deleteSecureLock(communityId, lockType, lockId) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  const url = `${config.baseURL}/api/v2/communities/${communityId}/delete_secure_lock?type=${lockType}&id=${lockId}`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Delete Igloohome Lock
 */

async function deleteIglooHomeLock(communityId, lockId) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  const url = `${config.baseURL}/api/v2/communities/${communityId}/delete_igloohome_lock?id=${lockId}`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Delete Secure Lock Files
 */

async function deleteSecureLockFile(communityId, lockType, lockId) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  const url = `${config.baseURL}/api/v2/communities/${communityId}/delete_lock_files?type=${lockType}&id=${lockId}`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Fetch Visiting Hours
 */

async function fetchVisitingHours(communityId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/opening_hours`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Create / Update Visiting Hours
 */

async function saveVisitingHours(communityId, data, status = "") {
  const formData = new FormData();

  formData.append("data", JSON.stringify(data));
  formData.append("status", status);
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/create_opening_hours`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Create / Update Tour Stops
 */

async function fetchTourStops(communityId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/get_tour_step_details`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Save Tour Stops Old
 */

async function saveTourStopsOld(communityId, data, status = "") {
  const formData = Utils.jsObjectToFormData(
    data.tourStop,
    new FormData(),
    "tour_stop"
  );

  const parentKey = "tour";
  const tour = data.tour;

  for (var key in tour) {
    formData.append(`${parentKey}[${key}]`, tour[key]);
  }

  formData.append("status", status);

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/add_tour_step_details`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Save Tour Stops
 */

async function saveTourStops(communityId, data) {
  const requestOptions = {
    method: "POST",
    headers: authHeaderJson(),
    body: JSON.stringify(data),
  };

  const url = `${config.baseURL}/api/v2/communities/${communityId}/tours/${data?.tour?.id}/add_tour_stops/`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Delete Tour Stops Old
 */

async function deleteTourStopsOld(communityId, tourStopId) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/delete_tour_stop?tour_stop_id=${tourStopId}`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Delete Tour Stops
 */

async function deleteTourStops(communityId, tourId, tourStopId) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/tours/${tourId}/delete_tour_stop?tour_stop_id=${tourStopId}`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Delete Tour Stop Gallery
 */

async function deleteTourStopGallery(communityId, galleryId) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/delete_tour_stop_gallery?gallery_id=${galleryId}`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Fetch Amenities of a community
 */

async function fetchCommunityAmenities(communityId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/community_amenity`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Add / Update Amenity of a community
 */

async function addCommunityAmenities(communityId, data, status = "") {
  const formData = Utils.jsObjectToFormData(data, new FormData(), "amenity");
  if (status) {
    formData.append("status", status);
  }

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };

  const url = `${config.baseURL}/api/v2/communities/${communityId}/add_community_amenity`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Delete Amenity of a community
 */

async function deleteCommunityAmenities(communityId, amenityId) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/community_amenity/${amenityId}`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Fetch Additional Pages of a community
 */

async function fetchAdditionalPages(communityId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/community_additional_pages`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Add / Update Additional Pages of a community
 */

async function addAdditionalPages(communityId, data, status = "") {
  const formData = Utils.jsObjectToFormData(
    data,
    new FormData(),
    "additional_pages"
  );
  if (status) {
    formData.append("status", status);
  }

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };

  const url = `${config.baseURL}/api/v2/communities/${communityId}/add_additional_pages`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Delete Additional Page of a community
 */

async function deleteAdditionalPages(communityId, pageId, pageType) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/community_additional_pages/${pageId}?type=${pageType}`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Delete Additional Page image of a community
 */

async function deleteAdditionalPagesImage(communityId, imageId, fileType) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/delete_imagepage_image?file_id=${imageId}&&get_file_type=${fileType}`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Fetch Design Direction of a community
 */

async function fetchDesignDirection(communityId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/design_direction`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Update Design Direction of a community
 */

async function updateDesignDirection(communityId, designId, data, status = "") {
  const formData = Utils.jsObjectToFormData(
    data,
    new FormData(),
    "design_direction"
  );
  if (status) {
    formData.append("status", status);
  }

  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: formData,
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/design_direction/${designId}`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Delete Design Direction image of a community
 */

async function deleteDesignDirectionImage(communityId, designId, type) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/design_direction/${designId}/delete_design_image?get_design_type=${type}`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  fetch Ebrochure of a community
 */

async function fetchEbrochure(communityId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/ebrochures`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Update Ebrochure of a community
 */

async function updateEbrochure(communityId, ebrochureId, data, status = "") {
  const formData = Utils.jsObjectToFormData(data, new FormData(), "ebrochure");

  if (status) {
    formData.append("status", status);
  }

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData,
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/ebrochures/${ebrochureId}/add_favorite_ebrochure`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Delete Ebrouchure image of a community
 */

async function deleteEbrochureImage(communityId, ebrochureId, imageId) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/ebrochures/${ebrochureId}/delete_ebrouchure_image?image_id=${imageId}`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Delete Ebrochure weblink of a community
 */

async function deleteEbrochureWeblink(communityId, ebrochureId, weblinkId) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  const url = `${config.baseURL}/api/v2/communities/${communityId}/ebrochures/${ebrochureId}/delete_ebrochure_weblink?weblink_id=${weblinkId}`;
  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Fetch Entrata Company Properties Ids
 */

async function fetchPropertiesIds(companyId, formData) {
  const requestOptions = {
    method: "POST",
    headers: authHeaderJson(),
    body: JSON.stringify(formData),
  };

  const url = `${config.baseURL}/api/v2/companies/${companyId}/fetch_entrata_property_ids`;

  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

/**
 *  Company Mass Import Data
 */

async function companyMassImportData(companyId, propertyId, propertyName, formData) {
  formData.property_id = propertyId;
  formData.property_name = propertyName;
  formData.limit_result = false;

  
  const requestOptions = {
    method: "POST",
    headers: authHeaderJson(),
    body: JSON.stringify(formData),
  };

  const url = `${config.baseURL}/api/v2/companies/${companyId}/import_data_credentials`;

  const response = await fetch(url, requestOptions);
  const result = await handleResponse(response);
  return result;
}

async function removeIgloohomeAuthAccount(communityId) {
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
    };

    const url = `${config.baseURL}/api/v2/communities/${communityId}/remove_igloohome_auth_account`;
    const response = await fetch(url, requestOptions);
    const result = await handleResponse(response);
    
    return result;
}

/**
 *  Response Handler
 */

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      return Promise.reject(data);
    }
    return data;
  });
}

export default FormApi;
