import React from "react";
import Stack from "@mui/material/Stack";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import {
  StyledCircularProgress,
  OuterCircularProgress,
  InnerCircularProgress,
  ColorDot,
  StyledStatus,
} from "./style";

function CircularProgress({
  size,
  strokeWidth = 3.5,
  outerCirclePercentage,
  innerCirclePercentage,
  outerCircleColor,
  innerCircleColor,
  outerPercentageTitle,
  innerPercentageTitle,
}) {
  return (
    <StyledCircularProgress size={size}>
      <OuterCircularProgress>
        <CircularProgressbar
          strokeWidth={strokeWidth}
          value={outerCirclePercentage}
          styles={buildStyles({
            rotation: 1,
            strokeLinecap: "round",
            pathTransitionDuration: 0.5,
            pathColor: outerCircleColor,
            trailColor: "#D3D3D3",
          })}
        />
      </OuterCircularProgress>

      <InnerCircularProgress circleSize={size}>
        <CircularProgressbar
          strokeWidth={strokeWidth}
          value={innerCirclePercentage}
          styles={buildStyles({
            rotation: 1,
            strokeLinecap: "round",
            pathTransitionDuration: 0.5,
            pathColor: innerCircleColor,
            trailColor: "#D3D3D3",
          })}
        />
      </InnerCircularProgress>

      <Stack>
        <Stack>
          <Stack
            direction='row'
            justifyContent='center'
            alignItems='center'
            spacing={0.3}
          >
            <ColorDot size='0.5rem' color={innerCircleColor} />
            <StyledStatus>{innerPercentageTitle}</StyledStatus>
          </Stack>
          <Stack direction='row' spacing={0.4}>
            <ColorDot size='0.5rem' color='transparent' />
            <StyledStatus weight='600'>{innerCirclePercentage}%</StyledStatus>
          </Stack>
        </Stack>

        <Stack>
          <Stack
            direction='row'
            justifyContent='center'
            alignItems='center'
            spacing={0.3}
          >
            <ColorDot size='0.5rem' color={outerCircleColor} />
            <StyledStatus>{outerPercentageTitle}</StyledStatus>
          </Stack>
          <Stack direction='row' spacing={0.4}>
            <ColorDot size='0.5rem' color='transparent' />
            <StyledStatus weight='600'>{outerCirclePercentage}%</StyledStatus>
          </Stack>
        </Stack>
      </Stack>
    </StyledCircularProgress>
  );
}

export default CircularProgress;
