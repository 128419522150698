import React, { useState } from "react";
import { useDispatch } from "react-redux";
import validator from "validator";
import { CompanyApi } from "apis";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "components/button";
import Divider from "@mui/material/Divider";
import TextField from "components/textfield";
import { useTheme } from "styled-components";
import FormAlert from "components/form-alert";
import Persistence from "helpers/persistence";
import MixConstants from "constants/mix-constants";
import { Container, StyledFooter } from "../style";
import LoggedInUserHelper from "helpers/loggedin-user-helper";
import updateStoreActions from "redux/actions/update_store_actions";

function CompanyDetailForm({adminViewCompany, checkStatus, status, closeForm}) {
  const [submitting, setSubmitting] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [message, setMessage] = useState("");
  const company = adminViewCompany ? adminViewCompany : LoggedInUserHelper.company();
  const theme = useTheme();
  const spacing = 2;
  const dispatch = useDispatch();

  const [name, setName] = useState(company?.name || "");
  const [email, setEmail] = useState(company?.email || "");
  const [phone, setPhone] = useState(company?.phone || "");
  const [address, setAddress] = useState(company?.address || "");
  const [city, setCity] = useState(company?.city || "");
  const [state, setState] = useState(company?.state || "");
  const [zipCode, setZipCode] = useState(company?.zip || "");

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [cityError, setCityError] = useState("");
  const [stateError, setStateError] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");

  const setFormatNumber = (e) => {
    let validator = /^[0-9,-]+$/
    if ((e.target.value.match(validator) && e.target.value.length <= 15) || e.target.value === ""){
      setPhone(e.target.value);
    }
  }
  const setMessageText = (text, type) => {
    setMessage(text);
    setMessageType(type);

    setTimeout(() => {
      formAlertCloseButtonClicked();
    }, 5000);
  };

  const canUserProceed = () => {
    let hasError = false;

    if (!company?.id) {
      setMessageText("Company id is missing", "error");
      hasError = true;
      return false;
    }
    if (name.trim() === "") {
      setNameError("Required");
      hasError = true;
    } else {
      setNameError("");
    }
    if (email.trim() === "") {
      setEmailError("Required");
      hasError = true;
    } else {
      setEmailError("");
    }
    if (email.trim() !== "" && !validator.isEmail(email)) {
      setEmailError("Invalid Email");
      hasError = true;
    }
    if (phone === "") {
      setPhoneError("Required");
      hasError = true;
    } else {
      setPhoneError("");
    }

    if (address.trim() === "") {
      setAddressError("Required");
      hasError = true;
    } else {
      setAddressError("");
    }
    if (city.trim() === "") {
      setCityError("Required");
      hasError = true;
    } else {
      setCityError("");
    }
    if (state.trim() === "") {
      setStateError("Required");
      hasError = true;
    } else {
      setStateError("");
    }
    if (zipCode === "") {
      setZipCodeError("Required");
      hasError = true;
    } else {
      setZipCodeError("");
    }
    return !hasError;
  };

  const formAlertCloseButtonClicked = () => {
    setMessage("");
  };

  const submitButtonClicked = () => {
    if (canUserProceed()) {
      const companyId = company?.id;

      const body = {
        company: {
          name: name,
          email: email,
          phone: phone,
          address: address,
          city: city,
          state: state,
          zip: zipCode,
        },
      };
      formAlertCloseButtonClicked();

      if (companyId) {
        const successMessage = "Company detail submitted successfully";
        const errorMessage = "Failed to submit company detail";

        setSubmitting(true);

        CompanyApi.updateCompany(companyId, body).then(
          (response) => {
            setSubmitting(false);

            if (response?.success) {
              const data = response?.data;
              let user = Persistence.getUserData();
              user.company_details = { ...user.company_details, ...data };
              Persistence.setUserData(user);
              dispatch(updateStoreActions.updateCompanyStatus(user.company_details));
              if (checkStatus){
                checkStatus();
              }
              setMessageText(successMessage, "success");
            } else {
              setMessageText(errorMessage, "error");
            }
          },
          (error) => {
            setSubmitting(false);
            setMessageText(errorMessage, "error");
          },
        );
      }
    }
  };

  const resetErrorMessage = () => {
    setNameError("");
    setEmailError("");
    setPhoneError("");
    setAddressError("");
    setCityError("");
    setZipCodeError("");
    setStateError("");
  }

  const fieldsValid = () => {
    let hasError = false;
    resetErrorMessage();
    if (!company?.id) {
      setMessageText("Company id is missing", "error");
      hasError = true;
      return false;
    }
    if (email !== "" && !validator.isEmail(email)) {
      setEmailError("Invalid Email");
      hasError = true;
    }
    let phoneNo = /^\(?([0-9]{10})$/;
    if (phone !== "" && !phone.match(phoneNo)) {
      setPhoneError("Invalid Phone");
      hasError = true;
    } else {
      setPhoneError("");
    }
    return !hasError;
  };


  const saveAndSubmitButtonClicked = () => {
    if (fieldsValid()) {
      const companyId = company?.id;

      const body = {
        status: "in_progress",
        company: {
          name: name,
          email: email,
          phone: phone,
          address: address,
          city: city,
          state: state,
          zip: zipCode,
        },
      };
      formAlertCloseButtonClicked();

      if (companyId) {

        CompanyApi.updateCompany(companyId, body).then(
          (response) => {
            setSubmitting(false);

            if (response?.success) {
              const data = response?.data;
              let user = Persistence.getUserData();
              user.company_details = { ...user.company_details, ...data };
              Persistence.setUserData(user);
              dispatch(updateStoreActions.updateCompanyStatus(user.company_details));
              if (checkStatus){
                checkStatus();
              }
            } else {
            }
          },
          (error) => {
          },
        );
      }
      closeForm();
    }
  };

  return (
    <Container>
      <Grid container spacing={spacing} paddingBottom='1rem'>
        <Grid item xs={12} md={4}>
          <TextField
            required
            disabled={submitting}
            error={nameError !== ""}
            helperText={nameError}
            label='Company Name'
            name='name'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            required
            disabled={submitting}
            label='Email'
            name='email'
            error={emailError !== ""}
            helperText={emailError}
            value={email}
            onChange={(e) => setEmail(e.target.value.trim())}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            required
            disabled={submitting}
            label='Phone'
            name='phone'
            placeholder={MixConstants.PhonePlaceholder}
            error={phoneError !== ""}
            helperText={phoneError}
            value={phone}
            onChange={(e) => setFormatNumber(e)}
          />
        </Grid>
      </Grid>

      <Divider />

      <Grid container spacing={spacing} paddingY='1rem'>
        <Grid item xs={12}>
          <TextField
            required
            multiline
            rows={3}
            disabled={submitting}
            error={addressError !== ""}
            helperText={addressError}
            label='Address'
            name='address'
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            required
            disabled={submitting}
            error={cityError !== ""}
            helperText={cityError}
            label='City'
            name='city'
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            required
            disabled={submitting}
            error={stateError !== ""}
            helperText={stateError}
            label='State'
            name='state'
            value={state}
            onChange={(e) => setState(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            required
            disabled={submitting}
            error={zipCodeError !== ""}
            helperText={zipCodeError}
            label='Zip Code'
            name='zipCode'
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
          />
        </Grid>
      </Grid>

      {message && messageType && (
        <Box sx={{ paddingBottom: "0.5rem" }}>
          <Divider sx={{ marginBottom: "0.5rem" }} />
          <FormAlert
            message={message}
            type={messageType}
            onClose={() => formAlertCloseButtonClicked()}
          />
        </Box>
      )}

      <Divider />

      <StyledFooter>
        <Button
          bgColor={theme.colors.gray}
          titleColor={theme.colors.white}
          title='Save & Finish Later'
          disabled={submitting}
          onClick={() => saveAndSubmitButtonClicked()}
        />
        <Button
          bgColor={theme.colors.green}
          title='Submit'
          disabled={status !== MixConstants.FormStatus.DEPLOYED ? submitting : true}
          loading={submitting}
          onClick={() => submitButtonClicked()}
        />
      </StyledFooter>
    </Container>
  );
}

export default CompanyDetailForm;