import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import RadioGroup from "@mui/material/RadioGroup";
import RadioButton from "components/radio-button";
import Typography from "@mui/material/Typography";

function MediaTypeSection({ types, selectedType, onChange }) {
  return (
    <Box sx={{ marginTop: "0", marginBottom: "0.5rem" }}>
      <Stack spacing={2}>
        <Typography>Type of Media:</Typography>
        <RadioGroup value={selectedType} onChange={onChange}>
          {types &&
            types.map((type, index) => (
              <Box key={index}>
                <RadioButton label={type} />
              </Box>
            ))}
        </RadioGroup>
      </Stack>
    </Box>
  );
}

export default MediaTypeSection;
