import React from "react";
import Button from "components/button";
import Stack from "@mui/material/Stack";
import { useTheme } from "styled-components";
import { StyledHeaderSection } from "../style";
import Typography from "@mui/material/Typography";

function HeaderSection({ disableButton = false, onClickAddNewGallery }) {
  const theme = useTheme();

  return (
    <StyledHeaderSection>
      <Stack spacing={1}>
        <Typography>
          Provide images and/or .mp4 files for each gallery. Gallery names below
          may be changed.
        </Typography>
        <Typography>You may add up to seven galleries.</Typography>
      </Stack>

      <Button
        title='Add New Gallery'
        bgColor={theme.colors.green}
        onClick={onClickAddNewGallery}
        disabled={disableButton}
      />
    </StyledHeaderSection>
  );
}

export default HeaderSection;
