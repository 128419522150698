import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import {
  StyledFileDropZone,
  StyledFileUploadIcon,
  StyledText,
} from "components/file-drop-zone/style";
import Typography from "@mui/material/Typography";
import { useTheme } from "styled-components";
import { StyledFooter } from "components/forms/style";
import Button from "components/button";
import Dropdown from "components/dropdown";
import TextField from "components/textfield";
import DragIcon from "assests/images/noun-drag-and-drop-3579280 (1).png";
import axios from "axios";
import { authHeader } from "helpers/api-header";
import config from "apis/api-config";
import filePath from "assests/files/RentCafe Multi-Property Credentials (1).csv";
import DataRow from "components/data_row";
import FormAlert from "components/form-alert";

const apiUrls = ["https://api.rentcafe.com", "https://api.rentcafe.co.uk"];
const apiVersions = ["RentCafe V1", "RentCafe V2"];
const selectOptions = ["Company Code", "Api Token"];

const currencies = [
  ["Canadian Dollar (CAD)", "124"],
  ["British Pound (GBP)", "826"],
  ["United States Dollar (USD)", "840"],
];

function YardiRentCafeFields({ cId, company }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const theme = useTheme();
  const [selectedApiUrl, setSelectedApiUrl] = useState(
    company?.credential?.yardi_rent_cafe_api_url ?? "https://api.rentcafe.com"
  );
  const [selectedApiVersion, setSelectedApiVersion] = useState(
    company?.credential?.rentcafe_api_version ?? "RentCafe V2"
  );
  const [selectedOption, setSelectedOption] = useState("Company Code");
  const [hashTable, setHashTable] = useState({});
  const [errorHashTable, setErrorHashTable] = useState({});
  const [apiToken, setApiToken] = useState(
    company?.credential?.api_token ?? ""
  );
  const [companyCode, setCompanyCode] = useState(
    company?.credential?.c_code ?? ""
  );
  const [selectedCurrency, setSelectedCurrency] = useState(
    "United States Dollar (USD)"
  );
  const [currency, setCurrency] = useState("840");
  const [dataList, setDataList] = useState([]);
  const [submitMessage, setSubmitMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [apiTokenError, setApiTokenError] = useState("");
  const [companyCodeError, setCompanyCodeError] = useState("");
  const [apiUrlError, setApiUrlError] = useState("");
  const [apiVersionError, setApiVersionError] = useState("");
  const [fileError, setFileError] = useState("");
  const [message, setMessage] = useState("");
  const [proceedFailed, setProceedFailed] = useState(true);

  const handleFileDragDrop = (event) => {
    event.preventDefault();
    setProceedFailed(false);
    const file = event.dataTransfer.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const setMessageText = (text, type) => {
    setSubmitMessage(text);
    setMessageType(type);
  };
  const handleFileSelect = (event) => {
    setProceedFailed(false);
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const parseCsvData = (file) => {
    if (dataList)
      setDataList([]);
  
    const reader = new FileReader();
  
    reader.onload = (event) => {
      const text = event.target.result;
      const results = [];
      const rows = text.trim().split("\n");
  
      rows.slice(1).forEach((row) => {
        const cleanedRow = row.replace(/[\r"]/g, "");
        const splitted_row = cleanedRow.split(",");
        const communityName = splitted_row[0];
        const p_ids = splitted_row.splice(1);
        const pCode = p_ids.map(item => item.trim()).join(',');

        if (communityName && pCode) {
          sendRowDataToBackend(communityName, pCode);
          results.push({communityName, pCode });
        }
      });
  
      setDataList(results);
    };
  
    reader.readAsText(file);
  };
  

  const sendRowDataToBackend = (communityName, pCode) => {
    const data = new FormData();
    data.append("property_name", communityName);
    data.append("p_code", pCode);
    data.append("api_url", selectedApiUrl);
    data.append("api_token", apiToken);
    data.append("currency", currency);
    data.append("c_code", companyCode);
    data.append("rentcafe_api_version", selectedApiVersion);
    data.append("data_provider", "yardirentcafe");
    data.append("limit_result", false);


    const header = Object.assign(authHeader(), {
      accept: "application/json",
      "Content-Type": "application/json",
    });
    let status = "Pending";
    axios({
      baseURL: `${config.baseURL}`,
      url: `api/v2/companies/${cId}/import_data_credentials`,
      method: "post",
      headers: header,
      data: data,
    })
      .then((response) => {
        if (response.status === 200 || response.status === true) {
          if (response.data.status === true) {
            setHashTable((prev) => ({
              ...prev,
              [pCode]: "Success",
            }));
            status = "Success";
          } else {
            setHashTable((prev) => ({
              ...prev,
              [pCode]: "Failed",
            }));
            setErrorHashTable((prev) => ({
              ...prev,
              [pCode]: response.data.message,
            }));
            status = "Failed";
          }
        } else {
          console.log("HTTP error");
          setHashTable((prev) => ({
            ...prev,
            [pCode]: "Failed",
          }));
        }
      })
      .catch((error) => {
        setErrorHashTable((prev) => ({
          ...prev,
          [pCode]: error,
        }));
        setHashTable((prev) => ({
          ...prev,
          [pCode]: "Failed",
        }));
        const errorMessage = "Failed to submit";
        setMessageText(errorMessage, "error");
        status = "Failed";
      })
      .finally(() => {});
    setTimeout(() => {
      formAlertCloseButtonClicked();
    }, 5000);

  };
  const canUserProceed = () => {
    let hasError = false;

    if (apiToken.trim() === "") {
      if (
        selectedApiVersion === "RentCafe V2" ||
        selectedOption === "Api Token"
      ) {
        setApiTokenError("Required");
        hasError = true;
      } else {
        setApiTokenError("");
      }
    } else {
      setApiTokenError("");
    }
    if (companyCode.trim() === "") {
      if (
        selectedApiVersion === "RentCafe V2" ||
        selectedOption === "Company Code"
      ) {
        setCompanyCodeError("Required");
        hasError = true;
      } else {
        setCompanyCodeError("");
      }
    } else {
      setCompanyCodeError("");
    }
    if (selectedApiUrl.trim() === "") {
      setApiUrlError("Required");
      hasError = true;
    } else {
      setApiUrlError("");
    }
    if (selectedApiVersion.trim() === "") {
      setApiVersionError("Required");
      hasError = true;
    } else {
      setApiVersionError("");
    }
    if (selectedFile === null) {
      setFileError("Required");
      hasError = true;
    } else {
      setFileError("");
    }
    return !hasError;
  };

  const submitButtonClicked = () => {
    if (canUserProceed()) {
      if (selectedFile) {
        parseCsvData(selectedFile);
        setSelectedFile(null);
      }
    }
    setTimeout(() => {
      formAlertCloseButtonClicked();
    }, 5000);
  };
  const preventDefault = (event) => {
    event.preventDefault();
  };
  const clearSelectedFile = () => {
    setSelectedFile(null);
  };
  const downloadCSVFile = () => {
    const fileUrl = filePath;
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = filePath;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSelectChange = (event) => {
    setSelectedApiUrl(event);
  };
  const handleSelectApiVersion = (event) => {
    setSelectedApiVersion(event);
  };
  const handleSelectedOption = (event) => {
    setSelectedOption(event);
  };
  const handleSelectCurrency = (event) => {
    setSelectedCurrency(event);
    setCurrency(event?.value);
  };
  const formAlertCloseButtonClicked = () => {
    setMessage("");
    setFileError("");
    setSubmitMessage("");
  };

  return (
    <>
      <Grid item xs={6}>
        <Typography sx={{ m: 0, p: 0, marginBottom: "5px" }}>
          {" "}
          Api Url{" "}
        </Typography>
        <Dropdown
          label={"Select Api Url"}
          options={apiUrls}
          value={selectedApiUrl}
          error={apiUrlError !== ""}
          helperText={apiUrlError}
          onChange={(e, apiUrl) => handleSelectChange(apiUrl)}
          disabled={
            company?.data_providers?.includes("yardirentcafe") &&
            company?.credential?.yardi_rent_cafe_api_url
              ? true
              : false
          }
        />
      </Grid>
      <Grid item xs={6}>
        <Typography sx={{ m: 0, p: 0, marginBottom: "5px" }}>
          {" "}
          Currency
        </Typography>
        <Dropdown
          label={"Select Currency"}
          options={currencies.map((currency) => ({
            label: currency[0],
            value: currency[1],
          }))}
          value={selectedCurrency}
          onChange={(e, currency) => handleSelectCurrency(currency)}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography sx={{ m: 0, p: 0, marginBottom: "5px" }}>
          {" "}
          API Version{" "}
        </Typography>
        <Dropdown
          label={"Select Api Version"}
          options={apiVersions}
          value={selectedApiVersion}
          error={apiVersionError !== ""}
          helperText={apiVersionError}
          onChange={(e, apiVersion) => handleSelectApiVersion(apiVersion)}
        />
      </Grid>
      {selectedApiVersion !== "RentCafe V2" ? (
        <Grid item xs={6}>
          <Typography sx={{ m: 0, p: 0, marginBottom: "5px" }}>
            {" "}
            Select Option{" "}
          </Typography>
          <Dropdown
            label={"Select Option"}
            options={selectOptions}
            value={selectedOption}
            onChange={(e, option) => handleSelectedOption(option)}
          />
        </Grid>
      ) : null}
      {selectedApiVersion === "RentCafe V2" ||
      selectedOption === "Api Token" ? (
        <Grid item xs={6}>
          <label> Vendor Api Token</label>
          <TextField
            name="Vendor Api Token"
            value={apiToken}
            label="Vendor Api Token"
            error={apiTokenError !== ""}
            disabled={
              company?.data_providers?.includes("yardirentcafe") &&
              company?.credential?.api_token
                ? true
                : false
            }
            onChange={(e) => setApiToken(e.target.value)}
          />
        </Grid>
      ) : null}
      {selectedApiVersion === "RentCafe V2" ||
      selectedOption === "Company Code" ? (
        <Grid item xs={6}>
          <Typography sx={{ m: 0, p: 0, marginBottom: "5px" }}>
            C Code
          </Typography>
          <TextField
            name="Company Code"
            value={companyCode}
            error={companyCodeError !== ""}
            label="Company Code"
            onChange={(e) => setCompanyCode(e.target.value)}
            disabled={
              company?.data_providers?.includes("yardirentcafe") &&
              company?.credential?.c_code
                ? true
                : false
            }
          />
        </Grid>
      ) : null}
      <Grid item xs={6} marginBottom="1rem">
        <Typography>
          Not sure how to format file?{" "}
          <a
            onClick={downloadCSVFile}
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            Example
          </a>
        </Typography>
      </Grid>
      <Grid container spacing={2} marginLeft="1rem">
        <Grid item xs={6}>
          <label htmlFor="file-input" style={{ cursor: "pointer" }}>
            <StyledFileDropZone padding="5rem">
              <Stack direction="column" alignItems="center" spacing={1}>
                <input
                  type="file"
                  accept=".csv"
                  onChange={(e) => handleFileSelect(e)}
                  style={{ display: "none" }}
                  id="file-input"
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                />
                <StyledFileUploadIcon size={"3rem"} />
                <StyledText>
                  {selectedFile?.name ? selectedFile.name : "Browse"}
                </StyledText>
              </Stack>
              `
            </StyledFileDropZone>
          </label>
        </Grid>

        <Grid item xs={6}>
          <div
            onDrop={(e) => handleFileDragDrop(e)}
            onDragOver={(e) => preventDefault(e)}
          >
            <StyledFileDropZone padding="5.3rem">
              <Stack direction="column" alignItems="center" spacing={1}>
                <img
                  src={DragIcon}
                  style={{ width: "3rem", filter: "brightness(0) invert(1)" }}
                />
                <StyledText>
                  {selectedFile?.name
                    ? selectedFile.name
                    : "Drag & Drop a File"}{" "}
                </StyledText>
              </Stack>
            </StyledFileDropZone>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2} marginLeft="1rem">
        <Grid item xs={12} marginTop="1rem">
          {fileError && (
            <FormAlert
              message={"Please select a csv file"}
              type={"error"}
              onClose={() => formAlertCloseButtonClicked()}
            />
          )}
          {submitMessage && (
            <FormAlert
              message={submitMessage}
              type={messageType}
              onClose={() => formAlertCloseButtonClicked()}
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <StyledFooter
            style={{ float: "left", marginLeft: "1rem", marginBottom: "1rem" }}
          >
            <Button
              bgColor={theme.colors.green}
              title="Submit"
              onClick={submitButtonClicked}
              disabled={proceedFailed}
            />
          </StyledFooter>
        </Grid>
      </Grid>
      <Grid container spacing={2} marginLeft="1rem" marginBottom="1rem">
        {dataList.map((row, index) => (
          <DataRow
            key={index}
            row={row}
            loader={!hashTable?.[row?.pCode]}
            theme={theme}
            status={hashTable?.[row?.pCode]}
            errorMessage={errorHashTable?.[row?.pCode]}
            number={index + 1}
          />
        ))}
      </Grid>
    </>
  );
}

export default YardiRentCafeFields;
 