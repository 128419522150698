import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

function FloorRangeInstructionsSection() {
  const text = {
    __html: `e.g. '<b>3</b>' for one &nbsp; &nbsp; floor '<b>3-10</b>' &nbsp; &nbsp; for range
    '<b>3,5,7,[...]</b>' for specific floors`,
  };

  return (
    <Box display='flex' justifyContent='center' marginBottom='1rem'>
      <Stack>
        <Stack>
          <Typography textAlign='center'>
            Enter the range of floors for each uploaded file. Below are valid
            input formats:
          </Typography>
        </Stack>
        <Stack>
          <Typography textAlign='center' dangerouslySetInnerHTML={text} />
        </Stack>
      </Stack>
    </Box>
  );
}

export default FloorRangeInstructionsSection;
