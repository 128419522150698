import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import ProgressStep from "./progress-step";
import { StyledProgressStepsList } from "./style";

function ProgressStepsList({ steps }) {
  const renderSteps = (steps) => {
    return (
      steps &&
      steps.map((step) => (
        <Box key={step.id}>
          <ProgressStep step={step} steps={steps} />
        </Box>
      ))
    );
  };

  return (
    <Box>
      <Divider variant='middle' />
      <StyledProgressStepsList>{renderSteps(steps)}</StyledProgressStepsList>
    </Box>
  );
}

export default ProgressStepsList;
