import React from "react";
import Button from "components/button";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

function Modal({
  fullWidth = true,
  maxWidth = "md",
  open,
  title,
  children,
  footerButtons,
  onClose,
}) {
  const renderFooterButtons = (buttons) => {
    return (
      buttons &&
      buttons.map((button) => (
        <Button
          key={button.id}
          bgColor={button.bgColor}
          titleColor={button.titleColor}
          title={button.title}
          disabled={button.disabled}
          loading={button.loading}
          startIcon={button.startIcon}
          endIcon={button.endIcon}
          onClick={button.onClick}
        />
      ))
    );
  };

  return (
    <div>
      <BootstrapDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        aria-labelledby='customized-dialog-title'
        onClose={onClose}
      >
        <BootstrapDialogTitle id='customized-dialog-title' onClose={onClose}>
          {title}
        </BootstrapDialogTitle>
        <DialogContent dividers>{children}</DialogContent>
        <DialogActions>{renderFooterButtons(footerButtons)}</DialogActions>
      </BootstrapDialog>
    </div>
  );
}

export default Modal;
