import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import Box from "@mui/material/Box";
import Button from "components/button";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { useTheme } from "styled-components";
import { Container, StyledFooter, StyledSpinnerContainer } from "../style";
import MediaTypeSection from "./media-type-section";
import FilesGridList from "components/files-grid-list";
import ImageFileUploadContainer from "components/image-file-upload-container";
import VideoFileUploadContainer from "components/video-file-upload-container";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography } from "@mui/material";
import FormAlert from "components/form-alert";
import { useParams } from "react-router-dom";
import { FormApi } from "apis";
import MixConstants from "constants/mix-constants";
import FileUploadLoaderModal from "components/file_upload_popup-loader";
import Utils from "helpers/utils";

function PynwheelTouchHomePageMediaForm({expanded, checkStatus, status, closeForm}) {
  const theme = useTheme();
  const [uploading, setUploading] = useState(false);
  const [fetchingHomePage, setFetchingHomePage] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [state, setState] = useState({
    selectedMediaType: "",
    images: [],
    videos: [],
  });

  let { communityId } = useParams();

  const mediaTypes = ["images", "videos"];

  const formAlertCloseButtonClicked = () => {
    setMessage("");
    setMessageType("");
  };

  const setMessageText = (text, type) => {
    setMessage(text);
    setMessageType(type)
    setTimeout(() => {
      formAlertCloseButtonClicked();
    }, 5000);
  };

  const getMappedMedia = useCallback((designs) => {
    if (designs?.length > 0){
      let homePageImages = [];
      let homePageVideo = [];
      let type;
      designs.map((design) => {
        if (design?.type === "images"){
          type = "images"
        } else if (design?.type === "video"){
          type = "videos"
        }
        type = changeMediaTypeName(design?.type);
        if (design?.images?.length > 0){
          design.images.map((img) => {
            homePageImages.push( {
              ...img,
              id: img.id,
              photo: img?.image?.url,
              type: "image",
              isLocal: false,
            });
            return [];
          })
        }
        if (design.video){
          homePageVideo[0] =  {
            ...design,
            id: design.video.id,
            type: "file",
            file: design.video.video?.url,
            isLocal: false,
          };
        }
        return []
      });
      return {
        selectedMediaType: type,
        images: homePageImages,
        videos: homePageVideo,
      };
    }

  }, []);

  const fetchHomePage = useCallback((abortController) => {
    if (expanded){
      if (communityId) {
      setFetchingHomePage(true);
      FormApi.fetchHomePage(communityId).then((response) => {
        const aborted = abortController.signal.aborted;
        if (aborted === false){
          setFetchingHomePage(false);
          if (response?.data){
            let mappedMedia = getMappedMedia(response.data)
            setState(mappedMedia)
          }
        }
      }, (error) => {
        setFetchingHomePage(false);
      }
      );
    }
    }
  }, [communityId, expanded, getMappedMedia]);

  useEffect(() => {
    let abortController = new AbortController();
    fetchHomePage(abortController);
    return () => {
      abortController.abort();
    };
  }, [fetchHomePage]);

  const handleDroppedImageFiles = (files) => {
    const images = _.union(state.images, files);
    setState({
      ...state,
      images: images,
    });
    if (Utils.isAnyLocalFile(images)) {
      uploadFiles(images);
    }
  };

  const handleDroppedVideoFiles = (files) => {
    if (files.length > 0){
      setState({
        ...state,
        videos: files,
      });
      uploadFiles(files);
    }
  };

  const handleImagesListUpdate = (updatedFilesList) => {
    setState({
      ...state,
      images: updatedFilesList,
    });
  };

  const changeMediaTypeName = (type) => {
    if (type === "videos"){
      return "video"
    } else if (type === "video"){
      return "videos";
    } else {
      return type;
    }
  }

  const handleHomePageImageDeleted = (deletedHomePageImage) => {
    const successMessage = "Homepage image deleted successfully";
    const errorMessage = "Failed to delete homepage image";

    const deleteMessage = () => {
      const index = _.findIndex(state.images, deletedHomePageImage);
      let homepageImage = [...state.images];
      homepageImage.splice(index, 1);
      setState({
        ...state,
        images: homepageImage,
      });
    }
    if (!deletedHomePageImage.isLocal){
      setSubmitting(true);
      FormApi.deleteHomePageimage(communityId, deletedHomePageImage.id).then((response)=>{
        setSubmitting(false);
        if (response?.success){
          deleteMessage();
          setMessageText(successMessage, "success");
          checkStatus();
        } else {
          setMessageText(errorMessage, "error");
        }
      },
      (error) => {
        setSubmitting(false);
        setMessageText(errorMessage, "error");
      }
      );
    } else {
      deleteMessage();
    }
  }

  const handleHomePageVideoDeleted = (deleteHomePageVideo) => {
    const successMessage = "Homepage video deleted successfully";
    const errorMessage = "Failed to delete homepage video";

    const deleteMessage = () => {
      const index = _.findIndex(state.videos, deleteHomePageVideo);
      let homepageVideo = [...state.videos];
      homepageVideo.splice(index, 1);
      setState({
        ...state,
        videos: homepageVideo,
      });
    }
    if (!deleteHomePageVideo.isLocal){
      setSubmitting(true);
      FormApi.deleteHomePageVideo(communityId, deleteHomePageVideo).then((response)=>{
        setSubmitting(false);
        if (response?.success){
          deleteMessage();
          setMessageText(successMessage, "success");
          checkStatus();
        } else {
          setMessageText(errorMessage, "error");
        }
      },
      (error) => {
        setSubmitting(false);
        setMessageText(errorMessage, "error");
      }
      );
    } else {
      deleteMessage();
    }
  };

  const handleVideosListUpdate = (updatedFilesList) => {
    setState({
      ...state,
      videos: updatedFilesList,
    });
    uploadFiles();
  };

  const handleMediaTypeChange = (e) => {
    if (e.target.value !== state.selectedMediaType){
      if (communityId){
        setSubmitting(true);
        FormApi.changePynwheelTouchHardwareSpec(communityId, changeMediaTypeName(e.target.value)).then((response) => {
          setSubmitting(false);
          setState({
            ...state,
            selectedMediaType: e.target.value,
          });
        }, (error)=>{
          setSubmitting(false);
        });
      }
    }
  };

  const getMappedDataForServer = () => {
    let media = [];
    if (state.selectedMediaType === mediaTypes[0] && state.images.length > 0){
      media = state.images.map((image) => {
        return {
          id: !image.isLocal ? image.id : null,
          file: image.originalFile
        }
      }); 
    }
    if (state.selectedMediaType === mediaTypes[1] && state.videos.length > 0){
      media = state.videos.map((image) => {
        return {
          id: !image.isLocal ? image.id : null,
          file: image.originalFile
        }
      });
    }
    return {
      "homepage_type": state.selectedMediaType,
      "homepage_design": media
    }
  }

  const getMappedImagesForUploadFiles = (images) => {
    let media = [];
    if (state.selectedMediaType === mediaTypes[0] && images.length > 0){
      media = images.map((image) => {
        return {
          id: !image.isLocal ? image.id : null,
          file: image.isLocal ? image.originalFile : null,
        }
      }); 
    }

    if (state.selectedMediaType === mediaTypes[1] && images.length > 0){
      media = images.map((image) => {
        return {
          id: !image.isLocal ? image.id : null,
          file: image.isLocal ? image.originalFile : null,
        }
      });
    }

    return {
      "homepage_type": state.selectedMediaType,
      "homepage_design": media
    }
  }

  const uploadFiles = (images) => {
    const data = getMappedImagesForUploadFiles(images);
    if (data?.homepage_design?.length > 0){
      const errorMessage = `Failed to save Homepage ${state.selectedMediaType}`;
      setUploading(true);
    FormApi.saveHomePage(communityId, data, "in_progress").then((response) =>{
      setUploading(false);
      if (response?.success){
        let mappedData = getMappedMedia(response.data);
        checkStatus();
        setState(mappedData);
      }
    },(error) => {
      setUploading(false);
      setMessageText(errorMessage, "error");

    });
    }
  }


  const submitButtonClicked = () => {
    const data = getMappedDataForServer();

    if (data?.homepage_design?.length > 0){
      const successMessage = `Homepage ${state.selectedMediaType} saved successfully`;
      const errorMessage = `Failed to save Homepage ${state.selectedMediaType}`;
      setSubmitting(true);
    FormApi.saveHomePage(communityId, data).then((response) =>{
      setMessageText(successMessage, "success");
      setSubmitting(false);
      if (response?.success){
        let mappedData = getMappedMedia(response.data);
        checkStatus();
        setState(mappedData);
      }
    },(error) => {
      setSubmitting(false);
      setMessageText(errorMessage, "error");

    });
    }
  }

  const saveAndSubmitButtonClicked = () => {
    const data = getMappedDataForServer();

    if (data?.homepage_design?.length > 0){
    FormApi.saveHomePage(communityId, data, "in_progress").then((response) =>{
      if (response?.success){
        checkStatus();
      }
    },(error) => {
    });
    }
    closeForm();
  }

  if (fetchingHomePage) {
    return (
      <StyledSpinnerContainer>
        <CircularProgress size={20} />
      </StyledSpinnerContainer>
    );
  }

  return (
    <Container>
      <MediaTypeSection
        types={mediaTypes}
        selectedType={state.selectedMediaType}
        onChange={handleMediaTypeChange}
      />

      {state.selectedMediaType === mediaTypes[0] ? (
        <Box>
          <Divider />

          <Stack marginTop='1rem'>
            <Typography>Upload Slide Show Images Below</Typography>
            <Typography>
              Images will appear at a size of 1920*1080 px. Please note that
              images that are too small may appear pixalated.
            </Typography>
          </Stack>
        </Box>
      ) : state.selectedMediaType === mediaTypes[1] ? (
        <Box>
          <Divider />

          <Stack marginTop='1rem'>
            <Typography>Upload Video Below</Typography>
            <Typography>
              Home page video size limit is 500MB. Upload only .mp4 videos.
            </Typography>
          </Stack>
        </Box>
      ) : null}

      {state.selectedMediaType === mediaTypes[0] ? (
        <Box sx={{ marginY: "1rem" }}>
          <ImageFileUploadContainer
            onFileDrop={handleDroppedImageFiles}
            helperText='.png or .jpg 1920*1080 px'
          />
        </Box>
      ) : state.selectedMediaType === mediaTypes[1] ? (
        <Box sx={{ marginY: "1rem" }}>
          <VideoFileUploadContainer
            onFileDrop={handleDroppedVideoFiles}
            maxFiles={1}
          />
        </Box>
      ) : null}

      {state.selectedMediaType === mediaTypes[0] && state.images.length > 0 ? (
        <FilesGridList
          files={state.images}
          onUpdate={(updatedFilesList) =>
            handleImagesListUpdate(updatedFilesList)
          }
          deleteImage={(homepageimage) => {handleHomePageImageDeleted(homepageimage)}}
        />
      ) : state.selectedMediaType === mediaTypes[1] &&
        state.videos.length > 0 ? (
        <FilesGridList
          files={state.videos}
          onUpdate={(updatedFilesList) =>
            handleVideosListUpdate(updatedFilesList)
          }
          deleteImage={(homepageVideo) => handleHomePageVideoDeleted(homepageVideo)}
        />
      ) : null}

      <Divider />
      {message && messageType && (
          <Box>
            <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
            <FormAlert
              message={message}
              type={messageType}
              onClose={() => formAlertCloseButtonClicked()}
            />
          </Box>
        )}
      <StyledFooter>
        <Button
          bgColor={theme.colors.gray}
          titleColor={theme.colors.white}
          title='Save & Finish Later'
          onClick={saveAndSubmitButtonClicked}
        />
        <Button 
          bgColor={theme.colors.green}
          title='Submit'
          disabled={status !== MixConstants.FormStatus.DEPLOYED ? submitting : true}
          loading={submitting}
          onClick={() => submitButtonClicked()} />
      </StyledFooter>
      <FileUploadLoaderModal
        openModal={uploading}
      />
    </Container>
  );
}

export default PynwheelTouchHomePageMediaForm;
