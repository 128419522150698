import { StyledSpinnerContainer } from "../../../src/components/forms/style";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { ApprovedImage, RejectedImage } from "assests/images";
import {
  StyledAvatar,
  StyledProgressStep,
} from "../../components/progress-steps-list/progress-step/style";
import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
const RejectMessageTooltip = styled(({ className, type, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, type }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "black",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    border: type === "error" ? "1px solid #F36538" : "1px solid green",
  },
}));

function DataRow({ row, loader, theme, status, errorMessage, number }) {
  return (
    <Grid item >
      {(row?.communityName !== "" || row?.property_name !== "") && (
        <span style={{ fontSize: "17px", fontWeight: "bold" }}>
          {loader ? (
            <>
              <StyledProgressStep sx={{ my: -1 }}>
                {number}){" "}
                <Typography
                  sx={{
                    m: 0,
                    p: 0,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                >
                  {row.communityName ? row.communityName : row.property_name }
                </Typography>
                <Typography sx={{ m: 0, p: 0 }}>
                  <CircularProgress size={15} />
                </Typography>
              </StyledProgressStep>
            </>
          ) : (
            <>
              <StyledProgressStep sx={{ my: -1 }}>
                {number}){" "}
                <Typography
                  sx={{
                    m: 0,
                    p: 0,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                >
                  {row.communityName ? row.communityName : row.property_name }
                </Typography>
                {status === "Success" ? (
                  <Typography sx={{ m: 0, p: 0 }}>
                    {
                      <RejectMessageTooltip
                        placement={"right"}
                        title={"Success! Property has been set up."}
                        type="success"
                      >
                        <StyledAvatar alt={""} src={ApprovedImage} />
                      </RejectMessageTooltip>
                    }
                  </Typography>
                ) : (
                  <Typography sx={{ m: 0, p: 0 }}>
                    {
                      <RejectMessageTooltip
                        placement={"right"}
                        title={errorMessage ? errorMessage : ""}
                        type="error"
                      >
                        <StyledAvatar alt={""} src={RejectedImage} />
                      </RejectMessageTooltip>
                    }
                  </Typography>
                )}
              </StyledProgressStep>
            </>
          )}
        </span>
      )}
    </Grid>
  );
}

export default DataRow;
