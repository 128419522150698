export const PYNLaunchLogoImage = require("./pyn-launch-logo-img.png").default;
export const PynwheelImage = require("./pynwheel-logo.png").default;
export const ProfileImage = require("./profile-img.png").default;
export const UpArrowImage = require("./up-arrow-img.png").default;
export const DownArrowImage = require("./down-arrow-img.png").default;
export const InProgressImage = require("./inprogress-img.png").default;
export const SubmittedImage = require("./submitted-img.png").default;
export const ApprovedImage = require("./approved-img.png").default;
export const RejectedImage = require("./rejected-img.png").default;
export const EmptyCircleImage = require("./empty-circle-img.png").default;
export const PoweredByImage = require("./powered-by-img.png").default;
export const SettingsImage = require("./settings-img.png").default;
export const CommentImage = require("./comment-img.png").default;
export const CommentDotImage = require("./comment-dot-img.png").default;
export const SitemapExample1 = require("./sitemap/SM-Ex.png").default;
export const SitemapExample2 = require("./sitemap/SM-Ex-2.png").default;
export const SitemapExample3 = require("./sitemap/SM-Ex-3.png").default;
export const FloorPlateSampleL2 = require("./floorplate/sample-FP-L2.png").default;
export const FloorPlateSampleL3 = require("./floorplate/sample-FP-L3.png").default;
export const FloorPlateSampleL4 = require("./floorplate/sample-FP-L4.png").default;
export const FloorPlateSampleL5 = require("./floorplate/sample-FP-L5.png").default;
export const FloorPlan2D = require("./floorplan/Floor-Plan-2D.jpg").default;
export const FloorPlan3D = require("./floorplan/Floor-Plan-3D.jpg").default;
export const Amenity1 = require("./amenity/amenity-1.jpg").default;
export const Amenity2 = require("./amenity/amenity-2.jpg").default;
export const Amenity3 = require("./amenity/amenity-3.jpg").default;
export const Amenity4 = require("./amenity/amenity-4.jpg").default;
export const Amenity5 = require("./amenity/amenity-5.jpg").default;
export const HardwareSpec1 = require("./harwarespec/hardware-spec-1.jpeg").default;
export const HardwareSpec2 = require("./harwarespec/hardware-spec-2.jpeg").default;
export const PoolStockImage = require("./stock/Pool Water.jpg").default;
export const LeasingCenter = require("./stock/Leasing Center- Keys.jpg").default;
export const FitnessCenterStock = require("./stock/Fitness Center- Dumbbells.jpg").default;
export const DogParkStock = require("./stock/Dog Park- Dog.jpg").default;
export const PlayGroundStock = require("./stock/Playground- Kids.jpg").default;
export const GameRoomStock = require("./stock/Game Room- Pool Table.jpg").default;
export const DogWashStock = require("./stock/Dog Wash- Dog with Bubbles.jpg").default;
export const PackageLockerStock = require("./stock/Package Locker- Package.jpg").default;
export const MailRoomStock = require("./stock/Mail Room- Mail 3.jpg").default;
export const ConferenceRoomStock = require("./stock/Conference Room- Pen and Paper.jpg").default;
export const BusinessCenterStock = require("./stock/Business Center- Keyboard.jpg").default;
export const ClubHouseStock = require("./stock/Clubhouse - Resident Lounge- Person Drinking Coffee.jpg").default;
export const OtherStockImage = require("./stock/Other- Happy Person.jpg").default;
export const SampleIglooHomeLockId = require("./locks/sample_igloo_lock_id.jpeg").default;
