import React from "react";
import { StyledBox } from "./style";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import { Link, Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import AvatarGroup from "@mui/material/AvatarGroup";
import { UpArrowImage, DownArrowImage } from "assests/images";

function ImagesListPreview({ images, expanded, onClickExpand }) {
  const renderImagesPreview = (images) => {
    return (
      <AvatarGroup max={3}>
        {images &&
          images.map((image, index) => (
            <Avatar
              key={index}
              alt='floor plan image'
              src={image?.photo ? image?.photo : image?.url}
            />
          ))}
      </AvatarGroup>
    );
  };

  return (
    <StyledBox>
      <Stack direction='row' spacing={2} alignItems='center'>
        <div>{renderImagesPreview(images)}</div>
        <Link onClick={onClickExpand} underline='none'>
          Preview {images.length} Images
        </Link>
      </Stack>
      <Box>
        <IconButton
          sx={{ width: "2rem", height: "2rem" }}
          onClick={onClickExpand}
        >
          {expanded ? (
            <img src={UpArrowImage} alt='icon' />
          ) : (
            <img src={DownArrowImage} alt='icon' />
          )}
        </IconButton>
      </Box>
    </StyledBox>
  );
}

export default ImagesListPreview;
