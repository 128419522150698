import React, { useCallback, useState } from "react";
import Stack from "@mui/material/Stack";
import { useDropzone } from "react-dropzone";
import { StyledFileDropZone, StyledFileUploadIcon, StyledText } from "./style";
import FileUploadErrorModal from "components/file-upload-errors-modal";
import Utils from "helpers/utils";
import MessageModal from "components/message-modal";

function FileDropZone({
  accept,
  onFileDrop,
  uploadIconSize = "3rem",
  helperText,
  padding,
  maxFiles = 0,
  disabled,
  validationEnabled = true,
  disableErrorMessage,
}) {
  const [errors, setErrors] = useState(false);
  const [fileErrors, setFileErrors] = useState("");
  const [disabledError, setDisabledError] = useState(false);
  const [disabledMessage, setDisabledMessage] = useState("");

  const maxFileSize = (file) => {
    if (validationEnabled) {
      if (Utils.isImageFile(file) && file.size > 2000000) {
        return {
          code: "image-too-large",
          maxSize: "2MB",
          message: "Max size allowed 2MB.",
        };
      }
      if (Utils.isVideoFile(file) && file.size > 500000000) {
        return {
          code: "video-too-large",
          message: "Max size allowed 500MB.",
          maxSize: "500MB",
        };
      }
    }
    return null;
  };

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      if (
        maxFiles > 0 &&
        acceptedFiles?.length + fileRejections?.length > maxFiles
      ) {
        setDisabledError(true);
        setDisabledMessage(`Maximum file(s) allowed ${maxFiles}`);
      } else {
        onFileDrop && onFileDrop(acceptedFiles);
      }
    },
    [onFileDrop, maxFiles],
  );

  const setFileErrorModal = (e) => {
    setFileErrors(e);
    setErrors(true);
  };

  const verifyDisabled = (e) => {
    if (disabled) {
      e.preventDefault();
      // setDisabledError(true);
      // setDisabledMessage(disableErrorMessage);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    validator: maxFileSize,
    accept: accept,
    onDrop,
    onDropRejected: (e) => {
      setFileErrorModal(e);
    },
  });

  const closeAllModals = () => {
    setErrors(false);
    setDisabledError(false);
  };

  return (
    <>
      <StyledFileDropZone
        {...getRootProps()}
        padding={padding}
        disabled={disabled}
      >
        <input {...getInputProps()} onClick={(e) => verifyDisabled(e)} />
        <Stack direction='column' alignItems='center' spacing={1}>
          <StyledFileUploadIcon size={uploadIconSize} />
          <StyledText>Drag & Drop File to Upload</StyledText>
          <StyledText>{helperText}</StyledText>
        </Stack>
      </StyledFileDropZone>
      {disabledError && (
        <MessageModal
          openModal={disabledError}
          closeModal={() => closeAllModals()}
          content={disabledMessage}
        />
      )}
      {errors && (
        <FileUploadErrorModal
          openModal={!disabledError && errors}
          closeModal={() => {
            setErrors(false);
          }}
          maxFiles={maxFiles}
          fileErrors={fileErrors}
        />
      )}
    </>
  );
}

export default FileDropZone;
