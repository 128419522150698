import { Grid } from '@mui/material'
import Utils from 'helpers/utils'
import React from 'react'
import ImagePageHeader from './header'
import ImagePageGalleryItem from './ImagePageGalleryItem'

const AdditionPageImagePage = ({imagePage, onUpdate, updateName, onDelete, removeFile, updateFiles}) => {
  const handleDropFile = (images, index) => {
    const imagePageList = [...imagePage];
    images.forEach((image) => {
      if (Utils.isImageVideo(image)){
        imagePageList[index].gallery.push(image);
      } else {
        imagePageList[index].files.push(image);
      }
    })
    onUpdate(imagePageList)
  }

  return (
    <Grid
    container
    spacing={2}
    marginTop={'0rem'}
    marginBottom={'0.5rem'}
    >
        <Grid item xs={12} md={12}>
        <ImagePageHeader />
        {imagePage?.map((imagePage, index) => (
            <ImagePageGalleryItem
              key={imagePage?.id}
              page={imagePage}
              index={index}
              onDropFile={handleDropFile}
              updateName={updateName}
              onDelete={onDelete}
              removeFile={removeFile}
              updateFiles={updateFiles}
            />
        )) }
        </Grid>
    </Grid>
  )
}

export default AdditionPageImagePage