import { FormApi } from "apis";
import Modal from "components/modal";
import React, { useState } from "react";
import Dropdown from "components/dropdown";
import { useTheme } from "styled-components";
import TextField from "components/textfield";
import FormAlert from "components/form-alert";
import FilesList from "components/files-list";
import { Container, Box } from "@mui/material";
import ImageFileUploadContainer from "components/image-file-upload-container";

export const amenityTypes = [
  "Leasing Center",
  "Fitness Center",
  "Pool",
  "Yoga Studio / Fitness Studio",
  "Dog Park",
  "Playground",
  "Clubhouse / Resident Lounge",
  "Game Room",
  "Dog Wash",
  "Package Locker",
  "Mail Room",
  "Conference Room",
  "Business Center / Lounge",
  "Other",
];

function CreateAmenityModal({ onClose, open = true, communityId, onCreate }) {
  const theme = useTheme();
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [amenityName, setAmenityName] = useState("");
  const [amenityTourLink, setAmenityTourLink] = useState("");
  const [creatingAmenity, setCreatingAmenity] = useState(false);
  const [amenityType, setAmenityType] = useState("");
  const [amenityImage, setAmenityImage] = useState(null);

  const actionButtons = [
    {
      id: 1,
      title: "Cancel",
      bgColor: theme.colors.lightGray,
      titleColor: theme.colors.white,
      disabled: creatingAmenity,
      onClick: onClose,
    },
    {
      id: 2,
      title: "Create",
      bgColor: theme.colors.green,
      titleColor: theme.colors.white,
      disabled:
        amenityName.length < 3 ||
        amenityImage === null ||
        creatingAmenity,
      loading: creatingAmenity,
      onClick: () => createButtonClicked(),
    },
  ];

  const onChangeAmenityType = (amenityType) => {
    setAmenityType(amenityType);
  };

  const handleAmenityImage = (files) => {
    setAmenityImage(files && files.length > 0 ? files[0] : null);
  };

  const createButtonClicked = () => {
    const successMessage = "Amenity created successfully";
    const errorMessage = "Failed to create amenity";

    if (communityId) {
      setCreatingAmenity(true);

      const data = {
        name: amenityName,
        amenity_type: amenityType,
        video_link: amenityTourLink,
        file: amenityImage?.originalFile,
      };

      FormApi.createAmenity(data, communityId).then(
        (response) => {
          setCreatingAmenity(false);
          if (response?.success) {
            setMessageText(successMessage, "success");
            if (onCreate) {
              onCreate(response?.data);
            }
          } else {
            setMessageText(errorMessage, "error");
          }
        },
        (error) => {
          setCreatingAmenity(false);
          setMessageText(error?.message ?? errorMessage, "error");
        },
      );
    }
  };

  const setMessageText = (text, type) => {
    setMessage(text);
    setMessageType(type);

    setTimeout(() => {
      setMessage("");
    }, 5000);
  };

  return (
    <Modal
      maxWidth='sm'
      open={open}
      title={"Create Amenity"}
      onClose={creatingAmenity ? null : onClose}
      footerButtons={actionButtons}
    >
      <Container maxWidth='md'>
        <TextField
          required
          disabled={creatingAmenity}
          label='Amenity Name'
          name='amenityName'
          value={amenityName}
          onChange={(e) => setAmenityName(e.target.value)}
        />

        <Box sx={{ marginTop: "15px" }}>
          <Dropdown
            label={"Select Amenity Type"}
            options={amenityTypes}
            disabled={creatingAmenity}
            onChange={(e, amenityType) => onChangeAmenityType(amenityType)}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>

        <Box sx={{ marginTop: "15px" }}>
          <TextField
            disabled={creatingAmenity}
            label='Video or 3D tour link (Optional)'
            value={amenityTourLink ?? ""}
            onChange={(e) => setAmenityTourLink(e.target.value)}
          />
        </Box>

        <Box sx={{ marginTop: "15px" }}>
          <ImageFileUploadContainer
            padding='0.5rem'
            helperText='.png or .jpg atleast 2000px'
            onFileDrop={handleAmenityImage}
            maxFiles={1}
            disabled={creatingAmenity}
          />
        </Box>

        {amenityImage && (
          <Box sx={{ marginTop: "15px" }}>
            <FilesList
              files={[amenityImage]}
              onUpdate={(updatedFilesList) =>
                handleAmenityImage(updatedFilesList)
              }
            />
          </Box>
        )}

        {message && messageType && (
          <Box sx={{ paddingTop: "1rem" }}>
            <FormAlert
              message={message}
              type={messageType}
              onClose={() => setMessage("")}
            />
          </Box>
        )}
      </Container>
    </Modal>
  );
}

export default CreateAmenityModal;
