import { Stack, Typography } from '@mui/material'
import Button from 'components/button'
import { useTheme } from 'styled-components'
import React from 'react'
import { StyledHeaderSection } from '../style'


function EBroucherFormHeader({ onClick }) {
    const theme = useTheme();

    return (
        <StyledHeaderSection>
            <Stack spacing={0}>
            <Typography>
                Optional: Add Weblinks
            </Typography>
            <Typography>
                These links will be added as buttons at the top of every brochure.
            </Typography>
            </Stack>
            <Button
                title="Add New Weblink"
                bgColor={theme.colors.green}
                onClick={onClick}
            />
        </StyledHeaderSection>
    );
}


export default EBroucherFormHeader