import _ from "lodash";
import Utils from "helpers/utils";
import Box from "@mui/material/Box";
import Button from "components/button";
import Divider from "@mui/material/Divider";
import { useTheme } from "styled-components";
import HeaderSection from "./header-section";
import Accordion from "components/accordion";
import { useParams } from "react-router-dom";
import FormAlert from "components/form-alert";
import { CommunityApi, FormApi } from "apis";
import { CircularProgress } from "@mui/material";
import MixConstants from "constants/mix-constants";
import React, { useState, useEffect, useCallback } from "react";
import TourStopAccordionHeader from "./tour-stop-accordion-header";
import TourStopAccordionContent from "./tour-stop-accordion-content";
import FileUploadLoaderModal from "components/file_upload_popup-loader";
import { Container, StyledFooter, StyledSpinnerContainer } from "../style";
const min = 1;
const max = 10;

function ManageSelfTourStopsForm({
  expanded,
  checkStatus,
  status,
  closeForm,
  characterLength,
}) {
  const theme = useTheme();
  const [fetching, setFetching] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [saveAndFinish, setSaveAndFinish] = useState(false);

  const getStockImage = (type) => {
    let baseUrl = window.location.origin;
    switch (type) {
      case "Pool":
        return `${baseUrl}/stock/Pool Water.jpg`;
      case "Leasing Center":
        return `${baseUrl}/stock/Leasing Center- Keys.jpg`;
      case "Yoga Studio / Fitness Studio":
        return `${baseUrl}/stock/Yoga-Fitness Studio- Yoga Mat 2.jpg`;
      case "Fitness Center":
        return `${baseUrl}/stock/Fitness Center- Dumbbells.jpg`;
      case "Dog Park":
        return `${baseUrl}/stock/Dog Park- Dog.jpg`;
      case "Playground":
        return `${baseUrl}/stock/Playground- Kids.jpg`;
      case "Game Room":
        return `${baseUrl}/stock/Game Room- Pool Table.jpg`;
      case "Dog Wash":
        return `${baseUrl}/stock/Dog Wash- Dog with Bubbles.jpg`;
      case "Package Locker":
        return `${baseUrl}/stock/Package Locker- Package.jpg`;
      case "Mail Room":
        return `${baseUrl}/stock/Mail Room- Stack of Mail.png`;
      case "Conference Room":
        return `${baseUrl}/stock/Conference Room- Pen and Paper.jpg`;
      case "Business Center / Lounge":
        return `${baseUrl}/stock/Business Center- Keyboard.jpg`;
      case "Clubhouse / Resident Lounge":
        return `${baseUrl}/stock/Clubhouse - Resident Lounge- Person Drinking Coffee.jpg`;
      case "Other":
        return `${baseUrl}/stock/Other- Happy Person.jpg`;
      default:
    }
  };
  function fetchImage(index, type) {
    let url = getStockImage(type);
    FormApi.fetchImageToSetUploadProperties(url).then(
      (response) => {
        let tourStops = [...state.tourStops];
        tourStops[index].images = [response];
        setState({
          ...state,
          tourStops: tourStops,
        });
      },
      (error) => {},
    );
  }

  const changeAmenityType = (value, index) => {
    let tourStops = [...state.tourStops];
    tourStops[index].amenityType = value;
    tourStops[index].amenityDetails = {};
    tourStops[index].name = value;
    tourStops[index].userTourNameChange = false;
    tourStops[index].description = "";
    fetchImage(index, tourStops[index].amenityType);
    if (value === "Mail Room") {
      tourStops[index].description =
        "Residents retrieve their mail in this convenient location.";
    } else if (value === "Playground") {
      tourStops[index].description =
        "Kids and parents alike love the fun and convenience of our on-site play area!";
    } else if (value === "Dog Wash") {
      tourStops[index].description =
        "Keep fido clean at our convenient on-site Dog Wash!";
    }

    setState({
      ...state,
      tourStops: tourStops,
    });
  };
  const generateTourStop = (
    name,
    description,
    directionalText,
    videoLink,
    images,
  ) => {
    return {
      id: _.uniqueId(),
      name: name,
      description: description,
      directionalText: directionalText,
      videoLink: videoLink,
      type: "unit",
      images: images,
      isLocal: true,
      expanded: true,
    };
  };

  const generateAmenityStop = (
    name,
    description,
    directionalText,
    videoLink,
    images,
  ) => {
    return {
      id: _.uniqueId(),
      name: name,
      description: description,
      directionalText: directionalText,
      videoLink: videoLink,
      type: "amenity",
      images: images,
      isLocal: true,
      expanded: true,
      amenityType: "",
      amenityDetails: {},
    };
  };

  const { communityId } = useParams();

  const formAlertCloseButtonClicked = () => {
    setMessage("");
    setMessageType("");
  };

  const setMessageText = (text, type) => {
    setMessage(text);
    setMessageType(type);
    setTimeout(() => {
      formAlertCloseButtonClicked();
    }, 5000);
  };

  const getMappedImages = useCallback((portal_tour) => {
    let gallery = [];
    let data = portal_tour?.portal_tour_stop_galleries;
    if (data.length > 0) {
      gallery = data.map((image) => {
        return {
          id: image?.id,
          type: "image",
          isLocal: false,
          photo: image?.image?.url,
          description: image.description,
        };
      });
    }
    return gallery;
  }, []);

  const getMappedData = useCallback(
    (data) => {
      let tour;
      let tourStop = [];
      if (data) {
        tour = {
          tourId: data?.id,
          startTour: data?.start_tour,
          startTourChanged: false,
          startingPoint: data?.start_tour_point,
          maxTour: data?.max_tour,
          isLocal: false,
        };
        if (data?.portal_tour_stops.length > 0) {
          data.portal_tour_stops.map((portalTourStop) => {
            tourStop.push({
              id: portalTourStop?.id,
              name: portalTourStop?.name,
              description: portalTourStop?.description,
              directionalText: portalTourStop?.direction,
              videoLink: portalTourStop?.video_link,
              amenityType: portalTourStop?.amenity_type
                ? portalTourStop.amenity_type
                : "",
              amenityDetails: portalTourStop?.tour_stop_details
                ? JSON.parse(portalTourStop?.tour_stop_details)
                : {},
              type: portalTourStop?.stop_type,
              images: getMappedImages(portalTourStop),
              isLocal: false,
              expanded: true,
            });
            return [];
          });
        }
      }
      return {
        tour: tour,
        tourStop: tourStop,
      };
    },
    [getMappedImages],
  );
  const userCanProceed = () => {
    let hasError = false;
    return !hasError;
  };

  const fetchingTourStops = useCallback(
    (abortController) => {
      if (expanded && communityId) {
        setFetching(true);
        CommunityApi.fetchTourStops(communityId).then(
          (response) => {
            setFetching(false);
            if (response.success) {
              const { data } = response;
              // const mappedData = getMappedData(data);
              //     setAmenities(data?.community?.plotted_amenities ?? []);
              // setUnits(data?.community?.plotted_units ?? []);
              // setMaxSelfTourUsers(data?.max_self_tour_users ?? "");
              // setStartingPoint(data?.name ?? "");

              setState({
                tourId: data?.id,
                maxTour: data?.max_self_tour_users ?? 1,
                startTour: data?.community?.one_hour_email_text,
                startingPoint: data?.name ?? "",
                tourStops: getMappedTourStops(data?.filtered_tour_stops),
                amenities: data?.community?.plotted_amenities ?? [],
                units: data?.community?.plotted_units ?? [],
              });
            }
          },
          (error) => {
            setFetching(false);
          },
        );
      }
    },
    [expanded, communityId, getMappedData],
  );

  function getMappedImagesForServer(images) {
    return images.map((image) => {
      if (images.length === 1) {
        return {
          id: image.isLocal ? null : image?.id,
          image_description: image?.description ?? null,
          image: image.isLocal ? image?.originalFile : null,
        };
      } else if (images.length > 1 && !image.stockImage) {
        return {
          id: image.isLocal ? null : image?.id,
          image_description: image?.description ?? null,
          image: image.isLocal ? image?.originalFile : null,
        };
      }
      return [];
    });
  }

  function onSubmitButtonClicked() {
    if (userCanProceed()) {
      let tour;
      let tourStops;

      if (state) {
        tour = {
          id: state?.tourId ?? null,
          starting_point: state?.startingPoint ?? "",
          max_self_tour_users: state?.maxTour ?? 0,

          community: {
            id: communityId,
            one_hour_email_text: state?.startTour,
          },
          tour_stops: [],
        };

        if (state.tourStops.length > 0) {
          tourStops = state?.tourStops.map((stop) => {
            return {
              id: stop?.isLocal ? null : stop.id,
              stop_id: !stop?.isLocal ? stop?.stop_id : stop?.id,
              stop_type: stop?.type,
              name: stop?.name,
              description_text: stop?.description,
              directional_text: stop?.directionalText,

              // video_link: stop?.videoLink,
              // type: stop?.type.toLowerCase(),
              // image:
              //   stop?.images.length > 0
              //     ? getMappedImagesForServer(stop?.images)
              //     : null,
              // amenity_type: stop?.amenityType ? stop.amenityType : "",
              // amenity_details: stop?.amenityDetails
              //   ? JSON.stringify(stop.amenityDetails)
              //   : null,
            };
          });

          tour["tour_stops"] = tourStops;
        }
      }

      const data = {
        tour: tour,
        status: "submitted",
      };

      if(checkRequiredFields()) {
        if (communityId && data) {
          const successMessage = "Tour stop details added successfully";
          const errorMessage = "Failed to add tour stop details";
          setSubmitting(true);
          FormApi.saveTourStops(communityId, data).then(
            (response) => {
              setSubmitting(false);
              if (response.success) {
                checkStatus();

                const abortController = new AbortController();
                fetchingTourStops(abortController);
                // const { data } = response;
                // const mappedData = getMappedData(data);
                setMessageText(successMessage, "success");
                // checkStatus();
                // setState({
                //   tourId: mappedData?.tour?.tourId,
                //   maxTour: mappedData?.tour?.maxTour,
                //   startingPoint: mappedData?.tour?.startingPoint,
                //   startTour: mappedData?.tour?.startTour,
                //   tourStops: mappedData?.tourStop,
                // });
              }
            },
            (error) => {
              setSubmitting(false);
              setMessageText(errorMessage, "error");
            },
          );
        }
      }
    }
  }

  const checkRequiredFields = () => {
    if( state?.startTour === "") {
      setSubmitting(false);
      setMessageText("Parking directions field is required!", "error");
      return false;
    } else {
      return true
    }
  }

  function uploadFiles() {
    let tour;
    let tourStop;
    if (state) {
      tour = {
        id: state?.tourId ?? null,
        max_tours: state?.maxTour ?? "",
        start_tour: state?.startTour,
        start_tour_point: state?.startingPoint ?? "",
      };
      if (state.tourStops.length > 0) {
        tourStop = state?.tourStops.map((stop) => {
          return {
            id: stop?.isLocal ? null : stop.id,
            name: stop?.name,
            description: stop?.description,
            direction: stop?.directionalText,
            video_link: stop?.videoLink,
            type: stop?.type.toLowerCase(),
            image:
              stop?.images.length > 0
                ? getMappedImagesForServer(stop?.images)
                : null,
            amenity_type: stop?.amenityType ? stop.amenityType : "",
            amenity_details: stop?.amenityDetails
              ? JSON.stringify(stop.amenityDetails)
              : null,
          };
        });
      }
    }

    const data = {
      tour: tour,
      tourStop: tourStop,
    };

    if (communityId && data) {
      setLoading(true);
      FormApi.saveTourStops(communityId, data, "in_progress").then(
        (response) => {
          setLoading(false);
          setSubmitting(false);
          if (response.success) {
            const { data } = response;
            const mappedData = getMappedData(data);
            checkStatus();
            setState({
              tourId: mappedData?.tour?.tourId,
              maxTour: mappedData?.tour?.maxTour,
              startingPoint: mappedData?.tour?.startingPoint,
              startTour: mappedData?.tour?.startTour,
              tourStops: mappedData?.tourStop,
            });
          }
        },
        (error) => {
          setLoading(false);
        },
      );
    }
  }

  function onSaveAndSubmitButtonClicked() {
    if (userCanProceed()) {
      let tour;
      let tourStops;

      if (state) {
        tour = {
          id: state?.tourId ?? null,
          starting_point: state?.startingPoint ?? "",
          max_self_tour_users: state?.maxTour ?? 0,

          community: {
            id: communityId,
            one_hour_email_text: state?.startTour,
          },
          tour_stops: [],
        };

        if (state.tourStops.length > 0) {
          tourStops = state?.tourStops.map((stop) => {
            return {
              id: stop?.isLocal ? null : stop.id,
              stop_id: !stop?.isLocal ? stop?.stop_id : stop?.id,
              stop_type: stop?.type,
              name: stop?.name,
              description_text: stop?.description,
              directional_text: stop?.directionalText,

              // video_link: stop?.videoLink,
              // type: stop?.type.toLowerCase(),
              // image:
              //   stop?.images.length > 0
              //     ? getMappedImagesForServer(stop?.images)
              //     : null,
              // amenity_type: stop?.amenityType ? stop.amenityType : "",
              // amenity_details: stop?.amenityDetails
              //   ? JSON.stringify(stop.amenityDetails)
              //   : null,
            };
          });

          tour["tour_stops"] = tourStops;
        }
      }

      const data = {
        tour: tour,
        status: "in_progress",
      };

      if (communityId && data) {
        setSaveAndFinish(true);
        FormApi.saveTourStops(communityId, data, "in_progress").then(
          (response) => {
            setSaveAndFinish(false);
            if (response.success) {
              // const { data } = response;
              // const mappedData = getMappedData(data);
              checkStatus();

              const abortController = new AbortController();
              fetchingTourStops(abortController);

              // setState({
              //   tourId: mappedData?.tour?.tourId,
              //   maxTour: mappedData?.tour?.maxTour,
              //   startingPoint: mappedData?.tour?.startingPoint,
              //   startTour: mappedData?.tour?.startTour,
              //   tourStops: mappedData?.tourStop,
              // });
            }
          },
          (error) => {
            setSaveAndFinish(false);
          },
        );
      }
    }
    closeForm();
  }

  const handleChangeSelectedType = (e, index) => {
    let tourStops = [...state.tourStops];
    tourStops[index].type = e.target.value;

    setState({
      ...state,
      tourStops: tourStops,
    });
  };

  const types = ["Amenity", "Unit"];

  const [state, setState] = useState({
    tourStops: [],
  });

  const getMappedTourStops = (tourStops) => {
    return tourStops.map((stop) => {
      let tourStop = generateTourStop("", "", "", "", "", []);
      tourStop.id = stop?.id;
      tourStop.isLocal = false;
      tourStop.type = stop?.stop_type;

      tourStop = {
        ...tourStop,
        ...stop,
        description: stop?.stop_description_text ?? "",
        directionalText: stop?.stop_directional_text ?? "",
      };

      return tourStop;
    });
  };

  const addTourStopButtonClicked = (unit) => {
    let newTourStop = generateTourStop("", "", "", "", "", []);

    newTourStop = {
      ...newTourStop,
      ...unit,
      description: unit?.stop_description_text ?? "",
      directionalText: unit?.stop_directional_text ?? "",
    };

    let tourStops = [...state.tourStops];
    tourStops.push(newTourStop);

    setState({
      ...state,
      tourStops: tourStops,
    });
  };

  const addAmenityButtonClicked = (amenity) => {
    let newTourStop = generateAmenityStop("", "", "", "", "", []);

    newTourStop = {
      ...newTourStop,
      ...amenity,
      description: amenity?.stop_description_text ?? "",
      directionalText: amenity?.stop_directional_text ?? "",
    };

    let tourStops = [...state.tourStops];
    tourStops.push(newTourStop);

    setState({
      ...state,
      tourStops: tourStops,
    });
  };

  const handleTourStopNameChange = (e, index) => {
    let tourStops = [...state.tourStops];
    tourStops[index].userTourNameChange = true;
    tourStops[index].name = e.target.value;
    setState({
      ...state,
      tourStops: tourStops,
    });
  };

  const deleteTourButtonClicked = (index) => {
    let tourStops = [...state.tourStops];

    if (tourStops[index].isLocal) {
      tourStops.splice(index, 1);
    } else {
      if (communityId && tourStops[index].id) {
        const successMessage = "Tour stop deleted successfully";
        const errorMessage = "Failed to delete tour stop";
        setSubmitting(true);
        FormApi.deleteTourStops(
          communityId,
          state.tourId,
          tourStops[index].id,
        ).then(
          (response) => {
            setSubmitting(false);
            if (response.success) {
              checkStatus();
              tourStops.splice(index, 1);
              setMessageText(successMessage, "success");
            }
          },
          (error) => {
            setSubmitting(false);
            setMessageText(errorMessage, "error");
          },
        );
      }
    }

    setState({
      ...state,
      tourStops: tourStops,
    });
  };

  const handleMaxTourChange = (e) => {
    setState({
      ...state,
      maxTour: fetchTourCount(e.target.value)
    });
  };

  const fetchTourCount = (value) => {
    return ( (value > max) ? max :  (value < min) ? min : value )
  }

  const handleStartTourChange = (e) => {
    setState({
      ...state,
      startTourChanged: true,
      startTour: e.target.value,
    });
  };

  const handleDirectionalTextChange = (e, index) => {
    let tourStops = [...state.tourStops];
    tourStops[index].directionalText = e.target.value;

    setState({
      ...state,
      tourStops: tourStops,
    });
  };

  const handleTourDescriptionChange = (e, index) => {
    let tourStops = [...state.tourStops];
    tourStops[index].description = e.target.value;

    setState({
      ...state,
      tourStops: tourStops,
    });
  };

  const handlestartingPointChange = (e, index) => {
    setState({
      ...state,
      startingPoint: e.target.value,
    });
  };

  const handleVideoLinkChange = (e, index) => {
    let tourStops = [...state.tourStops];
    tourStops[index].videoLink = e.target.value;

    setState({
      ...state,
      tourStops: tourStops,
    });
  };

  const handleUploadedFilesForTourStopAtIndex = (index, files) => {
    let tourStops = [...state.tourStops];
    tourStops[index].images = _.union(tourStops[index].images, files);
    setState({
      ...state,
      tourStops: tourStops,
    });
    uploadFiles();
  };

  const handleGalleryImageDelete = (tourIndex, tourStopIndex, file) => {
    let filesList = [...state.tourStops[tourIndex]?.images];
    if (communityId && file.id) {
      const successMessage = "Tour stop gallery deleted successfully";
      const errorMessage = "Failed to delete tour stop gallery image";
      setSubmitting(true);
      FormApi.deleteTourStopGallery(communityId, file.id).then(
        (response) => {
          setSubmitting(false);
          if (response.success) {
            filesList.splice(tourStopIndex, 1);
            handleUpdatedFilesOfTourStopAtIndex(tourIndex, filesList);
            setMessageText(successMessage, "success");
          }
        },
        (error) => {
          setSubmitting(false);
          setMessageText(errorMessage, "error");
        },
      );
    }
  };

  const getRoofTopLaunchDescription = (tourStop) => {
    let description = "";
    if (
      tourStop.amenityDetails?.openingHour &&
      tourStop.amenityDetails?.openingHour
    ) {
      description = `This area is open to all residents (from ${Utils.convert24hrto12hr(
        tourStop.amenityDetails?.openingHour,
      )} to ${Utils.convert24hrto12hr(
        tourStop.amenityDetails?.closingHour,
      )}) as a relaxing extension to their homes.`;
      if (
        tourStop.amenityDetails?.terrace &&
        tourStop.amenityDetails?.terrace === "Yes" &&
        (!tourStop.amenityDetails?.coffeeStation ||
          tourStop.amenityDetails?.coffeeStation !== "Yes")
      ) {
        description = `${description} Enjoy the terrace!`;
      } else if (
        tourStop.amenityDetails?.coffeeStation &&
        tourStop.amenityDetails?.coffeeStation === "Yes" &&
        (!tourStop.amenityDetails?.terrace ||
          tourStop.amenityDetails?.terrace !== "Yes")
      ) {
        description = `${description} Enjoy the coffee station!`;
      } else if (
        tourStop.amenityDetails?.coffeeStation &&
        tourStop.amenityDetails?.coffeeStation === "Yes" &&
        tourStop.amenityDetails?.terrace &&
        tourStop.amenityDetails?.terrace === "Yes"
      ) {
        description = `${description} Enjoy the terrace and coffee station!`;
      }
    }
    return description;
  };

  const getDescriptionFromOptionsSelected = (tourStop) => {
    if (
      tourStop.type === "amenity" &&
      tourStop.amenityType === "Leasing Center"
    ) {
      if (
        tourStop.amenityDetails?.openingHour &&
        tourStop.amenityDetails?.closingHour
      ) {
        return `Our friendly leasing staff is here to help (from ${Utils.convert24hrto12hr(
          tourStop.amenityDetails?.openingHour,
        )} to ${Utils.convert24hrto12hr(
          tourStop.amenityDetails?.closingHour,
        )})!`;
      }
    }
    if (tourStop.type === "amenity" && tourStop.amenityType === "Mail Room") {
      return "Residents retrieve their mail in this convenient location.";
    }
    if (
      tourStop.type === "amenity" &&
      tourStop.amenityType === "Fitness Center"
    ) {
      if (
        tourStop.amenityDetails?.openingHour &&
        tourStop.amenityDetails?.closingHour
      ) {
        return `Sweat it out! The fitness center (from ${Utils.convert24hrto12hr(
          tourStop.amenityDetails?.openingHour,
        )} to ${Utils.convert24hrto12hr(
          tourStop.amenityDetails?.closingHour,
        )}) (and) features ${
          tourStop.amenityDetails?.yogaText
            ? tourStop.amenityDetails?.yogaText
            : ""
        } professional equipment.`;
      }
    }
    if (
      tourStop.type === "amenity" &&
      tourStop.amenityType === "Yoga Studio / Fitness Studio"
    ) {
      if (
        tourStop.amenityDetails?.openingHour &&
        tourStop.amenityDetails?.closingHour
      ) {
        return `Find your zen at the Yoga / Fitness Studio (is open from ${Utils.convert24hrto12hr(
          tourStop.amenityDetails?.openingHour,
        )} to ${Utils.convert24hrto12hr(
          tourStop.amenityDetails?.closingHour,
        )}) ${
          tourStop.amenityDetails?.yogaClasses === "Yes"
            ? "Classes are offered for residents"
            : ""
        }.`;
      }
    }
    if (
      tourStop.type === "amenity" &&
      tourStop.amenityType === "Clubhouse / Resident Lounge"
    ) {
      if (
        tourStop.amenityDetails?.openingHour &&
        tourStop.amenityDetails?.closingHour
      ) {
        return getRoofTopLaunchDescription(tourStop);
      }
    }
    if (tourStop.type === "amenity" && tourStop.amenityType === "Other") {
      return "Another convenient amenity for you to explore! We really have it all.";
    }
    if (
      tourStop.type === "amenity" &&
      tourStop.amenityType === "Business Center / Lounge"
    ) {
      if (
        tourStop.amenityDetails?.openingHour &&
        tourStop.amenityDetails?.closingHour
      ) {
        return `Residents enjoy the convenience of our on-site Business Center(open from ${Utils.convert24hrto12hr(
          tourStop.amenityDetails?.openingHour,
        )} to ${Utils.convert24hrto12hr(
          tourStop.amenityDetails?.closingHour,
        )}).`;
      }
    }
    if (
      tourStop.type === "amenity" &&
      tourStop.amenityType === "Conference Room"
    ) {
      if (
        tourStop.amenityDetails?.openingHour &&
        tourStop.amenityDetails?.closingHour
      ) {
        return `Hunker down! Residents can book our Conference Room (from ${Utils.convert24hrto12hr(
          tourStop.amenityDetails?.openingHour,
        )} to ${Utils.convert24hrto12hr(
          tourStop.amenityDetails?.closingHour,
        )}) for personal use.`;
      }
    }
    if (
      tourStop.type === "amenity" &&
      tourStop.amenityType === "Package Locker"
    ) {
      if (tourStop.amenityDetails?.packageLocker) {
        return `Our ${tourStop.amenityDetails.packageLocker} Package Lockers make it secure and convenient for residents to receive packages.`;
      }
    }
    if (tourStop.type === "amenity" && tourStop.amenityType === "Game Room") {
      if (
        tourStop.amenityDetails?.openingHour &&
        tourStop.amenityDetails?.closingHour
      ) {
        return `Get your game face on! Residents are welcome to enjoy the game room with neighbors and friends (from ${Utils.convert24hrto12hr(
          tourStop.amenityDetails?.openingHour,
        )} to ${Utils.convert24hrto12hr(
          tourStop.amenityDetails?.closingHour,
        )}).`;
      }
    }
    if (tourStop.type === "amenity" && tourStop.amenityType === "Dog Park") {
      if (tourStop.amenityDetails?.petCriteria) {
        return `The convenience of the dog park can’t be beat! If it’s late or you’re short on time, you can still take care of your furry friend. ${
          tourStop.amenityDetails?.petCriteria
            ? "We welcome pets that meet the following criteria: "
            : ""
        } ${tourStop.amenityDetails?.petCriteria}`;
      }
    }
    if (tourStop.type === "amenity" && tourStop.amenityType === "Pool") {
      if (
        tourStop.amenityDetails?.closingHour &&
        tourStop.amenityDetails?.openingHour
      )
        return `Take a dip! (The pool is open ${
          tourStop.amenityDetails?.poolOpening
            ? tourStop.amenityDetails?.poolOpening
            : ""
        } from ${Utils.convert24hrto12hr(
          tourStop.amenityDetails?.openingHour,
        )} to ${Utils.convert24hrto12hr(
          tourStop.amenityDetails?.closingHour,
        )}). ${
          tourStop.amenityDetails?.poolGrills === "Yes"
            ? "Grills are available for residents’ use."
            : ""
        }`;
    }
  };

  const handleUpdateTourStop = (index, tourStop) => {
    let tourStops = [...state.tourStops];
    tourStop.description = getDescriptionFromOptionsSelected(tourStop);
    tourStops[index] = tourStop;

    setState({
      ...state,
      tourStops: tourStops,
    });
  };

  const handleUpdatedFilesOfTourStopAtIndex = (index, files) => {
    let tourStops = [...state.tourStops];
    tourStops[index].images = files;

    setState({
      ...state,
      tourStops: tourStops,
    });
  };

  const renderTourStops = (tourStops) => {
    return (
      tourStops &&
      tourStops.map((tourStop, index) => {
        return (
          <Box key={index} sx={{ marginTop: "1rem" }}>
            <Accordion
              key={index}
              expanded={tourStop.expanded}
              header={
                <TourStopAccordionHeader
                  index={index}
                  expanded={tourStop.expanded}
                  tourStop={tourStop}
                  types={types}
                  onChangeType={handleChangeSelectedType}
                  onClickDelete={() => deleteTourButtonClicked(index)}
                  submitting={submitting}
                  setAmenityType={changeAmenityType}
                  onChangeTourStopName={(e) =>
                    handleTourStopNameChange(e, index)
                  }
                />
              }
              showBorder={false}
            >
              <TourStopAccordionContent
                tourStopIndex={index}
                tourStop={tourStop}
                amenityType={tourStop.amenityType}
                updateTourStop={handleUpdateTourStop}
                updateVideoLink={handleVideoLinkChange}
                updateDirectionalText={handleDirectionalTextChange}
                updateTourDescription={handleTourDescriptionChange}
                onGalleryImageDelete={handleGalleryImageDelete}
                characterLength={characterLength}
                onUploadFiles={(files) =>
                  handleUploadedFilesForTourStopAtIndex(index, files)
                }
                onUpdateFiles={(files) =>
                  handleUpdatedFilesOfTourStopAtIndex(index, files)
                }
              />
            </Accordion>
            <Divider
              sx={{
                marginTop: "1rem",
                bgcolor: "primary.light",
                borderRightWidth: 5,
                height: "3px",
              }}
            />
          </Box>
        );
      })
    );
  };

  useEffect(() => {
    const abortController = new AbortController();
    fetchingTourStops(abortController);

    return () => {
      abortController.abort();
    };
  }, [fetchingTourStops]);

  if (fetching) {
    return (
      <StyledSpinnerContainer>
        <CircularProgress size={20} />
      </StyledSpinnerContainer>
    );
  }
  return (
    <Container>
      <HeaderSection
        maxTour={state.maxTour}
        startTour={state.startTour}
        startTourChanged={state.startTourChanged}
        amenities={state.amenities}
        units={state.units}
        updateMaxTour={handleMaxTourChange}
        updateStartTour={handleStartTourChange}
        startingPoint={state.startingPoint}
        updateStartingPoint={handlestartingPointChange}
        onChangeAmenity={(amenity) => addAmenityButtonClicked(amenity)}
        onChangeUnit={(unit) => addTourStopButtonClicked(unit)}
      />
      <Divider />
      {renderTourStops(state.tourStops)}
      <Divider />
      {message && messageType && (
        <Box>
          <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
          <FormAlert
            message={message}
            type={messageType}
            onClose={() => formAlertCloseButtonClicked()}
          />
        </Box>
      )}
      <StyledFooter>
        {/* {state.tourStops.length > 0 && (
          <>
            <Button
              width={"110px"}
              title='Add Unit-'
              bgColor={theme.colors.green}
              onClick={addTourStopButtonClicked}
            />
            <Button
              width={"110px"}
              title='Add Amenity+'
              bgColor={theme.colors.green}
              onClick={addAmenityButtonClicked}
            />
          </>
        )} */}
        <Button
          bgColor={theme.colors.gray}
          titleColor={theme.colors.white}
          title='Save & Finish Later'
          disabled={saveAndFinish}
          loading={saveAndFinish}
          onClick={onSaveAndSubmitButtonClicked}
        />
        <Button
          bgColor={theme.colors.green}
          title='Submit'
          disabled={
            status !== MixConstants.FormStatus.DEPLOYED ? submitting : true
          }
          loading={submitting}
          onClick={onSubmitButtonClicked}
        />
      </StyledFooter>
      <FileUploadLoaderModal openModal={loading} />
    </Container>
  );
}

export default ManageSelfTourStopsForm;
