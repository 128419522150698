import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Dropdown from "components/dropdown";
import Divider from "@mui/material/Divider";
import TextField from "components/textfield";
import { StyledHeaderSection } from "../style";
import Typography from "@mui/material/Typography";
import MixConstants from "constants/mix-constants";

function HeaderSection({
  startingPoint,
  updateStartingPoint,
  maxTour,
  startTour,
  amenities,
  units,
  updateMaxTour,
  updateStartTour,
  startTourChanged,
  onChangeAmenity,
  onChangeUnit,
}) {
  const handleAmenityDropdownChange = (amenity) => {
    if (amenity) {
      onChangeAmenity(amenity);
    }
  };

  const handleUnitDropdownChange = (unit) => {
    if (unit) {
      onChangeUnit(unit);
    }
  };

  return (
    <Box>
      <Box>
        <Grid item xs={12} sx={{ marginBottom: "1rem" }}>
          <Typography display="inline">
            Please provide specific directions for parking and to where the
            prospects will begin their tours.
          </Typography>{" "}
          <Typography
            display="inline"
            sx={{ fontSize: "1rem", fontStyle: "italic" }}
          >
            (Required)
          </Typography>
        </Grid>
        <TextField
          required
          multiline
          fontColor={startTour || startTourChanged ? "black" : "gray"}
          rows={4}
          sx={{ input: { color: "red" } }}
          placeholder={MixConstants.selfTour.defaultDirection}
          value={startTour}
          onChange={updateStartTour}
        />
        <Grid spacing={2} container alignItems="center" marginTop="0rem">
          <Grid item xs={12} md={6}>
            <Typography>
              What is the initial starting point of your tour?
            </Typography>{" "}
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              value={startingPoint ?? ""}
              onChange={updateStartingPoint}
            />
          </Grid>
        </Grid>
        <Grid spacing={2} container alignItems="center" marginTop="0rem">
          <Grid item xs={12} md={6}>
            <Typography display="inline">
              Maximum No. of on-site tours the property will offer at a given
              time
            </Typography>{" "}
            <Typography
              display="inline"
              sx={{ fontSize: "1rem", fontStyle: "italic" }}
            >
              (Optional)
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              type="number"
              maxLength={4}
              value={maxTour ?? ""}
              onChange={updateMaxTour}
            />
          </Grid>
        </Grid>
      </Box>

      <Divider sx={{ marginTop: "1rem" }} />

      <StyledHeaderSection>
        <Stack spacing={1} marginTop="1rem">
          <Typography>
            Select a Unit or Amenity from the dropdown list to add to your pynwheel tour.
          </Typography>
          <Typography>
            You will be able to add, remove and revise tour stops any time.
          </Typography>
          <Typography>
            (Please note you will not be able to add a stop until you have added
            amenities and data above.)
          </Typography>
        </Stack>
      </StyledHeaderSection>

      <Grid
        container
        spacing={2}
        marginBottom="2rem"
        display="flex"
        justifyContent="center"
      >
        <Grid item xs={12} md={6}>
          <Dropdown
            label={"Select Amenity"}
            options={amenities}
            getOptionLabel={(option) => option?.name}
            onChange={(e, amenity) => handleAmenityDropdownChange(amenity)}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Dropdown
            label={"Select Unit"}
            options={units}
            getOptionLabel={(option) => option?.name}
            onChange={(e, unit) => handleUnitDropdownChange(unit)}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default HeaderSection;
