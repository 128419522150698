import React, { useState } from "react";
import { saveAs } from "file-saver";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ImageViewer from "components/image-viewer";
import { VerticalScrollBar } from "components/scrollbar/style";
import FileGridItem from "components/files-grid-list/file-grid-item";
import ImageFileUploadContainer from "components/image-file-upload-container";
import VerticalFileItem from "./vertical-file-item";
import handleFileSource from "helpers/fileHandler";
import handleFileAdditionalSource from "helpers/fileHandlerAdditional";

function FilesList({
  files,
  onUpdate,
  withAdditionalImage,
  onDeleteImage,
  onDeleteLabelImage,
}) {
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [activePhotoIndex, setActivePhotoIndex] = useState(0);
  const [filesToView, setFilesToView] = useState([]);

  const viewFileButtonClicked = (index) => {
    setIsGalleryOpen(true);
    setActivePhotoIndex(index);
    setFilesToView(files?.map((file) => handleFileSource(file)));
  };

  const viewAdditionalFileButtonClicked = (additionalFiles) => {
    setIsGalleryOpen(true);
    setFilesToView(additionalFiles);
  };

  const removeFileButtonClicked = (index, file) => {
    if (file.isLocal) {
      let filesList = [...files];
      filesList.splice(index, 1);
      onUpdate(filesList);
    } else {
      onDeleteImage(file);
    }
  };

  const removeAdditionalFileButtonClicked = (index, file) => {
    if (file.isLocal) {
      let filesList = [...files];
      filesList[index].additionalImage = null;
      onUpdate(filesList);
    } else {
      onDeleteLabelImage(file, index);
    }
  };

  const handleAdditionalDroppedFiles = (additionalFiles, index) => {
    let filesList = [...files];
    filesList[index].additionalImage = additionalFiles[0];
    onUpdate(filesList);
  };

  const renderFiles = (files) => {
    return (
      files &&
      files.map((file, index) => (
        <Box key={file.id}>
          {!withAdditionalImage ? (
            <VerticalFileItem
              file={file}
              onClickView={() => viewFileButtonClicked(index)}
              onClickDownload={() => saveAs(handleFileSource(file))}
              onClickRemove={() => removeFileButtonClicked(index, file)}
            />
          ) : (
            <Grid container display='flex' alignItems='center'>
              <Grid
                item
                xs={12}
                md={withAdditionalImage && file.additionalImage ? 5.4 : 6}
              >
                <VerticalFileItem
                  file={file}
                  onClickView={() => viewFileButtonClicked(index)}
                  onClickDownload={() => saveAs(handleFileSource(file))}
                  onClickRemove={() => removeFileButtonClicked(index, file)}
                />
              </Grid>
              {withAdditionalImage && (
                <Grid
                  item
                  xs={12}
                  md={file.additionalImage ? 5.4 : 6}
                  sx={{
                    padding: "0.5rem",
                  }}
                >
                  <ImageFileUploadContainer
                    onFileDrop={(files) =>
                      handleAdditionalDroppedFiles(files, index)
                    }
                    helperText='.png or .jpg labeled image'
                    validationEnabled={false}
                  />
                </Grid>
              )}

              {file.additionalImage && withAdditionalImage && (
                <Grid item xs={12} md={1}>
                  <FileGridItem
                    file={{ ...file.additionalImage, type: "image" }}
                    onClickView={() =>
                      viewAdditionalFileButtonClicked(
                        file.additionalImage?.url
                          ? [file.additionalImage.url]
                          : [file.additionalImage.photo],
                      )
                    }
                    onClickDownload={() =>
                      saveAs(handleFileAdditionalSource(file))
                    }
                    onClickRemove={() =>
                      removeAdditionalFileButtonClicked(index, file)
                    }
                  />
                </Grid>
              )}
            </Grid>
          )}
        </Box>
      ))
    );
  };

  return (
    <Box>
      <VerticalScrollBar>{renderFiles(files)}</VerticalScrollBar>
      <ImageViewer
        show={isGalleryOpen}
        photos={filesToView}
        activePhotoIndex={activePhotoIndex}
        onClose={() => setIsGalleryOpen(false)}
      />
    </Box>
  );
}

export default FilesList;
