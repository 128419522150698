import LoggedInUserHelper from "helpers/loggedin-user-helper";
import loginConstants from "../constants/login-constants";
import updateStoreConstant from "../constants/update_store_constants";


let user = LoggedInUserHelper.user();
let accessToken = LoggedInUserHelper.token();

const initialState =
  accessToken && user
    ? { isLoggedIn: true, user }
    : { isLoggedIn: false, user: null };

export default function loginReducer(state = initialState, action) {
  switch (action.type) {
    case loginConstants.LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true,
      };
    case updateStoreConstant.UPDATE_COMPANY_STATUS:
      return {
        ...state,
        user: {
          ...state.user,
          company_details: action.user,
        },
      };
    case loginConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        isLoggedIn: true,
        user: action.user,
      };
    case loginConstants.LOGIN_FAILURE:
      return {
        ...state,
        loggingIn: false,
        isLoggedIn: false,
        user: null,
      };
    case loginConstants.LOGIN_CLEAR:
      return initialState;
    default:
      return state;
  }
}
