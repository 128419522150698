import Box from "@mui/material/Box";
import styled from "styled-components";
import { device } from "helpers/device";
import Typography from "@mui/material/Typography";

export const CircularProgressContainer = styled(Box)`
  width: ${({ size }) => `${size / 2}rem`};
  height: ${({ size }) => `${size / 2}rem`};
  margin-right: 1rem;

  @media ${device.mobileL} {
    margin-bottom: 1rem;
  }
`;

export const StyledSubTitleName = styled(Typography)`
  font-size: 0.875rem;
  font-weight: 400;
`;

export const StyledSubTitleValue = styled(Typography)`
  font-size: 0.875rem;
  font-weight: 500;
`;

export const KeyValueTextsBox = styled(Box)`
  display: flex;

  @media ${device.mobileM} {
    justify-content: center;
    margin-top: 0.5rem;
  }
`;
