import React from "react";
import Box from "@mui/material/Box";
import Button from "components/button";
import Checkbox from "components/checkbox";
import TextField from "components/textfield";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import LatchInviteModal from "./latch_invite-modal.js";
import { useTheme } from "styled-components";

function LatchLock({ state, setState, community, company }) {
  const theme = useTheme();
  const email = "integrations@latch.com";

  const handleChangeBuildingName = (event) => {
    setState((prevState) => ({
      ...prevState,
      latchPropertyName: event.target.value,
      latchCheckBoxOptions: prevState.latchCheckBoxOptions.map((option, index) => {
        if (index === 0) {
          return { ...option, checked: !!event.target.value };
        }
        return option;
      }),
    }));
  };

  const handleLatchOptionsChange = (name, value, index) => {
    let options = [...state.latchCheckBoxOptions];
    options[index].checked = value;

    setState({
      ...state,
      latchCheckBoxOptions: options,
    });
  };

  const setLatchEmailModalOpen = () => {
    setState({
      ...state,
      latchEmailModalOpen: true,
    });
  };

  const setLatchEmailModalClose = () => {
    setState({
      ...state,
      latchEmailModalOpen: false,
    });
  };

  return (
    <Box sx={{ paddingTop: "0.6rem" }}>
      <Grid sx={{ marginBottom: "2rem", marginLeft: "2rem" }}>
        <Typography>
          All sections must be completed for Secure Access Settings to be
          submitted
        </Typography>
      </Grid>

      {/* ---------------------------------------------- */}

      <Grid sx={{ marginLeft: "2rem" }}>
        <Checkbox
          label={"Provide the building name as it appears in LATCH"}
          checked={state.latchCheckBoxOptions[0].checked}
          onChange={(e) =>
            handleLatchOptionsChange(e.target.name, e.target.checked, 0)
          }
        />
        <Grid
          item
          xs={12}
          md={6}
          sx={{ marginBottom: "1.3rem", marginLeft: "2rem" }}
        >
          <TextField
            required
            label="Building Name (exactly how it is entered in Latch)"
            value={state.latchPropertyName}
            onChange={handleChangeBuildingName}
          />
        </Grid>
      </Grid>

      {/* ---------------------------------------------- */}

      <Grid sx={{ marginLeft: "2rem" }}>
        <Checkbox
          label={"Submit integration request"}
          checked={state.latchCheckBoxOptions[1].checked}
          onChange={(e) =>
            handleLatchOptionsChange(e.target.name, e.target.checked, 1)
          }
        />
        <Grid sx={{ marginLeft: "2rem" }}>
          <Typography>
            Clicking on this button will send an email to LATCH requesting that
            they enable Pynwheel as an OpenKit partner for your property.
          </Typography>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ marginBottom: "1.3rem", marginTop: "1.3rem" }}
          >
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  onClick={setLatchEmailModalOpen}
                  bgColor={theme.colors.green}
                  title={
                    <>
                      <Typography variant="body1">
                        Submit Integration Request &nbsp;
                      </Typography>
                      <Tooltip
                        title={
                          <Typography variant="body1">
                            Clicking on this button will send an email to Latch
                            requesting that they enable Pynwheel as an OpenKit
                            partner for your property.
                          </Typography>
                        }
                        arrow
                        placement="top"
                      >
                        <InfoIcon fontSize="small" />
                      </Tooltip>
                    </>
                  }
                  disabled={false}
                />
              </Grid>
              <Grid item>
                {state?.latchEmailModalOpen && (
                  <LatchInviteModal
                    isOpen={state?.latchEmailModalOpen}
                    onClose={setLatchEmailModalClose}
                    state={state}
                    setState={setState}
                    community={community}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* ---------------------------------------------- */}

      <Grid sx={{ marginLeft: "2rem" }}>
        <Checkbox
          label="Add Pynwheel to ALL doors in LATCH mission control"
          checked={state.latchCheckBoxOptions[2].checked}
          onChange={(e) =>
            handleLatchOptionsChange(e.target.name, e.target.checked, 2)
          }
        />

        <Grid sx={{ marginLeft: "2rem", marginBottom: "1rem" }}>
          <Typography>
            Please check this off when it is 100% complete{" "}
            <strong>
              <span style={{ color: "red" }}>IMPORTANT!!</span> In order for
              Pynwheel to work properly, Pynwheel needs to be added to all
              common areas, exterior doors and{" "}
              <span style={{ color: "red" }}>every unit door</span>, not just
              model unit and/or vacant unit doors.
            </strong>{" "}
            after Latch has confirmed that Pynwheel has been enabled for your
            property, you will need to add Pynwheel to all doors.
          </Typography>

          <Grid container alignItems="center" sx={{ marginTop: "1rem" }}>
            <Typography variant="body1" sx={{ marginRight: "0.5rem" }}>
              How do I do this?
            </Typography>
            <Tooltip
              title={
                <Typography variant="body1">
                  {" "}
                  Please navigate to the door page in Latch Mission Control,
                  click on “Edit,” select “Pynwheel” as the partner you want to
                  grant access to this door, and click “Save” to save the
                  configuration. If you need help with this process, please
                  email &nbsp;
                  <Link
                    href={`mailto:${email}`}
                    color="#fff"
                    underline="always"
                  >
                    {email}
                  </Link>
                  .
                </Typography>
              }
              arrow
              placement="top"
            >
              <InfoIcon fontSize="small" />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default LatchLock;
