import React, { useState } from "react";
import IgloohomeV2 from "./igloohome-v2";
import IgloohomeV3 from "./igloohome-v3";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const versionOptions = [
  { value: "igloohome", label: "Igloohome", component: IgloohomeV2 },
  { value: "iglooworks", label: "Iglooworks", component: IgloohomeV3 },
];

function IglooLock({ state, setState, removeAccount, communityId, closeForm }) {
  const { iglooVersion } = state;
  const [selectedVersion, setSelectedVersion] = useState(iglooVersion);

  const handleVersionChange = (event) => {
    const newVersion = event.target.value;
    setSelectedVersion(newVersion);
    setState((prevState) => ({
      ...prevState,
      iglooVersion: newVersion,
    }));
  };

  const SelectedComponent = versionOptions.find(
    (option) => option.value === selectedVersion
  ).component;

  return (
    <div>
      <Box sx={{ marginLeft: "2rem", width: "100%" }}>
        <Typography>
          Please note only one Igloo version may be selected per community.
        </Typography>
        <FormControl component="fieldset" sx={{ marginTop: "1rem" }}>
          <FormGroup>
            {versionOptions.map((option) => (
              <React.Fragment key={option.value}>
                <FormControlLabel
                  value={option.value}
                  control={
                    <Radio
                      checked={selectedVersion === option.value}
                      onChange={handleVersionChange}
                    />
                  }
                  label={option.label}
                />
                {selectedVersion === option.value && (
                  <Box sx={{ marginTop: "1rem" }}>
                    <SelectedComponent
                      state={state}
                      setState={setState}
                      removeAccount={removeAccount}
                      communityId={communityId}
                      closeForm={closeForm}
                    />
                  </Box>
                )}
              </React.Fragment>
            ))}
          </FormGroup>
        </FormControl>
      </Box>
    </div>
  );
}

export default IglooLock;
