import React from "react";
import _ from "lodash";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "components/textfield";
import Typography from "@mui/material/Typography";

function TourHoursSection({ type, days, setDays }) {

  const setAllDaysStartTime = (time) => {
    let updatedDays = days.map((day) => {
      let updatedDay = day;
      updatedDay.startTime = time;
      return updatedDay;
    });
    setDays(updatedDays);
  };

  const setAllDaysEndTime = (time) => {
    let updatedDays = days.map((day) => {
      let updatedDay = day;
      updatedDay.endTime = time;
      return updatedDay;
    });
    setDays(updatedDays);
  };

  const setStartTimeOfDay = (day, time) => {
    let daysList = [...days];
    const index = _.findIndex(daysList, day);
    daysList[index].startTime = time;
    daysList[0].startTime = "";
    setDays(daysList);
  };

  const setEndTimeOfDay = (day, time) => {
    let daysList = [...days];
    const index = _.findIndex(daysList, day);
    daysList[index].endTime = time;
    daysList[0].endTime = "";
    setDays(daysList);
  };

  const handleStartTimeChange = (e, day) => {
    if (day.name === "Everyday") {
      setAllDaysStartTime(e.target.value);
    } else {
      setStartTimeOfDay(day, e.target.value);
    }
  };

  const handleEndTimeChange = (e, day) => {
    if (day.name === "Everyday") {
      setAllDaysEndTime(e.target.value);
    } else {
      setEndTimeOfDay(day, e.target.value);
    }
  };

  const renderDays = () => {
    return days.map((day, index) => {
      return (
        <Grid key={index} container marginTop='1.5rem'>
          <Grid
            item
            xs={12}
            md={3}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography>{day?.name}</Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField
                type='time'
                value={day?.startTime}
                onChange={(e) => handleStartTimeChange(e, day)}
              />
              <Typography sx={{ marginX: "1rem" }}> — </Typography>
              <TextField
                type='time'
                value={day?.endTime}
                onChange={(e) => handleEndTimeChange(e, day)}
              />
            </Box>
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <Box
      sx={{ marginTop: type === "guided" ? "1rem" : "0", marginBottom: "1rem" }}
    >
      <Stack>
        <Typography
          sx={{ fontSize: "1.1rem", fontWeight: 500, marginBottom: "0.3rem" }}
        >
          Visiting Hours - {type === "self" ? "App" : "Person"} Guided Tour
        </Typography>
        <Typography>
          Please add visiting hours below for when you offer{" "}
          {type === "self" ? "app" : "person"} guided tours for each day of the week
        </Typography>
      </Stack>

      {renderDays()}
    </Box>
  );
}

export default TourHoursSection;
