import React from "react";
import ReactBnbGallery from "react-bnb-gallery";
import "react-bnb-gallery/dist/style.css";

function ImageViewer({ show, photos, activePhotoIndex = 0, onClose }) {
  // const imageFiles = (files) => {
  //   let images = [];
  //   if (files?.length > 0) {
  //     files?.forEach((file) => {
  //       if (
  //         file?.type === "image" ||
  //         file?.type === "photo" ||
  //         file?.file_type === "image" ||
  //         file?.file_type === "photo"
  //       ) {
  //         images.push(file);
  //       }
  //     });
  //   }
  //   return images;
  // };

  return (
    <ReactBnbGallery
      show={show}
      photos={photos}
      activePhotoIndex={activePhotoIndex}
      onClose={onClose}
    />
  );
}

export default ImageViewer;
