import messageConstants from "../constants/message-constants";

const messageActions = {
  setMessage,
  clearMessage,
};

/**
 *  Set Message
 */

function setMessage(message) {
  return { type: messageConstants.SET_MESSAGE, message };
}

/**
 *  Clear Login
 */

function clearMessage() {
  return { type: messageConstants.SET_MESSAGE };
}

export default messageActions;
