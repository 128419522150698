import axios from "axios";
import { FormApi } from "apis";
import Box from "@mui/material/Box";
import config from "apis/api-config";
import Grid from "@mui/material/Grid";
import { Divider } from "@mui/material";
import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Dropdown from "components/dropdown";
import Tooltip from "@mui/material/Tooltip";
import TextField from "components/textfield";
import { UpArrowImage } from "assests/images";
import { authHeader } from "helpers/api-header";
import { StyledBox, StyledTitle } from "./style";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import ImagesListPreview from "./images-list-preview";
import { amenityTypes } from "../create-amenity-modal";
import FilesGridList from "components/files-grid-list";
import CircularProgress from "@mui/material/CircularProgress";
import ImageFileUploadContainer from "components/image-file-upload-container";
import FilesListWithNameDescription from "components/files-list-with-name-description";

function AmenityListItem({
  communityId,
  index,
  amenity,
  onDelete,
  selfTourEnabled,
}) {
  const spacing = 2;

  const [, setUpdate] = useState(false);
  const [state, setState] = useState({
    amenityImagesExpanded: false,
    otherImagesExpanded: false,
  });

  const amenityImagesExpandButtonClicked = () => {
    setState({
      ...state,
      amenityImagesExpanded: !state.amenityImagesExpanded,
    });
  };

  const otherImagesExpandButtonClicked = () => {
    setState({
      ...state,
      otherImagesExpanded: !state.otherImagesExpanded,
    });
  };

  const handleAmenityNameChange = (e) => {
    amenity.name = e.target.value;
  };

  const handleAmenityDescriptionChange = (e) => {
    amenity.description = e.target.value;
  };

  const handleAmenityTypeChange = (amenityType) => {
    amenity.amenity_type = amenityType;
  };

  const handleAmenityTourUrlChange = (e) => {
    amenity.video_link = e.target.value;
  };

  const deleteAmenityButtonClicked = () => {
    amenity.updating = true;
    onDelete(amenity, index);
  };

  const handleOptionalImageFileNameChange = (e, i) => {
    amenity.amenity_galleries[i].name = e.target.value;
  };

  const handleOptionalImageFileDescriptionChange = (e, i) => {
    amenity.amenity_galleries[i].description = e.target.value;
  };

  const handlePrimaryImage = (images) => {
    if (images && images.length > 0) {
      const image = images[0];

      const file = {
        ...image,
        image: { url: image.photo },
        uploading: true,
        uploadedOn: new Date(),
        progress: 0.0,
        uploaded: false,
        failed: false,
        failedErrorMessage: "",
        fulfilled: false,
        isLocal: false,
      };

      amenity.amenity_image = file;
      const isPrimary = true;

      update();

      setState({
        ...state,
        amenityImagesExpanded: true,
      });

      setTimeout(() => {
        uploadFile(file, 0, isPrimary);
      }, 500);
    }
  };

  const handleAdditionalImages = (images) => {
    if (images && images.length > 0) {
      let imagesList = [];

      images.forEach((image, index) => {
        const file = {
          ...image,
          image: { url: image.photo },
          uploading: true,
          uploadedOn: new Date(),
          progress: 0.0,
          uploaded: false,
          failed: false,
          failedErrorMessage: "",
          fulfilled: false,
          isLocal: false,
        };

        imagesList.push(file);
        const isPrimary = false;

        setTimeout(() => {
          uploadFile(file, index, isPrimary);
        }, 500);
      });

      imagesList = [...imagesList, ...amenity?.amenity_galleries];
      amenity.amenity_galleries = imagesList;

      update();

      setState({
        ...state,
        otherImagesExpanded: true,
      });
    }
  };

  const retryUploadFile = (file, index, isPrimary) => {
    file.uploading = true;
    file.uploadedOn = new Date();
    file.progress = 0.0;
    file.uploaded = false;
    file.failed = false;
    file.failedErrorMessage = "";
    file.fulfilled = false;
    file.isLocal = false;

    uploadFile(file, index, isPrimary);
  };

  const uploadFile = (fileObj, arrayIndex, isPrimary) => {
    const header = Object.assign(authHeader(), {
      accept: "application/json",
      "Content-Type": "application/json",
    });
    const formData = new FormData();
    formData.append("id", amenity.id);
    formData.append("file", fileObj.originalFile, fileObj.originalFile.name);

    axios({
      baseURL: `${config.baseURL}`,
      url: isPrimary
        ? `/api/v2/communities/${communityId}/amenities/${amenity?.id}/update_amenity_media`
        : `/api/v2/communities/${communityId}/amenities/${amenity?.id}/amenity_galleries`,
      headers: header,
      method: isPrimary ? "put" : "post",
      data: formData,
      onUploadProgress: (progress) => {
        const { loaded, total } = progress;
        const percentageProgress = ((loaded / total) * 100).toFixed(2);
        fileObj.progress = percentageProgress;
        updateFileToUploadObject(isPrimary, fileObj, null, arrayIndex);
      },
    })
      .then(function (response) {
        fileObj.fulfilled = true;

        if (response && response.error) {
          if (fileObj.progress === "100.00") {
            fileObj.failed = false;
            fileObj.uploaded = true;
            fileObj.uploading = false;
          } else {
            fileObj.failedErrorMessage = response.error;
            fileObj.uploading = false;
            fileObj.failed = true;
          }
        }
        if (response && response.data && response.data.data) {
          fileObj.uploaded = true;
          fileObj.uploading = false;

          updateFileToUploadObject(
            isPrimary,
            fileObj,
            response.data.data,
            arrayIndex,
          );
        } else {
          if (fileObj.progress === "100.00") {
            fileObj.failed = false;
            fileObj.uploaded = true;
            fileObj.uploading = false;
          }
        }
        updateFileToUploadObject(isPrimary, fileObj, null, arrayIndex);
      })
      .catch(function (error) {
        fileObj.fulfilled = true;
        fileObj.uploading = false;

        if (fileObj.progress === "100.00") {
          fileObj.failed = true;
          fileObj.uploaded = true;
        } else {
          fileObj.failedErrorMessage = error;
          fileObj.failed = true;
        }
        updateFileToUploadObject(isPrimary, fileObj, null, arrayIndex);
      });
  };

  const updateFileToUploadObject = (
    isPrimary,
    fileObj,
    uploadedFile,
    arrayIndex,
  ) => {
    if (isPrimary) {
      amenity.amenity_image = uploadedFile?.image
        ? uploadedFile?.image
        : fileObj;
    } else {
      if (uploadedFile) {
        amenity.amenity_galleries[arrayIndex].image = uploadedFile?.image
          ? uploadedFile?.image
          : fileObj?.image;
        amenity.amenity_galleries[arrayIndex].id = uploadedFile?.id
          ? uploadedFile?.id
          : fileObj?.id;
      }
    }

    update();
  };

  const update = () => {
    setUpdate((prevState) => ({
      update: !prevState.update,
    }));
  };

  const handleAmenityPrimaryImageDelete = () => {
    amenity.updating = true;
    update();

    FormApi.deleteAmenityImage(communityId, amenity.id).then(
      (response) => {
        amenity.updating = false;

        if (response?.success) {
          amenity.amenity_image = null;
        }
        update();
      },
      (error) => {
        amenity.updating = false;
        update();
      },
    );
  };

  const handleAmenityAdditionalImageDelete = (file, index) => {
    amenity.updating = true;
    update();

    FormApi.deleteAmenityGalleryImage(communityId, amenity.id, file.id).then(
      (response) => {
        amenity.updating = false;

        if (response?.success) {
          let galleries = [...amenity.amenity_galleries];
          galleries.splice(index, 1);
          amenity.amenity_galleries = galleries;
        }
        update();
      },
      (error) => {
        amenity.updating = false;
        update();
      },
    );
  };

  const renderAmenityImagesView = () => {
    return amenity?.amenity_image && state.amenityImagesExpanded ? (
      <Box>
        <StyledBox>
          <Box />
          <IconButton
            sx={{
              width: "2rem",
              height: "2rem",
            }}
            onClick={() => amenityImagesExpandButtonClicked()}
          >
            <img src={UpArrowImage} alt='icon' />
          </IconButton>
        </StyledBox>

        <FilesGridList
          files={[amenity?.amenity_image]}
          disabled={amenity.updating}
          deleteImage={(file, index) => handleAmenityPrimaryImageDelete(file)}
        />
      </Box>
    ) : amenity?.amenity_image && !state.amenityImagesExpanded ? (
      <ImagesListPreview
        images={[amenity?.amenity_image]}
        onClickExpand={() => amenityImagesExpandButtonClicked()}
      />
    ) : null;
  };

  const renderOtherImagesView = () => {
    return amenity?.amenity_galleries?.length > 0 &&
      state.otherImagesExpanded ? (
      <Box>
        <StyledBox>
          <Box />
          <IconButton
            sx={{ width: "2rem", height: "2rem" }}
            onClick={() => otherImagesExpandButtonClicked()}
          >
            <img src={UpArrowImage} alt='icon' />
          </IconButton>
        </StyledBox>

        <FilesListWithNameDescription
          files={amenity?.amenity_galleries}
          disabled={amenity.updating}
          isDetailAvailable={selfTourEnabled}
          onRetry={(file, index) => retryUploadFile(file, index, false)}
          onUpdate={(files) => {
            amenity.amenity_galleries = files;
            update();
          }}
          deleteImage={(file, index) =>
            handleAmenityAdditionalImageDelete(file, index)
          }
          onNameChange={(e, index) =>
            handleOptionalImageFileNameChange(e, index)
          }
          onDescriptionChange={(e, index) =>
            handleOptionalImageFileDescriptionChange(e, index)
          }
        />
      </Box>
    ) : amenity?.amenity_galleries?.length > 0 && !state.otherImagesExpanded ? (
      <ImagesListPreview
        images={amenity?.amenity_galleries}
        onClickExpand={() => otherImagesExpandButtonClicked()}
      />
    ) : null;
  };

  return (
    <Box>
      <Grid spacing={spacing} container paddingTop={index !== 0 && "0.6rem"}>
        <Grid item xs={12} md={4}>
          <StyledTitle>Amenity Name & Type</StyledTitle>
        </Grid>
        <Grid item xs={12} md={4}>
          <Stack direction='row' spacing={spacing}>
            <StyledTitle>Primary Amenity Image</StyledTitle>
          </Stack>
        </Grid>
      </Grid>

      <Grid spacing={spacing} container sx={{ marginTop: "10px" }}>
        <Grid item xs={12} md={4}>
          <Stack direction='row' spacing={1}>
            {amenity?.updating ? (
              <CircularProgress
                sx={{ marginRight: "12px", marginTop: "12px" }}
                size={20}
              />
            ) : (
              <Tooltip title='Remove Amenity'>
                <IconButton
                  size='small'
                  onClick={() => deleteAmenityButtonClicked()}
                >
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            )}
            <TextField
              required
              label='Name'
              defaultValue={amenity?.name ?? ""}
              onChange={handleAmenityNameChange}
            />
          </Stack>

          <Box sx={{ marginLeft: "38px", marginTop: "15px" }}>
            <Dropdown
              label={"Select Amenity Type"}
              options={amenityTypes}
              defaultValue={amenity?.amenity_type ?? ""}
              onChange={(e, amenityType) =>
                handleAmenityTypeChange(amenityType)
              }
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <ImageFileUploadContainer
            padding='0.5rem'
            helperText='.png or .jpg atleast 2000px'
            onFileDrop={handlePrimaryImage}
            maxFiles={1}
          />
          <Box paddingY='0.5rem'>{renderAmenityImagesView()}</Box>
        </Grid>
      </Grid>

      <Grid
        spacing={spacing}
        container
        sx={{ marginBottom: "2rem", marginTop: "0.5rem" }}
      >
        <Grid item xs={12} md={4}>
          <StyledTitle>Video or 3D tour link (Optional)</StyledTitle>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            label='Tour Link'
            maxLength={500}
            defaultValue={amenity?.video_link ?? ""}
            onChange={handleAmenityTourUrlChange}
          />
        </Grid>
      </Grid>

      {selfTourEnabled ? (
        <Box sx={{ marginTop: "2rem" }}>
          <Typography sx={{ marginBottom: "1rem", display: "inline" }}>
            Short description (What would you tell a prospect about this stop on
            their tour? What are its best selling points or most important
            details?)
          </Typography>{" "}
          <Typography sx={{ display: "inline" }}>
            <strong>140 characters allowed</strong>.
          </Typography>
          <TextField
            maxLength={140}
            multiline
            rows={4}
            defaultValue={amenity?.description ?? ""}
            onChange={handleAmenityDescriptionChange}
          />
        </Box>
      ) : null}

      <Box sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
        <StyledTitle sx={{ marginBottom: "0.5rem" }}>
          Optional Additional Images
        </StyledTitle>

        <ImageFileUploadContainer
          padding='0.5rem'
          helperText='.png or .jpg atleast 2000px'
          onFileDrop={handleAdditionalImages}
          maxFiles={15}
        />
        <Box paddingY='0.5rem'>{renderOtherImagesView()}</Box>
      </Box>

      <Divider sx={{ height: "2px", background: "#42a5f5" }} />
    </Box>
  );
}

export default AmenityListItem;
