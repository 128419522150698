import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "components/textfield";

function KnockCRM({ credentials, onChange }) {
  const [apiKey, setApiKey] = useState(credentials?.apiKey ?? "");
  const [communityId, setCommunityId] = useState(
    credentials?.communityId ?? "",
  );

  const [companyId, setCompanyId] = useState(
    credentials?.companyId ?? "",
  );

  const gridItemStyle = { paddingBottom: "1.3rem" };

  const handleTextFieldsChange = (e) => {
    const { name, value } = e.target;

    const credentials = {
      communityId: communityId,
      companyId: companyId,
    };

    if (name === "communityId") {
      setCommunityId(value);
      credentials.communityId = value;
    } else if (name === "companyId") {
      setCompanyId(value);
      credentials.companyId = value;
    }

    onChange(credentials);
  };

  return (
    <Grid container paddingBottom='1rem'>
      {/* ----------- */}

      <Grid item xs={3}></Grid>
      <Grid item xs={12} md={6} sx={gridItemStyle}>
        <TextField
          required
          name='communityId'
          label='Community ID'
          value={communityId}
          onChange={handleTextFieldsChange}
        />
      </Grid>
      <Grid item xs={3}></Grid>

      {/* ----------- */}

      <Grid item xs={3}></Grid>
      <Grid item xs={12} md={6} sx={gridItemStyle}>
        <TextField
          required
          name='companyId'
          label='Company ID'
          value={companyId}
          onChange={handleTextFieldsChange}
        />
      </Grid>
      <Grid item xs={3}></Grid>
    </Grid>
  );
}

export default KnockCRM;
