import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "components/textfield";

function RealPageCRM({ credentials, onChange }) {
  const [siteId, setSiteId] = useState(credentials?.siteId ?? "");
  const [pmcId, setPmcId] = useState(credentials?.pmcId ?? "");

  const handleTextFieldsChange = (e) => {
    const { name, value } = e.target;

    const credentials = {
      siteId: siteId,
      pmcId: pmcId,
    };

    if (name === "siteId") {
      setSiteId(value);
      credentials.siteId = value;
    } else if (name === "pmcId") {
      setPmcId(value);
      credentials.pmcId = value;
    }

    onChange(credentials);
  };

  return (
    <Grid container paddingBottom='1rem'>
      <Grid item xs={3}></Grid>
      <Grid item xs={12} md={6} sx={{ marginBottom: "1.3rem" }}>
        <TextField
          required
          name='siteId'
          label='Site ID'
          value={siteId}
          onChange={handleTextFieldsChange}
        />
      </Grid>
      <Grid item xs={3}></Grid>

      {/* ----------- */}

      <Grid item xs={3}></Grid>
      <Grid item xs={12} md={6}>
        <TextField
          required
          name='pmcId'
          label='PMC ID'
          value={pmcId}
          onChange={handleTextFieldsChange}
        />
      </Grid>
      <Grid item xs={3}></Grid>
    </Grid>
  );
}

export default RealPageCRM;
