import React, {useState} from "react";
import Box from "@mui/material/Box";
import { StyledCommunitiesList } from "./style";
import Accordion from "components/accordion";

import CommunityAccordion from "./community-accordion";
import AccordionCompanyFormHeader from "../accordion/accordion-company-form-header";
import {CompanyDetailForm} from "../forms";
import MixConstants from "constants/mix-constants";

function CommunitiesList({ communities }) {
  const [accordionStates, setAccordionStates] = useState({});

  const handleAccordionChange = (companyName) => {
    setAccordionStates((prevState) => ({
      ...prevState,
      [companyName]: !prevState[companyName],
    }));
  };
  const renderCommunities = (communities) => {
    if (!communities) {
      return null;
    }

    const groupedCommunities = communities.reduce((groups, community) => {
      const companyName = community.companyName;

      if (!groups[companyName]) {
        groups[companyName] = {
          details: null,
          communities: [],
        };
      }

      if (community?.company) {
        groups[companyName].details = community?.company;
      }
      if(community?.title !== MixConstants.Community.DummyPropertyName) {
        groups[companyName].communities.push(community);
      }

      return groups;
    }, {});

    return (
        <div>
          {Object.keys(groupedCommunities).map((companyName) => (
              <div key={companyName}>
                <Accordion
                    key={groupedCommunities[companyName].details?.id}
                    expanded={accordionStates[companyName] || false}
                    header={
                  <AccordionCompanyFormHeader
                      cId={groupedCommunities[companyName].details?.id}
                      title={companyName}
                      expanded={accordionStates[companyName] || false}
                      onClickExpand={() => handleAccordionChange(companyName)}
                      companySetting={groupedCommunities[companyName].details.company_setting && groupedCommunities[companyName].details.company_setting.company_level_data_import === true ? true : false}
                      company={groupedCommunities[companyName].details}
                  />
                    }
                    >
                  <CompanyDetailForm
                      expanded={accordionStates[companyName] || false}
                      adminViewCompany={groupedCommunities[companyName].details}
                  />
                </Accordion>

                {groupedCommunities[companyName].communities.map((community) => (
                    <Box key={community.id} marginBottom='0.7rem'>
                      <CommunityAccordion community={community} />
                    </Box>

                ))}
              </div>
          ))}
        </div>
    );
  };


  return (
    <StyledCommunitiesList>
      {renderCommunities(communities)}
    </StyledCommunitiesList>
  );
}

export default CommunitiesList;
