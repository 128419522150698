import React, { useState } from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import TextField from "components/textfield";
import Typography from "@mui/material/Typography";

function RealPageSystem({ credentials,company, community, onChange }) {
  const [siteId, setSiteId] = useState(credentials?.siteId ?? "");
  const [pmcId, setPmcId] = useState(credentials?.pmcId ?? "");

  const handleTextFieldsChange = (e) => {
    const { name, value } = e.target;

    const credentials = {
      siteId: siteId,
      pmcId: pmcId,
    };

    if (name === "siteId") {
      setSiteId(value);
      credentials.siteId = value;
    } else if (name === "pmcId") {
      setPmcId(value);
      credentials.pmcId = value;
    }
    
    onChange(credentials);
  };
  const useCompanyLevel = community?.community?.use_company_level_data_settings && company?.data_providers?.includes("realpagesvc")
  const openUserGuide = () => {
    const url =
      "https://drive.google.com/file/d/0B02Gm-JJUcBvcGpqV1NUbERvSmc/view?usp=sharing";
    const win = window.open(url, "_blank");
    win.focus();
  };

  return (
    <Box>
      <Typography sx={{ paddingY: "1rem" }}>
        Please log into RealPage Market Exchange and submit a subscription
        request. For the full instructions from RealPage about how to do this:
        click here{" "}
        <Link onClick={() => openUserGuide()}>
          RealPage Exchange App Partner Marketplace User Guide
        </Link>
      </Typography>

      <Grid container paddingBottom='1rem'>
        <Grid item xs={3}></Grid>
        <Grid item xs={12} md={6} sx={{ marginBottom: "1.3rem" }}>
          <TextField
            required
            name='siteId'
            label='Site ID'
            value={siteId}
            onChange={handleTextFieldsChange}
          />
        </Grid>
        <Grid item xs={3}></Grid>

        {/* ----------- */}

        <Grid item xs={3}></Grid>
        <Grid item xs={12} md={6}>
          {useCompanyLevel ? (null) : (
          <TextField
            name='pmcId'
            label='PMC ID'
            value={pmcId}
            onChange={handleTextFieldsChange}
          />)}
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
    </Box>
  );
}

export default RealPageSystem;
