import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { StyledSpinnerContainer } from 'components/forms/style';
import { CircularProgress } from '@mui/material';

export default function FileUploadLoaderModal({ openModal, closeModal }) {
    return (
        <div>
            <Dialog
                open={openModal}
                onClose={closeModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Uploading selected media please wait..."}
                </DialogTitle>
                <DialogContent>
                <StyledSpinnerContainer>
                    <CircularProgress size={20} />
                </StyledSpinnerContainer>
                </DialogContent>
            </Dialog>
        </div>
    );
}
