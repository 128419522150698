import React, { useState, Fragment } from "react";
import { AuthApi } from "apis";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import { ProfileImage } from "assests/images";
import MenuItem from "@mui/material/MenuItem";
import Persistence from "helpers/persistence";
import { DownArrowImage } from "assests/images";
import IconButton from "@mui/material/IconButton";
import { StyledUsername, StyledArrowBox } from "./style";
import LoggedInUserHelper from "helpers/loggedin-user-helper";

function AccountMenu() {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    logout();
  };

  const logout = () => {
    AuthApi.logout().then(
      (response) => {
        Persistence.removeAll();
        window.location.reload();
      },
      (error) => {
        var errorString = "Failed to logout";
        console.log(errorString);
      },
    );
  };

  return (
    <Fragment>
      <IconButton
        size='large'
        aria-label='account of current user'
        aria-controls='menu-appbar'
        aria-haspopup='true'
        onClick={handleMenu}
        color='inherit'
        disableRipple
      >
        <Avatar alt='User name' src={ProfileImage} />
        <StyledUsername variant='body1' component='label'>
          {LoggedInUserHelper.fullName()}
        </StyledUsername>
        <StyledArrowBox>
          <img src={DownArrowImage} alt='icon' />
        </StyledArrowBox>
      </IconButton>

      <Fragment>
        <Menu
          id='menu-appbar'
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          keepMounted
        >
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Fragment>
    </Fragment>
  );
}

export default AccountMenu;
