import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "components/textfield";
import Stack from "@mui/material/Stack";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Container } from "../../style";

function IgloohomeV3({ state, setState }) {
  const spacing = 2;

  const [showApiKey, setShowApiKey] = useState(false);
  const { iglooworksDepartmentId, iglooworksApiKey } = state;

  const handleDepartmentIdChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      iglooworksDepartmentId: e.target.value,
    }));
  };

  const handleApiKeyChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      iglooworksApiKey: e.target.value,
    }));
  };

  return (
    <Container>
      <Stack spacing={spacing} alignItems="center">
        <Grid container spacing={spacing} direction="column">
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label="API Key"
              value={iglooworksApiKey}
              onChange={handleApiKeyChange}
              type={showApiKey ? "text" : "password"}
              rightIcon={
                showApiKey ? <VisibilityIcon /> : <VisibilityOffIcon />
              }
              onClickRightIcon={() => setShowApiKey(!showApiKey)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label="Department ID"
              value={iglooworksDepartmentId}
              onChange={handleDepartmentIdChange}
            />
          </Grid>
        </Grid>
      </Stack>
    </Container>
  );
}

export default IgloohomeV3;
