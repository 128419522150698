import Box from "@mui/material/Box";
import { device } from "helpers/device";
import Avatar from "@mui/material/Avatar";
import Accordion from "@mui/material/Accordion";
import styled, { css } from "styled-components";
import Typography from "@mui/material/Typography";

export const StyledAccordion = styled(Accordion)`
  box-shadow: none;

  ${({ showborder }) =>
    showborder === "true" &&
    css`
      border: ${({ theme }) => `1px solid ${theme.colors.lightGray}`};
    `}
`;

export const StyledAccordionHeader = styled(Box)`
  display: flex;
  align-items: center;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;


  @media ${device.mobileL} {
    flex-direction: column;
  }
`;

export const StyledAccordionHeaderContent = styled(Box)`
  display: flex;

  @media ${device.mobileS} {
    margin-bottom: 1rem;
  }

  @media ${device.mobileM} {
    flex-direction: column;
    align-items: center;

    h6 {
      width: 100%;
      text-align: center;
    }

    p {
      text-align: center;
    }
  }
`;

export const StyledAccordionHeaderTextContentContainer = styled(Box)`
  @media ${device.mobileL} {
    margin-bottom: 1rem;
    flex-direction: column;
  }
`;

export const StyledAvatar = styled(Avatar)`
  width: 2rem;
  height: 2rem;

  @media ${device.mobileM} {
    margin-bottom: 1rem;
  }
`;

export const StyledStatus = styled(Typography)`
  min-width: 6rem;
  color: ${({ theme }) => theme.colors.status};
  margin-top: 0.4rem;
  margin-left: 0.5rem;
`;
