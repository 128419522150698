import React from "react";
import Box from "@mui/material/Box";
import AccountMenu from "./account-menu";
import Toolbar from "@mui/material/Toolbar";
import { StyledAppBar, StyledLogo } from "./style";
import { PYNLaunchLogoImage } from "assests/images";

function Header() {
  return (
    <Box sx={{ flexGrow: 1, marginBottom: "2rem" }}>
      <StyledAppBar position='static'>
        <Toolbar sx={{ flexGrow: 1 }}>
          <Box sx={{ flexGrow: 1 }}>
            <StyledLogo src={PYNLaunchLogoImage} alt='logo' />
          </Box>
          <AccountMenu />
        </Toolbar>
      </StyledAppBar>
    </Box>
  );
}

export default Header;
