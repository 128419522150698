import React from "react";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { StyledAvatar, StyledName, StyledBox } from "./style";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import handleFileSource from "helpers/fileHandler";

function FileItem({ file, onClickView, onClickDownload, onClickRemove }) {
  return (
    <StyledBox>
      <Stack spacing={2} direction='row' alignItems='center'>
        <StyledAvatar
          alt='file thumbnail'
          variant='rounded'
          src={handleFileSource(file)}
        />
        <StyledName>{file?.name}</StyledName>
      </Stack>
      <Stack direction='row'>
        <Tooltip title='View'>
          <IconButton onClick={onClickView}>
            <VisibilityIcon fontSize='small' />
          </IconButton>
        </Tooltip>

        <Tooltip title='Download'>
          <IconButton onClick={onClickDownload}>
            <FileDownloadIcon fontSize='small' />
          </IconButton>
        </Tooltip>

        <Tooltip title='Remove'>
          <IconButton onClick={onClickRemove}>
            <ClearIcon fontSize='small' />
          </IconButton>
        </Tooltip>
      </Stack>
    </StyledBox>
  );
}

export default FileItem;
