import LoggedInUserHelper from "helpers/loggedin-user-helper";

export function simpleHeader() {
  return {
    "Content-Type": "application/json",
  };
}

export function authHeader() {
  let token = LoggedInUserHelper.token();

  if (token) {
    return {
      Authorization: "Bearer " + token,
    };
  } else {
    return {};
  }
}

export function authHeaderJson() {
  let token = LoggedInUserHelper.token();

  if (token) {
    return {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    };
  } else {
    return {};
  }
}
