import React, { useState } from "react";
import MixConstants from "constants/mix-constants";
import Box from "@mui/material/Box";
import Button from "components/button";
import Grid from "@mui/material/Grid";
import TextField from "components/textfield";
import Stack from "@mui/material/Stack";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import { useTheme } from "styled-components";
import { Container } from "../../style";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function IgloohomeV2({ state, setState, removeAccount, closeForm }) {
  const theme = useTheme();
  const spacing = 2;
  const [showClientSecret, setShowClientSecret] = useState(false);
  const [, setAuthorizationType] = useState("");

  const {
    isClientAuth,
    isAuthCode,
    clientId,
    clientSecret,
    isAuthorizedWithPynwheel,
    homeName,
  } = state;

  const handleLatchOptionChange = (value) => {
    setAuthorizationType(value);

    setState((prevState) => ({
      ...prevState,
      isClientAuth: value === "client_credentials",
      isAuthCode: value === "authorization_code",
    }));
  };

  const handleClientIdChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      clientId: e.target.value,
    }));
  };

  const handleHomeNameChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      homeName: e.target.value,
    }));
  };

  const openRedirectURI = () => {
    window.open(MixConstants.IglooAuthURL, "_blank");
    closeForm();
  };

  const handleClientSecretChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      clientSecret: e.target.value,
    }));
  };

  return (
    <Container sx={{ marginLeft: "1rem" }}>
      <Stack spacing={spacing}>
        <Grid>
          <Typography>
            Provide the home name as it appears in Igloohome
          </Typography>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ marginBottom: "1.3rem", marginTop: "0.5rem" }}
          >
            <TextField
              required
              label="Home Name (exactly how it is entered in Igloohome)"
              value={homeName}
              onChange={handleHomeNameChange}
            />
          </Grid>
        </Grid>

        <Box>
          <FormControlLabel
            value="authorization_code"
            control={
              <Radio
                checked={isAuthCode}
                onChange={() => handleLatchOptionChange("authorization_code")}
              />
            }
            label="Authorization Code Grant"
          />
          <Box sx={{ marginLeft: "2rem" }}>
            <Typography>
              Authorization Code Grant flow provides a secure way Pynwheel to
              obtain access tokens from Igloo, allowing Pynwheel to access locks
              on behalf of properties with their consent.
            </Typography>

            {isAuthCode ? (
              <Box>
                <Typography sx={{ marginTop: "1rem" }}>
                  Please sign into your Igloohome account using the button below
                  to Authorize Pynwheel.
                </Typography>
                {isAuthorizedWithPynwheel ? (
                  <Button
                    bgColor={theme.colors.red}
                    title="Remove Account"
                    onClick={removeAccount}
                  />
                ) : (
                  <Button
                    bgColor={theme.colors.green}
                    title="Authorize Pynwheel"
                    onClick={openRedirectURI}
                  />
                )}
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Box>

        <Box>
          <FormControlLabel
            value="client_credentials"
            control={
              <Radio
                checked={isClientAuth}
                onChange={() => handleLatchOptionChange("client_credentials")}
              />
            }
            label="OAuth Client Credentials"
          />

          <Box sx={{ marginLeft: "2rem" }}>
            {!isClientAuth ? (
              <Typography sx={{ marginBottom: "1rem" }}>
                OAuth can be used if you already have your own Igloohome API
                account and would like to use those credentials.
              </Typography>
            ) : (
              ""
            )}

            {isClientAuth ? (
              <Box>
                <Box>
                  <Typography>
                    OAuth Credentials can be obtained by contacting Igloo.
                  </Typography>
                  <Typography>
                    <strong>Please Note</strong> if using your own credentials,
                    you will be charged by Igloo accordingly for API usage.
                  </Typography>
                </Box>
                <Box sx={{ marginTop: "1rem" }}>
                  <Grid container>
                    <Grid item xs={11} md={6} sx={{ marginBottom: "1rem" }}>
                      <TextField
                        label="Client ID"
                        value={clientId}
                        onChange={(e) => handleClientIdChange(e)}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={11} md={6} sx={{ marginBottom: "1rem" }}>
                      <TextField
                        label="Client Secret"
                        value={clientSecret}
                        onChange={handleClientSecretChange}
                        type={showClientSecret ? "text" : "password"}
                        rightIcon={
                          showClientSecret ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )
                        }
                        onClickRightIcon={() =>
                          setShowClientSecret(!showClientSecret)
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Stack>
    </Container>
  );
}

export default IgloohomeV2;
