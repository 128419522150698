import axios from "axios";
import { FormApi } from "apis";
import Box from "@mui/material/Box";
import config from "apis/api-config";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import TextField from "components/textfield";
import { UpArrowImage } from "assests/images";
import { authHeader } from "helpers/api-header";
import { StyledBox, StyledTitle } from "./style";
import Typography from "@mui/material/Typography";
import ImageViewer from "components/image-viewer";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ImagesListPreview from "./images-list-preview";
import { FloorPlan2D, Amenity1 } from "assests/images";
import FilesGridList from "components/files-grid-list";
import CircularProgress from "@mui/material/CircularProgress";
import ImageFileUploadContainer from "components/image-file-upload-container";
import FilesListWithNameDescription from "components/files-list-with-name-description";

function FloorPlanListItem({
  communityId,
  index,
  plan,
  onDelete,
  selfTourEnabled,
}) {
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [exampleImageToShow, setExampleImageToShow] = useState(null);
  const spacing = 2;

  const [, setUpdate] = useState(false);
  const [state, setState] = useState({
    floorPlanImagesExpanded: false,
    otherImagesExpanded: false,
  });

  const exampleFloorPlanImage = {
    photo: FloorPlan2D,
    name: "Garden Style Community",
    caption: "",
    subcaption: "",
  };

  const examplePhotographyImage = {
    photo: Amenity1,
    name: "Mid-rise or High-rise Building",
    caption: "",
    subcaption: "",
  };

  const exampleButtonClicked = (index) => {
    if (index === 0) {
      setExampleImageToShow(exampleFloorPlanImage);
    } else {
      setExampleImageToShow(examplePhotographyImage);
    }
    setIsGalleryOpen(true);
  };

  const floorPlanImagesExpandButtonClicked = () => {
    setState({
      ...state,
      floorPlanImagesExpanded: !state.floorPlanImagesExpanded,
    });
  };

  const otherImagesExpandButtonClicked = () => {
    setState({
      ...state,
      otherImagesExpanded: !state.otherImagesExpanded,
    });
  };

  const handleFloorplanNameChange = (e) => {
    plan.name = e.target.value;
  };

  const handleFloorplanDescriptionChange = (e) => {
    plan.description = e.target.value;
  };

  const handleFloorplanTourUrlChange = (e) => {
    plan.virtual_tour_url = e.target.value;
  };

  const handleOptionalImageFileNameChange = (e, i) => {
    plan.amenities[i].name = e.target.value;
  };

  const handleOptionalImageFileDescriptionChange = (e, i) => {
    plan.amenities[i].description = e.target.value;
  };

  const handlePrimaryImage = (images) => {
    if (images && images.length > 0) {
      const image = images[0];

      const file = {
        ...image,
        image: { url: image.photo },
        uploading: true,
        uploadedOn: new Date(),
        progress: 0.0,
        uploaded: false,
        failed: false,
        failedErrorMessage: "",
        fulfilled: false,
        isLocal: false,
      };

      plan.floorplan_image = file;
      const isPrimary = true;

      update();

      setState({
        ...state,
        floorPlanImagesExpanded: true,
      });

      setTimeout(() => {
        uploadFile(file, 0, isPrimary);
      }, 500);
    }
  };

  const handleAdditionalImages = (images) => {
    if (images && images.length > 0) {
      let imagesList = [];

      images.forEach((image, index) => {
        const file = {
          ...image,
          image: { url: image.photo },
          uploading: true,
          uploadedOn: new Date(),
          progress: 0.0,
          uploaded: false,
          failed: false,
          failedErrorMessage: "",
          fulfilled: false,
          isLocal: false,
        };

        imagesList.push(file);

        const isPrimary = false;

        setTimeout(() => {
          uploadFile(file, index, isPrimary);
        }, 500);
      });

      imagesList = [...imagesList, ...plan?.amenities];
      plan.amenities = imagesList;

      update();

      setState({
        ...state,
        otherImagesExpanded: true,
      });
    }
  };

  const retryUploadFile = (file, index, isPrimary) => {
    file.uploading = true;
    file.uploadedOn = new Date();
    file.progress = 0.0;
    file.uploaded = false;
    file.failed = false;
    file.failedErrorMessage = "";
    file.fulfilled = false;
    file.isLocal = false;

    uploadFile(file, index, isPrimary);
  };

  const uploadFile = (fileObj, arrayIndex, isPrimary) => {
    const header = Object.assign(authHeader(), {
      accept: "application/json",
      "Content-Type": "application/json",
    });
    const formData = new FormData();
    formData.append("id", plan.id);
    formData.append("file", fileObj.originalFile, fileObj.originalFile.name);

    axios({
      baseURL: `${config.baseURL}`,
      url: isPrimary
        ? `/api/v2/communities/${communityId}/floorplans/${plan?.id}/update_floorplan_media`
        : `/api/v2/communities/${communityId}/floorplans/${plan?.id}/floorplan_amenities`,
      headers: header,
      method: isPrimary ? "put" : "post",
      data: formData,
      onUploadProgress: (progress) => {
        const { loaded, total } = progress;
        const percentageProgress = ((loaded / total) * 100).toFixed(2);
        fileObj.progress = percentageProgress;
        updateFileToUploadObject(isPrimary, fileObj, null, arrayIndex);
      },
    })
      .then(function (response) {
        fileObj.fulfilled = true;

        if (response && response.error) {
          if (fileObj.progress === "100.00") {
            fileObj.failed = false;
            fileObj.uploaded = true;
            fileObj.uploading = false;
          } else {
            fileObj.failedErrorMessage = response.error;
            fileObj.uploading = false;
            fileObj.failed = true;
          }
        }
        if (response && response.data && response.data.data) {
          fileObj.uploaded = true;
          fileObj.uploading = false;

          updateFileToUploadObject(
            isPrimary,
            fileObj,
            response.data.data,
            arrayIndex,
          );
        } else {
          if (fileObj.progress === "100.00") {
            fileObj.failed = false;
            fileObj.uploaded = true;
            fileObj.uploading = false;
          }
        }
        updateFileToUploadObject(isPrimary, fileObj, null, arrayIndex);
      })
      .catch(function (error) {
        fileObj.fulfilled = true;
        fileObj.uploading = false;

        if (fileObj.progress === "100.00") {
          fileObj.failed = true;
          fileObj.uploaded = true;
        } else {
          fileObj.failedErrorMessage = error;
          fileObj.failed = true;
        }
        updateFileToUploadObject(isPrimary, fileObj, null, arrayIndex);
      });
  };

  const updateFileToUploadObject = (
    isPrimary,
    fileObj,
    uploadedFile,
    arrayIndex,
  ) => {
    if (isPrimary) {
      plan.floorplan_image = uploadedFile?.image
        ? uploadedFile?.image
        : fileObj;
    } else {
      if (uploadedFile) {
        plan.amenities[arrayIndex].image = uploadedFile?.image
          ? uploadedFile?.image
          : fileObj?.image;
        plan.amenities[arrayIndex].id = uploadedFile?.id
          ? uploadedFile?.id
          : fileObj?.id;
      }
    }

    update();
  };

  const update = () => {
    setUpdate((prevState) => ({
      update: !prevState.update,
    }));
  };

  const deleteFloorplanButtonClicked = () => {
    plan.updating = true;
    onDelete(plan, index);
  };

  const handleFloorplanPrimaryImageDelete = () => {
    plan.updating = true;
    update();

    FormApi.deleteFloorplanImage(communityId, plan.id).then(
      (response) => {
        plan.updating = false;

        if (response?.success) {
          plan.floorplan_image = null;
        }
        update();
      },
      (error) => {
        plan.updating = false;
        update();
      },
    );
  };

  const handleFloorplanAdditionalImageDelete = (file, index) => {
    plan.updating = true;
    update();

    FormApi.deleteFloorplanGalleryImage(communityId, plan.id, file.id).then(
      (response) => {
        plan.updating = false;

        if (response?.success) {
          let galleries = [...plan.amenities];
          galleries.splice(index, 1);
          plan.amenities = galleries;
        }
        update();
      },
      (error) => {
        plan.updating = false;
        update();
      },
    );
  };

  const renderFloorplanDescriptionView = () => {
    return selfTourEnabled ? (
      <Grid sx={{ marginTop: "2rem" }}>
        <Typography sx={{ marginBottom: "1rem", display: "inline" }}>
          Short description (What would you tell a prospect about this floor
          plan on their tour? What are its best selling points or most important
          details?)
        </Typography>
        <Typography sx={{ display: "inline" }}>
          {" "}
          <strong>
            {plan?.description_text_limit ?? 140} characters allowed
          </strong>
          .
        </Typography>
        <TextField
          maxLength={plan?.description_text_limit ?? 140}
          multiline
          rows={4}
          defaultValue={plan?.description ?? ""}
          onChange={handleFloorplanDescriptionChange}
        />
      </Grid>
    ) : null;
  };

  const renderFloorPlanImagesView = () => {
    return plan?.floorplan_image && state.floorPlanImagesExpanded ? (
      <Box>
        <StyledBox>
          <Box />
          <IconButton
            sx={{
              width: "2rem",
              height: "2rem",
            }}
            onClick={() => floorPlanImagesExpandButtonClicked()}
          >
            <img src={UpArrowImage} alt='icon' />
          </IconButton>
        </StyledBox>

        <FilesGridList
          files={[plan?.floorplan_image]}
          disabled={plan.updating}
          deleteImage={(file, index) => handleFloorplanPrimaryImageDelete(file)}
        />
      </Box>
    ) : plan?.floorplan_image && !state.floorPlanImagesExpanded ? (
      <ImagesListPreview
        images={[plan?.floorplan_image]}
        onClickExpand={() => floorPlanImagesExpandButtonClicked()}
      />
    ) : null;
  };

  const renderOtherImagesView = () => {
    return plan?.amenities?.length > 0 && state.otherImagesExpanded ? (
      <Box>
        <StyledBox>
          <Box />
          <IconButton
            sx={{ width: "2rem", height: "2rem" }}
            onClick={() => otherImagesExpandButtonClicked()}
          >
            <img src={UpArrowImage} alt='icon' />
          </IconButton>
        </StyledBox>

        <FilesListWithNameDescription
          files={plan?.amenities}
          disabled={plan.updating}
          isDetailAvailable={selfTourEnabled}
          onRetry={(file, index) => retryUploadFile(file, index, false)}
          onUpdate={(files) => {
            plan.amenities = files;
            update();
          }}
          deleteImage={(file, index) =>
            handleFloorplanAdditionalImageDelete(file, index)
          }
          onNameChange={(e, index) =>
            handleOptionalImageFileNameChange(e, index)
          }
          onDescriptionChange={(e, index) =>
            handleOptionalImageFileDescriptionChange(e, index)
          }
        />
      </Box>
    ) : plan?.amenities?.length > 0 && !state.otherImagesExpanded ? (
      <ImagesListPreview
        images={plan?.amenities}
        onClickExpand={() => otherImagesExpandButtonClicked()}
      />
    ) : null;
  };

  return (
    <Box>
      <Grid spacing={spacing} container paddingTop={index !== 0 && "0.6rem"}>
        <Grid item xs={12} md={4}>
          <StyledTitle>Floor Plan Name</StyledTitle>
        </Grid>
        <Grid item xs={12} md={4}>
          <Stack direction='row' spacing={spacing}>
            <StyledTitle>Floor Plan Images *</StyledTitle>
            <Link onClick={() => exampleButtonClicked(0)}>example</Link>
          </Stack>
        </Grid>
      </Grid>

      <Grid spacing={spacing} container sx={{ marginTop: "10px" }}>
        <Grid item xs={12} md={4}>
          <Stack direction='row' spacing={1}>
            {plan?.updating ? (
              <CircularProgress
                sx={{ marginRight: "12px", marginTop: "12px" }}
                size={20}
              />
            ) : (
              <Tooltip title='Remove Floor Plan'>
                <IconButton
                  size='small'
                  onClick={() => deleteFloorplanButtonClicked()}
                >
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            )}
            <TextField
              required
              label='Name'
              defaultValue={plan?.name ?? ""}
              onChange={handleFloorplanNameChange}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <ImageFileUploadContainer
            padding='0.5rem'
            helperText='.png or .jpg atleast 2000px'
            onFileDrop={handlePrimaryImage}
            maxFiles={1}
          />
          <Box paddingY='0.5rem'>{renderFloorPlanImagesView()}</Box>
        </Grid>
      </Grid>

      <Grid
        spacing={spacing}
        container
        sx={{ marginBottom: "2rem", marginTop: "0.5rem" }}
      >
        <Grid item xs={12} md={4}>
          <StyledTitle>Video or 3D tour link (Optional)</StyledTitle>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            label='Tour Link'
            maxLength={500}
            defaultValue={plan?.virtual_tour_url ?? ""}
            onChange={handleFloorplanTourUrlChange}
          />
        </Grid>
      </Grid>

      {renderFloorplanDescriptionView()}

      <Box sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
        <Stack direction='row' spacing={spacing} sx={{ marginBottom: "1rem" }}>
          <StyledTitle>Photography / Renderings</StyledTitle>
          <Link onClick={() => exampleButtonClicked(1)}>example</Link>
        </Stack>

        <ImageFileUploadContainer
          padding='0.5rem'
          helperText='.png or .jpg atleast 2000px'
          onFileDrop={handleAdditionalImages}
          maxFiles={15}
        />
        <Box padding='0.5rem'>{renderOtherImagesView()}</Box>
      </Box>

      <Divider sx={{ height: "2px", background: "#42a5f5" }} />

      <ImageViewer
        show={isGalleryOpen}
        photos={[exampleImageToShow]}
        onClose={() => setIsGalleryOpen(false)}
      />
    </Box>
  );
}

export default FloorPlanListItem;
