import React from "react";
import Box from "@mui/material/Box";
import { PoweredByImage } from "assests/images";

function Footer() {
  return (
    <Box sx={{ padding: "5vw" }}>
      <img src={PoweredByImage} alt='img' />
    </Box>
  );
}

export default Footer;
