import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "components/textfield";
import Typography from "@mui/material/Typography";

function OtherSystem({ credentials, onChange }) {
  const [other, setOther] = useState(credentials?.other ?? "");

  const handleTextFieldsChange = (e) => {
    const { name, value } = e.target;

    const credentials = {
      other: other,
    };

    if (name === "other") {
      setOther(value);
      credentials.other = value;
    }

    onChange(credentials);
  };

  return (
    <Box sx={{ paddingY: "1rem" }}>
      <Typography sx={{ paddingBottom: "0.9rem" }}>
        Provide details about property management system below.
      </Typography>
      <TextField
        multiline
        rows={6}
        name='other'
        value={other}
        onChange={handleTextFieldsChange}
      />
    </Box>
  );
}

export default OtherSystem;
