import MixConstants from "constants/mix-constants";
import Persistence from "./persistence";

const LoggedInUserHelper = {
  isLoggedIn,
  user,
  id,
  uuid,
  token,
  company,
  profilePicUrl,
  firstName,
  lastName,
  fullName,
  userName,
  email,
  isActive,
  isSuperAdmin,
  isClient,
};

/**
 *  Get user login status
 */

function isLoggedIn() {
  let token = Persistence.getAccessToken();

  return Boolean(token);
}

/**
 *  Get current user
 */

function user() {
  let currentUser = Persistence.getUserData();

  return currentUser ?? null;
}

/**
 *  Get current user id
 */

function id() {
  let currentUser = this.user();

  return currentUser?.id ?? null;
}

/**
 *  Get current user uuid
 */

function uuid() {
  let currentUser = this.user();

  return currentUser?.uuid ?? null;
}

/**
 *  Get current user token
 */

function token() {
  let token = Persistence.getAccessToken();

  return token;
}

/**
 *  Get current company
 */

function company() {
  let currentUser = this.user();

  return currentUser?.company_details ?? null;
}

/**
 *  Get user profile pic
 */

function profilePicUrl() {
  let currentUser = this.user();

  return currentUser?.profile?.profile_picture ?? null;
}

/**
 *  Get current user first name
 */

function firstName() {
  let currentUser = this.user();

  return currentUser?.first_name ?? "User";
}

/**
 *  Get current user last name
 */

function lastName() {
  let currentUser = this.user();

  return currentUser?.last_name ?? "Name";
}

/**
 *  Get current user full name
 */

function fullName() {
  let currentUser = this.user();

  let fName = this.firstName();
  let lName = this.lastName();

  return currentUser?.full_name ?? `${fName} ${lName}`;
}

/**
 *  Get current user username
 */

function userName() {
  let currentUser = this.user();

  return currentUser?.username ?? "";
}

/**
 *  Get current user email
 */

function email() {
  let currentUser = this.user();

  return currentUser?.email ?? "";
}

/**
 *  Is active user
 */

function isActive() {
  let currentUser = this.user();

  return currentUser?.is_active;
}

/**
 *  Is user super admin
 */

function isSuperAdmin() {
  const currentUser = this.user();

  return (
    currentUser?.role?.toLowerCase() ===
    MixConstants.Role.SUPER_ADMIN.toLowerCase()
  );
}

/**
 *  Is user client
 */

function isClient() {
  const currentUser = this.user();

  return (
    currentUser?.role?.toLowerCase() === MixConstants.Role.CLIENT.toLowerCase()
  );
}

export default LoggedInUserHelper;
