import React, { useCallback, useState, useEffect } from "react";
import { saveAs } from "file-saver";
import Grid from "@mui/material/Grid";
import Button from "components/button";
import Box from "@mui/material/Box";
import FormAlert from "components/form-alert";
import { FormApi } from "apis";
import Divider from "@mui/material/Divider";
import { useTheme } from "styled-components";
import ImageViewer from "components/image-viewer";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Container, StyledFooter, StyledSpinnerContainer } from "../style";
import FileGridItem from "components/files-grid-list/file-grid-item";
import ImageFileUploadContainer from "components/image-file-upload-container";
import EBroucherFormHeader from "./header";
import _ from "lodash";
import EbroucherWebLinkForm from "./ebroucher-weblink-form";
import { Typography } from "@mui/material";

function EBroucher({ expanded, checkStatus, status, closeForm }) {
  const theme = useTheme();
  const spacing = 2;
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [activePhotoIndex, setActivePhotoIndex] = useState(0);
  const [fetchingSpecs, setFetchingSpecs] = useState(false);
  const [message, setMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [saveAndFinish, setSaveAndFinish] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [ebrochure, setEbrochure] = useState({
    images: [],
    weblinks: [],
  })

  const formAlertCloseButtonClicked = () => {
    setMessage("");
    setMessageType("");
  };

  const setMessageText = (text, type) => {
    setMessage(text);
    setMessageType(type)
    setTimeout(() => {
      formAlertCloseButtonClicked();
    }, 5000);
  };

  const { communityId } = useParams();

  const handleAddNewWebLinkButtonClick = () => {
    let webLinksList = [...ebrochure.weblinks];
    if (webLinksList.length < 4) {
      const newWebLink = generateWebLinks();
      webLinksList.push(newWebLink);
      setEbrochure({
        ...ebrochure,
        weblinks: webLinksList,
      })
    } else {
      setMessageText("Maximum 4 weblinks are allowed", "error");
    }
  }

  const generateWebLinks = () => {
    return {
      id: _.uniqueId(),
      name: "",
      url: "",
      isLocal: true,
    }
  }

  const getMappedImagesForClient = (media) => {
    let images = [];
    if (media?.length > 0) {
      media.forEach((image) => {
        if (image?.image?.url) {
          images.push(
            {
              id: image?.id,
              photo: image?.image?.url,
              type: "image",
              isLocal: false,
            }
          )
        }
      })
    }
    return images;
  }

  const getMappedWeblinksForClient = (data) => {
    let weblinks = [];
    if (data?.length > 0) {
      data.forEach((weblink) => {
        weblinks.push({
          id: weblink?.id,
          name: weblink?.name,
          url: weblink?.url,
          isLocal: false,
        })
      })
    }
    return weblinks;
  }

  const getMappedEbrochureForClient = useCallback((data) => {
    if (data) {
      return {
        id: data?.id,
        images: getMappedImagesForClient(data?.favorite_images),
        weblinks: getMappedWeblinksForClient(data?.ebrochure_menu_buttons),
        isLocal: false,
      }
    }
  }, [])

  const fetchEbrochure = useCallback(() => {
    if (expanded) {
      if (communityId) {
        setFetchingSpecs(true);
        FormApi.fetchEbrochure(communityId).then((response) => {
          setFetchingSpecs(false);
          if (response.success) {
            let { data } = response;
            const getMappedData = getMappedEbrochureForClient(data);
            setEbrochure(getMappedData);
          }
        }, (error) => {
          setFetchingSpecs(false);
        })
      }
    }
  }, [expanded, communityId, getMappedEbrochureForClient])

  const viewFileButtonClicked = (index) => {
    setIsGalleryOpen(true);
    setActivePhotoIndex(index);
  };

  const removeFileButtonClicked = () => {
    if (ebrochure?.images[0].isLocal) {
      setEbrochure({
        ...ebrochure,
        images: [],
      });
    } else {
      if (communityId) {
        setSubmitting(true);
        const successMessage = "Ebrochure image deleted successfully";
        const errorMessage = "Failed to delete ebrochure image";
        FormApi.deleteEbrochureImage(communityId, ebrochure?.id, ebrochure?.images[0]?.id).then((response) => {
          setSubmitting(false);
          if (response.success) {
            setMessageText(successMessage, "success");
            const { data } = response;
            const getMappedData = getMappedEbrochureForClient(data);
            setEbrochure(getMappedData);
            checkStatus();
          }
        }, (error) => {
          setSubmitting(false);
          setMessageText(errorMessage, "error");
        });
      }
    }
  };

  const getMappedImagesForServer = () => {
    let images = [];
    if (ebrochure?.images?.length > 0) {
      ebrochure?.images.forEach((image) => {
        images.push({
          id: image?.isLocal ? null : image?.id,
          image: image?.isLocal ? image?.originalFile : null,
        })
      })
    }
    return images;
  }

  const getmappedWeblinksForServer = () => {
    let weblinks = [];
    if (ebrochure?.weblinks?.length > 0) {
      ebrochure?.weblinks?.forEach((weblink) => {
        weblinks.push({
          id: weblink?.isLocal ? null : weblink?.id,
          name: weblink?.name,
          url: weblink?.url
        })
      })
    }
    return weblinks;
  }

  const getMappedDataForServer = () => {
    return {
      id: ebrochure?.id,
      images: getMappedImagesForServer(),
      weblink: getmappedWeblinksForServer(),
    }
  }

  const sendRequestToBE = () => {
    let sendRequest = false;
    if (ebrochure?.weblinks.length > 0 || ebrochure?.images.length > 0) {
      sendRequest = true;
      return true;
    }
    return sendRequest;
  }

  const validFields = () => {
    let hasError = false;
    if (ebrochure?.weblinks.length > 0) {
      ebrochure?.weblinks.forEach((weblink) => {
        if (weblink?.name.trim() !== "" && weblink?.url.trim() === "") {
          setMessageText("Please add link before submission", "error");
          hasError = true;
          return false;
        }
        if (weblink?.name.trim() === "" && weblink?.url.trim() !== "") {
          setMessageText("Please add name before submission", "error");
          hasError = true;
          return false;
        }
      })
    }
    return !hasError;
  }

  const canUserProceed = () => {
    let hasError = false;
    if (ebrochure?.weblinks.length === 0 && ebrochure?.images.length === 0) {
      setMessageText("Please add image before submission", "error")
      hasError = true;
      return false;
    }
    if (ebrochure?.weblinks.length > 0) {
      ebrochure?.weblinks.forEach((weblink) => {
        if (weblink?.name.trim() === "") {
          setMessageText("Please add name before submission", "error");
          hasError = true;
          return false;
        }
        if (weblink?.url.trim() === "") {
          setMessageText("Please add link before submission", "error");
          hasError = true;
          return false;
        }
      })
    }
    return !hasError;
  }

  const onSaveAndSubmitButtonClicked = () => {
    if (sendRequestToBE()) {
      if (validFields()) {
        const data = getMappedDataForServer();
        if (communityId) {
          setSaveAndFinish(true);
          FormApi.updateEbrochure(communityId, ebrochure.id, data, "in_progress").then((response) => {
            setSaveAndFinish(false);
            if (response?.success) {
              const { data } = response;
              const getMappedData = getMappedEbrochureForClient(data);
              setEbrochure(getMappedData);
              checkStatus();
            }
          }, (error) => {
            setSaveAndFinish(false);
          })
          closeForm();
        }
      }
    } else {
      closeForm();
    }
  }

  const onSubmitButtonClicked = () => {
    if (canUserProceed()) {
      setSubmitting(true);
      const data = getMappedDataForServer();
      if (communityId) {
        const successMessage = "Ebrochure saved successfully";
        const errorMessage = "Failed to save Ebrochure";
        FormApi.updateEbrochure(communityId, ebrochure.id, data).then((response) => {
          setMessageText(successMessage, "success");
          setSubmitting(false);
          if (response?.success) {
            const { data } = response;
            const getMappedData = getMappedEbrochureForClient(data);
            setEbrochure(getMappedData);
            checkStatus();
          }
        }, (error) => {
          setSubmitting(false);
          setMessageText(errorMessage, "error");
        })
      }
    }
  }


  const handleDroppedFile = (files) => {
    if (files && files.length > 0) {
      setEbrochure({
        ...ebrochure,
        images: [files[0]],
      });
    }
  };

  useEffect(() => {
    let abortController = new AbortController();
    fetchEbrochure(abortController);
    return () => {
      abortController.abort();
    }
  }, [fetchEbrochure]);

  if (fetchingSpecs) {
    return (
      <StyledSpinnerContainer>
        <CircularProgress size={20} />
      </StyledSpinnerContainer>
    );
  }

  const handleChangeName = (e, index) => {
    const weblinks = [...ebrochure.weblinks]
    weblinks[index].name = e.target.value;
    setEbrochure({
      ...ebrochure,
      weblinks: weblinks,
    })
  }

  const handleChangeUrl = (e, index) => {
    const weblinks = [...ebrochure.weblinks];
    weblinks[index].url = e.target.value;
    setEbrochure({
      ...ebrochure,
      weblinks: weblinks,
    })
  }

  const onDeleteWeblink = (weblink) => {
    if (weblink?.isLocal) {
      const weblinks = [...ebrochure.weblinks];
      const index = _.findIndex(weblinks, weblink);
      weblinks.splice(index, 1);
      setEbrochure({
        ...ebrochure,
        weblinks: weblinks,
      })
    } else {
      if (communityId) {
        setSubmitting(true);
        const successMessage = "Weblink deleted successfully!";
        const errorMessage = "Failed to delete weblink!";
        FormApi.deleteEbrochureWeblink(communityId, ebrochure.id, weblink.id).then((response) => {
          setSubmitting(false);
          if (response.success) {
            setMessageText(successMessage, "success");
            const { data } = response;
            const getMappedData = getMappedEbrochureForClient(data);
            setEbrochure(getMappedData);
            checkStatus();
          }
        }, (error) => {
          setSubmitting(false);
          setMessageText(errorMessage, "error");
        })
      }
    }
  }

  return (
    <Container>
      <Grid
        container
        spacing={2}
        alignItems='center'
        marginTop='0rem'
        marginBottom='1rem'
      >

        <Grid item xs={12} md={ebrochure?.images?.length > 0 ? 10 : 12}>
          <Typography sx={{ mb: 0.5 }}>
            Optional: Upload a hero image here. This image will appear at the top of every ebrochure.
          </Typography>
          <ImageFileUploadContainer
            padding='1rem'
            helperText=''
            onFileDrop={handleDroppedFile}
            maxFiles={1}
          />
        </Grid>
        {ebrochure?.images.length > 0 && (
          <Grid item xs={12} md={2}>
            <FileGridItem
              file={ebrochure?.images[0]}
              onClickView={() => viewFileButtonClicked()}
              onClickDownload={() => saveAs(ebrochure.images[0].isLocal ? ebrochure?.images[0].originalFile : ebrochure?.images[0]?.photo, "ebrochure")}
              onClickRemove={() => removeFileButtonClicked()}
            />
          </Grid>
        )}

      </Grid>

      <Divider />
      <Grid
        container
        spacing={spacing}
        alignItems='center'
        marginTop='0rem'
        marginBottom='1rem'
      >
        <Grid item xs={12} md={12}>
          <EBroucherFormHeader onClick={handleAddNewWebLinkButtonClick} />
          <Divider />
          {ebrochure?.weblinks.map((webLink, index) => (
            <EbroucherWebLinkForm
              key={webLink?.id}
              weblink={webLink}
              index={index}
              updateName={handleChangeName}
              updateUrl={handleChangeUrl}
              onDelete={onDeleteWeblink}
            />
          ))}
        </Grid>
      </Grid>
      {message && messageType && (
        <Box>
          <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
          <FormAlert
            message={message}
            type={messageType}
            onClose={() => formAlertCloseButtonClicked()}
          />
        </Box>
      )}

      <StyledFooter>
        <Button
          bgColor={theme.colors.gray}
          titleColor={theme.colors.white}
          title='Save & Finish Later'
          loading={saveAndFinish}
          disabled={saveAndFinish}
          onClick={onSaveAndSubmitButtonClicked}
        />
        <Button
          bgColor={theme.colors.green}
          title='Submit'
          disabled={submitting}
          loading={submitting}
          onClick={onSubmitButtonClicked} />
      </StyledFooter>

      <ImageViewer
        show={isGalleryOpen}
        photos={ebrochure.images}
        activePhotoIndex={activePhotoIndex}
        onClose={() => setIsGalleryOpen(false)}
      />
    </Container>
  );
}

export default EBroucher;
