import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "components/textfield";
import Typography from "@mui/material/Typography";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function EntrataSystem({ credentials,company, community, onChange }) {
  const [url, setUrl] = useState(credentials?.url ?? "");
  const [username, setUsername] = useState(credentials?.username ?? "");
  const [password, setPassword] = useState(credentials?.password ?? "");
  const [propertyId, setPropertyId] = useState(credentials?.propertyId ?? "");
  const [showPassword, setShowPassword] = useState(false);

  const gridItemStyle = { paddingBottom: "1.3rem" };

  const handleTextFieldsChange = (e) => {
    const { name, value } = e.target;

    const credentials = {
      url: url,
      username: username,
      password: password,
      propertyId: propertyId,
    };

    if (name === "domain") {
      setUrl(value);
      credentials.url = value;
    } else if (name === "username") {
      setUsername(value);
      credentials.username = value;
    } else if (name === "password") {
      setPassword(value);
      credentials.password = value;
    } else if (name === "propertyId") {
      setPropertyId(value);
      credentials.propertyId = value;
    }

    onChange(credentials);
  };
    const useCompanyLevel = community?.community?.use_company_level_data_settings && company?.data_providers?.includes("psi")
  return (
    <Grid container paddingY='1rem'>
      <Grid item xs={3}></Grid>
      <Grid item xs={12} md={5} sx={gridItemStyle}>
          {useCompanyLevel ? (null) : (
        <TextField
          required
          name='domain'
          label='Domain'
          value={url}
          onChange={handleTextFieldsChange}
        />)}
      </Grid>
      <Grid
        item
        xs={3}
        sx={{ display: "flex", alignItems: "center", marginBottom: "1.3rem" }}
      >
          {useCompanyLevel ? (null) : (
        <Typography sx={{ marginLeft: "0.5rem" }}>
          .entrata.com/api/v1/propertyunits
        </Typography>)}
      </Grid>

      {/* ----------- */}

      <Grid item xs={3}></Grid>
      <Grid item xs={12} md={5} sx={gridItemStyle}>
          {useCompanyLevel ? (null) : (
        <TextField
          required
          name='username'
          label='Username'
          value={username}
          onChange={handleTextFieldsChange}
        />)}
      </Grid>
      <Grid item xs={3}></Grid>

      {/* ----------- */}

      <Grid item xs={3}></Grid>
      <Grid item xs={12} md={5} sx={gridItemStyle}>
          {useCompanyLevel ? (null) : (
        <TextField
          required
          name='password'
          label='Password'
          value={password}
          type={showPassword ? "text" : "password"}
          rightIcon={showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
          onClickRightIcon={() => setShowPassword(!showPassword)}
          onChange={handleTextFieldsChange}
        />)}
      </Grid>
      <Grid item xs={3}></Grid>

      {/* ----------- */}

      <Grid item xs={3}></Grid>
      <Grid item xs={12} md={5}>
        <TextField
          required
          name='propertyId'
          label='Property ID'
          value={propertyId}
          onChange={handleTextFieldsChange}
        />
      </Grid>
      <Grid item xs={3}></Grid>
    </Grid>
  );
}

export default EntrataSystem;
