import React from "react";
import Stack from "@mui/material/Stack";
import { useTheme } from "styled-components";
import {
  StyledProgressStep,
  StyledAvatar,
  StyledTitle,
  StyledStatus,
  StyledSubTitle,
} from "./style";
import {
  InProgressImage,
  SubmittedImage,
  ApprovedImage,
  RejectedImage,
  EmptyCircleImage,
} from "assests/images";
import MixConstants from "constants/mix-constants";
import Utils from "helpers/utils";
import Typography from "@mui/material/Typography";


function ProgressStep({ step, steps }) {
  const theme = useTheme();
  let propertyManagementStep = steps.find(step => step?.title === 'Connect Your Property Management System' );
  let avatar = "";
  let statusColor = theme.colors.primary;
  const validStatuses = ['', MixConstants.FormStatus.IN_PROGRESS, MixConstants.FormStatus.SUBMITTED, MixConstants.FormStatus.REJECTED];
  const lowerValidStatuses = validStatuses.map(status => status.toLowerCase());

  if (step?.avatar) {
    avatar = step.avatar;
  } else if (step?.status?.name === MixConstants.FormStatus.IN_PROGRESS) {
    avatar = <InProgressImage />;
  } else if (step?.status?.name === MixConstants.FormStatus.SUBMITTED) {
    avatar = <SubmittedImage />;
  } else if (step?.status?.name === MixConstants.FormStatus.APPLICATION_IN_REVIEW || step?.status?.name === MixConstants.FormStatus.APPROVED || step?.status?.name === MixConstants.FormStatus.APPLICATION_IN_PRODUCTION || step?.status?.name === MixConstants.FormStatus.RELEASED) {
    avatar = <ApprovedImage />;
    statusColor = theme.colors.green;
  } else if (step?.status?.name === MixConstants.FormStatus.ATTENTION_REQUIRED) {
    avatar = <RejectedImage />;
    statusColor = theme.colors.red;
  } else {
    avatar = <EmptyCircleImage />;
  }
  { if (step?.title === 'Floor Plan Images' &&  lowerValidStatuses.includes(propertyManagementStep?.status?.name?.toLowerCase()) ){
    avatar = <EmptyCircleImage />;
  }

  }
  return (
    <StyledProgressStep sx={{ my: -1 }}>
      <StyledAvatar
        alt={step?.status?.name}
        src={avatar.type ? avatar.type : avatar}
      />
      <Stack direction='column' spacing={0}>
        <Stack direction='row' spacing={1} alignItems='center' >
          <StyledTitle variant='h6'>{step?.title}</StyledTitle>
          { step?.title === 'Floor Plan Images' && (lowerValidStatuses.includes(propertyManagementStep?.status?.name?.toLowerCase()))  ? (
              <Typography style={{ color: theme.colors.actionRed, fontSize: '14px' }}>
                Images can be loaded after Property Management System is connected and approved
              </Typography>
          ) : (step?.status?.name && (<StyledStatus color={statusColor}>{Utils.inReviewToApproved(step?.status?.name)}</StyledStatus>))
          }
        </Stack>

        {step?.status.name === MixConstants.FormStatus.ATTENTION_REQUIRED ? <StyledSubTitle color={statusColor}>{step?.status?.remarks}</StyledSubTitle> : ""}

      </Stack>
    </StyledProgressStep>
  );
}

export default ProgressStep;
