import communityFilterConstants from "redux/constants/community-filter-constants";

const communityFilterActions = {
  updateProductList,
  updateStatusesList,
}

function updateProductList(values) {
  return { type: communityFilterConstants.UPDATE_PRODUCT_LIST, values };
}

function updateStatusesList(values) {
  return { type: communityFilterConstants.UPDATE_STATUSES_LIST, values };
}

export default communityFilterActions;