import React from "react";
import Button from "components/button";
import Stack from "@mui/material/Stack";

function AccordionActionButtons({ buttons, title, status, statusUpdate=false }) {
  const checkDisabled = (buttonTitle) => {
    if ((status === "Approved") && (buttonTitle === "APPROVED")){
      return true;
    } 
    if ((status === "Attention Required") && (buttonTitle === "APPROVE")){
      return true;
    }
  }
  const renderButtons = (buttons) => {
    return (
      buttons &&
      buttons.map((button) => {
        return (
          <Button
            key={button.id}
            bgColor={button.bgColor}
            titleColor={button.titleColor}
            title={button.title}
            disabled = {button.title === "Submit for Review" || button.title === "Release" || button.title === "Released"? button.disabled : checkDisabled(button.title)}
            startIcon={button.startIcon}
            endIcon={button.endIcon}
            onClick={() => button.onClick(title)}
            loading={statusUpdate}
          />
        )
      })
    );
  };

  return (
    <Stack direction='column' spacing={1}>
      {renderButtons(buttons)}
    </Stack>
  );
}

export default AccordionActionButtons;
