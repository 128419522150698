import { AuthApi } from "apis";
import messageActions from "./message-actions";
import loginConstants from "../constants/login-constants";

const loginActions = {
  login,
  clearLogin,
};

/**
 *  Login
 */

function login(email, password) {
  return (dispatch) => {
    dispatch(request({ email }));

    return AuthApi.login(email, password).then(
      (user) => {
        if(user.success){
          dispatch( success(user.user_details) );
        }
        else {
          dispatch( messageActions.setMessage( user.message ) );
          dispatch( failure(user.message) );
        }
      },
      (error) => {
        var errorString = "Failed to login";
        dispatch(
          messageActions.setMessage(
            error.message ? error.message : errorString,
          ),
        );
        dispatch(failure(errorString));
      },
    );
  };

  function request(user) {
    return { type: loginConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: loginConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: loginConstants.LOGIN_FAILURE, error };
  }
}

/**
 *  Clear Login
 */

function clearLogin() {
  return { type: loginConstants.LOGIN_CLEAR };
}

export default loginActions;
