import React from "react";
import { StyledTextField } from "./style";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

function TextField({
  variant = "outlined",
  size = "small",
  type = "text",
  label = "",
  placeholder = "",
  fontColor = "#362B28",
  name,
  disabled,
  required,
  value,
  defaultValue,
  maxLength,
  onChange,
  onKeyDown,
  width,
  fullWidth = true,
  multiline = false,
  autoFocus = false,
  rows = 1,
  error = false,
  helperText = "",
  leftIcon,
  rightIcon,
  onClickLeftIcon,
  onClickRightIcon,
}) {
  let maxTextLength = 0;

  if (maxLength) {
    maxTextLength = maxLength;
  } else if (multiline) {
    maxTextLength = 500;
  } else {
    maxTextLength = 200;
  }

  return (
    <StyledTextField
      variant={variant}
      size={size}
      type={type}
      label={label}
      placeholder={placeholder}
      name={name}
      disabled={disabled}
      required={required}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      onKeyDown={onKeyDown}
      width={width}
      fullWidth={width ? false : fullWidth}
      multiline={multiline}
      autoFocus={autoFocus}
      rows={rows}
      error={error}
      helperText={helperText}
      autoComplete='new-password'
      inputProps={{
        maxLength: maxTextLength,
        style: { color: fontColor },
      }}
      InputProps={{
        startAdornment: leftIcon && (
          <InputAdornment position='start'>
            <IconButton onClick={onClickLeftIcon} edge='start'>
              {leftIcon}
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: rightIcon && (
          <InputAdornment position='end'>
            <IconButton onClick={onClickRightIcon} edge='end'>
              {rightIcon}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default TextField;
