import _ from "lodash";
import { FormApi } from "apis";
import Box from "@mui/material/Box";
import Button from "components/button";
import Divider from "@mui/material/Divider";
import { useTheme } from "styled-components";
import HeaderSection from "./header-section";
import { useParams } from "react-router-dom";
import FormAlert from "components/form-alert";
import FloorPlansList from "./floor-plans-list";
import MixConstants from "constants/mix-constants";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useCallback, useEffect, useState } from "react";
import { Container, StyledFooter, StyledSpinnerContainer } from "../style";

function FloorPlansImagesForm({
  expanded,
  checkStatus,
  status,
  closeForm,
  selfTourEnabled,
  isMessage,
}) {
  const theme = useTheme();

  const [fetchingFloorplan, setFetchingFloorplan] = useState(false);
  const [message, setMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [saveAndFinsihLater, setSaveAndFinsihLater] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [floorPlans, setFloorPlans] = useState([]);

  const { communityId } = useParams();

  const formAlertCloseButtonClicked = () => {
    setMessage("");
    setMessageType("");
  };

  const getMappedFloorplans = useCallback((dataList) => {
    if (dataList && dataList.length > 0) {
      const mappedData = dataList.map((data) => {
        let amenityGalleries = data?.amenities;
        let floorplanImage = data?.floorplan_image;

        amenityGalleries = amenityGalleries.map((amenityGallery) => {
          return {
            ...amenityGallery,
            isLocal: false,
            photo: amenityGallery?.amenity_image?.url,
          };
        });

        if (floorplanImage) {
          floorplanImage = {
            isLocal: false,
            photo: data?.floorplan_image?.url,
          };
        }
        return {
          ...data,
          floorplan_image: floorplanImage,
          amenities: amenityGalleries,
        };
      });

      return mappedData;
    }

    return [];
  }, []);

  const canUserProceed = () => {
    if (floorPlans.length === 0) {
      setMessageText("Please add floorplans", "error");
      return false;
    }

    let emptyName = false;

    for (let floorplan of floorPlans) {
      if (
        floorplan?.floorPlanName?.trim() === "" ||
        floorplan.floorPlanName === null
      ) {
        emptyName = true;
        break;
      }
    }

    if (emptyName) {
      setMessageText("Please add floorplan name", "error");
      return false;
    }

    return true;
  };

  const setMessageText = (text, type) => {
    setMessage(text);
    setMessageType(type);
    setTimeout(() => {
      formAlertCloseButtonClicked();
    }, 5000);
  };

  const getMappedDataForServer = (status) => {
    let data = { status: status };
    if (floorPlans.length > 0) {
      data.floorplans = floorPlans.map((plan) => {
        return {
          id: plan?.id,
          name: plan?.name,
          description: plan?.description,
          virtual_tour_url: plan?.virtual_tour_url,
          amenities: plan?.amenities?.filter((g) => g.failed !== true),
        };
      });
    }
    return data;
  };

  const onSubmitButtonClicked = () => {
    if (canUserProceed()) {
      setSubmitting(true);
      const data = getMappedDataForServer("submitted");
      const successMessage = "Floorplan saved successfully";
      const errorMessage = "Failed to save floorplan";

      FormApi.saveFloorplans(communityId, data).then(
        (response) => {
          setSubmitting(false);
          if (response?.success) {
            let abortController = new AbortController();
            fetchFloorplans(abortController);

            setMessageText(successMessage, "success");
            checkStatus();
          }
        },
        (error) => {
          setSubmitting(false);
          setMessageText(errorMessage, "error");
        },
      );
    }
  };

  const onSaveAndSubmitButtonClicked = () => {
    if (canUserProceed()) {
      setSaveAndFinsihLater(true);
      const data = getMappedDataForServer("in_progress");

      FormApi.saveFloorplans(communityId, data, "in_progress").then(
        (response) => {
          setSaveAndFinsihLater(false);
          if (response?.success) {
            checkStatus();
            closeForm();
          }
        },
        (error) => {},
      );
      closeForm();
    }
  };

  const fetchFloorplans = useCallback(
    (abortController) => {
      if (expanded) {
        if (communityId) {
          setFetchingFloorplan(true);
          setFloorPlans([]);

          FormApi.fetchFloorplans(communityId).then(
            (response) => {
              const aborted = abortController.signal.aborted;
              if (aborted === false) {
                setFetchingFloorplan(false);
                if (response?.success) {
                  let fetchedFloorplan = getMappedFloorplans(response.data);
                  setFloorPlans(fetchedFloorplan);
                }
              }
            },
            (error) => {
              setFetchingFloorplan(false);
            },
          );
        }
      }
    },
    [communityId, expanded, getMappedFloorplans],
  );

  const addFloorPlanButtonClicked = () => {
    const newFloorPlan = {
      id: _.uniqueId(),
      floorPlanName: "",
      floorPlanTourUrl: "",
      floorPlanDescription: "",
      floorPlanImages: [],
      otherImages: [],
      isLocal: true,
    };
    let plans = [...floorPlans];
    plans.unshift(newFloorPlan);

    setFloorPlans(plans);
  };

  useEffect(() => {
    let abortController = new AbortController();
    fetchFloorplans(abortController);
    return () => {
      abortController.abort();
    };
  }, [fetchFloorplans]);

  // const handleUpdateFloorPlan = (plan, index) => {
  //   const plansList = [...floorPlans];
  //   plansList[index] = plan;
  //   setFloorPlans(plansList);
  // };

  const handleDeleteFloorPlan = (plan, index) => {
    if (plan) {
      const successMessage = "Floor plan deleted successfully";
      const errorMessage = "Failed to delete floor plan";

      setSubmitting(true);
      FormApi.deleteFloorplan(communityId, plan.id).then(
        (response) => {
          setSubmitting(false);
          const plansList = [...floorPlans];
          plansList[index].updating = false;

          if (response?.success) {
            plansList.splice(index, 1);
            setMessageText(successMessage, "success");
          } else {
            setMessageText(errorMessage, "error");
          }
          setFloorPlans(plansList);
        },
        (error) => {
          const plansList = [...floorPlans];
          plansList[index].updating = false;
          setFloorPlans(plansList);
          setSubmitting(false);
          setMessageText(errorMessage, "error");
        },
      );
    }
  };

  if (fetchingFloorplan) {
    return (
      <StyledSpinnerContainer>
        <CircularProgress size={20} />
      </StyledSpinnerContainer>
    );
  }

  return (
      <>
  {isMessage ? null : <>
    <Container>
      <HeaderSection onClickAddFloorPlan={() => addFloorPlanButtonClicked()} />

      <Divider />

      <FloorPlansList
        communityId={communityId}
        floorPlans={floorPlans}
        selfTourEnabled={selfTourEnabled}
        onDelete={(plan, index) => handleDeleteFloorPlan(plan, index)}
        // onUpdate={(plan, index) => handleUpdateFloorPlan(plan, index)}
      />

      {message && messageType && (
        <Box>
          <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
          <FormAlert
            message={message}
            type={messageType}
            onClose={() => formAlertCloseButtonClicked()}
          />
        </Box>
      )}

      <StyledFooter>
        <Button
          bgColor={theme.colors.gray}
          titleColor={theme.colors.white}
          title='Save & Finish Later'
          disabled={saveAndFinsihLater}
          loading={saveAndFinsihLater}
          onClick={onSaveAndSubmitButtonClicked}
        />
        <Button
          bgColor={theme.colors.green}
          disabled={
            status !== MixConstants.FormStatus.DEPLOYED ? submitting : true
          }
          loading={submitting}
          title='Submit'
          onClick={() => onSubmitButtonClicked()}
        />
      </StyledFooter>
      {/* <FileUploadLoaderModal openModal={loading} /> */}
    </Container>
  </>
  }
    </>
  );
}

export default FloorPlansImagesForm;
