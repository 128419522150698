import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { EmptyCircleImage, RejectedImage } from "assests/images";
import { useTheme } from "styled-components";
import Typography from "@mui/material/Typography";
import MixConstants from "constants/mix-constants";
import { StyledBox, TitleAndStatusBox } from "./style";
import AccordionArrowButton from "../accordion-arrow-button";
import AccordionActionButtons from "../accordion-action-buttons";
import {
  StyledAvatar,
  StyledStatus,
  StyledAccordionHeader,
  StyledAccordionHeaderContent,
} from "../style";
import Utils from "helpers/utils";

function AccordionFormHeader({
  avatar = EmptyCircleImage,
  title,
  status,
  subTitle,
  expanded,
  onClickExpand,
  actionButtons,
  rejectMessage,
  floorplanalert,
  statusUpdate,
  floorPlanAvatar = EmptyCircleImage,
}) {
  const theme = useTheme();
  status = floorplanalert ? "Attention Required" : status;

  let colors = "";
  if (
    status === "Approved" ||
    status === MixConstants.FormStatus.APPLICATION_IN_REVIEW ||
    status === "Form Approved" ||
    status === "Released"
  ) {
    colors = theme.colors.green;
  } else if (status === "Attention Required") {
    colors = theme.colors.red;
  }
  return (
    <Box>
      <StyledAccordionHeader>
        <Box sx={{ flexGrow: 1 }}>
          <StyledAccordionHeaderContent>
            <StyledAvatar
              alt="status icon"
              src={floorplanalert ? floorPlanAvatar : avatar}
            />
            <StyledBox>
              <TitleAndStatusBox>
                <Typography
                  variant="h6"
                  fontSize={17}
                  sx={{ textAlign: "center" }}
                >
                  {title}
                </Typography>
                {floorplanalert
                  ? null
                  : status && (
                      <StyledStatus
                        variant="subtitle2"
                        style={{ color: colors }}
                      >
                        {Utils.inReviewToApproved(status)}
                      </StyledStatus>
                    )}
              </TitleAndStatusBox>
              {status === MixConstants.FormStatus.ATTENTION_REQUIRED
                ? rejectMessage && (
                    <Typography style={{ color: theme.colors.actionRed }}>
                      {rejectMessage}
                    </Typography>
                  )
                : ""}
              {!rejectMessage && <Typography>{subTitle}</Typography>}
              {floorplanalert && (
                <Typography style={{ color: theme.colors.actionRed }}>
                  {floorplanalert}
                </Typography>
              )}
            </StyledBox>
          </StyledAccordionHeaderContent>
        </Box>
        <AccordionActionButtons
          buttons={actionButtons}
          title={title}
          status={status}
          statusUpdate={statusUpdate}
        />
        {floorplanalert ? null : title === "Your Company Details" ? null : (
          <AccordionArrowButton expanded={expanded} onClick={onClickExpand} />
        )}
      </StyledAccordionHeader>
      {expanded && <Divider variant="middle" />}
    </Box>
  );
}

export default AccordionFormHeader;
