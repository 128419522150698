import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  header {
      font-family: 'Raleway', sans-serif;
      font-size: 16px;
  }

  body {
    background: ${({ theme }) => theme.colors.white};
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    margin: 0;
  }

  p, label, text, button, span, h1, h2, h3, h4, h5, h6 {
    font-family: 'Raleway', sans-serif !important;
  }

  img {
    max-width: 100%;
  }

  a {
    cursor: pointer;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.blue};
  }

  button {
    cursor: pointer;
    border: none;

    :hover {
      opacity: 0.8;
    }
  }

  .MuiInputLabel-asterisk {
    color: red;
  }
`;

export default GlobalStyles;
