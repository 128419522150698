import React, { useState } from "react";
import Accordion from "components/accordion";
import { useTheme } from "styled-components";
import ProgressStepsList from "components/progress-steps-list";
import AccordionFormProgressHeader from "components/accordion/accordion-form-progress-header";

function CommunityAccordion({ community }) {
  const theme = useTheme();

  let [state, setState] = useState({
    accordionExpanded: false,
  });

  const handleAccordionChange = () => {
    setState({
      ...state,
      accordionExpanded: !state.accordionExpanded,
    });
  };

  const checkRejectStatus = () => {
    let statuses = community.progressSteps.map((form) => {
      return form.status?.name
    });
    if (statuses.includes("Attention Required")){
      return true
    }
    return false
  }

  return (
    <Accordion
      expanded={state.accordionExpanded}
      header={
        <AccordionFormProgressHeader
          expanded={state.accordionExpanded}
          onClickExpand={handleAccordionChange}
          firstPercentageTitle={"Approved"}
          firstPercentage={community?.approvedPercentage}
          firstPercentageColor={theme.colors.green}
          secondPercentageTitle={"Submitted"}
          secondPercentage={community?.submittedPercentage}
          secondPercentageColor={theme.colors.yellow}
          title={community?.title}
          subTitle1Name='Invitation Date: '
          subTitle1Value={community?.invitationDate}
          subTitle2Name='Products: '
          subTitle2Value={community?.products}
          showCancelIcon={checkRejectStatus()}
          // status={community?.status}
          actionButtons={community?.actionButtons}
          iconActionButtons={community?.iconActionButtons}
          productionStarted={community?.productionStarted}
          releaseDate={community?.releaseDate}
          submittedFinalApproval={community?.submittedForFinalApproval}
        />
      }
    >
      <ProgressStepsList steps={community?.progressSteps} />
    </Accordion>
  );
}

export default CommunityAccordion;
