import React from "react";
import Box from "@mui/material/Box";
import FloorPlanListItem from "./floor-plan-list-item";

function FloorPlansList({
  communityId,
  floorPlans,
  onDelete,
  selfTourEnabled,
}) {
  const renderFloorPlans = (plans) => {
    return (
      plans &&
      plans.map((plan, index) => {
        return (
          <Box key={plan.id} sx={{ marginTop: "1rem" }}>
            <FloorPlanListItem
              communityId={communityId}
              index={index}
              plan={plan}
              onDelete={onDelete}
              selfTourEnabled={selfTourEnabled}
            />
          </Box>
        );
      })
    );
  };
  return <Box>{renderFloorPlans(floorPlans)}</Box>;
}

export default FloorPlansList;
