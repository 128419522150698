import React from 'react';
import Dialog from '@mui/material/Dialog';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import DoneAllSharpIcon from '@mui/icons-material/DoneAllSharp';


function ConfirmModal({text, onClose, open=true}) {
  setTimeout(() => {
    onClose();
  }, 2000);

  return (
    <Dialog onClose={onClose} open={open}>
      <Grid container justifyContent="center" sx={{width: 500, py:3 }}>
        <Grid item >
          <Typography>
            {text}
          </Typography>
        </Grid>
        <Grid item sx={{px:3}} >
        <DoneAllSharpIcon color="success"/>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default ConfirmModal;
<DoneAllSharpIcon color/>