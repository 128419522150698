import Box from "@mui/material/Box";
import styled from "styled-components";
import Container from "@mui/material/Container";

export const PageMainContainer = styled(Container)`
  min-height: calc(100vh - 16rem);
`;

export const CenteredBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
