import Box from "@mui/material/Box";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

export const StyledCommentView = styled(Box)`
  display: flex;
  padding-bottom: 1rem;
`;

export const StyledText = styled(Typography)`
  font-size: 0.9rem;
  font-weight: ${({ bold }) => (bold === "true" ? 600 : 400)};
`;

export const TimeText = styled(Typography)`
  color: ${({ theme }) => theme.colors.darkGray};
  margin-left: 0.5rem;
  font-size: 0.75rem;
  font-weight: ${({ bold }) => (bold === "true" ? 600 : 500)};
`;
