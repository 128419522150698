import Box from "@mui/material/Box";
import styled from "styled-components";
import { device } from "helpers/device";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

export const StyledAvatar = styled(Avatar)`
  width: 3.5rem;
  height: 3.5rem;
  object-fit: contain;
  border: ${({ theme }) => `1px solid ${theme.colors.lightGray}`};
`;

export const StyledName = styled(Typography)`
  max-width: 11rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  // @media ${device.desktop} {
  //   display: none;
  // }
`;

export const VerticalStyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center ;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
`;

export const StyledBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
`;
