import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "components/textfield";
import Typography from "@mui/material/Typography";

function RentManagerSystem({ credentials, company, community, onChange }) {
  const [rentmanagerUsername, setRentmanagerUsername] = useState(
    credentials?.rentmanagerUsername ?? ""
  );
  const [rentmanagerPassword, setRentmanagerPassword] = useState(
    credentials?.rentmanagerPassword ?? ""
  );
  const [rentmanagerPropertyId, setRentmanagerPropertyId] = useState(
    credentials?.rentmanagerPropertyId ?? ""
  );
  const [rentmanagerBaseUrl, setRentmanagerBaseUrl] = useState(
    credentials?.rentmanagerBaseUrl ?? ""
  );

  const handleTextFieldsChange = (e) => {
    const { name, value } = e.target;

    const credentials = {
      rentmanagerUsername: rentmanagerUsername,
      rentmanagerPassword: rentmanagerPassword,
      rentmanagerPropertyId: rentmanagerPropertyId,
      rentmanagerBaseUrl: rentmanagerBaseUrl,
    };

    if (name === "rentmanagerUsername") {
      setRentmanagerUsername(value);
      credentials.rentmanagerUsername = value;
    } else if (name === "rentmanagerPassword") {
      setRentmanagerPassword(value);
      credentials.rentmanagerPassword = value;
    } else if (name === "rentmanagerPropertyId") {
      setRentmanagerPropertyId(value);
      credentials.rentmanagerPropertyId = value;
    } else if (name === "rentmanagerBaseUrl") {
      setRentmanagerBaseUrl(value);
      credentials.rentmanagerBaseUrl = value;
    }

    onChange(credentials);
  };

  useEffect(() => {}, [
    rentmanagerUsername,
    rentmanagerPassword,
    rentmanagerPropertyId,
    rentmanagerBaseUrl,
  ]);

  return (
    <Box sx={{ paddingY: "1rem" }}>
      <Grid container>
        <Grid item xs={3}></Grid>
        <Grid item xs={12} md={5} sx={{ marginBottom: "1.3rem" }}>
          <TextField
            required
            name="rentmanagerBaseUrl"
            label="Domain"
            value={rentmanagerBaseUrl}
            onChange={handleTextFieldsChange}
          />
        </Grid>
        <Grid
          item
          xs={3}
          sx={{ display: "flex", alignItems: "center", marginBottom: "1.3rem" }}
        >
          <Typography sx={{ marginLeft: "0.5rem" }}>
            .api.rentmanager.com
          </Typography>
        </Grid>

        {/* ----------- */}

        <Grid item xs={3}></Grid>
        <Grid item xs={12} md={5} sx={{ marginBottom: "1.3rem" }}>
          <TextField
            required
            name="rentmanagerUsername"
            label="Username"
            value={rentmanagerUsername}
            onChange={handleTextFieldsChange}
          />
        </Grid>
        <Grid item xs={3}></Grid>

        {/* ----------- */}

        <Grid item xs={3}></Grid>
        <Grid item xs={12} md={5} sx={{ marginBottom: "1.3rem" }}>
          <TextField
            required
            name="rentmanagerPassword"
            label="Password"
            value={rentmanagerPassword}
            onChange={handleTextFieldsChange}
          />
        </Grid>
        <Grid item xs={3}></Grid>

        {/* ----------- */}

        <Grid item xs={3}></Grid>
        <Grid item xs={12} md={5}>
          <TextField
            required
            name="rentmanagerPropertyId"
            label="Property Code"
            value={rentmanagerPropertyId}
            onChange={handleTextFieldsChange}
          />
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
    </Box>
  );
}

export default RentManagerSystem;
