import React, { useCallback } from "react";
import _ from "lodash";
import FileDropZone from "components/file-drop-zone";

function VideoFileUploadContainer({
  helperText = ".mp4 file only",
  padding,
  onFileDrop,
  maxFiles = 0,
  disabled,
}) {
  const onDrop = useCallback(
    (files) => {
      let filesList = files.map((file, index) => {
        const videoUrl = URL.createObjectURL(file);
        return {
          id: _.uniqueId(),
          type: "image",
          photo: videoUrl,
          number: index + 1,
          name: file?.name,
          caption: file?.name,
          subcaption: "",
          thumbnail: videoUrl,
          originalFile: file,
          isLocal: true
        };
      });

      onFileDrop && onFileDrop(filesList);
    },
    [onFileDrop],
  );

  return (
    <FileDropZone
      accept='video/mp4'
      helperText={helperText}
      padding={padding}
      onFileDrop={onDrop}
      maxFiles = {maxFiles}
      disabled = {disabled}
    />
  );
}

export default VideoFileUploadContainer;
