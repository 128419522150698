import messageConstants from "../constants/message-constants";

const initialState = { message: "" };

export default function messageReducer(state = initialState, action) {
  switch (action.type) {
    case messageConstants.SET_MESSAGE:
      return {
        message: action.message,
      };
    case messageConstants.CLEAR_MESSAGE:
      return initialState;
    default:
      return state;
  }
}
