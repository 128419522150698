import React, { useCallback } from "react";
import _ from "lodash";
import FileDropZone from "components/file-drop-zone";

function ImageVideoFileUploadContainer({
  helperText = ".png or .jpg atleast 2000px or .mp4 file",
  padding,
  onFileDrop,
  disabled,
  disableErrorMessage,
}) {
  const onDrop = useCallback(
    (files) => {
      let images = [];
      let videos = [];

      files.forEach((file, index) => {
        if (file.type === "video/mp4") {
          const videoUrl = URL.createObjectURL(file);
          const video = {
            id: _.uniqueId(),
            type: "video",
            photo: videoUrl,
            number: index + 1,
            name: file?.name,
            caption: file?.name,
            isLocal: true,
            subcaption: "",
            thumbnail: videoUrl,
            originalFile: file,
          };
          videos.push(video);
        } else {
          const imageUrl = URL.createObjectURL(file);
          const image = {
            id: _.uniqueId(),
            type: "image",
            photo: imageUrl,
            number: index + 1,
            name: file?.name,
            isLocal: true,
            caption: file?.name,
            subcaption: "",
            thumbnail: imageUrl,
            originalFile: file,
          };
          images.push(image);
        }
      });

      onFileDrop && onFileDrop(images, videos);
    },
    [onFileDrop],
  );

  return (
    <FileDropZone
      accept='image/jpeg, image/png, video/mp4'
      helperText={helperText}
      padding={padding}
      onFileDrop={onDrop}
      disabled={disabled}
      disableErrorMessage={disableErrorMessage}
    />
  );
}

export default ImageVideoFileUploadContainer;
