import React from 'react'
import ContentEditable from 'react-contenteditable'

class TextEditor extends React.Component {
  constructor(props) {
    super(props);
    this.contentEditable = React.createRef();
    this.state = {html: this.props.html};
  };


  handleChange = evt => {
    this.props.onChange(evt.target.value);
  };

  render = () => {
    const styles = {
        border: '1px solid rgba(0, 0, 0, 0.2)', 
        borderRadius: '6px',
        padding: '10px',
        minHeight: '200px'
    };
    return <ContentEditable
              innerRef={this.contentEditable}
              html={this.props.html} // innerHTML of the editable div
              disabled={false}       // use true to disable editing
              onChange={this.handleChange} // handle innerHTML change
              tagName='article' // Use a custom HTML tag (uses a div by default)
              style={styles}
            />
  };
};


export default TextEditor;
