import React, { useState } from "react";
import { FormApi } from "apis";
import Box from "@mui/material/Box";
import Modal from "components/modal";
import { Divider } from "@mui/material";
import { useTheme } from "styled-components";
import FormAlert from "components/form-alert";
import { useParams } from "react-router-dom";
import { Typography } from '@mui/material';


function DeleteSecureLockConfirmationModal({
  open = true,
  title = "Delete Credentials",
  onClose,
  lock,
  onAccept,
  checkStatus,
}) {
  const [submittingReport, setSubmittingReport] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [message, setMessage] = useState("");
  const theme = useTheme();

  const { communityId } = useParams();
  const changeModalMessage = `After accepting your credentials will be deleted. Are you sure to continue?`;
  const actionButtons = [
    {
      id: 1,
      title: "Yes",
      bgColor: theme.colors.green,
      titleColor: theme.colors.white,
      loading: submittingReport,
      disabled: submittingReport,
      onClick: () => submitButtonClicked(),
    },
    {
      id: 2,
      title: "No",
      bgColor: theme.colors.lightGray,
      titleColor: theme.colors.white,
      disabled: submittingReport,
      onClick: onClose,
    },
  ];

  const submitButtonClicked = () => {
    formAlertCloseButtonClicked();
    setSubmittingReport(true);

    if (communityId && lock) {
    const errorMessage = "failed to delete secure credentials";

    FormApi.deleteSecureLock(
      communityId, lock.type, lock.id
      ).then(
        (response) => {
          setSubmittingReport(false);
          if (response?.success) {
            const {data} = response;
            onAccept(data, lock.clientType, lock.lockType);
            checkStatus();
          } else {
            setMessageText(errorMessage, "error");
          }
        },
        (error) => {
          setSubmittingReport(false);
          setMessageText(errorMessage, "error");
        },
      );
    }
  };

  const setMessageText = (text, type) => {
    setMessage(text);
    setMessageType(type);

    setTimeout(() => {
      formAlertCloseButtonClicked();
    }, 5000);
  };

  const formAlertCloseButtonClicked = () => {
      setMessage("");
  };

  return (
    <Modal
      open={open}
      title={title}
      onClose={submittingReport ? null : onClose}
      footerButtons={actionButtons}
    >
      <Box sx={{ paddingTop: "1rem" }}>
        <Typography variant="body1">{changeModalMessage}</Typography>
      </Box>
      {message && messageType && (
        <Box>
          <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
          <FormAlert
            message={message}
            type={messageType}
            onClose={() => formAlertCloseButtonClicked()}
          />
        </Box>
      )}
    </Modal>
  );
}

export default DeleteSecureLockConfirmationModal;