import React, { useState } from "react";
import _ from "lodash";
import axios from "axios";
import { FormApi } from "apis";
import config from "apis/api-config";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import TextField from "components/textfield";
import { authHeader } from "helpers/api-header";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import FilesGridList from "components/files-grid-list";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircularProgress from "@mui/material/CircularProgress";
import ImageVideoFileUploadContainer from "components/image-video-file-upload-container";

function GalleryListItem({
  index,
  gallery,
  communityId,
  disabled,
  onDelete,
  onUpdate,
  onDeleteImage,
  onNameUpdate,
  onUploadAllFiles,
}) {
  const [, setUpdate] = useState(false);
  const [galleryName, setGalleryName] = useState(gallery?.name ?? "");
  const [updatingGalleryName, setUpdatingGalleryName] = useState(false);

  var galleryToUpload = gallery;
  var filesToUpload = _.union(gallery?.images ?? [], gallery?.videos ?? []);

  const handleGalleryNameChange = (e) => {
    setGalleryName(e.target.value);
  };

  const handleGalleryImageDelete = (image, index) => {
    onDeleteImage(gallery, image, index);
  };

  const handleGalleryDroppedFiles = (images, videos) => {
    const previousImagesList = galleryToUpload?.images
      ? galleryToUpload.images
      : [];
    const previousVideosList = galleryToUpload?.videos
      ? galleryToUpload.videos
      : [];

    const newImages = images.map((image) => {
      const file = Object.assign(image, {
        uploading: true,
        uploadedOn: new Date(),
        progress: 0.0,
        uploaded: false,
        failed: false,
        failedErrorMessage: "",
        fulfilled: false,
      });

      return file;
    });
    const newVideos = videos.map((video) => {
      const file = Object.assign(video, {
        uploading: true,
        uploadedOn: new Date(),
        progress: 0.0,
        uploaded: false,
        failed: false,
        failedErrorMessage: "",
        fulfilled: false,
      });

      return file;
    });

    const imagesList = _.union(previousImagesList, newImages);
    const videosList = _.union(previousVideosList, newVideos);
    const filesList = _.union(imagesList, videosList);

    galleryToUpload.images = imagesList;
    galleryToUpload.videos = videosList;
    filesToUpload = filesList;

    setTimeout(() => {
      for (let i = 0; i < filesList.length; i++) {
        const file = filesList[i];
        if (galleryToUpload && galleryToUpload.id && file.originalFile) {
          uploadFile(file, i);
        }
      }
    }, 500);
  };

  const handleGalleryImagesListUpdate = (updatedFilesList) => {
    if (updatedFilesList.length === 0) {
      gallery.images = [];
      gallery.videos = [];
    } else {
      const images = updatedFilesList.filter((file) => file.type === "image");
      const videos = updatedFilesList.filter((file) => file.type === "video");
      gallery.images = images;
      gallery.videos = videos;
    }
    onUpdate(gallery);
  };

  const updateFileToUploadObject = (fileObj, uploadedFile, arrayIndex) => {
    const index = filesToUpload.findIndex((file) => file.id === fileObj.id);
    if (index >= 0) {
      filesToUpload[index] = fileObj;
      if (fileObj.uploaded && uploadedFile) {
        filesToUpload[arrayIndex] = uploadedFile;
        filesToUpload[arrayIndex].uploaded = true;
        filesToUpload[arrayIndex].photo = uploadedFile?.file?.url;
        filesToUpload[arrayIndex].isLocal = false;

        if (areAllFilesFulFilledAndUploaded(filesToUpload)) {
          onUploadAllFiles();
        }
      }
      setUpdate((prevState) => ({
        update: !prevState.update,
      }));
    }
  };

  const areAllFilesFulFilledAndUploaded = (files) => {
    const notYetFulfilled = files.filter((file) => file.fulfilled === false);
    if (notYetFulfilled.length === 0) {
      const uploadedFiles = files.filter((file) => file.uploaded === true);
      return uploadedFiles.length > 0;
    }
    return false;
  };

  const updateUploadedFileInGalleryMedia = (uploadedFile, arrayIndex) => {
    const isImage = uploadedFile.file_type === "image";

    if (isImage) {
      const imagesList = galleryToUpload.images;
      imagesList[arrayIndex] = uploadedFile;
      imagesList[arrayIndex].isLocal = false;
      imagesList[arrayIndex].photo = uploadedFile?.file?.url;
      galleryToUpload.images = imagesList;
    } else {
      const videosList = galleryToUpload.videos;
      videosList[arrayIndex] = uploadedFile;
      videosList[arrayIndex].isLocal = false;
      videosList[arrayIndex].photo = uploadedFile?.file?.url;
      galleryToUpload.videos = videosList;
    }
    setUpdate((prevState) => ({
      update: !prevState.update,
    }));
  };

  const uploadFile = (fileObj, arrayIndex) => {
    const header = Object.assign(authHeader(), {
      accept: "application/json",
      "Content-Type": "application/json",
    });
    const formData = new FormData();
    formData.append("id", gallery.id);
    formData.append("file", fileObj.originalFile, fileObj.originalFile.name);

    axios({
      baseURL: `${config.baseURL}`,
      url: `/api/v2/communities/${communityId}/upload_gallery_image`,
      headers: header,
      method: "post",
      data: formData,
      onUploadProgress: (progress) => {
        const { loaded, total } = progress;
        const percentageProgress = ((loaded / total) * 100).toFixed(2);
        fileObj.progress = percentageProgress;
        updateFileToUploadObject(fileObj);
      },
    })
      .then(function (response) {
        fileObj.fulfilled = true;

        if (response && response.error) {
          if (fileObj.progress === "100.00") {
            fileObj.failed = false;
            fileObj.uploaded = true;
            fileObj.uploading = false;
          } else {
            fileObj.failedErrorMessage = response.error;
            fileObj.uploading = false;
            fileObj.failed = true;
          }
        }
        if (response && response.data) {
          fileObj.uploaded = true;
          fileObj.uploading = false;

          updateFileToUploadObject(fileObj, response.data, arrayIndex);
          updateUploadedFileInGalleryMedia(response.data, arrayIndex);
        } else {
          if (fileObj.progress === "100.00") {
            fileObj.failed = false;
            fileObj.uploaded = true;
            fileObj.uploading = false;
          }
        }
        updateFileToUploadObject(fileObj);
      })
      .catch(function (error) {
        fileObj.fulfilled = true;
        fileObj.uploading = false;

        if (fileObj.progress === "100.00") {
          fileObj.failed = true;
          fileObj.uploaded = true;
        } else {
          fileObj.failedErrorMessage = error;
          fileObj.failed = true;
        }
        updateFileToUploadObject(fileObj);
      });
  };

  const updateGalleryNameButtonClicked = () => {
    if (communityId) {
      setUpdatingGalleryName(true);

      const successMessage = "Gallery name updated successfully";
      const errorMessage = "Failed to update gallery name";

      FormApi.updateGalleryName(gallery.id, galleryName, communityId).then(
        (response) => {
          setUpdatingGalleryName(false);
          if (response?.success) {
            gallery.name = galleryName;
            onNameUpdate(gallery);

            alert(response?.message ?? successMessage);
          } else {
            alert(response?.message ?? errorMessage);
          }
        },
        (error) => {
          setUpdatingGalleryName(false);
          alert(errorMessage);
        },
      );
    }
  };

  return (
    <Box>
      <Grid spacing={2} container alignItems='center' paddingY='0.4rem'>
        <Grid item xs={12} md={3}>
          <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
            {index > 1 && !disabled && (
              <Tooltip title='Remove Gallery'>
                <IconButton
                  size='small'
                  onClick={() => onDelete(gallery)}
                  disabled={gallery?.fetchingMedia || updatingGalleryName}
                >
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            )}
            <TextField
              required
              label='Name'
              disabled={
                gallery?.fetchingMedia ||
                updatingGalleryName ||
                disabled ||
                index < 2
              }
              value={galleryName}
              onChange={handleGalleryNameChange}
            />
            {index > 1 &&
              (updatingGalleryName ? (
                <CircularProgress size={20} />
              ) : galleryName !== gallery.name ? (
                <Tooltip title='Update Gallery Name'>
                  <IconButton
                    size='small'
                    onClick={() => updateGalleryNameButtonClicked()}
                  >
                    <CheckCircleIcon fontSize='small' />
                  </IconButton>
                </Tooltip>
              ) : null)}
          </Stack>
        </Grid>
        <Grid item xs={12} md={3}>
          <ImageVideoFileUploadContainer
            padding='0.5rem'
            onFileDrop={handleGalleryDroppedFiles}
            disabled={
              gallery?.fetchingMedia || disabled || gallery?.name === ""
                ? true
                : false
            }
            disableErrorMessage='Please add gallery name first.'
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {gallery?.fetchingMedia ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CircularProgress size={20} sx={{ marginRight: "5px" }} />
              <span>Fetching media...</span>
            </Box>
          ) : filesToUpload.length > 0 ? (
            <FilesGridList
              disabled={disabled}
              files={filesToUpload}
              onUpdate={(updatedFilesList) =>
                handleGalleryImagesListUpdate(updatedFilesList)
              }
              deleteImage={handleGalleryImageDelete}
            />
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
}

export default GalleryListItem;
