import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "components/textfield";

function FunnelCRM({ credentials, onChange }) {
  const [apiKey, setApiKey] = useState(credentials?.apiKey ?? "");
  const [communityId, setCommunityId] = useState(
    credentials?.communityId ?? "",
  );

  const gridItemStyle = { paddingBottom: "1.3rem" };

  const handleTextFieldsChange = (e) => {
    const { name, value } = e.target;

    const credentials = {
      apiKey: apiKey,
      communityId: communityId,
    };

    if (name === "apiKey") {
      setApiKey(value);
      credentials.apiKey = value;
    } else if (name === "communityId") {
      setCommunityId(value);
      credentials.communityId = value;
    }

    onChange(credentials);
  };

  return (
    <Grid container paddingBottom='1rem'>
      <Grid item xs={3}></Grid>
      <Grid item xs={12} md={6} sx={gridItemStyle}>
        <TextField
          required
          name='apiKey'
          label='API Key'
          value={apiKey}
          onChange={handleTextFieldsChange}
        />
      </Grid>
      <Grid item xs={3}></Grid>

      {/* ----------- */}

      <Grid item xs={3}></Grid>
      <Grid item xs={12} md={6} sx={gridItemStyle}>
        <TextField
          required
          name='communityId'
          label='Community ID'
          value={communityId}
          onChange={handleTextFieldsChange}
        />
      </Grid>
      <Grid item xs={3}></Grid>
    </Grid>
  );
}

export default FunnelCRM;
